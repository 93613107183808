import { render, staticRenderFns } from "./product-info.vue?vue&type=template&id=286da204&scoped=true&"
import script from "./product-info.vue?vue&type=script&lang=js&"
export * from "./product-info.vue?vue&type=script&lang=js&"
import style0 from "./product-info.vue?vue&type=style&index=0&id=286da204&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "286da204",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})
