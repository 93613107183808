import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class newAiPricing extends ServiceBase {
    async getHeaderPricing(idEnterprisePricesProjection) {
        return await axiosInstance.get(API_URL + "api/v2/Enterprise/Pricing/HeaderFujioka", {
            params: {
                idEnterprisePricesProjection: idEnterprisePricesProjection
            }
        })
    }
    async getErpList(idEnterprisePriceGroup) {
        return await axiosInstance.get(API_URL + "api/v2/Enterprise/Pricing/ListToSendApproval", {
            params: {
                idEnterprisePriceGroup: idEnterprisePriceGroup
            }
        })
    }
    async getHeaderInfoById(idEnterprisePricesProjection) {
        return await axiosInstance.get(API_URL + "api/v2/Enterprise/Pricing/GetFujioka", {
            params: {
                idEnterprisePricesProjection: idEnterprisePricesProjection
            }
        })
    }
    async getRulePath(idEnterprisePricesProjection) {
        return await axiosInstance.get(API_URL + "api/v2/Enterprise/Pricing/GetPriceProjectionRulePath", {
            params: {
                idEnterprisePriceProjection: idEnterprisePricesProjection
            }
        })
    }
    async getHeaderInfo(request) {
        return await axiosInstance.post(API_URL + "api/v2/Enterprise/PriceProjection/ListFujioka", request)
    }
    async adjustPrice(request) {
        return await axiosInstance.post(API_URL + "api/v2/Enterprise/Pricing/AddValueManual", request)
    }
    async sendToERP(idEnterprisePricesProjection) {
        return await axiosInstance.post(API_URL + "api/v2/Enterprise/Pricing/SendPriceToERP", idEnterprisePricesProjection)
    }
    async setWorkflow(request) {
        return await axiosInstance.post(API_URL + "api/v2/Enterprise/Pricing/SetActionWorkflow", request)
    }
    async deleteReplyPricing(request) {
        return await axiosInstance.post(API_URL + "api/v2/Enterprise/Pricing/UpdateReplicatePrice", request)
    }

}