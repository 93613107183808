var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"d-flex justify-center align-center",attrs:{"persistent":"","width":"100%","max-width":"1224"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('prd-btn',_vm._g(_vm._b({attrs:{"title":_vm.$t('TXT_SEE_PRICING_CENARY')}},'prd-btn',attrs,false),on))]}}]),model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c('v-card',{staticClass:"d-flex flex-column gap-y-6 pa-8 white rounded-lg"},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"space-between"}},[_c('h2',{directives:[{name:"t",rawName:"v-t",value:('TXT_PRICING_CENARY'),expression:"'TXT_PRICING_CENARY'"}],staticClass:"font-size-16"}),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{attrs:{"no-gutters":"","align":"start"}},[(_vm.idCompany == 3026)?_c('change-curve',{staticClass:"mr-4",on:{"getErpPreview":_vm.getErpPreview}}):_vm._e(),_c('v-icon',{on:{"click":_vm.closeDialog}},[_vm._v("mdi-close")])],1)],1)],1),_c('loading-div',{staticClass:"grid gap-y-4 gap-x-6",attrs:{"is-loading":_vm.service.getErpPreview.isLoading,"error":_vm.service.getErpPreview.error}},[_vm._l((_vm.categories),function(ref){
var title = ref.title;
var icon = ref.icon;
var values = ref.values;
return [_c('div',{key:title,staticClass:"font-size-16 font-weight-bold d-flex align-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"primary"}},[_vm._v(_vm._s(icon))]),_c('span',[_vm._v(_vm._s(title))])],1),_vm._l((values),function(ref){
var label = ref.label;
var color = ref.color;
var value = ref.value;
return _c('div',{key:label + title,staticClass:"font-size-12 d-flex flex-column shadow gap-y-6 white rounded-lg pa-4"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(label)+" ")]),_c('span',{class:("show-" + color + " font-size-16")},[_vm._v(_vm._s(value))])])})]})],2),(!_vm.service.getErpPreview.isLoading && _vm.idCompany == 3026)?_c('div',{staticClass:"grid-teste gap-y-4 gap-x-6"},[_c('div',{staticClass:"font-size-12 d-flex flex-column shadow gap-y-6 white rounded-lg pa-4",staticStyle:{"grid-column":"2"}},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('TXT_MONTH_VARIATION'),expression:"'TXT_MONTH_VARIATION'"}],staticClass:"font-weight-bold"}),_c('span',{class:"show-blue font-size-16"},[_vm._v(" "+_vm._s(_vm.setCurrency(_vm.vemSallesMonthProjection, true)))])]),_c('div',{staticClass:"font-size-12 d-flex flex-column shadow gap-y-6 white rounded-lg pa-4",staticStyle:{"grid-column":"3"}},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('TXT_MONTH_VARIATION'),expression:"'TXT_MONTH_VARIATION'"}],staticClass:"font-weight-bold"}),_c('span',{class:"show-blue font-size-16"},[_vm._v(_vm._s(_vm.setCurrency(_vm.vemProfitMonthProjection, true)))])])]):_vm._e(),(!_vm.service.getErpPreview.isLoading)?_c('div',[_c('v-row',{attrs:{"align":"center"}},[_c('v-spacer'),(this.$store.getters.userData.idCompany == 2565)?_c('div',[_c('v-btn',{staticClass:"white--text mr-4",attrs:{"loading":_vm.disableExtractReportSAP,"color":"#3c5ca7"},on:{"click":_vm.extractReportSAP}},[_vm._v(_vm._s(this.$i18n.t("TXT_EXTRACT_SAP"))+" ")])],1):_vm._e(),_c('Prd-btn',{staticClass:"mr-4",attrs:{"title":_vm.$t('TXT_EXTRACT_REPORT'),"loading":_vm.disableExtractReport,"color":_vm.$prdStyles('color-primary'),"icon":'mdi-list-box-outline',"leftIcon":true},on:{"click":_vm.extractReport}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }