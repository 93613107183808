<template>
  <v-col>
    <v-row no-gutters justify="space-between" align="center">
      <v-col cols="auto">
        <h4>{{ name }}</h4>
        <label>{{ subtitle }}</label>
      </v-col>

      <v-col cols="auto" v-if="isAdminUpl">
        <Prd-btn
          :title="'Adicionar Agrupamento'"
          @click="eddEditModalItsOpen = true"
        />
      </v-col>
    </v-row>

    <Table
      :headers="headers"
      :items="items"
      @setSnackbarType="setSnackbarType"
      @updateAgroupment="updateAgroupment"
      @refreshTargetMarketSegmentationTable="
        $emit('refreshTargetMarketSegmentationTable')
      "
    />

    <add-edit-agroupment-modal
      :itsOpen="eddEditModalItsOpen"
      :agroupmentMacroNames="agroupmentMacroNames"
      :agroupmentMicroNames="agroupmentMicroNames"
      :selectedAgroupmentId="selectedAgroupmentId"
      @closeModal="closeModal"
      @setSnackbarType="setSnackbarType"
      @refreshTargetMarketSegmentationTable="
        $emit('refreshTargetMarketSegmentationTable')
      "
    />

    <!-- MESSAGE COMPONENTS -->
    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.snackbarColor"
      :timeout="2000"
    >
      {{ snackbar.snackbarText }}
    </v-snackbar>
  </v-col>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import Table from "./components/target-market-segmentation-table.vue";
import AddEditAgroupmentModal from "./components/add-edit-agroupment-modal/add-edit-agroupment-modal.vue";
export default {
  components: { PrdBtn, Table, AddEditAgroupmentModal },
  props: {
    name: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      eddEditModalItsOpen: false,
      selectedAgroupmentId: null,
      snackbar: {
        snackbar: false,
        snackbarText: null,
        snackbarColor: "#3C5CA7",
      },
    };
  },
  computed: {
    isAdminUpl() {
      return (
        this.$store.getters.Acesso_E_Edicao_UPL_Geral ||
        this.$store.getters.isPredifyAdmin
      );
    },
    agroupmentMacroNames() {
      return this.items.map((item) => item.agMacro);
    },
    agroupmentMicroNames() {
      return this.items.map((item) => item.agMicro);
    },
  },
  methods: {
    updateAgroupment(id) {
      this.selectedAgroupmentId = id;
      this.eddEditModalItsOpen = true;
    },
    closeModal() {
      this.selectedAgroupmentId = null;
      this.eddEditModalItsOpen = false;
    },
    setSnackbarType(number) {
      let text = null;
      let type = null;
      switch (number) {
        case 1:
          text = "Agrupamento adicionado com sucesso !";
          type = "success";
          break;
        case 2:
          text =
            "Erro ao adicionar agrupamento, tente novamente, se o problema persistir, contate a PREDIFY !";
          type = "error";
          break;

        case 3:
          text = "Agrupamento atualizado com sucesso !";
          type = "success";
          break;
        case 4:
          text =
            "Erro ao atualizar agrupamento, tente novamente, se o problema persistir, contate a PREDIFY !";
          type = "error";
          break;

        case 5:
          text = "Agrupamento excluído com sucesso !";
          type = "success";
          break;
        case 6:
          text =
            "Erro ao excluir agrupamento, tente novamente, se o problema persistir, contate a PREDIFY !";
          type = "error";
          break;

        default:
          break;
      }
      this.showSnackbar(text, type);
    },
    showSnackbar(text, type) {
      let snackbar = {
        snackbar: true,
        snackbarColor: null,
        snackbarText: null,
      };

      snackbar.snackbarText = text;

      switch (type) {
        case "success":
          snackbar.snackbarColor = "#56a667";
          break;
        case "error":
          snackbar.snackbarColor = "#ec4c37";
          break;
        case "info":
          snackbar.snackbarColor = "#3c5ca7";
      }
      this.snackbar = snackbar;
    },
  },
};
</script>