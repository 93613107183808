<template>
  <div>
    <prd-btn
      :title="'Variáveis de Cálculos'"
      :disabled="readOnly"
      @click="isDialogOpen = true"
      style="height: 40px;"
    />
    <v-dialog
      v-model="isDialogOpen"
      :max-width="400"
      persistent
      v-if="variaveisTributacaoEdited != null"
    >
      <v-card
        class="pa-4 pt-0"
        :loading="isLoading"
        :disabled="isLoading"
        flat
        id="new-action-dialog"
      >
        <h4 class="pt-4">Variáveis de Cálculos</h4>

        <v-row no-gutters class="my-4">
          <v-col cols="12" md="8" class="mr-6">
            <label>Frete (%)</label>
            <v-text-field
              v-model="variaveisTributacaoEdited.frete"
              dense
              outlined
              hide-details
              type="number"
              class="mt-1"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters class="my-4">
          <v-col cols="12" md="8" class="mr-6">
            <label>Dólar</label>
            <v-text-field
              v-model="variaveisTributacaoEdited.dolar"
              dense
              outlined
              hide-details
              type="number"
              class="mt-1"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters class="my-4">
          <v-col cols="12" md="8" class="mr-6">
            <label>Juros BRL (%)</label>
            <v-text-field
              v-model="variaveisTributacaoEdited.jurosBRL"
              dense
              outlined
              hide-details
              type="number"
              class="mt-1"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters class="my-4">
          <v-col cols="12" md="8" class="mr-6">
            <label>Juros USD (%)</label>
            <v-text-field
              v-model="variaveisTributacaoEdited.jurosUSD"
              dense
              outlined
              hide-details
              type="number"
              class="mt-1"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters class="my-4">
          <v-col cols="12" md="8" class="mr-6">
            <label>Imposto (%)</label>
            <v-text-field
              v-model="variaveisTributacaoEdited.imposto"
              dense
              outlined
              hide-details
              type="number"
              class="mt-1"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters class="my-4">
          <v-col cols="12" md="8" class="mr-6">
            <label>Período Médio (meses)</label>
            <v-text-field
              v-model="variaveisTributacaoEdited.periodoMedio"
              dense
              outlined
              hide-details
              type="number"
              class="mt-1"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <prd-btn
            @click="cancelChanges"
            :title="'Cancelar'"
            :outlined="true"
            class="mr-4"
          />
          <prd-btn :title="'Salvar'" @click="saveProducts" />
        </v-row>
        <br />
        <v-row no-gutters>
          <prd-btn
            :title="'Recalcular Todos os Produtos'"
            @click="aplicarMudancas"
          />
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
import ServiceUPL from "@/service/upl/index";

export default {
  components: { prdBtn },
  props: ["variaveisTributacao", "readOnly"],
  data() {
    return {
      isDialogOpen: false,
      actionName: null,
      isLoading: false,
      variaveisTributacaoEdited: null,
      service: new ServiceUPL(),
    };
  },
  methods: {
    async aplicarMudancas() {
      if (
        confirm(
          "Tem certeza que deseja recalcular todos os produtos? Esse processo não pode ser desfeito."
        )
      ) {
        this.isLoading = true;
        await this.service.recalculateProduct();
        this.isLoading = false;
        this.isDialogOpen = false;
        this.$router.go(0);
      }
    },
    async saveProducts() {
      this.isLoading = true;
      await this.service.updatetVariaveisTributacao(
        this.variaveisTributacaoEdited
      );
      this.$emit("variaveisTributacao", this.variaveisTributacaoEdited);
      this.isLoading = false;
      this.isDialogOpen = false;
    },

    cancelChanges() {
      this.isDialogOpen = false;
    },
  },
  beforeMount() {
    if (this.variaveisTributacao != null) {
      this.variaveisTributacaoEdited = this.variaveisTributacao;
    }
  },
};
</script>
