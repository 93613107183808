var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-4",attrs:{"loading":_vm.isLoading,"disabled":_vm.isLoading}},[_c('h3',{directives:[{name:"t",rawName:"v-t",value:(_vm.$t('TXT_CRAWLER_LIST')),expression:"$t('TXT_CRAWLER_LIST')"}]}),_c('v-row',{staticClass:"my-4",attrs:{"no-gutters":"","justify":"space-between","align":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{staticClass:"gap-4",attrs:{"no-gutters":""}},[_c('prd-search',{attrs:{"useDelay":false},on:{"input":_vm.filterCrawlers},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('prd-combo-box',{attrs:{"title":'Tipo',"items":_vm.isOfflineList},on:{"input":_vm.filterCrawlers},model:{value:(_vm.selectedIsOffline),callback:function ($$v) {_vm.selectedIsOffline=$$v},expression:"selectedIsOffline"}})],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('crawler-config-add-dialog',{attrs:{"lastId":_vm.lastID,"crawlerList":_vm.originalContentTable,"countryList":_vm.countryList,"sourceList":_vm.sourceList,"languageList":_vm.languageList,"typeList":_vm.typeList},on:{"ActualizarTable":_vm.getCrawler,"showSnackbar":_vm.showSnackbar}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headersTable,"items":_vm.contentTable,"hide-default-footer":_vm.contentTable == [] || _vm.contentTable.length == 0,"footer-props":_vm.footerProps,"no-data-text":_vm.$t('TXT_NO_DATA_AVAILABLE')},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"truncate",style:({ maxWidth: '400px' })},[(!item.isEdit)?_c('span',{staticClass:"pa-0"},[_vm._v(" "+_vm._s(item.description)+" ")]):_vm._e(),(item.isEdit)?_c('v-textarea',{attrs:{"single-line":"","outlined":"","hide-details":"","no-resize":""},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}}):_vm._e()],1)]}},{key:"item.crawlerType",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"center","max-width":"100px"}},[(!item.isEdit)?_c('span',[_vm._v(_vm._s(_vm.getNameById(item.crawlerType, _vm.typeList)))]):_vm._e(),(item.isEdit)?_c('prd-combo-box',{attrs:{"items":_vm.typeList,"clearable":""},on:{"click:clear":function($event){item.crawlerType = null}},model:{value:(item.crawlerType),callback:function ($$v) {_vm.$set(item, "crawlerType", $$v)},expression:"item.crawlerType"}}):_vm._e()],1)]}},{key:"item.isOffline",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ml-4",staticStyle:{"display":"flex","justify-content":"center","align":"center"}},[_c('v-switch',{staticClass:"ma-0 pa-0",attrs:{"dense":"","hide-details":"","inset":"","disabled":!item.isEdit},model:{value:(item.isOffline),callback:function ($$v) {_vm.$set(item, "isOffline", $$v)},expression:"item.isOffline"}})],1)]}},{key:"item.source",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"center","max-width":"100px"}},[(!item.isEdit)?_c('span',[_vm._v(_vm._s(_vm.getNameById(item.source, _vm.sourceList)))]):_vm._e(),(item.isEdit)?_c('prd-combo-box',{attrs:{"items":_vm.sourceList,"item-text":'text',"item-value":'value',"clearable":""},on:{"click:clear":function($event){item.source = null}},model:{value:(item.source),callback:function ($$v) {_vm.$set(item, "source", $$v)},expression:"item.source"}}):_vm._e()],1)]}},{key:"item.language",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"center","max-width":"100px"}},[(!item.isEdit)?_c('span',[_vm._v(_vm._s(_vm._f("setLanguage")(item.language)))]):_vm._e(),(item.isEdit)?_c('prd-combo-box',{attrs:{"items":_vm.languageList,"item-text":'text',"item-value":'value',"clearable":""},on:{"click:clear":function($event){item.language = null}},model:{value:(item.language),callback:function ($$v) {_vm.$set(item, "language", $$v)},expression:"item.language"}}):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center","gap":"8px"}},[(!item.isEdit)?_c('v-icon',{attrs:{"color":_vm.$prdStyles('color-primary')},on:{"click":function($event){return _vm.UpdateMode(true, item.idCrawler)}}},[_vm._v("mdi-pencil-box ")]):_vm._e(),(!item.isEdit)?_c('crawler-config-delete-dialog',{attrs:{"item":item,"countryList":_vm.countryList,"sourceList":_vm.sourceList,"typeList":_vm.typeList},on:{"ActualizarTable":_vm.getCrawler,"showSnackbar":_vm.showSnackbar}}):_vm._e()],1),(item.isEdit)?_c('div',{staticStyle:{"display":"flex","justify-content":"center","gap":"8px"}},[_c('v-icon',{attrs:{"color":_vm.$prdStyles('color-success'),"disabled":item.description.length < 1 || item.description == null},on:{"click":function($event){return _vm.UpdateDataCrawler(item)}}},[_vm._v("mdi-checkbox-marked")]),_c('v-icon',{attrs:{"color":_vm.$prdStyles('color-warning')},on:{"click":function($event){return _vm.UpdateMode(false, item.idCrawler)}}},[_vm._v("mdi-close")])],1):_vm._e()]}}],null,true)}),_c('v-snackbar',{attrs:{"color":_vm.snackbar.snackbarColor,"timeout":2000},model:{value:(_vm.snackbar.snackbar),callback:function ($$v) {_vm.$set(_vm.snackbar, "snackbar", $$v)},expression:"snackbar.snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }