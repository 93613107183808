import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class AddProductModal extends ServiceBase {
    /**
  * Listar Produtos
  * @param {long} idMonitoringItem 
  * @param {dateTime} startDate    
  * @param {dateTime} endDate  
  * @param {idTask} String  
  */
    async listProducts(idMonitoringItem, startDate, endDate, idTask) {
        return await axiosInstance.get(API_URL + "api/V2/MonitoringItemManualResult/list", { params: { idMonitoringItem: idMonitoringItem, startDate: startDate, endDate: endDate, idTask: idTask } });
    }

    /**
   * Adicionar Produtos
   * @param {long} idMonitoringItem 
   * @param {string} descricao 
   * @param {string} productLink 
   * @param {string} seller  
   * @param {decimal} price  
   * @param {int} installment   
   * @param {dateTime} refDate    
   */
    async insertProduct(request) {
        return await axiosInstance.post(API_URL + `api/V2/MonitoringItemManualResult/Insert`, request);
    }

    /**
       * Editar ou excluir Produtos
       * @param {long} idMonitoringItemProductManualResult 
       * @param {string} descricao 
       * @param {string} productLink 
       * @param {string} seller  
       * @param {decimal} price  
       * @param {int} installment   
       * @param {dateTime} refDate    
       * @param {bool} isDeleted
    */
    async updateProduct(request) {
        return await axiosInstance.post(API_URL + `api/V2/MonitoringItemManualResult/Update`, request);
    }
}