<template>
  <v-row no-gutters class="my-4">
    <v-col cols="4" class="pr-2">
      <about-product style="height: 100%" v-bind:product="this.product" />
    </v-col>
    <v-col cols="4" class="px-2">
      <column-chart
        style="height: 340px"
        v-bind:product="this.product"
        class="mb-4"
      />
      <card-info
        style="height: 200px"
        :title="'ESTRATÉGIA DE POSICIONAMENTO'"
        :text="estrategiaDePosicionamentoText"
        :productId="productId"
        @newValueatt="
          $emit('newValueatt', {
            fieldName: 'estrategiaDePosicionamento',
            value: $event,
          })
        "
        :cardType="'estrategia'"
      />
    </v-col>
    <v-col cols="4" class="pl-2">
      <card-info
        style="height: 162px"
        class="mb-4"
        :title="'PROPOSTA DE VALOR'"
        :text="propostaValorText"
        @newValueatt="
          $emit('newValueatt', { fieldName: 'propostaValor', value: $event })
        "
        :cardType="'proposta'"
        :productId="productId"
        :tooltipText="`<p style='font-size: 15px;'>- Deve ser elaborado pelo Gerente de Portfólio.</p><p  style='font-size: 15px;'>- Relacioná-los aos cultivos.</p><p  style='font-size: 15px;'>- Destacar o posicionamento estratégico da Brand.</p>`"
      />
      <card-info
        style="height: 162px"
        class="mb-4"
        :title="'OBJETIVO DO NEGÓCIO'"
        :text="objetivoNegocioText"
        @newValueatt="
          $emit('newValueatt', { fieldName: 'objetivoNegocio', value: $event })
        "
        :cardType="'objetivo'"
        :productId="productId"
        :tooltipText="`<p style='font-size: 15px;'>- Deve ser elaborado pelos Gerentes de Portfólio e Gerentes de Cultivos;</p><p  style='font-size: 15px;'>- Descrever sucintamente objetivos. Exemplo: 'Alcançar USD 200Mio' ou 'Aumentar Mkt Share em MT'.</p><p  style='font-size: 15px;'>- Indicar também oportunidades identificadas.</p>`"
      />
      <card-info
        style="height: 200px"
        :title="'GATILHOS DE REPOSICIONAMENTO'"
        :text="gatilhosPosicionamentoText"
        @newValueatt="
          $emit('newValueatt', {
            fieldName: 'gatilhosPosicionamento',
            value: $event,
          })
        "
        :cardType="'gatilho'"
        :productId="productId"
        :tooltipText="`<p style='font-size: 15px;'>- Deve ser elaborado pelos Gerentes de Portfólio;</p><p  style='font-size: 15px;'>- Destacar variações que gatilhem a revisão imediata do preço.<br>Exemplo: 'Custo por Hectare frente ao competidor deve manter-se entre 5% e 12%'<br> ou 'Margem de Contribuição deve ser de no mínimo 40%'.</p>`"
      />
    </v-col>
  </v-row>
</template>

<script>
import CardInfo from "../card-info.vue";
import aboutProduct from "./components/about-product/about-product-main.vue";
import ColumnChart from "./components/column-chart.vue";
export default {
  components: { aboutProduct, CardInfo, ColumnChart },
  props: ["product"],
  data() {
    return {
      valueProposal: "",
      bussinesObjective: "",
      repositionTrigger: "",
      positionEstrategy: "",
    };
  },

  computed: {
    productId() {
      return this.product?.id ?? null;
    },
    estrategiaDePosicionamentoText() {
      return this.product?.estrategiaDePosicionamento ?? "";
    },
    propostaValorText() {
      return this.product?.propostaValor ?? "";
    },
    objetivoNegocioText() {
      return this.product?.objetivoNegocio ?? "";
    },
    gatilhosPosicionamentoText() {
      return this.product?.gatilhosPosicionamento ?? "";
    },
  },
};
</script>