<template>
  <v-card v-if="this.product != null" class="pa-4" elevation="4">
    <h4>SOBRE O PRODUTO</h4>
    <v-row no-gutters>
      <v-col class="pa-0 mt-4">
        <label>Segmento</label>
        <v-text-field
          readonly
          v-model="segmento"
          hide-details
          outlined
          dense
          filled
          class="mt-2"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="pa-0 mt-4">
        <label>Classificação</label>
        <v-text-field
          readonly
          v-model="classificacao"
          hide-details
          outlined
          dense
          filled
          class="mt-2"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="pa-0 mt-4 mb-8">
        <label>Cultura driver</label>
        <v-text-field
          readonly
          v-model="culturaDriver"
          hide-details
          outlined
          dense
          filled
          class="mt-2"
        ></v-text-field>
      </v-col>
    </v-row>

    <Market-data :data="product.mercado" :idProduct="product.id" />
  </v-card>
</template>

<script>
import MarketData from "./components/market-data.vue";
export default {
  components: { MarketData },
  props: ["product"],
  computed: {
    segmento() {
      return this.product?.sobre?.segmento ?? null;
    },
    classificacao() {
      return this.product?.sobre?.classificacao ?? null;
    },
    culturaDriver() {
      return this.product?.sobre?.culturaDriver ?? null;
    },
  },
};
</script>