<template>
  <v-dialog persistent max-width="860px" v-model="localIsOpen">
    <v-card flat class="pa-4">
      <h3 class="mb-4">Salvar nova Deliberação</h3>

      <v-radio-group v-model="isNextMonth" row :disabled="isLoading">
        <v-radio label="Salvar para o mês atual" :value="false" :color="$prdStyles('color-primary')" />
        <v-radio label="Salvar para o mês seguinte" :value="true" :color="$prdStyles('color-primary')" />
      </v-radio-group>

      <v-row no-gutters justify="center" class="red-border mb-4">
        <v-col cols="12">
          <v-row no-gutters justify="center">
            <span>
              <v-icon :color="$prdStyles('color-warning')" class="mr-2">mdi-alert-outline</v-icon>
              Se já existir alguma deliberação correspondente ao mês escolhido, a mesma será substituída por uma nova.
            </span>
          </v-row>
          <v-row no-gutters justify="center">
            <span class="red--text">
              Esta operação não poderá ser desfeita.
            </span>
          </v-row>
        </v-col>
      </v-row>

      <v-row no-gutters justify="end">
        <prd-btn :disabled="isLoading" @click="$emit('closeModal')" :title="'Cancelar'" :outlined="true" class="mr-2" />
        <prd-btn :disabled="isLoading" :loading="isLoading" @click=" $emit('createNewDeliberation', isNextMonth)"
          :title="isNextMonth ? 'Salvar para o mês seguinte' : 'Salvar para o mês atual'" />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import prdBtn from '@/Design_System/common/prd-btn.vue'
export default {
  props: ["isLoading"],
  components: { prdBtn },
  data() {
    return {
      localIsOpen: true,
      isNextMonth: false
    }
  },
}
</script>

<style lang="scss" scoped>
.red-border {
  border: 2px solid red;
  border-radius: 8px;
  padding: 8px;
}
</style>