<template>
  <div class="alignItems">
    <div>
      <h2 class="titleMargin">Gráfico de competitividade</h2>
      <p class="grayColor">Data de exportação: {{ currentDate }}</p>
    </div>
    <div>
      <img src="@/assets/logo_predify_blue.png" width="165" />
      <a href="https://app.predify.me" target="_blank"
        ><p class="grayColor">https://app.predify.me</p></a
      >
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/utils/prd-format-date";
export default {
  computed: {
    currentDate() {
      const currentDate = new Date();
      const formatedDate = formatDate(currentDate);
      return formatedDate;
    },
  },
};
</script>

<style lang="scss" scoped>
.alignItems {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.titleMargin {
  margin-bottom: 24px;
}
.grayColor {
  color: $neutral-color-low-medium;
  padding: 0px;
  margin: 0px;
}
</style>