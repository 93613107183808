<template>
  <v-progress-circular
    v-if="isLoadingRevenueGraphic"
    size="150"
    color="#3c5ca7"
    indeterminate
  />
  <highcharts v-else :options="chartOptions" />
</template>

<script>
import Highcharts from "highcharts";

// Exporta dados em PNG, JPEG, PDF, SVG, imprime e visualiza tela cheia
import exportingInit from "highcharts/modules/exporting";
exportingInit(Highcharts);

// Exporta dados CSV, XLS, e muda visualização por tabela
import exportData from "highcharts/modules/export-data";
exportData(Highcharts);
import { formatMonetary } from "@/utils/format-toMonetary";

export default {
  props: ["listRevenueGraphic", "isLoadingRevenueGraphic"],
  data() {
    return {
      chartOptions: {
        lang: {
          // variáveis para renomear as ações de exportação de dados
          viewFullscreen: this.$i18n.t("TXT_VISUALIZE_FULLSCREEN"),
          printChart: this.$i18n.t("TXT_PRINT_CHART"),
          downloadCSV: this.$i18n.t("TXT_DOWNLOAD_CSV_FILE"),
          downloadJPEG: this.$i18n.t("TXT_DOWNLOAD_JPEG_IMAGE"),
          downloadPDF: this.$i18n.t("TXT_DOWNLOAD_PDF"),
          downloadPNG: this.$i18n.t("TXT_DOWNLOAD_PNG_IMAGE"),
          downloadSVG: this.$i18n.t("TXT_DOWNLOAD_SVG_IMAGE"),
          downloadXLS: this.$i18n.t("TXT_DOWNLOAD_XLS_FILE"),
          exitFullscreen: this.$i18n.t("TXT_LEAVE_FULLSCREEN"),
          viewData: this.$i18n.t("TXT_DISPLAY_DATA_IN_TABLE"),
          noData: this.$i18n.t("TXT_NO_DATA_AVAILABLE"),
        },
        chart: {
          type: "column",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: [],
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
          },
        },
        tooltip: {
          borderRadius: 15,
          formatter: function () {
            return (
              `<p><strong>${this.point.category}</strong></p><br>` +
              formatMonetary(this.y)
            );
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        series: [
          {
            name: "Total Revenue in week",
            data: [],
            showInLegend: false,
            color: "#3c5ca7",
          },
        ],
      },
      weekDays: [
        this.$i18n.t("TXT_WEEKDAY_MONDAY"),
        this.$i18n.t("TXT_WEEKDAY_TUESDAY"),
        this.$i18n.t("TXT_WEEKDAY_WEDNESDAY"),
        this.$i18n.t("TXT_WEEKDAY_THURSDAY"),
        this.$i18n.t("TXT_WEEKDAY_FRIDAY"),
        this.$i18n.t("TXT_WEEKDAY_SATURDAY"),
        this.$i18n.t("TXT_WEEKDAY_SUNDAY"),
      ],
    };
  },
  methods: {
    formatMonetary,
    getLastSevenDatas(listData) {
      return listData.slice(-7);
    },

    prepareteDataForGraphic(list) {
      const lastSevenDatas = this.getLastSevenDatas(list);
      this.chartOptions.series[0].data = lastSevenDatas.map((data) => {
        return data.value;
      });
    },
    prepareteXAxisForGraphic(list) {
      const lastSevenDatas = this.getLastSevenDatas(list);
      const categoriesXAxis = lastSevenDatas.map((data) => {
        const date = data.date.split("-");
        const newDate = `${date[2]}/${date[1]}/${date[0]}`;
        return `${this.getWeekDayByDate(data.date)} - ${newDate}`;
      });
      this.chartOptions.xAxis.categories = categoriesXAxis;
    },

    getWeekDayByDate(date) {
      const dateObj = new Date(date);
      const dateIndex = dateObj.getDay();
      const weekDay = this.weekDays[dateIndex];
      return weekDay;
    },
  },
  watch: {
    listRevenueGraphic: {
      handler(value) {
        const newList = value.map((data) => {
          return { ...data, date: data.date.split("T")[0] };
        });
        this.prepareteDataForGraphic(newList);
        this.prepareteXAxisForGraphic(newList);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
</style>