export default {
    state: () => ({
        erpList: [],
        replicateItems: []
    }),
    getters: {
        erpList: (state) => state.erpList,
        replicateItems: (state) => state.replicateItems,

    },
    mutations: {
        saveErpToList(state, product) {
            const existingProduct = state.erpList.find(p => p.idEnterprisePricesProjection === product.idEnterprisePricesProjection);

            if (!existingProduct) {
                state.erpList.push(product);
            }
        },

        removeItem(state, index) {
            if (index >= 0 && index < state.erpList.length) {
                state.erpList.splice(index, 1);
            }
        },

        cleanList(state) {
            state.erpList = []
        },

        updateTeste(state, idReplicates) {
            console.log(idReplicates);
            idReplicates.forEach((id) => {
                state.replicateItems.push(id)
            })

            console.log(state);
        },

        removeTeste(state, idReplicate) {
            const filteredItems = [];

            state.replicateItems.forEach((id) => {
                if (id != idReplicate) filteredItems.push(id)
            })


            state.replicateItems = filteredItems
        }
    },
};
