export function formatPercentage(value, decimal) {
    if (value === null || value === undefined || isNaN(value) || value == '-') return '-'
    if (value == 0) return '0%'

    let decimals = null
    if (!decimal) decimals = 2

    const numberToString = value.toString();
    const parts = numberToString.split('.');

    let integerPart = parts[0];
    let decimalPart = parts[1] || '00';

    if (decimalPart.length < 2) {
        decimalPart = decimalPart.padEnd(decimals, '0');
    } else if (decimalPart.length > 2) {
        decimalPart = decimalPart.slice(0, decimals);
    }

    const formatedNumber = integerPart + ',' + decimalPart;
    var pointNumber = parseFloat(formatedNumber.replace(',', '.'));
    var pointDot = pointNumber.toString().replace('.', ',')
    return `${pointDot}%`;
}