<template>
  <v-dialog
    v-model="isModalOpen"
    persistent
    width="100%"
    max-width="1224"
    class="d-flex justify-center align-center"
  >
    <template v-slot:activator="{ on, attrs }">
      <prd-btn :title="$t('TXT_SEE_PRICING_CENARY')" v-bind="attrs" v-on="on" />
    </template>

    <v-card class="d-flex flex-column gap-y-6 pa-8 white rounded-lg">
      <v-row no-gutters align="center" justify="space-between">
        <h2 v-t="'TXT_PRICING_CENARY'" class="font-size-16"></h2>
        <v-col cols="auto">
          <v-row no-gutters align="start">
            <change-curve
              @getErpPreview="getErpPreview"
              v-if="idCompany == 3026"
              class="mr-4"
            />
            <v-icon @click="closeDialog">mdi-close</v-icon>
          </v-row>
        </v-col>
      </v-row>

      <loading-div
        class="grid gap-y-4 gap-x-6"
        :is-loading="service.getErpPreview.isLoading"
        :error="service.getErpPreview.error"
      >
        <template v-for="{ title, icon, values } in categories">
          <div
            class="font-size-16 font-weight-bold d-flex align-center"
            :key="title"
          >
            <v-icon small class="mr-2" color="primary">{{ icon }}</v-icon>
            <span>{{ title }}</span>
          </div>
          <div
            v-for="{ label, color, value } in values"
            :key="label + title"
            class="font-size-12 d-flex flex-column shadow gap-y-6 white rounded-lg pa-4"
          >
            <span class="font-weight-bold">
              {{ label }}
            </span>

            <span :class="`show-${color} font-size-16`">{{ value }}</span>
          </div>
        </template>
      </loading-div>

      <div
        v-if="!service.getErpPreview.isLoading && idCompany == 3026"
        class="grid-teste gap-y-4 gap-x-6"
      >
        <div
          class="font-size-12 d-flex flex-column shadow gap-y-6 white rounded-lg pa-4"
          style="grid-column: 2"
        >
          <span v-t="'TXT_MONTH_VARIATION'" class="font-weight-bold"></span>
          <span :class="`show-blue font-size-16`">
            {{ setCurrency(vemSallesMonthProjection, true) }}</span
          >
        </div>

        <div
          class="font-size-12 d-flex flex-column shadow gap-y-6 white rounded-lg pa-4"
          style="grid-column: 3"
        >
          <span v-t="'TXT_MONTH_VARIATION'" class="font-weight-bold"></span>
          <span :class="`show-blue font-size-16`">{{
            setCurrency(vemProfitMonthProjection, true)
          }}</span>
        </div>
      </div>

      <div v-if="!service.getErpPreview.isLoading">
        <v-row align="center">
          <v-spacer></v-spacer>

          <div v-if="this.$store.getters.userData.idCompany == 2565">
            <v-btn
              :loading="disableExtractReportSAP"
              @click="extractReportSAP"
              class="white--text mr-4"
              color="#3c5ca7"
              >{{ this.$i18n.t("TXT_EXTRACT_SAP") }}
            </v-btn>
          </div>

          <Prd-btn
            :title="$t('TXT_EXTRACT_REPORT')"
            :loading="disableExtractReport"
            :color="$prdStyles('color-primary')"
            :icon="'mdi-list-box-outline'"
            :leftIcon="true"
            @click="extractReport"
            class="mr-4"
          />
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn";
import loadingDiv from "@/Design_System/common/loading-div";
import AiPricingService from "@/service/ai-pricing";

import { formatNumberPercentage } from "@/utils/format-numbers";
import { setCurrency } from "@/utils/format-currency";
import ChangeCurve from "../change-curve.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";

export default {
  components: {
    prdBtn,
    loadingDiv,
    ChangeCurve,
    PrdBtn,
  },
  props: [
    "idPriceGroup",
    "approvedSalePrice",
    "currentSalePrice",
    "currentProfit",
    "approvedProfit",
    "currentMargin",
    "approvedMargin",
  ],
  data() {
    return {
      service: new AiPricingService(),
      isModalOpen: false,
      categories: null,
      disableExtractReport: false,
      disableExtractReportSAP: false,
      vemSallesMonthProjection: null,
      vemProfitMonthProjection: null,
    };
  },
  watch: {
    isModalOpen() {
      if (!this.isModalOpen) return;
      this.getErpPreview();
    },
  },
  methods: {
    setCurrency,
    setToIsoString(value) {
      let currency = localStorage.getItem("currency");
      if (!value) return "-";
      if (value == "-") return "-";

      const formato = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      const valorFormatado = value.toLocaleString("pt-BR", formato);

      return currency + " " + valorFormatado;
    },
    resolveNumberColor(num) {
      return num > 0 ? "blue" : num < 0 ? "blue" : "blue";
    },
    extractReportSAP() {
      this.disableExtractReportSAP = true;

      let data = {
        idCompany: this.$store.getters.userData.idCompany,
        IdEnterprisePriceGroups: this.idPriceGroup,
        IsSimulation: false,
      };

      this.service
        .extractReportSAP(data)
        .then((res) => {
          var downloadLink = document.createElement("a");
          var blob = new Blob(["\ufeff", res.data]);
          var url = URL.createObjectURL(blob);
          downloadLink.href = url;
          downloadLink.download = "exportSAP.txt";

          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);

          this.disableExtractReportSAP = false;
        })
        .catch((error) => {
          this.$store.commit(
            "snackbar-error",
            `${this.$i18n.t("TXT_ERROR_OCURRED", {
              ERROR: error,
            })}`
          );

          this.disableExtractReport = false;
        });
    },

    extractReport() {
      this.disableExtractReport = true;

      let data = {
        idCompany: this.$store.getters.userData.idCompany,
        IdEnterprisePriceGroups: this.idPriceGroup,
        IsSimulation: false,
      };

      this.service
        .extractReport(data)
        .then((res) => {
          var downloadLink = document.createElement("a");
          var blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          var url = URL.createObjectURL(blob);
          downloadLink.href = url;
          downloadLink.download = "export.xlsx";

          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);

          this.disableExtractReport = false;
        })
        .catch((error) => {
          this.$store.commit(
            "snackbar-error",
            `${this.$i18n.t("TXT_ERROR_OCURRED", {
              ERROR: error,
            })}`
          );

          this.disableExtractReport = false;
        });
    },

    async getErpPreview(curve = "", status = "") {
      let response;
      try {
        response = await this.service.getErpPreview.call(
          this.idCompany,
          this.idPriceGroup,
          curve,
          status
        );
      } catch (e) {
        console.error(e);
        this.$store.commit("snackbar-error", this.$i18n.t("TXT_ERROR_ERP"));
        return;
      }

      if (this.idCompany == 3026) {
        this.vemSallesMonthProjection =
          response.saleVariation_SaleVariation * 30;
        this.vemProfitMonthProjection =
          response.profitVariation_ProfitVariation * 30;
        this.categories = [
          {
            title: this.$i18n.t("TXT_CHANGES").toUpperCase(),
            icon: "$arrow2",
            values: [
              {
                label: this.$i18n.t("TXT_TOTAL_OF_ITEMS"),
                value: response.changes_TotalCount,
                color: "blue",
              },
              {
                label: this.$i18n.t("TXT_ITEMS_RULES_APROVED"),
                value: response.changes_ChangesApproved,
                color: "blue",
              },
              {
                label: this.$i18n.t("TXT_PRICE_IA_SUGGESTED"),
                value: response.changes_ApprovedSuggested,
                color: "blue",
              },
            ],
          },
          {
            title: this.$i18n.t("TXT_SALES_VARIATION"),
            icon: "$tag",
            values: [
              {
                label: this.$i18n.t("TXT_SALES_ACTUAL_PRICE"),
                value: this.setToIsoString(
                  response.saleVariation_SaleCurrentPrice
                ),
                color: "blue",
              },
              {
                label: this.$i18n.t("TXT_PRICE_PROJECTION_AFTER_IA"),
                value: this.setToIsoString(
                  response.saleVariation_SaleApprovedPrice
                ),
                color: "blue",
              },
              {
                label: this.$i18n.t("TXT_DAILY_VARIATION"),
                value:
                  this.idCompany == 3026
                    ? `${this.setToIsoString(
                        response.saleVariation_SaleVariation
                      )} (${this.calculateVariationInPercentage(
                        response.saleVariation_SaleVariation,
                        response.saleVariation_SaleApprovedPrice
                      )})`
                    : this.setToIsoString(response.saleVariation_SaleVariation),

                color: this.resolveNumberColor(
                  response.saleVariation_SaleVariation
                ),
              },
            ],
          },
          {
            title: this.$i18n.t("TXT_PROFIT_VARIATION"),
            icon: "$dolar",
            values: [
              {
                label: this.$i18n.t("TXT_SALLES_PROFIT"),
                value: this.setToIsoString(
                  response.profitVariation_SaleCurrentProfit
                ),
                color: "blue",
              },
              {
                label: this.$i18n.t("TXT_SALLES_PROFIT_AFTER_IA"),
                value: this.setToIsoString(
                  response.profitVariation_SaleApprovedProfit
                ),
                color: "blue",
              },
              {
                label: this.$i18n.t("TXT_DAILY_VARIATION"),
                value:
                  this.idCompany == 3026
                    ? `${this.setToIsoString(
                        response.profitVariation_ProfitVariation
                      )} (${this.calculateVariationInPercentage(
                        response.profitVariation_ProfitVariation,
                        response.profitVariation_SaleApprovedProfit
                      )})`
                    : this.setToIsoString(
                        response.profitVariation_ProfitVariation
                      ),
                color: this.resolveNumberColor(
                  response.profitVariation_ProfitVariation
                ),
              },
            ],
          },
          {
            title: this.$i18n.t("TXT_MARGIN_VARIATION"),
            icon: "$percent",
            values: [
              {
                label: this.$i18n.t("TXT_ACTUAL_MARGIN"),
                value: formatNumberPercentage(
                  response.marginVariation_SaleMargin,
                  { fractionLength: 2 }
                ),
                color: "blue",
              },
              {
                label: this.$i18n.t("TXT_ACTUAL_MARGIN_APPROVAL"),
                value: formatNumberPercentage(
                  response.marginVariation_SaleMarginApproved,
                  { fractionLength: 2 }
                ),
                color: "blue",
              },
              {
                label: this.$i18n.t("TXT_VARIATION"),
                value: formatNumberPercentage(
                  response.marginVariation_MarginVariation,
                  { alwaysRenderSign: true, fractionLength: 2 }
                ),
                color: this.resolveNumberColor(
                  response.marginVariation_MarginVariation
                ),
              },
            ],
          },
        ];
      } else {
        this.categories = [
          {
            title: this.$i18n.t("TXT_CHANGES").toUpperCase(),
            icon: "$arrow2",
            values: [
              {
                label: this.$i18n.t("TXT_APPROVAL_CHANGES"),
                value: response.changes_ChangesApproved,
                color: "blue",
              },
              {
                label: this.$i18n.t("TXT_STORE_CHANGES"),
                value:
                  this.idCompany == 3026
                    ? "-"
                    : response.changes_ShopMoreChanges,
                color: "blue",
              },
              {
                label: `${this.$i18n.t("TXT_CHANGES_IN", {
                  STORE:
                    this.idCompany == 3026
                      ? ""
                      : response.changes_ShopMoreChanges,
                })}`,
                value:
                  this.idCompany == 3026
                    ? "-"
                    : response.changes_ChangesShopMoreChanges,
                color: "blue",
              },
            ],
          },
          {
            title: this.$i18n.t("TXT_SALES_VARIATION"),
            icon: "$tag",
            values: [
              {
                label: this.$i18n.t("TXT_SALES_ACTUAL_PRICE"),
                value: this.setToIsoString(
                  response.saleVariation_SaleCurrentPrice
                ),
                color: "blue",
              },
              {
                label: this.$i18n.t("TXT_SALES_APPROVAL_PRICE"),
                value: this.setToIsoString(
                  response.saleVariation_SaleApprovedPrice
                ),
                color: "blue",
              },
              {
                label: this.$i18n.t("TXT_SALES_VARIATION_LOWER"),
                value:
                  this.idCompany == 3026
                    ? `${this.setToIsoString(
                        response.saleVariation_SaleVariation
                      )} (${this.calculateVariationInPercentage(
                        response.saleVariation_SaleVariation,
                        response.saleVariation_SaleApprovedPrice
                      )})`
                    : this.setToIsoString(response.saleVariation_SaleVariation),

                color: this.resolveNumberColor(
                  response.saleVariation_SaleVariation
                ),
              },
            ],
          },
          {
            title: this.$i18n.t("TXT_PROFIT_VARIATION"),
            icon: "$dolar",
            values: [
              {
                label: this.$i18n.t("TXT_PROFIT_BRUT_ACTUAL"),
                value: this.setToIsoString(
                  response.profitVariation_SaleCurrentProfit
                ),
                color: "blue",
              },
              {
                label: this.$i18n.t("TXT_PROFIT_APPROVAL"),
                value: this.setToIsoString(
                  response.profitVariation_SaleApprovedProfit
                ),
                color: "blue",
              },
              {
                label: this.$i18n.t("TXT_PROFIT_VARIATION_LOWER"),
                value:
                  this.idCompany == 3026
                    ? `${this.setToIsoString(
                        response.profitVariation_ProfitVariation
                      )} (${this.calculateVariationInPercentage(
                        response.profitVariation_ProfitVariation,
                        response.profitVariation_SaleApprovedProfit
                      )})`
                    : this.setToIsoString(
                        response.profitVariation_ProfitVariation
                      ),
                color: this.resolveNumberColor(
                  response.profitVariation_ProfitVariation
                ),
              },
            ],
          },
          {
            title: this.$i18n.t("TXT_MARGIN_VARIATION"),
            icon: "$percent",
            values: [
              {
                label: this.$i18n.t("TXT_ACTUAL_MARGIN"),
                value: formatNumberPercentage(
                  response.marginVariation_SaleMargin,
                  { fractionLength: 2 }
                ),
                color: "blue",
              },
              {
                label: this.$i18n.t("TXT_ACTUAL_MARGIN_APPROVAL"),
                value: formatNumberPercentage(
                  response.marginVariation_SaleMarginApproved,
                  { fractionLength: 2 }
                ),
                color: "blue",
              },
              {
                label: this.$i18n.t("TXT_MARGIN_VARIATION_LOWER"),
                value: formatNumberPercentage(
                  response.marginVariation_MarginVariation,
                  { alwaysRenderSign: true, fractionLength: 2 }
                ),
                color: this.resolveNumberColor(
                  response.marginVariation_MarginVariation
                ),
              },
            ],
          },
        ];
      }
    },
    onInput(values) {
      this.$emit("input", values);
    },
    closeDialog() {
      this.isModalOpen = false;
    },
    sendERP() {
      this.$emit("send-ERP", this.closeDialog());
    },

    calculateVariationInPercentage(value, relational) {
      return formatNumberPercentage((value / relational) * 100, {
        alwaysRenderSign: true,
        fractionLength: 2,
      });
    },
  },
  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    somaVenda() {
      return this.approvedSalePrice - this.currentSalePrice;
    },
    sumProfit() {
      return this.approvedProfit - this.currentProfit;
    },
    sumMargin() {
      return this.approvedMargin - this.currentMargin;
    },
  },
};
</script>

<style lang="scss" scoped>
.shadow {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: max-content 1fr 1fr 1fr;
  grid-auto-flow: column;
  height: max-content;
}

.show-blue {
  color: $brand-color-primary-pure;
}

.show-red {
  color: $brand-color-secondary-pure;
}

.show-green {
  color: $feedback-color-success-pure;
}

.btn-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  gap: 8px;

  width: 175px;
  height: 34px;
}

.grid-teste {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: max-content;
  grid-auto-flow: column;
  height: max-content;
  justify-content: center;
}
</style>