<template>
  <v-app-bar app color="var(--very-light-gray)">
    <v-app-bar-nav-icon
      @click="showOrHideMenu()"
      v-show="$vuetify.breakpoint.smAndDown"
    >
    </v-app-bar-nav-icon>
    <span class="  ml-8">
      Company: <span class="font-weight-bold">{{idCompany}}</span>
    </span>
    <v-spacer></v-spacer>
    <!-- lang -->
    <template>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" plain v-bind="attrs" v-on="on" fab small>
            <v-icon>mdi-translate</v-icon>
          </v-btn>
        </template>
        <v-item-group v-model="lang">
          <v-container>
            <v-row v-for="item in supportedLanguages" :key="item.code">
              <v-item v-slot="{ active, toggle }" :value="item.code">
                <v-btn
                  :color="active ? 'primary' : ''"
                  block
                  elevation="0"
                  @click="toggle"
                  class="justify-start"
                >
                  <v-img
                    class="mr-2"
                    height="12px"
                    max-width="16px"
                    width="16px"
                    :src="`/images/icons/flags/${item.flag}.svg`"
                  />
                  {{ item.text }}
                </v-btn>
              </v-item>
            </v-row>
          </v-container>
        </v-item-group>
      </v-menu>
    </template>

    <!-- currency -->
    <template>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" plain v-bind="attrs" v-on="on" fab small>
            <v-icon>mdi-currency-btc</v-icon>
          </v-btn>
        </template>

        <v-item-group v-model="currency">
          <v-container>
            <v-row v-for="item in currencyItems" :key="item.code">
              <v-item v-slot="{ active, toggle }" :value="item.code">
                <v-btn
                  :color="active ? 'primary' : ''"
                  block
                  elevation="0"
                  @click="toggle"
                  class="justify-start"
                >
                  {{ item.code }}
                  {{ item.text }}
                </v-btn>
              </v-item>
            </v-row>
          </v-container>
        </v-item-group>
      </v-menu>
    </template>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import companyService from "@/service/company-service.js";
const Service = new companyService();
export default {
  name: "headerComponent",

  data: function () {
    const lang = localStorage.getItem("lang") || "en-US";
    const currency = localStorage.getItem("currency") || "R$";
    return {
      lang,
      currency,
      supportedLanguages: [
        { text: "English", code: "en-US", flag: "us" },
        { text: "Español", code: "es-ES", flag: "es" },
        { text: "Português", code: "pt-BR", flag: "br" },
      ],
      currencyItems: [
        { text: "Real", code: "R$", flag: "us" },
        { text: "Dolar", code: "$", flag: "es" },
        { text: "Euro", code: "€", flag: "br" },
        { text: "Libra", code: "£", flag: "br" },
      ],
    };
  },
  watch: {
    lang(newLang) {
      localStorage.setItem("lang", newLang);
      window.location.reload();
    },
    currency(newCurrency) {
      let request = {
        idCompany: this.idCompany,
        symbol: newCurrency,
      };
      Service.setCurrency(request)
        .then((res) => {
          console.log("Resposta do salvar moeda", res);
          window.location.reload()
        })
        .catch((error) => {
          console.log("Erro ao salvar moeda", error);
        });
    },
  },
  methods: {
    showOrHideMenu() {
      this.$emit("showOrHideMenu");
    },
  },
  computed: {
    ...mapGetters(["hasUser"]),

    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },
};
</script>
<style scoped lang="scss"></style>
