<template>
  <div id="carousel-item">
    <v-carousel
      :show-arrows="actionData.length > 1"
      v-model="carousel"
      hide-delimiter-background
      :max="400"
      progress-color="#ccc"
    >
      <v-carousel-item v-for="(action, index) in actionData" :key="index">
        <v-card class="pa-4" flat>
          <div class="header-info">
            <div class="action-name">
              <h4>{{ action.actionName }}</h4>
              <tooltip-informations
                :title="action.tooltipText"
                :rightIcon="true"
              />
            </div>

            <div class="font-weight-bold my-flex">
              <div class="dates">
                <div>
                  Início da vigência:
                  <span class="initial-date">{{ action.initialDate }}</span>
                </div>
                <div>
                  Fim da vigência:
                  <span class="final-date">{{ action.finalDate }}</span>
                </div>
              </div>
            </div>
          </div>
          <h5 class="mt-4">PRODUTOS ({{ action.products.length }})</h5>

          <div class="product-main">
            <div
              v-for="product in action.products"
              :key="product.id"
              class="product-info mt-2"
            >
              <v-row no-gutters>
                <span class="font-weight-bold">{{ product.productName }}</span>
                <v-spacer></v-spacer>
                <span class="font-weight-bold">
                  Preço promocional:
                  <span class="product-price">
                    {{ formatMonetary(product.price) }}</span
                  >
                </span>
              </v-row>
            </div>
          </div>
        </v-card>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import { formatMonetary } from "@/utils/format-toMonetary";

import tooltipInformations from "@/Design_System/common/tooltip-informations.vue";
export default {
  components: { tooltipInformations },
  props: ["actionData"],
  data() {
    return {
      carousel: 0,
    };
  },

  methods: {
    formatMonetary,
  },

  beforeMount() {},
};
</script>

<style lang="scss" scoped>
.header-info {
  display: flex;
  justify-content: space-between;

  .action-name {
    display: flex;
    align-items: center;

    width: 25%;
  }

  .my-flex {
    display: flex;
    justify-content: flex-end;
    background-color: $neutral-color-high-light;
    padding: 10px;
    width: 75%;

    .dates {
      display: flex;
      gap: 1rem;
    }

    .initial-date {
      color: $feedback-color-success-pure;
    }

    .final-date {
      color: $feedback-color-warning-pure;
    }
  }
}

.product-main {
  max-height: 350px;
  overflow-y: scroll;

  .product-info {
    background-color: $neutral-color-high-light;
    border-left: 6px solid $feedback-color-warning-pure;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 10px;

    .product-price {
      color: $brand-color-primary-pure;
    }
  }
}
</style>