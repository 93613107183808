<template>
  <v-card class="pa-4" min-width="280" height="525">
    <v-row no-gutters justify="center">
      <h4 class="mr-1">{{ $t("TXT__AVERAGE_COMPETITIVENESS") }}</h4>
      <v-tooltip
        max-width="450px"
        color="#F5F5F5"
        style="pointer-events: all"
        right
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            style="cursor: pointer; pointer-events: all"
            v-bind="attrs"
            v-on="on"
            color="#6792F8"
            right
            small
          >
            mdi-information-outline</v-icon
          >
        </template>
        <span
          style="color: #000; font-size: 14px !important; line-height: 1.4"
          v-html="$t('TXT_COMPETITIVENESS_CARD')"
        ></span>
      </v-tooltip>
    </v-row>
    <!-- gráfico  -->
    <v-row no-gutters class="my-4" justify="center">
      <thermometer-chart
        :ic="ic"
        :thermometerHeightProps="310"
        :scaleThermometer="[75, 85, 95, 105, 115, 125]"
      />
    </v-row>
    <v-row no-gutters>
      <span class="text-center">
        {{ $t("TXT_CI_FIRST_PERIOD") }}

        <span class="font-weight-bold"
          >{{ ic | setIC }}
          {{
            ic > 100
              ? $t("TXT_CI_MIDDLE_PERIOD_HIGHER")
              : $t("TXT_CI_MIDDLE_PERIOD_LOWER")
          }}
        </span>
        {{ $t("TXT_CI_LAST_PERIOD") }}
      </span>
    </v-row>
  </v-card>
</template>

<script>
import ThermometerChart from "../components/thermometer-chart-2/thermometer-chart-2.vue";
export default {
  components: { ThermometerChart },
  props: ["ic"],
  filters: {
    setIC(value) {
      let ic = (value - 100).toFixed(2);
      if (ic < 0) {
        ic = ic.replace("-", "");
      }
      return ic + "%";
    },
  },
};
</script>