<template>
  <v-card class="pa-4 pt-0" flat :disabled="isDisable || isLoading" :loading="isLoading" v-ripple="false">
    <h4 class="mt-4">{{ this.$i18n.t("TXT_CRAWLERS_ATTACHED_COMPANY") }}</h4>

    <div class="crawler-info pa-4 my-6" v-if="data">
      <h4 class="mb-4">
        {{ data.idEmpresa }} -
        {{ data.nomeRazaoSocial.toUpperCase() }}
      </h4>

      <span class="crawler-info-title">{{ this.$i18n.t("TXT_CRAWLERS_ATTACHED") }}:</span>
      <span v-for="crawler in originalItems" :key="crawler.idCrawler">
        <span v-if="crawler.isLinked">
          {{ crawler.description }} - {{ crawler.idCrawler }} |
        </span>
      </span>
    </div>

    <v-row no-gutters class="mt-4" style="justify-content: space-between">
      <prd-search style="width: 45%" @input="searchTerm = $event" :searchLabel="$t('TXT_SEARCH_BY_NAME_ID')"
        :useDelay="false" />
      <prd-combo-box class="mt-1" style="width: 35%" v-model="linkedFilter" :title="$t('TXT_FILTER_LOWERCASE')"
        :items="itemsFilter" :placeholder="$t('TXT_ALL')" :useDelay="false" />
    </v-row>

    <div class="crawler-list mt-4">
      <div v-for="(item, index) in items" :key="index" class="crawler-item pa-4">
        <div style="display: flex; align-items: center">
          <v-simple-checkbox v-model="item.isLinked" :color="$prdStyles('color-primary')"
            @click="arrayComparer(item)"></v-simple-checkbox>
          <span>{{ item.description }}</span>
        </div>

        <div class="my-flex">
          <span class="font-weight-bold">ID: {{ item.idCrawler }}</span>
          |
          <span :class="[item.isOffline ? 'offline' : 'online']">{{
            item.isOffline ? "Offline" : "Online"
          }}</span>
          |
          <div class="mt-1" v-if="item.idCountry">
            <img width="25px" style="border-radius: 5px" :src="setToCountryFlag(item.idCountry)" />
          </div>
        </div>
      </div>
    </div>
    <v-row no-gutters justify="end" class="mt-4" v-if="!isDisable">
      <prd-btn @click="invertCard" :outlined="true" :title="$t('TXT_CANCEL')" class="mr-4" />
      <crawler-change-dialog v-if="newCrawlersLinked.length > 0 || crawlersUnlinked.length > 0" :data="bodyToDataChange"
        @buildBody="
          bodyToDataChange = buildDataToChange(
            newCrawlersLinked,
            crawlersUnlinked
          )
          " @invertCard="invertCard" @showSnackbar="$emit('showSnackbar', $event)" />
    </v-row>
  </v-card>
</template>

<script>
import PrdSearch from "@/Design_System/common/prd-search.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import CrawlerChangeDialog from "./management-crawler-change-dialog.vue";
import service from "@/service/crawlers/crawler-management.js";
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";
const Service = new service();
export default {
  components: { PrdSearch, PrdBtn, CrawlerChangeDialog, PrdComboBox },
  props: {
    isDisableSecondCard: {
      type: Boolean,
      default: true,
    },
    dataCrawlersLinked: {
      type: Object,
      default: null,
      deep: true,
    },
  },
  data() {
    return {
      data: null,
      isDisable: true,
      isLoading: false,
      originalItems: [],
      items: [],
      searchTerm: null,
      newCrawlersLinked: [],
      crawlersUnlinked: [],
      bodyToDataChange: null,
      linkedFilter: { text: this.$i18n.t("TXT_ALL"), value: "" },
      itemsFilter: [
        {
          text: this.$i18n.t("TXT_NOT_ATTACHED"),
          value: false,
        },
        {
          text: this.$i18n.t("TXT_ATTACHED_2"),
          value: true,
        },
        { text: this.$i18n.t("TXT_ALL"), value: "" },
      ],
    };
  },
  watch: {
    searchTerm: {
      handler() {
        this.searchV2()
      },
    },

    linkedFilter: {
      handler() {
        this.searchV2()
      },
    },

    dataCrawlersLinked: {
      handler(newValue) {
        if (newValue !== null) {
          this.isLoading = true;
          this.data = newValue;
          this.setLinkedCrawler(newValue.crawlerLinked);
          this.linkedFilter = {
            text: this.$i18n.t("TXT_ATTACHED_2"),
            value: true,
          }
          this.searchV2()
          this.isLoading = false;
        }
      },
    },

    isDisableSecondCard: {
      handler(newValue) {
        this.isDisable = newValue;
      },
    },
  },
  methods: {
    getAllCrawler() {
      this.isLoading = true;
      Service.getAllCrawler()
        .then((res) => {
          if (res.status != 500) {
            let data = res.data.answer;
            data.forEach((element) => {
              this.$set(element, "isLinked", false);
            });
            data.sort(this.orderArrayById);
            this.items = data;
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log("Erro do getAllCrawler: ", error);
        });
    },
    orderArrayById(actual, next) {
      return actual.idCrawler - next.idCrawler;
    },
    setToCountryFlag(value) {
      switch (value) {
        case 29:
          return require("../../../../../../public/images/icons/flags/br.svg");
        case 232:
          return require("../../../../../../public/images/icons/flags/us.svg");
        case 205:
          return require("../../../../../../public/images/icons/flags/es.svg");
        default:
          return "";
      }
    },
    setLinkedCrawler(listCrawlerLinked) {
      this.isLoading = true;
      listCrawlerLinked.forEach((crawlerLinked) => {
        if (crawlerLinked !== null) {
          let foundedIndex = this.items.findIndex(
            (elementFoundedIndex) =>
              elementFoundedIndex.idCrawler ===
              crawlerLinked.idCrawler
          );

          this.items[foundedIndex].isLinked = true;
          this.items[foundedIndex].idCompanyMonitoringCrawler = crawlerLinked.idCompanyMonitoringCrawler;
        }
      });
      this.originalItems = JSON.parse(JSON.stringify(this.items));
      this.isLoading = false;
    },
    buildDataToChange(newCrawlersLinked, crawlersUnlinked) {
      return {
        idEmpresa: this.data.idEmpresa,
        nomeRazaoSocial: this.data.nomeRazaoSocial,
        newCrawlersLinked: newCrawlersLinked,
        crawlersUnlinked: crawlersUnlinked,
      };
    },
    arrayComparer(crawler) {
      const id = crawler.idCrawler
      const originalIsLinked = this.originalItems.find((item) => item.idCrawler == id).isLinked
      const modifiedIsLinked = crawler.isLinked

      if (originalIsLinked != modifiedIsLinked) {
        if (modifiedIsLinked) {
          this.newCrawlersLinked.push(crawler)
        } else {
          this.crawlersUnlinked.push(crawler)
        }
      }
      else {
        const linkedIndex = this.newCrawlersLinked.findIndex((crawler) => crawler.idCrawler == id)
        if (linkedIndex !== -1) this.newCrawlersLinked.splice(linkedIndex, 1)
        const unlinkedIndex = this.crawlersUnlinked.findIndex((crawler) => crawler.idCrawler == id)
        if (unlinkedIndex !== -1) this.crawlersUnlinked.splice(unlinkedIndex, 1)
      }
    },
    searchV2() {
      this.isLoading = true;
      this.items = []
      let filteredList = JSON.parse(JSON.stringify(this.originalItems))
      if (this.searchTerm && this.searchTerm != '') {
        filteredList = filteredList.filter((crawler) => crawler.idCrawler.toString().includes(this.searchTerm.toString()) || crawler.description.toString().toLowerCase().includes(this.searchTerm.toString().toLowerCase()))
      }
      if (this.linkedFilter && this.linkedFilter.value !== '') {
        filteredList = filteredList.filter((crawler) => crawler.isLinked === this.linkedFilter.value)
      }
      this.items = filteredList
      this.isLoading = false;

    },
    invertCard() {
      this.isLoading = true;
      this.$emit("dataCrawlersLinked", null);
      this.searchTerm = "";
      this.linkedFilter = { text: this.$i18n.t("TXT_ALL"), value: "" };
      this.data = null;
      this.newCrawlersLinked = [];
      this.crawlersUnlinked = [];
      this.originalItems.map((element) => {
        element.isLinked = false;
      });
      this.items.map((element) => {
        element.isLinked = false;
      });
      this.isDisable = true;
      this.$emit("invertCard", false);
      this.isLoading = false;
    },
  },

  created() {
    this.getAllCrawler();
  },
};
</script>

<style lang="scss" scoped>
.crawler-list {
  max-height: 300px !important;
  overflow: auto;
}

.crawler-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $neutral-color-high-light;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 4px;
}

.online {
  color: $brand-color-primary-pure;
}

.offline {
  color: $neutral-color-low-light;
}

.my-flex {
  display: flex;
  align-items: center;
  gap: 4px;
}

.crawler-info {
  background-color: $neutral-color-high-light;
}

.crawler-info-title {
  color: $brand-color-primary-pure;
}
</style>

