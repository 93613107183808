var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","color":"transparent"}},[_c('h4',[_vm._v("FILTROS")]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"5","md":"4"}},[_c('span',[_c('label',{directives:[{name:"t",rawName:"v-t",value:('TXT_REFERENCE_PERIOD'),expression:"'TXT_REFERENCE_PERIOD'"}]})]),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-datapicker mt-1",attrs:{"label":_vm.$t('TXT_SELECT_PERIOD'),"placeholder":_vm.$t('TXT_SELECT_PERIOD'),"hide-details":"","outlined":"","single-line":"","dense":"","readonly":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticClass:"append-calendar",attrs:{"color":"white","small":""}},[_vm._v(" mdi-calendar")])]},proxy:true}],null,true),model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","range":"","min":_vm.minDay,"max":_vm.maxDay},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:('TXT_CANCEL'),expression:"'TXT_CANCEL'"}],attrs:{"text":"","color":"error"},on:{"click":function($event){_vm.menu = false && _vm.dateRangeText == null}}}),_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.setDate}},[_vm._v("Reset")]),_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:('TXT_OK'),expression:"'TXT_OK'"}],attrs:{"color":"primary"},on:{"click":function () {
                _vm.$refs.menu.save(_vm.date);
                _vm.$emit('dateSelection', this$1.date);
              }}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }