<template>
  <div class="mt-6" v-if="service.listProducts.state() !== 'NOT_CALLED'">
    <h3 v-t="'TXT_PRODUCTS_UPPERCASE'" class="mb-6">
      <span v-if="service.listProducts.state() === 'DATA_READY'">
        ({{ service.listProducts.data.totalRecords }})
      </span>
    </h3>
    <div class="d-flex align-end justify-space-between gap-4">
      <div>
        <product-color-caption
          colorClass="blue"
          :text="
            idCompany == 3026
              ? $t('TXT_PRICES_UNCHANGED')
              : $t('TXT_SEMAPHORE_APPROVAL_REPROVAL')
          "
          tooltipText=""
        />
        <product-color-caption
          colorClass="green"
          :text="$t('TXT_SEMAPHORE_ALL_APPROVAL')"
          tooltipText=""
        />
        <!-- <product-color-caption
          colorClass="yellow"
          :text="$t('TXT_SEMAPHORE_LOW_VARIATION')"
          tooltipText=""
        /> -->
        <product-color-caption
          colorClass="red"
          :text="$t('TXT_SEMAPHORE_ALL_REPROVAL')"
          tooltipText=""
        />
      </div>

      <dialog-pricing :idPriceGroup="localIdEnterprisePriceGroups" />

      <div class="d-flex gap-6 align-end">
        <combo-box
          :hideIcon="true"
          :title="$t('TXT_ORDERING')"
          :placeholder="$t('TXT_ALPHABETICAL')"
          :items="[
            { text: $t('TXT_ALPHABETICAL'), value: 0 },
            { text: $t('TXT_MARGIN'), value: 1 },
            { text: $t('TXT_PROFIT'), value: 2 },
            { text: $t('TXT_REVENUE'), value: 3 },
          ]"
          :multiple="false"
          v-model="selectedOrder"
          class="max-width-200"
        />
        <combo-box
          :hideIcon="true"
          :title="$t('TXT_RESULTS_BY_PAGE')"
          :placeholder="$t('TXT_SELECT')"
          :items="[
            { text: 5, value: 5 },
            { text: 10, value: 10 },
            { text: 20, value: 20 },
            { text: 50, value: 50 },
            { text: 100, value: 100 },
          ]"
          :multiple="false"
          :value="recordsPerPage"
          @input="recordsPerPage = $event.value"
          class="max-width-200"
        />
      </div>
    </div>

    <loading-div
      v-if="service.listProducts.state() !== 'NOT_CALLED'"
      :is-loading="service.listProducts.isLoading"
      :error="service.listProducts.error"
      class="mt-6"
    >
      <v-expansion-panels
        class="d-flex flex-column"
        v-if="service.listProducts.state() === 'DATA_READY'"
      >
        <product-item
          v-for="item in service.listProducts.data.records"
          :key="item.key"
          :product="item"
          :listMapViewInfo="listMapViewInfo"
          :isDisabledManualPrice="isDisabledManualPrice"
        />
      </v-expansion-panels>
      <div
        class="d-flex align-center justify-space-between text-primary"
        v-if="service.listProducts.state() === 'DATA_READY'"
      >
        <div>
          {{ this.$i18n.t("TXT_SHOWING") }}:
          {{ service.listProducts.data.numberOfRecords }}
          {{ this.$i18n.t("TXT_OF") }}
          {{ service.listProducts.data.totalRecords }}
          {{ this.$i18n.t("TXT_RESULT") }}
        </div>
        <v-pagination
          :length="service.listProducts.data.totalPages"
          v-model="currentPage"
          total-visible="8"
        ></v-pagination>
        <div>
          {{ this.$i18n.t("TXT_PAGINA") }}
          <span class="px-3 py-2 bordered rounded-lg">{{
            service.listProducts.data.currentPage
          }}</span>
          {{ this.$i18n.t("TXT_OF") }}
          {{ service.listProducts.data.totalPages }}
        </div>
      </div>
    </loading-div>
  </div>
</template>
<script>
import productColorCaption from "./color-caption";
import productItem from "./product-item/product-item";
import comboBox from "@/Design_System/common/comboBox";
import loadingDiv from "@/Design_System/common/loading-div";
import AiPricingService from "@/service/ai-pricing";
import dialogPricing from "./dialog-pricing";

export default {
  props: [
    "idEnterprisePriceGroups",
    "filters",
    "isDisabledManualPrice",
    "dataByRedirect",
    "calcDate",
  ],
  components: {
    dialogPricing,
    productColorCaption,
    comboBox,
    loadingDiv,
    productItem,
  },
  data: () => ({
    currentPage: 1,
    recordsPerPage: 10,
    selectedOrder: null,
    listMapViewInfo: [],
    localIdEnterprisePriceGroups: null,
    localDataByRedirect: null,
    service: new AiPricingService(),
  }),
  methods: {
    userClickToFetchProducts() {
      this.$store.commit("resetState");
      this.fetchProducts();
    },

    async fetchProducts(dataByRedirect = null) {
      this.$emit("loadingProductList", true);
      try {
        this.service.listProducts.data = null;

        if (
          !this.localIdEnterprisePriceGroups &&
          dataByRedirect?.idEnterprisePriceGroup
        ) {
          this.localIdEnterprisePriceGroups =
            dataByRedirect.idEnterprisePriceGroup;
        }

        if (!this.localIdEnterprisePriceGroups) {
          return;
        }

        const request = {
          isSimulation: false,
          idEnterprisePriceGroups: this.localIdEnterprisePriceGroups,
          page: this.currentPage,
          recordsPerPage: this.recordsPerPage,
          order: this.selectedOrder?.value ?? 0,
          idCompany: this.idCompany,
          filter: dataByRedirect
            ? {
                idEnterprisePricesProjection: null,
                listIdEnterprisePricesProjection:
                  dataByRedirect.listIdEnterprisePricesProjection,
                customFilter: [],
              }
            : this.filters,
        };

        await this.service.listProducts.call(request);

        const res = await this.service.listMapViewInfo(this.idCompany);
        this.listMapViewInfo = res.data.result.leftSideInfo;
      } catch (error) {
        console.error(error);
        this.$store.commit(
          "snackbar-error",
          this.$i18n.t("TXT_FAIL_SEARCH_PRODUCT")
        );
      }

      this.$emit("loadingProductList", false);
    },

    choseFetchProducts() {
      const data = this.localDataByRedirect || null;
      this.fetchProducts(data);
    },
  },
  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },
  watch: {
    currentPage() {
      this.choseFetchProducts();
    },
    recordsPerPage() {
      this.choseFetchProducts();
    },
    filters() {
      if (this.filters.length != 0) {
        this.choseFetchProducts();
      }
    },
    idEnterprisePriceGroups() {
      if (this.calcDate) {
        this.localIdEnterprisePriceGroups = this.idEnterprisePriceGroups;
        this.localDataByRedirect = null;
        this.fetchProducts();
      }
    },
    selectedOrder() {
      this.choseFetchProducts();
    },

    dataByRedirect: {
      handler(value) {
        this.localDataByRedirect = value;
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.gap-6 {
  column-gap: 24px;
  row-gap: 24px;
}
.gap-4 {
  column-gap: 16px;
  row-gap: 16px;
}
.max-width-200 {
  max-width: 200px;
}
.text-primary {
  color: $brand-color-primary-pure;
}
.bordered {
  border: 1px solid $neutral-color-high-dark;
}
</style>
