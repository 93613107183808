import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class newAiPricing extends ServiceBase {
    async getFilters(idCompany, idPriceGroup) {
        return await axiosInstance.get(API_URL + `api/v2/Enterprise/PriceProjection/PriceProjectionFilter?idCompany=${idCompany}&idPriceGroup=${idPriceGroup}&isSimulation=false`)
    }

    async getCreatePricingFilters(idCompany) {
        return await axiosInstance.get(API_URL + `api/V2/Enterprise/PriceGroups/Filter?idCompany=${idCompany}&preSelect=undefined&showInApriori=false&showInPricing=true`)
    }

    async calculatePrices(idCompany, idEnterprisePriceGroup) {
        return await axiosInstance.get(API_URL + `api/v2/Enterprise/PriceProjection/CalculatePrices?idCompany=${idCompany}&idEnterprisePriceGroups=${idEnterprisePriceGroup}&isManual=false`);
    }

    async calculateRules(idCompany, idEnterprisePriceGroups) {
        return await axiosInstance.get(API_URL + `api/v2/Enterprise/PriceProjection/CalculateRules?idCompany=${idCompany}&idEnterprisePriceGroups=${idEnterprisePriceGroups}&isManual=false`);
    }

    async extractReportSAP(data) {
        return await axiosInstance.get(API_URL + "api/V2/Enterprise/PriceProjection/ListExport", { params: data });
    }

    async getErrorMessages(idPriceGroup) {
        return await axiosInstance.get(API_URL + `api/v2/Enterprise/PriceGroups/ListStatusMessages?idEnterprisePriceGroup=${idPriceGroup}`);
    }

    async getWarningToAprove(idEnterprisePriceGroup) {
        return await axiosInstance.get(API_URL + `api/v2/Enterprise/PriceGroups/VerifyWarning?idEnterprisePriceGroup=${idEnterprisePriceGroup}`);
    }

    async getWarningList(idEnterprisePriceGroup) {
        return await axiosInstance.get(API_URL + `api/v2/Enterprise/PriceGroups/ListPriceGroupsWarning?idEnterprisePriceGroup=${idEnterprisePriceGroup}`)
    }

    async termsAccepted(idEnterprisePriceGroup) {
        return await axiosInstance.post(API_URL + "api/v2/Enterprise/PriceGroups/AcceptWarning", { idEnterprisePriceGroup: idEnterprisePriceGroup })
    }

    async getProductList(request) {
        return await axiosInstance.post(API_URL + "api/v2/Enterprise/Pricing/ListFujioka", request)
    }

    async getPricingGroup(request) {
        return await axiosInstance.post(API_URL + "api/v2/Enterprise/PriceGroups/List", request);
    }

    async createNewPricingGroup(request) {
        return await axiosInstance.post(API_URL + "api/V2/Enterprise/PriceGroups", request);
    }



}