<template>
  <div>
    <v-icon
      @click="isPricingModalOpen = true"
      color="#1D8527"
      large
      class="mt-1"
      >mdi-plus-box</v-icon
    >
    <v-dialog v-model="isPricingModalOpen" :max-width="800">
      <v-card class="pa-4" :disabled="isLoading">
        <h4 class="mb-6">NOVA PRECIFICAÇÃO</h4>

        <v-row no-gutters>
          <v-col cols="12" md="6">
            <label>Nome da precificação</label>
            <v-text-field
              v-model="pricingName"
              outlined
              dense
              hide-details
              class="mt-2 mr-4"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <label>Data atual</label>
            <v-text-field
              v-model="currentDate"
              :disabled="true"
              outlined
              dense
              hide-details
              class="mt-2"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <div v-show="filterLoading" class="my-flex">
          <h4>Carregando filtros</h4>
          <v-progress-circular
            indeterminate
            :color="$prdStyles('color-primary')"
          ></v-progress-circular>
        </div>

        <v-row no-gutters class="mt-6">
          <v-col
            v-for="filter in filters"
            :key="filter.idEnterprisePriceGroupDefaultFilter"
            cols="12"
            md="6"
          >
            <label>{{ filter.fieldName }}</label>
            <prd-combo-box
              v-model="filter.selected"
              :items="filter.values"
              multiple
              small-chips
              deletable-chips
              class="mr-4"
            />
          </v-col>
        </v-row>

        <v-row no-gutters class="mt-4">
          <v-spacer></v-spacer>
          <prd-btn
            @click="isPricingModalOpen = false"
            :title="'Cancelar'"
            :outlined="true"
            :disabled="isLoading"
            class="mr-4"
          />
          <prd-btn
            @click="savePricing"
            :title="'Salvar precificação'"
            :loading="isLoading"
            :disabled="           
              pricingName == '' ||
              pricingName == null ||
              isLoading
            "
          />
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { formatDate } from "@/utils/format-date";
import prdComboBox from "@/Design_System/common/prd-comboBox.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import service from "@/service/new-ai-pricing/search-filters.js";
const Service = new service();
export default {
  components: { prdComboBox, PrdBtn },
  data() {
    return {
      filterLoading: false,
      isLoading: false,
      isPricingModalOpen: false,
      pricingName: null,
      filters: [],
    };
  },

  computed: {
    currentDate() {
      return formatDate(new Date(), "dd/MM/yyyy");
    },

    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  methods: {
    getModalFilters() {
      this.filterLoading = true;
      Service.getCreatePricingFilters(this.idCompany)
        .then((res) => {
          let response = res.data.result;

          response.forEach((filter) => {
            filter.selected = null;

            filter.values = filter.values.map((el) => ({
              text: el.description,
              value: el.value,
              idParent: el.idParent,
              idEnterprisePriceGroupDefaultFilterValue:
                el.idEnterprisePriceGroupDefaultFilterValue,
            }));
          });

          this.filters = response;
          this.filterLoading = false;
        })
        .catch((error) => {
          console.log("Erro ao  buscar filtros da modal", error);
          this.filterLoading = false;
        });

      // let response = [
      //   {
      //     idEnterprisePriceGroupDefaultFilter: 269,
      //     idParent: null,
      //     fieldName: "Categoria principal",
      //     order: 0,
      //     dbField: 5,
      //     groupTag: null,
      //     preSelect: false,
      //     values: [
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2023,
      //         idParent: null,
      //         value: "3.CONSTR_HIDRAULICA ",
      //         description: "3.CONSTR_HIDRAULICA ",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2024,
      //         idParent: null,
      //         value: "Impermeabilizantes",
      //         description: "Impermeabilizantes",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2025,
      //         idParent: null,
      //         value: "Utilidades Doméstica",
      //         description: "Utilidades Doméstica",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2026,
      //         idParent: null,
      //         value: "3.LAR_JARDINAGEM",
      //         description: "3.LAR_JARDINAGEM",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2027,
      //         idParent: null,
      //         value: "3.LAR_UTILIDADES DOMESTICAS E LAZER",
      //         description: "3.LAR_UTILIDADES DOMESTICAS E LAZER",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2028,
      //         idParent: null,
      //         value: "4.CONSTR_PINTURA E ACESSORIOS",
      //         description: "4.CONSTR_PINTURA E ACESSORIOS",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2029,
      //         idParent: null,
      //         value: "4.CONSTR_QUIMICO",
      //         description: "4.CONSTR_QUIMICO",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2030,
      //         idParent: null,
      //         value: "Pintura e Acessórios",
      //         description: "Pintura e Acessórios",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2031,
      //         idParent: null,
      //         value: "1.CAMPO_FERRAGENS",
      //         description: "1.CAMPO_FERRAGENS",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2032,
      //         idParent: null,
      //         value: "1.CONSTR_FERRAGENS",
      //         description: "1.CONSTR_FERRAGENS",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2033,
      //         idParent: null,
      //         value: "Agro e Jardinagem",
      //         description: "Agro e Jardinagem",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2034,
      //         idParent: null,
      //         value: "Eletrônico e Digital",
      //         description: "Eletrônico e Digital",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2035,
      //         idParent: null,
      //         value: "EXPOSITORES",
      //         description: "EXPOSITORES",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2036,
      //         idParent: null,
      //         value: "Ferragens",
      //         description: "Ferragens",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2037,
      //         idParent: null,
      //         value: "Hidráulica",
      //         description: "Hidráulica",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2038,
      //         idParent: null,
      //         value: "Iluminação e Acessórios",
      //         description: "Iluminação e Acessórios",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2039,
      //         idParent: null,
      //         value: "Piscina",
      //         description: "Piscina",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2040,
      //         idParent: null,
      //         value: "5.CONSTR_ELETRICA",
      //         description: "5.CONSTR_ELETRICA",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2041,
      //         idParent: null,
      //         value: "Automotivo e Acessórios",
      //         description: "Automotivo e Acessórios",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2042,
      //         idParent: null,
      //         value: "Fitas e Adesivos",
      //         description: "Fitas e Adesivos",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2043,
      //         idParent: null,
      //         value: "LAR_CUTELARIA",
      //         description: "LAR_CUTELARIA",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2044,
      //         idParent: null,
      //         value: "Materiais Elétricos",
      //         description: "Materiais Elétricos",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2045,
      //         idParent: null,
      //         value: "Segurança e EPIs",
      //         description: "Segurança e EPIs",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2046,
      //         idParent: null,
      //         value: "Ventiladores",
      //         description: "Ventiladores",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2047,
      //         idParent: null,
      //         value: "Ferramentas",
      //         description: "Ferramentas",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2048,
      //         idParent: null,
      //         value: "Marketing",
      //         description: "Marketing",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2049,
      //         idParent: null,
      //         value: "Materiais de Construção",
      //         description: "Materiais de Construção",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2050,
      //         idParent: null,
      //         value: "2.CAMPO_FERRAMENTAS",
      //         description: "2.CAMPO_FERRAMENTAS",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2051,
      //         idParent: null,
      //         value: "2.CONSTR_FERRAMENTAS",
      //         description: "2.CONSTR_FERRAMENTAS",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2052,
      //         idParent: null,
      //         value: "5.ELETRO_ELETRONICO",
      //         description: "5.ELETRO_ELETRONICO",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2053,
      //         idParent: null,
      //         value: "A-PRODUTOS NOVOS",
      //         description: "A-PRODUTOS NOVOS",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2054,
      //         idParent: null,
      //         value: "Limpeza",
      //         description: "Limpeza",
      //       },
      //     ],
      //   },
      //   {
      //     idEnterprisePriceGroupDefaultFilter: 176,
      //     idParent: 269,
      //     fieldName: "SubCategoria",
      //     order: 1,
      //     dbField: 6,
      //     groupTag: null,
      //     preSelect: false,
      //     values: [
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2055,
      //         idParent: 2023,
      //         value: "ACABAMENTOS P/ REGISTROS",
      //         description: "ACABAMENTOS P/ REGISTROS",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2056,
      //         idParent: 2023,
      //         value: "ANEIS BORRACHA P/ ESGOTO",
      //         description: "ANEIS BORRACHA P/ ESGOTO",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2057,
      //         idParent: 2023,
      //         value: "CANOPLAS P/ VALV. DESC.",
      //         description: "CANOPLAS P/ VALV. DESC.",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2058,
      //         idParent: 2023,
      //         value: "CONEXOES GALVANIZADA",
      //         description: "CONEXOES GALVANIZADA",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2059,
      //         idParent: 2023,
      //         value: "CONEXOES LINHA AGUA - ESGOTO",
      //         description: "CONEXOES LINHA AGUA - ESGOTO",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2060,
      //         idParent: 2023,
      //         value: "CONEXOES LINHA AGUA - MARROM",
      //         description: "CONEXOES LINHA AGUA - MARROM",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2061,
      //         idParent: 2023,
      //         value: "CONEXOES PARA GAS",
      //         description: "CONEXOES PARA GAS",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2062,
      //         idParent: 2023,
      //         value: "DESENTUPIDORES",
      //         description: "DESENTUPIDORES",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2063,
      //         idParent: 2023,
      //         value: "GRELHAS P/ RALO INOX",
      //         description: "GRELHAS P/ RALO INOX",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2064,
      //         idParent: 2023,
      //         value: "OUTROS",
      //         description: "OUTROS",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2065,
      //         idParent: 2023,
      //         value: "PARAFUSOS P/ FIXAR ASSENTO SANITARIO",
      //         description: "PARAFUSOS P/ FIXAR ASSENTO SANITARIO",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2066,
      //         idParent: 2023,
      //         value: "REGISTROS METAL GAVETA",
      //         description: "REGISTROS METAL GAVETA",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2067,
      //         idParent: 2023,
      //         value: "REGISTROS P MAQ LAVAR ROUPA",
      //         description: "REGISTROS P MAQ LAVAR ROUPA",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2068,
      //         idParent: 2023,
      //         value: "REGISTROS PVC GAVETA",
      //         description: "REGISTROS PVC GAVETA",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2069,
      //         idParent: 2023,
      //         value: "REGISTROS PVC PRESSAO",
      //         description: "REGISTROS PVC PRESSAO",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2070,
      //         idParent: 2023,
      //         value: "SALVA REGISTRO",
      //         description: "SALVA REGISTRO",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2071,
      //         idParent: 2023,
      //         value: "SIFOES COPO",
      //         description: "SIFOES COPO",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2072,
      //         idParent: 2023,
      //         value: "TORNEIRAS BOIA BEBEDOURO",
      //         description: "TORNEIRAS BOIA BEBEDOURO",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2073,
      //         idParent: 2023,
      //         value: "TORNEIRAS METAL BICA MOVEL",
      //         description: "TORNEIRAS METAL BICA MOVEL",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2074,
      //         idParent: 2023,
      //         value: "TORNEIRAS METAL P/ LAVATORIO",
      //         description: "TORNEIRAS METAL P/ LAVATORIO",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2075,
      //         idParent: 2023,
      //         value: "TORNEIRAS PLAST. MAQ. LAVAR",
      //         description: "TORNEIRAS PLAST. MAQ. LAVAR",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2076,
      //         idParent: 2023,
      //         value: "TORNEIRAS PLAST. PIA",
      //         description: "TORNEIRAS PLAST. PIA",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2077,
      //         idParent: 2023,
      //         value: "TORNEIRAS PVC BICA MOVEL",
      //         description: "TORNEIRAS PVC BICA MOVEL",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2078,
      //         idParent: 2023,
      //         value: "VALVULAS P/ LAVATORIO",
      //         description: "VALVULAS P/ LAVATORIO",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2079,
      //         idParent: 2023,
      //         value: "VEDA ROSCA",
      //         description: "VEDA ROSCA",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2080,
      //         idParent: 2023,
      //         value: "VEDANEIS",
      //         description: "VEDANEIS",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2081,
      //         idParent: 2024,
      //         value: "Aditivos",
      //         description: "Aditivos",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2082,
      //         idParent: 2024,
      //         value: "Espuma Expansiva",
      //         description: "Espuma Expansiva",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2083,
      //         idParent: 2024,
      //         value: "Fitas",
      //         description: "Fitas",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2084,
      //         idParent: 2024,
      //         value: "Mantas",
      //         description: "Mantas",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2085,
      //         idParent: 2024,
      //         value: "Rejunte",
      //         description: "Rejunte",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2086,
      //         idParent: 2024,
      //         value: "Rejunte Acrílico",
      //         description: "Rejunte Acrílico",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2087,
      //         idParent: 2024,
      //         value: "Rejunte Cerâmica",
      //         description: "Rejunte Cerâmica",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2088,
      //         idParent: 2024,
      //         value: "Rejunte Epoxi",
      //         description: "Rejunte Epoxi",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2089,
      //         idParent: 2024,
      //         value: "Rejunte Extra",
      //         description: "Rejunte Extra",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2090,
      //         idParent: 2024,
      //         value: "Rejunte Porcelanato",
      //         description: "Rejunte Porcelanato",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2091,
      //         idParent: 2024,
      //         value: "Vedação",
      //         description: "Vedação",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2092,
      //         idParent: 2025,
      //         value: "Acessórios",
      //         description: "Acessórios",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2093,
      //         idParent: 2025,
      //         value: "Balanças",
      //         description: "Balanças",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2094,
      //         idParent: 2025,
      //         value: "Caixa para Cartas",
      //         description: "Caixa para Cartas",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2095,
      //         idParent: 2025,
      //         value: "Canivetes",
      //         description: "Canivetes",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2096,
      //         idParent: 2025,
      //         value: "Escadas",
      //         description: "Escadas",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2097,
      //         idParent: 2025,
      //         value: "Facas",
      //         description: "Facas",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2098,
      //         idParent: 2025,
      //         value: "Fixadores",
      //         description: "Fixadores",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2099,
      //         idParent: 2025,
      //         value: "Limpeza",
      //         description: "Limpeza",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2100,
      //         idParent: 2025,
      //         value: "Suportes",
      //         description: "Suportes",
      //       },
      //       {
      //         idEnterprisePriceGroupDefaultFilterValue: 2101,
      //         idParent: 2025,
      //         value: "Tesouras",
      //         description: "Tesouras",
      //       },
      //     ],
      //   },
      // ];
    },

    savePricing() {
      this.isLoading = true;
      let request = this.buildRequest();
      Service.createNewPricingGroup(request)
        .then((res) => {
          console.log("Resposta do criar precificação", res);
          if (res.status != 500) {
            this.$emit("setSavePricingJob");
            this.isPricingModalOpen = false;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("Erro ao criar precificação", error);
          this.isLoading = false;
        });
    },

    buildRequest() {
      let request = {
        idCompany: this.idCompany,
        name: this.pricingName,
        idEnterprisePriceGroup: 0,
        isSimulation: false,
        filters: [],
      };

      let selectedFilterList = [];

      this.filters.forEach((el) => {
        if (el.selected) {
          el.selected.forEach((filter) => {
            let filterModel = {
              idEnterprisePriceGroup_DefaultFilter:
                el.idEnterprisePriceGroupDefaultFilter,
              idEnterprisePriceGroup_Filter: 0,
              value: filter.value,
              isDeleted: false,
            };
            selectedFilterList.push(filterModel);
          });
        }
      });

      if (selectedFilterList.length != 0) {
        request.filters = selectedFilterList;
      }
      return request;
    },
  },

  mounted() {
    this.getModalFilters();
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  gap: 1rem;
}
</style>