<template>
  <v-dialog v-model="isModalOpen" :max-width="1000" persistent>
    <v-card class="pa-4">
      <div class="text-center mt-6">
        <img
          style="width: 150px"
          src="@/assets/flags/GIF_LOGO_PREDIFY.gif"
          alt="GIF Description"
        />
      </div>
      <h2 class="text-center my-6">{{ this.$i18n.t(loadingText) }}</h2>

      <v-row no-gutters justify="center">
        <v-col cols="12" md="6">
          <v-progress-linear
            :color="$prdStyles('color-primary')"
            background-color="#f5f5f5"
            height="8px"
            :value="loadingValue"
            rounded
          ></v-progress-linear>
        </v-col>
      </v-row>

      <p class="text-center mt-6">{{ $t("TXT_MAY_TAKE_A_WHILE") }}</p>
      <p class="text-center pricing-warning">
        {{ $t("TXT_PRICING_CALCULATION_WARNING") }}
      </p>
    </v-card>
  </v-dialog>
</template>

<script>
import service from "@/service/ai-pricing/create-pricing.js";
const Service = new service();

export default {
  props: ["controlModal", "idEnterprisePriceGroups", "isFromLoading"],

  data() {
    return {
      isModalOpen: false,
      isLoading: false,
      loadingText: "TXT_PRICING_ANALYZING",
      loadingValue: 3,
      intervalId: null,
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  watch: {
    controlModal: {
      handler(value) {
        console.log("Fecha a modal", value);
        if (value) {
          this.isModalOpen = value;
          if (!this.isFromLoading) this.calculatePricing();
          this.getPricingStatus();
        } else {
          clearInterval(this.intervalId);
          this.isModalOpen = value;
        }
      },
    },

    loadingValue: {
      handler(value) {
        if (value == 10000) {
          clearInterval(this.intervalId);
          this.$store.commit("snackbarV2/set", {
            message: this.$t("TXT_ERROR_CALCULATING_PRICING"),
            type: "error",
          });
          this.$emit("calculateFinish", this.idEnterprisePriceGroups, false);
        }
      },
    },
  },

  methods: {
    async calculatePricing() {
      try {
        await Service.calculatePricing(
          this.idCompany,
          this.idEnterprisePriceGroups
        );

        this.$emit("calculateFinish", this.idEnterprisePriceGroups, true);
      } catch (error) {
        console.log(error);
      }
    },

    getPricingStatus() {
      this.intervalId = setInterval(() => {
        this.getStatus();
      }, 2000);
    },

    async getStatus() {
      try {
        let response = await Service.getPricingStatus(
          this.idEnterprisePriceGroups
        );
        let data = response?.data ?? null;
        this.loadingText = data.message;
        this.loadingValue = data.enumMessage;

        if (this.loadingValue == 100) {
          clearInterval(this.intervalId);
          if (this.isFromLoading)
            this.$emit("calculateFinish", this.idEnterprisePriceGroups, true);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pricing-warning {
  border: 0.5px solid $feedback-color-warning-pure;
  border-radius: 5px;
  color: $feedback-color-warning-pure;
  padding: 8px;
  margin-top: 140px;
}
</style>
