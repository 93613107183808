<template>
  <v-card elevation="4">
    <highcharts class="hc" :options="chartData"></highcharts>
  </v-card>
</template>

<script>
import Highcharts from "highcharts";

// Exporta data in PNG, JPEG, PDF, SVG, imprime and fullscreen
import exportingInit from "highcharts/modules/exporting";
import ServiceUPL from "@/service/upl/index";
exportingInit(Highcharts);

// export data CSV, XLS, e change vizualization per table
import exportData from "highcharts/modules/export-data";
exportData(Highcharts);
export default {
  props: ["product"],
  data() {
    return {
      service: new ServiceUPL(),
      dados: [],
      series: [],
      chartData: {
        chart: {
          type: "column",
        },
        title: {
          text: "<b> CUSTO PARA PRECIFICAÇÃO | PRINCIPAL EMBALAGEM",
          align: "left",
        },
        subtitle: {
          text: "Fonte: SCM/Supply",
          align: "left",
        },
        xAxis: {
          title: {
            text: "Data",
          },
          categories: [
          ],
          crosshair: true,
        },
        yAxis: {
          title: {
            text: "Custo",
          },
        },

        series: [
          {
            name: "",
            data: [

            ],
            color: "#6792F8",
          },
        ],
      },
    };
  },
  beforeMount() {
    if (this.product != null) {
      this.service.getCustoReposicaoCompletoFiles(this.product.nome).then((res) => {
        this.dados = res.data;
        this.series.push({
          name: this.product.nome,
          data: this.dados.values,
          color: "#3C5CA7",
        });
        this.chartData.series = this.series;
        this.chartData.xAxis.categories = this.dados.meses;
        this.chartData.subtitle.text = "Fonte: SCM/Supply | Atualizado em " + this.dados.dataInsert;
      });
    }
  }
};
</script>