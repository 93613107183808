<template>
  <v-card :loading="isLoading" :disabled="isLoading" class="pa-4">
    <h3 v-t="$t('TXT_CRAWLER_LIST')" />
    <v-row no-gutters justify="space-between" align="end" class="my-4">
      <v-col cols="auto">
        <v-row no-gutters class="gap-4">
          <prd-search @input="filterCrawlers" :useDelay="false" v-model="searchTerm" />
          <prd-combo-box :title="'Tipo'" v-model="selectedIsOffline" :items="isOfflineList" @input="filterCrawlers" />
        </v-row>
      </v-col>
      <v-col cols="auto">
        <crawler-config-add-dialog :lastId="lastID" :crawlerList="originalContentTable" :countryList="countryList"
          :sourceList="sourceList" :languageList="languageList" :typeList="typeList" @ActualizarTable="getCrawler"
          @showSnackbar="showSnackbar" />
      </v-col>
    </v-row>
    <v-data-table :headers="headersTable" :items="contentTable"
      :hide-default-footer="contentTable == [] || contentTable.length == 0" :footer-props="footerProps"
      :no-data-text="$t('TXT_NO_DATA_AVAILABLE')">
      <!-- Description Field -->
      <template v-slot:[`item.description`]="{ item }">
        <td class="truncate" :style="{ maxWidth: '400px' }">
          <span class="pa-0" v-if="!item.isEdit">
            {{ item.description }}
          </span>
          <v-textarea single-line outlined v-if="item.isEdit" v-model="item.description" hide-details
            no-resize></v-textarea>
        </td>
      </template>

      <!-- Type Field -->
      <template v-slot:[`item.crawlerType`]="{ item }">
        <td style="text-align: center; max-width: 100px">
          <span v-if="!item.isEdit">{{
            getNameById(item.crawlerType, typeList)
          }}</span>
          <prd-combo-box v-if="item.isEdit" v-model="item.crawlerType" :items="typeList" clearable
            @click:clear="item.crawlerType = null" />
        </td>
      </template>

      <!-- toggle Online or Offline -->
      <template v-slot:[`item.isOffline`]="{ item }">
        <div class="ml-4" style="display: flex; justify-content: center; align: center">
          <v-switch dense hide-details v-model="item.isOffline" inset class="ma-0 pa-0"
            :disabled="!item.isEdit"></v-switch>
        </div>
      </template>

      <!-- Source Field -->
      <template v-slot:[`item.source`]="{ item }">
        <td style="text-align: center; max-width: 100px">
          <span v-if="!item.isEdit">{{
            getNameById(item.source, sourceList)
          }}</span>
          <prd-combo-box v-if="item.isEdit" v-model="item.source" :items="sourceList" :item-text="'text'"
            :item-value="'value'" clearable @click:clear="item.source = null" />
        </td>
      </template>

      <!-- Country Field -->
      <!-- <template v-slot:[`item.idCountry`]="{ item }">
        <td style="text-align: center; max-width: 100px">
          <span v-if="!item.isEdit">{{
            getNameById(item.idCountry, countryList)
          }}</span>
          <prd-combo-box v-if="item.isEdit" v-model="item.idCountry" :items="countryList" clearable @click:clear="item.idCountry = null" />
        </td>
      </template> -->

      <!-- Language Field -->
      <template v-slot:[`item.language`]="{ item }">
        <td style="text-align: center; max-width: 100px">
          <span v-if="!item.isEdit">{{ item.language | setLanguage }}</span>
          <prd-combo-box v-if="item.isEdit" v-model="item.language" :items="languageList" :item-text="'text'"
            :item-value="'value'" clearable @click:clear="item.language = null"/>
        </td>
      </template>

      <!-- Actions Field -->
      <template v-slot:[`item.actions`]="{ item }">
        <div style="display: flex; justify-content: center; gap: 8px">
          <v-icon v-if="!item.isEdit" @click="UpdateMode(true, item.idCrawler)"
            :color="$prdStyles('color-primary')">mdi-pencil-box
          </v-icon>
          <crawler-config-delete-dialog v-if="!item.isEdit" :item="item" @ActualizarTable="getCrawler"
            :countryList="countryList" :sourceList="sourceList" :typeList="typeList" @showSnackbar="showSnackbar" />
        </div>

        <div v-if="item.isEdit" style="display: flex; justify-content: center; gap: 8px">
          <v-icon @click="UpdateDataCrawler(item)" :color="$prdStyles('color-success')"
            :disabled="item.description.length < 1 || item.description == null">mdi-checkbox-marked</v-icon>
          <v-icon @click="UpdateMode(false, item.idCrawler)" :color="$prdStyles('color-warning')">mdi-close</v-icon>
        </div>
      </template>
    </v-data-table>


    <!-- MESSAGE COMPONENTS -->
    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.snackbarColor" :timeout="2000">
      {{ snackbar.snackbarText }}
    </v-snackbar>
  </v-card>
</template>

<script>
import CrawlerConfigDeleteDialog from "./crawler-config-delete-dialog.vue";
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";
import CrawlerConfigAddDialog from "./crawler-config-add-dialog.vue";
import CrawlerConfigService from "@/service/crawlers/crawler-config.js";
import PrdSearch from '@/Design_System/common/prd-search.vue';
export default {
  components: {
    PrdComboBox,
    CrawlerConfigDeleteDialog,
    CrawlerConfigAddDialog,
    PrdSearch,
  },
  data() {
    return {
      service: new CrawlerConfigService(),
      headersTable: [
        {
          text: "ID", value: "idCrawler", align: "center",
          width: "60px",
        },
        {
          text: this.$i18n.t("TXT_DESCRIPTION"),
          value: "description",
          align: "center",
          width: "250px",
        },
        {
          text: this.$i18n.t("TXT_TYPE"),
          value: "crawlerType",
          align: "center",
          width: "150px",
        },
        {
          text: this.$i18n.t("TXT_OFFLINE"),
          value: "isOffline",
          align: "center",
          width: "80px",
        },
        {
          text: this.$i18n.t("TXT_SOURCE"),
          value: "source",
          align: "center",
          width: "150px",
        },
        // {
        //   text: this.$i18n.t("TXT_COUNTRY"),
        //   value: "idCountry",
        //   align: "center",
        //   width: "100px",
        // },
        {
          text: this.$i18n.t("TXT_LANGUAGE"),
          value: "language",
          align: "center",
          width: "150px",
        },
        {
          text: this.$i18n.t("TXT_ACTIONS"),
          value: "actions",
          align: "center",
          width: "80px",

        },
      ],
      contentTable: [],
      originalContentTable: [],
      sourceList: [
        { text: "Predify", value: 1 },
        { text: "Linx", value: 2 },
        { text: "Horus_Search", value: 3 },
        { text: "Smarket", value: 4 },
      ],
      typeList: [
        { text: "Search", value: 1 },
        { text: "Paginate", value: 2 },
        { text: "Driver", value: 3 },
      ],
      countryList: [],
      languageList: ["PT-BR", "EN"],
      oldID: null,
      isLoading: false,
      // Snackbar vars
      snackbar: {
        snackbar: false,
        snackbarText: null,
        snackbarColor: "#3C5CA7",
      },
      lastID: null,
      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },
      selectedIsOffline: { text: 'Todos', value: null },
      isOfflineList: [{ text: 'Todos', value: null }, { text: 'Offline', value: true }, { text: 'Online', value: false }],
      searchTerm: null
    };
  },
  methods: {
    async getCrawler() {
      try {
        this.isLoading = true;
        const list = await this.service.GetAllCrawler();
        this.lastID = this.getLastIdCrawler(list.data.answer)
        const listOrganized = list.data.answer.map((crawler) => {
          return {
            ...crawler,
            isEdit: false,
            source: this.sourceList.find((source) => source.value == crawler.source) ?? null,
            crawlerType: this.typeList.find((type) => type.value == crawler.crawlerType) ?? null
          }
        });
        this.originalContentTable = listOrganized
        this.contentTable = listOrganized
        this.isLoading = false;
      }
      catch (error) {
        console.log(this.$i18n.t("TXT_ERROR_METHOD"), " 'getCrawler':", error);
      }
    },
    getLastIdCrawler(list) {
      let max = 0
      list.forEach((item) => {
        if (item.idCrawler > max) max = item.idCrawler
      })
      return max
    },
    async getCountries() {
      const request = await this.service.GetCountries();
      const list = request.data;
      list.forEach((el) => {
        this.countryList.push({ text: el.country, value: el.idCountries });
      });
    },

    UpdateMode(isOpen, id) {
      let indexCrawler = this.contentTable.findIndex(
        (linha) => linha.idCrawler == id
      );

      // first click
      if (isOpen == true && this.oldID == null && this.oldIndex == null) {
        this.oldIndex = indexCrawler;
        this.oldID = id;
        this.contentTable[indexCrawler].isEdit = true;
      }

      // other clicks
      else if (
        isOpen == true &&
        this.oldID != id &&
        this.oldIndex != indexCrawler
      ) {
        this.contentTable[this.oldIndex].isEdit = false;
        this.contentTable[indexCrawler].isEdit = true;
        this.oldIndex = indexCrawler;
        this.oldID = id;
      }

      // close mode
      else {
        this.contentTable[indexCrawler].idCrawler = this.originalContentTable[indexCrawler].idCrawler
        this.contentTable[indexCrawler].description = this.originalContentTable[indexCrawler].description
        this.contentTable[indexCrawler].isOffline = this.originalContentTable[indexCrawler].isOffline
        this.contentTable[indexCrawler].source = this.originalContentTable[indexCrawler].source
        this.contentTable[indexCrawler].crawlerType = this.originalContentTable[indexCrawler].crawlerType
        this.contentTable[indexCrawler].language = this.originalContentTable[indexCrawler].language
        this.contentTable[indexCrawler].idCountry = this.originalContentTable[indexCrawler].idCountry
        this.contentTable[indexCrawler].country = this.originalContentTable[indexCrawler].country
        this.contentTable[indexCrawler].isDeleted = this.originalContentTable[indexCrawler].isDeleted
        this.contentTable[indexCrawler].isEdit = false;
        this.contentTable[this.oldIndex].isEdit = false;
        this.oldIndex = null;
        this.oldID = null;
      }
    },

    buildRequest(item) {
      return {
        idCrawler: item.idCrawler,
        description: item.description,
        crawlerType: item.crawlerType?.value || null,
        isOffline: item.isOffline,
        source: item.source?.value || null,
        language: item.language,
        idCountry: item.idCountry?.value || null,
        isDeleted: false,
        isInsert: false
      };
    },

    async UpdateDataCrawler(item) {
      try {
        let request = this.buildRequest(item);
        let response = await this.service.InsertUpdateDeleteCrawler(request);
        if (response.status != 500) {
          console;
          this.UpdateMode(false, item.idCrawler);
          this.getCrawler();
          this.showSnackbar(
            `${this.$i18n.t("TXT_SUCCESS_ALTER")} CRAWLER !`,
            "success"
          );
        } else {
          this.showSnackbar(
            `${this.$i18n.t("TXT_ERROR_ALTER")} CRAWLER !`,
            "error"
          );
          this.UpdateMode(false, item.idCrawler);
        }
      } catch (error) {
        console.log(error);
      }
    },

    getNameById(value, list) {
      let localValue = null;
      if (value == null || value == "") return "-";
      if (value.value) {
        localValue = value.value;
      } else {
        localValue = value;
      }
      let nameFounded = list.find((el) => el.value == localValue);
      return nameFounded.text;
    },

    showSnackbar(text, type) {
      let snackbar = {
        snackbar: true,
        snackbarColor: null,
        snackbarText: null,
      };

      snackbar.snackbarText = text;

      switch (type) {
        case "success":
          snackbar.snackbarColor = "#56a667";
          break;
        case "error":
          snackbar.snackbarColor = "#ec4c37";
          break;
        case "info":
          snackbar.snackbarColor = "#3c5ca7";
      }
      this.snackbar = snackbar;
    },
    filterCrawlers() {
      let filteredList = this.originalContentTable
      if (this.searchTerm && this.searchTerm !== "") {
        filteredList = filteredList.filter((item) =>
          (item.idCrawler && item.idCrawler.toString().toLowerCase().includes(this.searchTerm.toString().toLowerCase())) ||
          (item.description && item.description.toString().toLowerCase().includes(this.searchTerm.toString().toLowerCase())) ||
          (item.crawlerType && item.crawlerType.text && item.crawlerType.text.toString().toLowerCase().includes(this.searchTerm.toString().toLowerCase())) ||
          (item.source && item.source.text && item.source.text.toString().toLowerCase().includes(this.searchTerm.toString().toLowerCase())) ||
          (item.language && item.language.toString().toLowerCase().includes(this.searchTerm.toString().toLowerCase()))
        );
      }
      if (this.selectedIsOffline && this.selectedIsOffline.value != null) {
        filteredList = filteredList.filter((item) => item.isOffline == this.selectedIsOffline.value)
      }
      this.contentTable = filteredList;
    },
  },
  async mounted() {
    // await this.getCountries();
    await this.getCrawler();
  },
  filters: {
    setLanguage(value) {
      return value ? value : "-";
    },
  },
};
</script>
<style lang="scss" scoped>
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
</style>