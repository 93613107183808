<template>
  <v-container fluid>
    <div v-if="editedProduct == null && newProduct == false">
      <titleBreadCrumbsComponent :title="'ONE PAGE'" :hasTooltip="false" />
      <v-card class="pa-4" elevation="4">
        <carousel-info :variaveisTributacao="variaveisTributacao" />

        <v-row no-gutters class="mb-4 gap-4" align="end">
          <v-col cols="12" md="3">
            <label>Selecione um segmento:</label>
            <v-combobox
              dense
              outlined
              append-icon="mdi-chevron-down"
              hide-details
              class="mt-2"
              :items="segments"
              :disabled="readOnly"
              v-on:change="loadProductsBySegment($event)"
              v-model="selectedSegment"
            >
            </v-combobox>
          </v-col>
          <v-col cols="12" md="3">
            <label>Selecione um produto:</label>
            <v-combobox
              dense
              outlined
              append-icon="mdi-chevron-down"
              hide-details
              class="mt-2"
              :items="filteredProducts"
              :disabled="readOnly"
              item-value="id"
              item-text="nome"
              v-on:change="loadProductDetails($event)"
              v-model="selectedProduct"
            >
            </v-combobox>
          </v-col>
          <v-col cols="auto">
            <Update-history :selectedProduct="selectedProduct" />
          </v-col>
        </v-row>

        <v-row no-gutters class="gap-4" v-if="!isConsultivo">
          <prd-btn
            style="height: 40px"
            @click="createNewProduct"
            :title="'Novo Produto'"
            :disabled="readOnly"
          />

          <prd-btn
            style="height: 40px"
            @click="editProduct"
            :title="'Editar Produto'"
            :disabled="readOnly"
          />

          <VariaveisFinanceiras
            v-if="variaveisTributacao != null"
            v-bind:variaveisTributacao="variaveisTributacao"
            :readOnly="readOnly"
          />

          <base-imports-main :readOnly="readOnly" />
          <prd-btn
            style="height: 40px"
            @click="relatorio"
            :outlined="true"
            :title="'Relatório'"
          />
        </v-row>
      </v-card>

      <info-product-main
        v-bind:key="idSelectedProduct"
        v-bind:product="this.selectedProduct"
        @newValueatt="selectedProduct[$event.fieldName] = $event.value"
      />
      <price-table-main
        v-bind:key="idSelectedProduct"
        v-bind:product="this.selectedProduct"
        @newValueatt="selectedProduct[$event.fieldName] = $event.value"
      />

      <charts-main
        v-if="selectedProduct"
        v-bind:product="this.selectedProduct"
      />
    </div>

    <New-edit-product
      v-else
      @closeNewEditProduct="closeNewEditProduct"
      :editedProduct="editedProduct"
    />
  </v-container>
</template>

<script>
import VariaveisFinanceiras from "./components/info-product/variaveis-financeiras.vue";
import titleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import InfoProductMain from "./components/info-product/info-product-main.vue";
import PriceTableMain from "./components/price-table/price-table-main.vue";
import NewEditProduct from "./components/inputs/V2/new-edit-products-main.vue";
import ServiceUPL from "@/service/upl/index";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import ChartsMain from "./components/charts/charts-main.vue";
import CarouselInfo from "./components/carousel-info.vue";
import BaseImportsMain from "./components/info-product/components/base-imports/v2/base-imports-main.vue";
import UpdateHistory from "./components/update-history/update-history-main.vue";
export default {
  name: "upl-main",
  components: {
    titleBreadCrumbsComponent,
    InfoProductMain,
    PriceTableMain,
    NewEditProduct,
    VariaveisFinanceiras,
    PrdBtn,
    ChartsMain,
    CarouselInfo,
    BaseImportsMain,
    UpdateHistory,
  },
  data() {
    return {
      products: [],
      filteredProducts: [],
      variaveisTributacao: null,
      selectedProduct: null,
      editedProduct: null,
      newProduct: false,
      service: new ServiceUPL(),
      selectedSegment: "Todos os segmentos",
      segments: [],
    };
  },

  computed: {
    readOnly() {
      return this.$store.getters.readOnlyUPL;
    },
    isConsultivo() {
      return this.$store.getters.Acesso_UPL_Leitor;
    },
    idSelectedProduct() {
      return this.selectedProduct?.id ?? null;
    },
  },

  methods: {
    async getProducts() {
      await this.service.getProducts().then((res) => {
        this.products = res.data;
        this.filteredProducts = res.data;
        this.selectedProduct = this.products[0];
        this.prepareSegments(res.data);
      });
    },
    prepareSegments(list) {
      this.segments = ["Todos os segmentos"];
      const allSegments = list.map((product) => {
        return product?.sobre?.segmento ?? null;
      });
      const uniqueSegments = [...new Set(allSegments)];
      uniqueSegments.sort();
      this.segments.push(...uniqueSegments);
    },
    loadProductsBySegment(segment) {
      let filterProducts = JSON.parse(JSON.stringify(this.products));
      if (segment !== "Todos os segmentos") {
        filterProducts = this.products.filter(
          (product) =>
            product?.sobre?.segmento.toLowerCase() == segment.toLowerCase()
        );
      }
      this.filteredProducts = filterProducts;
      this.selectedProduct = filterProducts[0];
    },
    async getVariaveisTributacao() {
      await this.service.getVariaveisTributacao().then((res) => {
        this.variaveisTributacao = res.data;
        localStorage.setItem("valorDolar", res.data.dolar);
      });
    },
    async relatorio() {
      await this.service
        .getRelatorio()
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/vnd.ms-excel" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "RelatorioUPL.xlsx");
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadProductDetails(id) {
      if (!id) return;
      this.selectedProduct = this.products.find((x) => x.id == id.id);
    },
    editProduct() {
      this.editedProduct = this.selectedProduct;
      this.newProduct = false;
    },
    async closeEditProduct(id) {
      this.editedProduct = null;
      this.newProduct = false;
      this.products = [];
      this.filteredProducts = [];
      await this.service.getProducts().then((res) => {
        this.products = res.data;
        this.filteredProducts = res.data;
        this.prepareSegments(res.data);
        this.selectedProduct = this.products.find((x) => x.id == id);
      });
    },
    createNewProduct() {
      this.newProduct = true;
      this.editedProduct = null;
    },
    closeNewEditProduct() {
      this.newProduct = false;
      this.editedProduct = null;
    },
  },
  async beforeMount() {
    await this.getProducts();
    await this.getVariaveisTributacao();
  },
};
</script>
