<template>
  <v-card
    :class="disableElasticity ? 'disableElasticity' : ''"
    class="pa-4 d-flex flex-column gap-4 font-size-12"
    flat
  >
    <div class="d-flex align-center">
      <label
        v-t="'TXT_ELASTICITY_UPPERCASE'"
        class="font-weight-bold mr-4"
      ></label>
      <!-- <v-icon
        @click="
          searchElasticity(productRegion.price.idEnterprisePricesProjection)
        "
        right
        :color="$prdStyles('color-primary')"
        >mdi-arrow-right-bold-box</v-icon
      > -->
      <v-spacer />
      <div class="font-size-12">
        <span v-show="elasticityType"
          >{{ $t("TXT_TOOLTIP_ELASTICITY_TYPE_TITLE") }}:
          <span class="font-weight-bold">{{ elasticityType }}</span
          ><tooltip-informations
            :rightIcon="true"
            :title="$t('TXT_TOOLTIP_ELASTICITY_TYPE')"
        /></span>
        <span v-show="false">
          {{ $t("TXT_INDEX") }}:
          <span class="font-weight-bold">{{ elasticityIndex }}</span>
          <tooltip-informations
            :rightIcon="true"
            :title="$t('TXT_TOOLTIP_ELASTICITY_INDEX')"
          />
        </span>
      </div>
    </div>

    <div class="d-flex align-end gap-6">
      <div class="d-flex flex-column gap-2 flex-1">
        <v-row no-gutters align="center">
          <label v-t="'TXT_AVERAGE_PRICE'"></label>
          <prd-tooltip
            v-if="idCompany == 2858"
            :text="$t('TXT_AVERAGE_DEMAND')"
          />
        </v-row>
        <div class="bordered pa-2 rounded">
          <span v-if="averagePrice !== null">{{
            formatMonetary(averagePrice)
          }}</span>
          <span v-else>&nbsp;</span>
        </div>
      </div>

      <div class="d-flex flex-column gap-2 flex-1">
        <v-row no-gutters align="center">
          <label v-t="'TXT_ELASTICITY_MEAN_QUANTITY'"></label>
          <prd-tooltip
            v-if="idCompany == 2858"
            :text="$t('TXT_AVERAGE_DEMAND')"
          />
        </v-row>
        <div class="bordered pa-2 rounded">
          <span v-if="averageDemand !== null">{{ averageDemand }} </span>
          <span v-else>&nbsp;</span>
        </div>
      </div>
      <div class="d-flex flex-column gap-2 flex-1">
        <label v-t="'TXT_DEMAND_PRICE'"></label>
        <div class="bordered pa-2 rounded">
          <span v-if="salePrice !== null">{{
            formatMonetary(aiDemandPrice)
          }}</span>
          <span v-else>&nbsp;</span>
        </div>
      </div>
      <div class="d-flex flex-column gap-2 flex-1">
        <label v-t="'TXT_PREDICTED_DEMAND'"></label>
        <div class="bordered pa-2 rounded">
          <span v-if="calculatedDemand !== null">{{ calculatedDemand }}</span>
          <span v-else>&nbsp;</span>
        </div>
      </div>
      <div
        v-if="productRegion.elasticity != null"
        class="d-flex flex-column gap-2 flex-1"
      >
        <label v-t="'TXT_DEMAND_VARIABILITY'"></label>
        <div
          :class="[
            'bordered',
            'pa-2',
            'rounded',
            demandVariation >= 0 ? 'green-value' : 'red-value',
          ]"
        >
          <span v-if="demandVariation !== null">
            {{ formatPercentage(demandVariation) }}</span
          >
          <span v-else></span>
        </div>
      </div>
      <div class="d-flex flex-column gap-2 flex-1">
        <label v-t="'TXT_SALLES_VARIATION'"></label>
        <div
          :class="[
            'bordered',
            'pa-2',
            'rounded',
            salesVariation >= 0 ? 'green-value' : 'red-value',
          ]"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-if="salesVariation !== null" v-bind="attrs" v-on="on">{{
                formatMonetaryOriginalValue(salesVariation)
              }}</span>
              <span v-else>&nbsp;</span>
            </template>
            <span>{{ salesVariation }}</span>
          </v-tooltip>
          <!-- <span v-if="salesVariation !== null">{{
            formatMonetary(salesVariation)
          }}</span>
          <span v-else>&nbsp;</span> -->
        </div>
      </div>
    </div>
  </v-card>
</template>
<script>
import { formatNumberPercentage } from "@/utils/format-numbers";
import { formatMonetary } from "@/utils/format-toMonetary.js";
import prdTooltip from "@/Design_System/common/prd-tooltip.vue";
import TooltipInformations from "@/Design_System/common/tooltip-informations.vue";
export default {
  components: { prdTooltip, TooltipInformations },
  props: ["productRegion", "productName"],
  data: () => ({
    disableElasticity: false,
  }),
  methods: {
    formatMonetary,
    formatPercentage(val) {
      return formatNumberPercentage(val, {
        alwaysRenderSign: true,
        fractionLength: 1,
      });
    },

    searchElasticity(idProductPredify) {
      top.window.location = `https://hom.predify.me/index_app.html#/main/resultados/dashboard-inteligente/3/${idProductPredify}`;
    },

    formatMonetaryOriginalValue(value) {
      if (value == null) return "-";

      if (value == 0.0) return formatMonetary(value);

      const [first, second] = value.toString().split(".");

      const formatedValue =
        second.substring(0, 2) == "00" ? parseFloat(`${first}.01`) : value;

      return formatMonetary(formatedValue);
    },
  },
  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    demandVariation() {
      if (this.idCompany == 2857)
        //Feito temporariamente para a Suzano
        return null;

      return (this.calculatedDemand / this.averageDemand - 1) * 100 ?? null;
    },
    salesVariation() {
      let meanPrice = null;
      let meanQuantity = null;
      let calculatedDemand = null;

      if (this.productRegion?.price?.elasticity) {
        calculatedDemand = this.productRegion?.price?.suggestedPrice_Demand;
        meanPrice = this.productRegion?.price?.elasticity.meanPrice;
        meanQuantity = this.productRegion?.price?.elasticity.meanQuantity;
      } else if (this.productRegion?.elasticity) {
        if (this.productRegion?.price.override != null) {
          if (this.productRegion?.price.override.isManual)
            calculatedDemand =
              this.productRegion?.price.override?.manual_Price_Demand;
          else
            calculatedDemand = this.productRegion?.price.override?.price_Demand;
        } else
          calculatedDemand = this.productRegion?.elasticity?.calculatedDemand;

        meanPrice = this.productRegion?.elasticity?.priceDemand;
        meanQuantity = this.productRegion?.elasticity?.averageDemand;
      }

      if (this.idCompany == 2857)
        //Feito temporariamente para a Suzano
        return null;

      // if (
      //   typeof this.productRegion?.elasticity?.priceDemand !== "number" ||
      //   typeof this.productRegion?.elasticity?.averageDemand !== "number" ||
      //   typeof this.productRegion?.elasticity?.calculatedDemand !== "number" ||
      //   typeof this.productRegion?.price?.suggestedPrice !== "number"
      // )
      //   return null;
      // else {
      let value =
        this.productRegion.price.suggestedPrice * calculatedDemand -
        meanPrice * meanQuantity;

      return value.toFixed(8);
      // }
    },
    aiDemandPrice() {
      return this.productRegion?.price?.suggestedPrice ?? null;
    },
    currentPrice() {
      return this.productRegion?.price?.lastSalePrice ?? null;
    },
    averagePrice() {
      let meanPrice = null;

      if (this.productRegion?.price?.elasticity) {
        meanPrice = this.productRegion?.price?.elasticity.meanPrice;
      } else if (this.productRegion?.elasticity) {
        meanPrice = this.productRegion?.elasticity?.priceDemand;
      }
      return meanPrice;
    },
    averageDemand() {
      let meanQuantity = null;

      if (this.productRegion?.price?.elasticity) {
        meanQuantity = this.productRegion?.price?.elasticity.meanQuantity;
      } else if (this.productRegion?.elasticity) {
        meanQuantity = this.productRegion?.elasticity?.averageDemand;
      }

      return meanQuantity?.toFixed(2).replace(".", ",") ?? null;
    },
    salePrice() {
      return this?.productRegion?.price?.salePrice ?? null;
    },
    calculatedDemand() {
      let calculatedDemand = null;

      if (
        this.productRegion?.price?.override ||
        this.productRegion?.price?.elasticity
      ) {
        calculatedDemand = this.productRegion?.price?.suggestedPrice_Demand;
      } else if (this.productRegion?.elasticity) {
        calculatedDemand = this.productRegion?.elasticity?.calculatedDemand;
      }

      if (
        calculatedDemand == null ||
        calculatedDemand == undefined ||
        isNaN(calculatedDemand)
      )
        return null;

      if (calculatedDemand < 0) return 0;

      if (calculatedDemand < 0) return 0;
      return calculatedDemand?.toFixed(2).replace(".", ",") ?? null;
    },
    idMonitoringItem() {
      return this.productRegion?.price?.idMonitoringItem ?? null;
    },
    elasticityType() {
      if (!this.productRegion) return null;
      let elasticity = null;

      if (
        this.productRegion?.price?.elasticity?.elasticity !== null &&
        this.productRegion?.price?.elasticity?.elasticity !== undefined
      ) {
        elasticity = this.productRegion.price.elasticity.elasticity.toFixed(2);
      } else if (
        this.productRegion?.elasticity?.elasticity !== null &&
        this.productRegion?.elasticity?.elasticity !== undefined
      ) {
        elasticity = this.productRegion.elasticity.elasticity.toFixed(2);
      } else {
        elasticity = null;
      }

      if (elasticity) {
        if (elasticity < 1 && elasticity > -1)
          return this.$i18n.t("TXT_INELASTIC");
        else return this.$i18n.t("TXT_ELASTIC");
      } else {
        return null;
      }
    },
    elasticityIndex() {
      if (!this.productRegion) return null;
      if (
        this.productRegion?.price?.elasticity?.elasticity !== null &&
        this.productRegion?.price?.elasticity?.elasticity !== undefined
      ) {
        return this.productRegion.price.elasticity.elasticity.toFixed(2);
      } else if (
        this.productRegion?.elasticity?.elasticity !== null &&
        this.productRegion?.elasticity?.elasticity !== undefined
      ) {
        return this.productRegion.elasticity.elasticity.toFixed(2);
      } else {
        return null;
      }
    },
  },
  mounted() {
    console.log(this.productRegion);
    if (
      this.productRegion.elasticity == null &&
      this.productRegion.price.elasticity == null
    ) {
      this.disableElasticity = true;
    }
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.card-subtitle {
  font-size: 16px;
  font-weight: bolder;
}

.border-prices-box {
  flex-grow: 1;
}

.bordered {
  border: 1px solid $neutral-color-low-light;
}

.disableElasticity {
  opacity: 0.5;
  pointer-events: none;
}

.red-value {
  color: $feedback-color-warning-pure;
}

.green-value {
  color: $feedback-color-success-pure;
}

.elasticity {
  justify-content: flex-start;
}
</style>
