<template>
  <v-card flat color="#E0E0E0" class="pa-4 my-2" style="border-radius: 5px">
    <v-row no-gutters class="gap-2 mb-2" style="border-radius: 5px">
      <span class="font-weight-bold">{{ data.date }}</span>
      <span class="font-weight-bold">{{ data.hour }}</span>
      |
      <span class="font-weight-bold">{{ data.productName }}</span>
      |
      <span class="font-weight-bold">Responsável: {{ data.userName }}</span>
    </v-row>
    <v-row no-gutters class="pa-2 gap-1" style="background-color: white">
      <v-col cols="12">
        <p v-for="(log, index) in changeLogs" :key="index" class="mb-2">
          {{ log }}
        </p>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: ["data"],
  computed: {
    changeLogs() {
      return this.data.changeLog.split(" | ");
    },
  },
};
</script>
