<template>
  <v-dialog v-model="isModalOpen" persistent width="100%" max-width="1224">
    <template v-slot:activator="{ on }">
      <v-icon
        @click="listMonitoringMarketResultItem"
        v-on="on"
        right
        :color="$prdStyles('color-primary')"
      >
        mdi-arrow-right-bold-box
      </v-icon>
    </template>
    <v-card class="pa-4">
      <h4 class="mb-6">{{ this.$i18n.t("TXT_COMPETITIVE_PRICES") }}</h4>
      <loading-div
        :is-loading="isLoading"
        :error="service.listMonitoringMarketResultItem.error"
      >
        <div>
          <h3
            v-t="this.productRegion.price.productName"
            class="mb-6 font-size-15"
          ></h3>

          <template>
            <!-- table -->
            <v-data-table
              :headers="headers"
              :items="beta_productsToShow"
              class="custom-table"
              :footer-props="footerProps"
              hide-default-header
            >
              <template v-slot:header>
                <td
                  v-for="(header, index) in headers"
                  :key="index"
                  class="original-header-table"
                >
                  <v-row no-gutters justify="center" align="center">
                    <span class="mr-1">
                      {{ header.text }}
                    </span>
                    <tooltip-informations
                      v-if="header.value == 'cityState' && idCompany == 3026"
                      :title="textTooltipVem"
                    />
                  </v-row>
                </td>
              </template>
              <!-- DISREGARDED -->
              <template v-slot:item="{ item }">
                <tr
                  v-show="isSeeDisregarded || !item.disregarded"
                  class="custom-table"
                >
                  <td
                    v-show="isOpenOrCloseDisregarded"
                    class="custom-table"
                    :class="{ 'background-color': item.disregarded }"
                  >
                    <v-simple-checkbox
                      :ripple="false"
                      :disabled="!isOpenOrCloseDisregarded"
                      outlined
                      color="#3C5CA7"
                      v-model="item.disregarded"
                    ></v-simple-checkbox>
                  </td>

                  <!-- PRODUCT NAME -->
                  <td
                    class="custom-table"
                    :class="{ 'background-color': item.disregarded }"
                  >
                    <div v-if="item.productLink">
                      <a target="_blank" :href="item.productLink">{{
                        item.productName
                      }}</a>
                    </div>
                    <span v-else>
                      {{ item.productName }}
                    </span>
                  </td>

                  <!-- BRAND -->
                  <td
                    class="custom-table"
                    :class="{ 'background-color': item.disregarded }"
                  >
                    {{ item.brand }}
                  </td>

                  <!-- MARKETPLACE -->
                  <td
                    class="custom-table"
                    :class="{ 'background-color': item.disregarded }"
                  >
                    {{ item.crawlerName }}
                  </td>

                  <!-- SELLER NAME -->
                  <td
                    class="custom-table"
                    :class="{ 'background-color': item.disregarded }"
                  >
                    <span v-if="item.sellerLink"
                      ><a target="_blank" :href="item.sellerLink">{{
                        item.sellerName
                      }}</a></span
                    >
                    <span v-else>{{ item.sellerName }}</span>
                  </td>

                  <!-- PRICE -->
                  <td
                    :class="`custom-table ${
                      item.disregarded == true ? 'background-color' : ''
                    } ${
                      verifyPriceAndSeller(item)
                        ? 'font-weight-bold background-color-equals-price'
                        : ''
                    } `"
                  >
                    {{ item.price | setToIsoString }}
                  </td>

                  <!-- City State -->
                  <td
                    class="custom-table"
                    :class="{ 'background-color': item.disregarded }"
                  >
                    {{ item.cityState }}
                  </td>

                  <!-- CRAWLER DATE -->
                  <td
                    class="custom-table"
                    :class="{ 'background-color': item.disregarded }"
                  >
                    {{ item.refDate | setDate }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </template>
        </div>
      </loading-div>

      <div>
        <v-row no-gutters align="center">
          <loading-div
            :is-loading="isLoading"
            v-show="isOpenOrCloseDisregarded"
          >
            <v-btn
              class="white--text mr-4 mb-6"
              @click="
                saveConsideredProducts(
                  beta_productsToShow,
                  productRegion.price.idMonitoringItem
                )
              "
              color="#56a667"
              >{{ this.$i18n.t("TXT_SAVE_EDITIONS") }}
            </v-btn>
            <v-btn
              class="white--text mr-4 mb-6"
              @click="closeDisregarded()"
              color="#ec4c37"
              >{{ this.$i18n.t("TXT_BACK") }}
            </v-btn>
          </loading-div>
        </v-row>
        <v-row no-gutters align="center">
          <!-- <loading-div :is-loading="isLoading">
            <v-btn
              :disabled="isOpenOrCloseDisregarded"
              @click="openOrCloseDisregarded"
              class="white--text mr-4"
              color="#3c5ca7"
              >{{ this.$i18n.t("TXT_SHOW_DISREGARDED") }}</v-btn
            >
          </loading-div> -->
          <v-spacer></v-spacer>
          <v-btn
            :disabled="isOpenOrCloseDisregarded"
            @click="closeDialog"
            outlined
            color="#3c5ca7"
          >
            {{ this.$i18n.t("TXT_CANCEL") }}</v-btn
          >
        </v-row>
      </div>

      <v-snackbar
        v-model="snackbar.snackbar"
        :color="snackbar.snackbarColor"
        :timeout="2000"
      >
        {{ snackbar.snackbarText }}
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>
//import prdBtn from "@/Design_System/common/prd-btn";
import loadingDiv from "@/Design_System/common/loading-div";
import AiPricingService from "@/service/ai-pricing";

import {
  formatNumber,
  formatNumberMoney,
  // formatNumberPercentage,
} from "@/utils/format-numbers";
import TooltipInformations from "@/Design_System/common/tooltip-informations.vue";

export default {
  components: {
    loadingDiv,
    TooltipInformations,
  },
  props: ["productRegion"],
  data() {
    return {
      service: new AiPricingService(),
      isModalOpen: false,
      isLoading: false,
      isFrontDisregarded: false,
      isOpenOrCloseDisregarded: false,
      isSeeDisregarded: false,
      selectedRow: null,
      beta_productsToShow: [],
      // Snackbar vars
      snackbar: {
        snackbar: false,
        snackbarText: null,
        snackbarColor: "#3C5CA7",
      },

      headers: [
        {
          text: this.$i18n.t("TXT_PRODUCT"),
          align: "center",
          value: "productName",
          width: "300px",
          sortable: false,
        },
        {
          text: this.$i18n.t("TXT_BRAND"),
          align: "center",
          value: "brand",
          sortable: false,
        },

        {
          text: this.$i18n.t("TXT_MARKETPLACE"),
          align: "center",
          value: "crawlerName",
          sortable: false,
        },
        {
          text: this.$i18n.t("TXT_SELLER"),
          align: "center",
          value: "sellerName",
          width: "180px",
          sortable: false,
        },
        {
          text: this.$i18n.t("TXT_PRICE"),
          align: "center",
          value: "price",
          width: "180px",
          sortable: false,
        },
        {
          text: `${this.$i18n.t("TXT_CITY")} / ${this.$i18n.t("TXT_STATE")}`,
          align: "center",
          value: "cityState",
          width: "180px",
          sortable: false,
        },
        {
          text: this.$i18n.t("TXT_COLECT_DATE"),
          align: "center",
          value: "refDate",
          width: "180px",
          sortable: false,
        },
      ],
      disregarded: {
        text: this.$i18n.t("TXT_DISREGARD"),
        align: "center",
        value: "disregarded",
        width: "180px",
        sortable: false,
      },
      item: [],
      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },
      textTooltipVem:
        "<p>Caso não tenha dados dos concorrentes da mesma cidade da filial, a busca será feita no estado.</p><p> Se ainda assim não possuir dados no estado da filial, a busca será feita no Brasil, a fim de garantir dados de concorrência.</p>",
    };
  },

  watch: {
    isModalOpen() {
      if (!this.isModalOpen) return;
    },
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  methods: {
    formatNumber,
    formatNumberMoney,

    resolveNumberColor(num) {
      return num > 0 ? "green" : num < 0 ? "red" : "blue";
    },
    verifyLink(url) {
      const validUrl = require("valid-url");
      return validUrl.isWebUri(url);
    },

    listMonitoringMarketResultItem() {
      this.isLoading = true;
      this.beta_productsToShow = [];

      this.service
        .listMonitoringMarketResultItem(
          this.idCompany,
          this.productRegion.price.marketResult
            ?.idMonitoring_ProductMarketResult
        )
        .then((res) => {
          this.beta_productsToShow = res.data.result;
          this.beta_productsToShow.forEach((element) => {
            if (!this.verifyLink(element.productLink)) {
              element.productLink = null;
            }

            if (!this.verifyLink(element.sellerLink)) {
              element.sellerLink = null;
            }

            if (!element.localCity && element.localState) {
              element.cityState = `${element.localState.toUpperCase()}`;
            } else if (element.localCity) {
              const city =
                element.localCity.charAt(0).toUpperCase() +
                element.localCity.slice(1);
              if (!element.localState) {
                element.cityState = `${city}`;
              } else {
                element.cityState = `${city} / ${element.localState.toUpperCase()}`;
              }
            } else {
              element.cityState = "-";
            }
          });
          this.isLoading = false;
          //console.log('aqui', this.beta_productsToShow)
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$store.commit(
            "snackbar-error",
            this.$i18n.t("TXT_FAIL_SEARCH_PRODUCT")
          );
        });
    },

    closeDialog() {
      this.isModalOpen = false;
    },

    openOrCloseDisregarded() {
      this.isOpenOrCloseDisregarded = !this.isOpenOrCloseDisregarded;

      this.isSeeDisregarded = !this.isSeeDisregarded;

      this.headers.unshift(this.disregarded);
    },

    closeDisregarded() {
      this.isOpenOrCloseDisregarded = !this.isOpenOrCloseDisregarded;

      this.isSeeDisregarded = !this.isSeeDisregarded;

      this.headers.splice(0, 1);

      this.listMonitoringMarketResultItem();
    },

    saveConsideredProducts(priceResults, idMonitoringItem) {
      this.isLoading = true;

      let data = priceResults.map((serie) => ({
        idMonitoringItem: idMonitoringItem,
        productSeller: serie.sellerName,
        productLink: serie.productLink,
        disregarded: serie.disregarded,
      }));

      this.service
        .tableResultsDisregarded(data)
        .then((res) => {
          if (res.data == "Items salvos com sucesso.") {
            this.showSnackbar(
              `${this.$i18n.t("TXT_ADD_UPDATE_SUCCESS")}`,
              "success"
            );
          } else {
            this.showSnackbar(
              `${this.$i18n.t("TXT_ADD_UPDATE_ERROR")}`,
              "success"
            );
          }

          this.isOpenOrCloseDisregarded = !this.isOpenOrCloseDisregarded;

          this.isSeeDisregarded = !this.isSeeDisregarded;

          this.headers.splice(0, 1);

          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    showSnackbar(text, type) {
      let snackbar = {
        snackbar: true,
        snackbarColor: null,
        snackbarText: null,
      };

      snackbar.snackbarText = text;

      switch (type) {
        case "success":
          snackbar.snackbarColor = "#56a667";
          break;
        case "error":
          snackbar.snackbarColor = "#ec4c37";
          break;
        case "info":
          snackbar.snackbarColor = "#3c5ca7";
      }
      this.snackbar = snackbar;
    },

    verifyPriceAndSeller(data) {
      const seller = data.sellerName;
      const currentPrice = this.productRegion.price.lastSalePrice;
      const sellerPrice = data.price;
      if (seller == "BR MANIA" && Number(currentPrice) == Number(sellerPrice)) {
        return true;
      } else {
        return false;
      }
    },
  },

  filters: {
    setDate: function (value) {
      if (!value) return null;

      const dataHora = new Date(value.replace("Z", ""));
      const dia = dataHora.getUTCDate().toString().padStart(2, "0");
      const mes = (dataHora.getUTCMonth() + 1).toString().padStart(2, "0");
      const ano = dataHora.getUTCFullYear().toString();

      const dataFormatada = `${dia}/${mes}/${ano}`;

      return dataFormatada;
    },

    setToUpperCase: function (value) {
      if (!value) return null;

      return value.toUpperCase();
    },
  },
};
</script>

<style lang="scss" scoped>
.shadow {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: max-content 1fr 1fr 1fr;
  grid-auto-flow: column;
  height: max-content;
}

.show-blue {
  color: $brand-color-primary-pure;
}

.show-red {
  color: $brand-color-secondary-pure;
}
.show-green {
  color: $feedback-color-success-pure;
}

.btn-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  gap: 8px;

  width: 175px;
  height: 34px;
}

.custom-table {
  text-align: center;
}

.background-color {
  background-color: #b8b8b8;
}
.background-color-equals-price {
  background-color: rgb(60, 92, 167, 0.6);
}
.original-header-table {
  background-color: rgba(224, 224, 224, 255);
  padding: 12px;
  font-weight: bold;
  font-size: 13px;
  border-right: 2px solid white;
}
</style>