<template>
  <v-row no-gutters align="center">
    <v-icon :disabled="disabled" @click="$emit('addCapture')" :color="$prdStyles('color-success')" size="35"
      >mdi-plus-box</v-icon
    >
    <span :class="`${disabled ? 'gray' : 'green'}-text`"
      >{{$t('TXT_ADD_NEW_PRODUCT')}}</span
    >
  </v-row>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.green-text {
  color: $feedback-color-success-pure;
}
.gray-text {
  color: $neutral-color-low-light;
}
</style>