<template>
  <v-card :disabled="isLoading" class="login-card" elevation="0">
    <v-img class="logo mx-auto" src="@/assets/logo_predify_blue.png"></v-img>
    <h2 class="title my-8">{{ $t("TXT_LOGIN") }}</h2>
    <!--E-MAIL-->
    <label>{{ $t("TXT_EMAIL") }}</label>
    <v-text-field :rules="[rules.required, rules.email]" :placeholder="$t('TXT_EMAIL')" outlined dense v-model="email"
      @keyup="emailToLowerCase()"></v-text-field>
    <!--PASSWORD-->
    <label>{{ $t("TXT_PASSWORD") }}</label>
    <v-text-field :rules="[rules.required]" :type="showPassword ? 'text' : 'password'"
      @click:append-outer="showPassword = !showPassword" :placeholder="$t('TXT_PASSWORD')" outlined dense
      v-model="password">
      <template slot="append-outer">
        <actionButton :icons="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click.native="showPassword = !showPassword">
        </actionButton>
      </template>
    </v-text-field>
    <!--LOGIN ACTIONS-->
    <v-card-actions class="d-flex ma-0 pa-0 justify-center">
      <v-checkbox class="ma-0 pa-0" hide-details v-model="rememberLogin" required>
        <template slot="label">
          <span>
            {{ $t("TXT_PASSWORD_REMEMBER") }}
          </span>
        </template>
      </v-checkbox>
      <v-spacer></v-spacer>
      <router-link to="sign-in">
        {{ $t("TXT_PASSWORD_RECOVER") }}
      </router-link>
    </v-card-actions>
    <div>
      <label>{{ $t(errorCode) }}</label>
    </div>
    <v-card-actions>
      <v-row>
        <v-col cols="12" class="mt-4 d-flex justify-center">
          <defaultButton v-show="!isLoading" :title="$t('TXT_LOGIN')" @click="login"></defaultButton>

          <!--PROGRESS BAR-->
          <v-progress-circular v-if="isLoading === true" class="mx-auto my-8" :size="30" color="primary" indeterminate>
          </v-progress-circular>
        </v-col>
        <!-- <v-col cols="12" class="d-flex justify-center">
          <defaultButton :loading="isLoading"
            @click.native="signUp()"
            outlined
            :title="$t('TXT_REGISTER')"
          ></defaultButton>
        </v-col> -->
        <v-col cols="12" class="d-flex justify-center">
          <router-link to="/termos"> {{ $t("TXT_USE_TERM") }} </router-link>
          <router-link to="/">&nbsp; | &nbsp;</router-link>
          <router-link to="/politica">
            {{ $t("TXT_PRIVACY_POLICY") }}
          </router-link>
        </v-col>
        <!-- COPYRIGHT -->
        <v-col cols="12" class="d-flex justify-center">
          <router-link to="/">
            &copy; Predify {{ year }} - {{ $t("TXT_COPYRIGHT") }}
          </router-link>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import defaultButton from "@/Design_System/common/prd-btn.vue";
import actionButton from "@/Design_System/common/prd-action-button.vue";
import LoginService from "@/service/login-service.js";
const Service = new LoginService();
export default {
  name: "sign-in-v2",
  components: {
    defaultButton,
    actionButton,
  },
  data() {
    return {
      errorCode: null,
      password: "",
      email: "",
      rules: {
        required: (value) => !!value || "Obrigatório.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Email inválido";
        },
      },
      rememberLogin: null,
      showPassword: false,
      showEmailErrorMessage: false,
      isLoading: false,
      year: new Date().getFullYear(), // copyright year refresh
    };
  },

  methods: {
    async login() {
      this.isLoading = true;
      try {
        const res = await Service.login.call({
          email: this.email,
          password: this.password,
        });

        let userData = {
          email: res.data.userName,
          hasUser: true,
          rememberLogin: this.rememberLogin,
          token: res.data.access_token,
          adminRoles: [],
          idCompany: null
        };

        this.$store.dispatch("setUser", userData);

        const userRoles = await Service.getUserRoles(this.email);

        userData = {
          email: res.data.userName,
          hasUser: true,
          rememberLogin: this.rememberLogin,
          token: res.data.access_token,
          adminRoles: userRoles?.data?.adminRoles || [],
          idCompany: null
        };

        this.$store.dispatch("setUser", userData);
        this.isLoading = false;

        this.$router.push("/main");
      } catch (e) {
        console.error(e);
        this.isLoading = false;
      }
    },
    displayPassword() {
      this.showPassword = !this.showPassword;
    },
    emailToLowerCase() {
      if (this.email) this.email = this.email.toLowerCase();
    },

    isValid() {
      if (!this.password) return false;
      if (!this.password.length >= 6) return false;
      if (!this.email) return false;
      if (!this.email.includes("@")) return false;
      if (!this.email.split("@")[1].includes(".")) return false;
      return true;
    },
  },
  created() {
    document.title = "PREDIFY | LOGIN";
  },
};
</script>

<style lang="scss" scoped>
.login-card {
  width: 330px;
  margin: 0 auto;
  padding: 0;

  .logo {
    width: 245px;
  }

  .title {
    text-align: center;
    font-weight: $font-weight-bold;
    font-size: $font-size-lg;
    color: $brand-color-primary-pure;
    text-transform: uppercase;
  }

  label {
    font-size: $font-size-xxs;
    font-weight: $font-weight-bold;
  }

  v-text-field {
    font-size: $font-size-xxs;
  }

  span {
    font-size: $font-size-xxxs;
  }

  a {
    font-size: $font-size-xxxs;
    text-decoration: none;
  }
}
</style>