<template>
  <v-card class="pa-4 mt-4 mb-6" id="table-result">
    <v-expansion-panels
      v-model="panel"
      :disabled="isDisable"
      accordion
      flat
      multiple
      class="pa-0"
    >
      <v-expansion-panel class="pa-0">
        <v-expansion-panel-header
          expand-icon="mdi-chevron-down"
          class="ma-0 pa-0"
        >
          <v-row justify="space-between" align="center">
            <label
              class="font-weight-bold font-size-16 ml-3"
              v-t="'TXT_PRODUCTS_UPPERCASE'"
            ></label>
            <div>
              <Prd-btn
                class="mr-4"
                :title="$t('TXT_EXTRACT_REPORT')"
                @click.stop="extractReport(products)"
                :disabled="isLoading || products.length == 0"
              />
              <Prd-btn
                :title="$t('TXT_EXTRACT_HISTORY')"
                @click.stop="extractHistory()"
                :disabled="isLoading || products.length == 0"
                class="mr-4"
              />
            </div>
          </v-row>
        </v-expansion-panel-header>

        <!-- SKELETON LOADERS -->
        <div v-show="isDisable && panel.length != 0">
          <v-skeleton-loader
            class="mx-auto mt-2"
            type="card"
            height="50px"
            v-for="(skeleton, index) in skeletons"
            :key="index"
          ></v-skeleton-loader>
        </div>

        <v-expansion-panel-content
          v-for="(product, index) in products"
          :key="index"
          class="mb-4 pa-0"
        >
          <!--TABLE -->
          <product-panel-table
            v-show="!isDisable"
            :isDisable="isDisable"
            :productItem="product"
            :resourceGroup="resourceGroup"
            :userList="userList"
            :priceCardLoading="priceCardLoading"
            :listaDeAlertas="product.listaDeAlertas"
            @showSnackbar="showSnackbar"
            @attData="$emit('attData')"
            @seila="$emit('seila')"
            @showSnackBarItemsSave="$emit('showSnackBarItemsSave', $event)"
            @actualPriceModified="$emit('actualPriceModified', $event)"
            @setGeneralIC="$emit('setGeneralIC')"
            class="mt-2"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import productPanelTable from "./components/product-panel-table.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import CircularJSON from "circular-json";
import { formatMonetary } from "@/utils/format-toMonetary.js";
import { formatDate } from "@/utils/prd-format-date.js";
import * as XLSX from "xlsx";

export default {
  components: { productPanelTable, PrdBtn },
  props: [
    "requestData",
    "idCompraAgora",
    "tableResultData",
    "isLoading",
    "isDisable",
    "closePanel",
    "resourceGroup",
    "userList",
    "priceAlertList",
    "priceCardLoading",
    "monitoringItems",
  ],
  data() {
    return {
      // Array to assemble the products
      products: [],
      dataProducts: [],

      panel: [],
      skeletons: 5,
    };
  },

  watch: {
    tableResultData() {
      this.dataProducts = [];
      this.$store.commit("setPredimonitorResults", []);
      this.newValue = this.tableResultData;
      this.formatTableResults();
      this.$emit("setLoading", false);
    },

    closePanel() {
      if (this.closePanel) {
        this.panel = [];
        this.$store.commit("setPredimonitorResults", []);
      }
    },
  },

  methods: {
    formatMonetary,
    formatDate,

    formatTableResults() {
      this.$store.commit("setPredimonitorResults", []);
      this.dataProducts = [];
      this.products = [];
      this.dataProducts = this.newValue;

      // Sets the min max e avg of product
      this.maxMinAvg(this.dataProducts);

      // Filter only the most current add
      this.filterAdds();

      // Format date to default aaa/mm/dd
      this.formatDateToTable(this.products);

      // save the result in vueX to show in the us chart
      this.$store.commit("setPredimonitorResults", this.products);
      this.$emit("setLoading", false);
    },

    filterAdds() {
      this.dataProducts.forEach((product) => {
        let addsGroup = [];

        let findAddGroup = function (priceResult) {
          for (let i in addsGroup) {
            let ag = addsGroup[i];
            if (
              ag.productLink == priceResult.productLink &&
              ag.sellerName == priceResult.sellerName &&
              ag.productName == priceResult.productName &&
              ag.marketplace == priceResult.marketplace
            ) {
              return ag;
            }
          }
          return null;
        };

        product.priceResults.forEach((adds) => {
          let addGroup = findAddGroup(adds);

          if (addGroup) {
            let newDate = new Date(adds.crawlerDate);
            let addGroupDate = new Date(addGroup.newest.crawlerDate);

            if (newDate > addGroupDate) {
              addGroup.olders.push(addGroup.newest);
              addGroup.newest = adds;
            } else {
              addGroup.olders.push(adds);
            }
          } else {
            addsGroup.push({
              productLink: adds.productLink,
              sellerName: adds.sellerName,
              productName: adds.productName,
              marketplace: adds.marketplace,
              newest: adds,
              olders: [],
            });
          }
        });

        let newProduct = Object.assign({}, product);
        newProduct.priceResults = [];

        addsGroup.forEach((group) => {
          group.newest.olderAdds = group.olders;
          newProduct.priceResults.push(group.newest);
        });

        let json = CircularJSON.stringify(newProduct);
        let obj = CircularJSON.parse(json);
        this.products.push(obj);

        this.products.forEach((el) => {
          el.listaDeAlertas = null;
          el.actualPrice = null;
          if (
            this.monitoringItems != null ||
            this.monitoringItems != undefined ||
            this.monitoringItems?.length > 0
          ) {
            const currentPrice = this.monitoringItems.find(
              (element) => element.monitoringId == el.idMonitoringItem
            );
            el.actualPrice = currentPrice?.actualPrice;
          }
        });
      });
    },

    formatDateToTable(date) {
      for (let i in date) {
        date[i].priceResults.forEach((el) => {
          el.crawlerDate = el.crawlerDate.slice(0, 10);
        });
      }
    },

    maxMinAvg(data) {
      for (let i in data) {
        var prices = [];
        if (data[i].priceResults != 0) {
          data[i].priceResults.forEach((el) => {
            if (el.disregarded == false) {
              prices.push(el.price);

              let soma = 0;
              for (let s in prices) {
                soma += prices[s];
              }

              data[i].maxPrice = Math.max(...prices);
              data[i].minPrice = Math.min(...prices);
              data[i].avgPrice = soma / prices.length;
            }
          });
        }
      }
    },

    setHeadersToCompraAgora() {
      if (this.idCompraAgora == true) {
        let compraAgoraHeaders = [
          {
            text: "Tipo de preço",
            align: "center",
            sortable: true,
            value: "price_unit_type",
          },
          {
            text: "Qtde. na caixa",
            align: "center",
            sortable: true,
            value: "price_unit_quantity",
          },
        ];

        this.headers.splice(5, 0, compraAgoraHeaders[0]);
        this.headers.splice(6, 0, compraAgoraHeaders[1]);
      }
    },

    formatPriceAlert(priceAlertList) {
      let groupedPriceAlerts = {};

      priceAlertList.forEach((alert) => {
        const { idMonitoringItem, isParentItem } = alert;

        if (!groupedPriceAlerts[idMonitoringItem]) {
          groupedPriceAlerts[idMonitoringItem] = {
            idMonitoringItem,
            products: [],
            adds: [],
          };
        }

        if (isParentItem) {
          groupedPriceAlerts[idMonitoringItem].products.push(alert);
        } else {
          groupedPriceAlerts[idMonitoringItem].adds.push(alert);
        }
      });
      const groupedPriceAlertsArray = Object.values(groupedPriceAlerts);

      this.products.map((product) => {
        product.listaDeAlertas = null;
        const { idMonitoringItem } = product;

        const correspondingAlert = groupedPriceAlertsArray.find(
          (alert) => alert.idMonitoringItem === idMonitoringItem
        );

        if (correspondingAlert) {
          product.listaDeAlertas = correspondingAlert;
        }
        return product;
      });
    },

    showSnackbar(text, type) {
      this.$emit("showSnackbar", text, type);
    },

    hasResource(value) {
      return this.$store.getters.hasResource(value);
    },

    // extractReport(data) {
    //   data.forEach((el) => {
    //     let newData = [];
    //     el.priceResults.forEach((item) => {
    //       if (!item.disregarded) {
    //         let body = null;
    //         if (this.hasResource("marketplace.machinery")) {
    //           const productUsedHoursFormated =
    //             this.$options.filters.setDotEveryThreeCharacters(
    //               item.productUsedHours
    //             );
    //           const productUsedHours =
    //             productUsedHoursFormated != "-"
    //               ? Number(productUsedHoursFormated)
    //               : productUsedHoursFormated;

    //           body = {
    //             "Nome do produto": item.productName,
    //             Marca: item.productBrand,
    //             productVehicleYearModel: item.productVehicleYearModel ?? "-",
    //             Marketplace: item.marketplace,
    //             productUsedHours,
    //             productAxles: item.productAxles ?? "-",
    //             productMileage: item.productMileage ?? "-",
    //             productFuelTankCapacity: item.productFuelTankCapacity ?? "-",
    //             productTruckBodyType: item.productTruckBodyType ?? "-",
    //             Fabricante: item.productManufacturer ?? "-",
    //             Seller: item.sellerName,
    //             Preço: this.formatMonetary(item.price),
    //             "Tipo de pagamento": item.paymentType,
    //             "Data da coleta": this.formatDate(item.crawlerDate),
    //             OnRequest: item.on_request,
    //             Link: item.productLink,
    //           };
    //         } else {
    //           body = {
    //             "Nome do produto": item.productName,
    //             Marca: item.productBrand,
    //             Marketplace: item.marketplace,
    //             Seller: item.sellerName,
    //             Preço: this.formatMonetary(item.price),
    //             "Tipo de pagamento": item.paymentType,
    //             "Data da coleta": this.formatDate(item.crawlerDate),
    //             OnRequest: item.on_request,
    //             Link: item.productLink,
    //           };
    //         }
    //         newData.push(body);
    //       }
    //     });

    //     el.priceResults = newData;
    //   });

    //   let groupedData = [];
    //   data.forEach((el) => {
    //     el.priceResults.forEach((data) => {
    //       groupedData.push(data);
    //     });
    //   });

    //   const ws = XLSX.utils.json_to_sheet(groupedData);
    //   const wb = XLSX.utils.book_new();
    //   XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    //   const filename = "predimonitorExport.xlsx";

    //   XLSX.writeFile(wb, filename);
    // },

    extractReport(data) {
      // Função para formatar um item com ou sem detalhes de maquinaria
      const formatItem = (item, hasMachineryResource) => {
        const baseBody = {
          "Nome do produto": item.productName,
          Marca: item.productBrand,
          Marketplace: item.marketplace,
          Seller: item.sellerName,
          Preço: this.formatMonetary(item.price),
          "Tipo de pagamento": item.paymentType,
          "Data da coleta": this.formatDate(item.crawlerDate),
          OnRequest: item.on_request,
          Link: item.productLink,
        };

        if (hasMachineryResource) {
          const productUsedHoursFormated =
            this.$options.filters.setDotEveryThreeCharacters(
              item.productUsedHours
            );
          const productUsedHours =
            productUsedHoursFormated !== "-"
              ? Number(productUsedHoursFormated)
              : productUsedHoursFormated;

          return {
            ...baseBody,
            productVehicleYearModel: item.productVehicleYearModel ?? "-",
            productUsedHours,
            productAxles: item.productAxles ?? "-",
            productMileage: item.productMileage ?? "-",
            productFuelTankCapacity: item.productFuelTankCapacity ?? "-",
            productTruckBodyType: item.productTruckBodyType ?? "-",
            Fabricante: item.productManufacturer ?? "-",
          };
        }

        return baseBody;
      };

      // Faz uma cópia profunda dos dados para evitar modificar o parâmetro original
      const clonedData = structuredClone(data);
      const hasMachineryResource = this.hasResource("marketplace.machinery");

      // Processa os dados clonados
      const groupedData = clonedData.reduce((acc, el) => {
        const newData = el.priceResults
          .filter((item) => !item.disregarded)
          .map((item) => formatItem(item, hasMachineryResource));

        return acc.concat(newData);
      }, []);

      // Cria uma nova planilha a partir dos dados agrupados
      const ws = XLSX.utils.json_to_sheet(groupedData);
      const wb = XLSX.utils.book_new(); // Cria uma nova pasta de trabalho
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1"); // Adiciona a planilha à pasta de trabalho

      // Define o nome do arquivo e escreve a pasta de trabalho em um arquivo
      const filename = "predimonitorExport.xlsx";
      XLSX.writeFile(wb, filename);
    },

    extractHistory() {
      let groupedProducts = [];

      this.tableResultData.forEach((el) => {
        el.priceResults.forEach((price) => {
          groupedProducts.push({
            "Nome do produto": price.productName
              .replace(/\\/g, " ")
              .replace(/"/g, " ")
              .replace(/\n/g, " "),
            "Data da coleta": this.formatDate(price.crawlerDate) || "-",
            Preço: this.formatMonetary(price.price),
            Loja: price.marketplace || "-",
          });
        });
      });

      this.downloadXlsx(groupedProducts);
    },

    downloadXlsx(data) {
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const filename = "predimonitorHistory.xlsx";

      XLSX.writeFile(wb, filename);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title {
  font-size: 18px;
  font-weight: 600;
}
</style>