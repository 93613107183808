<template>
  <v-expansion-panel-header>
    <div class="item-header">
      <span class="font-weight-bold">{{ product.displayName }} </span>
      <div class="d-flex">
        <span
          ><span class="font-weight-bold">{{ $i18n.t("TXT_PRICES") }}</span> -
          <span v-if="saoJoaoPharmacy">
            Regular:
            <span class="font-weight-bold">{{
              formatMonetary(product.regularPrice)
            }}</span>
            |
          </span>
          {{ $i18n.t("TXT_ACTUAL") }}:
          <span class="font-weight-bold">{{
            formatMonetary(product.lastSalePrice)
          }}</span>
          |
          {{ $i18n.t("TXT_PRICES_OPTIMIZED") }}:
          <span class="font-weight-bold">{{
            formatMonetary(
              priceToHeader ? priceToHeader : product.optimizedPrice
            )
          }}</span>
          |
          <span v-if="novaEra">{{ $t("TXT_IA_MARGIN") }}: </span>
          <span v-if="marest"> {{ $t("TXT_EXPECTED(EM)_MARGIN") }}: </span>
          <span v-if="!novaEra && !marest">{{ $i18n.t("TXT_MARGIN") }}: </span>
          <span :class="switchColors(product.optimizedMargin)">{{
            formatPercentage(
              marginToHeader ? marginToHeader : product.optimizedMargin
            )
          }}</span>
          <Tooltip-informations
            v-if="marest"
            class="pb-6 ml-3"
            :title="$t('TXT_EXPECTED(EM)_MARGIN_TOOLTIP')"
          />
          <span v-if="novaEra">
            |
            {{ $t("TXT_COMMERCIAL_MARGIN") }}:
            <span :class="switchColors(lastMargin)"
              >{{ formatPercentage(lastMargin) }}
            </span>
            <Tooltip-informations
              :title="$t('TXT_COMMERCIAL_MARGIN_TOOLTIP')"
            />
          </span>
          <span v-if="saoJoaoPharmacy">
            | {{ $t("TXT_SUGGESTED_DISCOUNT") }}:
            <span class="font-weight-bold">{{
              formatIdealDiscount(product.idealDiscount)
            }}</span>
          </span>
          | IC:
          <span :class="setICColor(product.competitivenessIndex)">{{
            ic
          }}</span></span
        >
        <Bold-cluster-informations-main v-if="bold" :headerData="product" class="ml-1" />
      </div>
      <div class="rules-validation">
        <span
          ><Rule-path
            :idEnterprisePricesProjection="product.idEnterprisePricesProjection"
            :productName="product.displayName"
            :lastAction="product.lastAction"
          />
        </span>
      </div>
    </div>
  </v-expansion-panel-header>
</template>

<script>
import { formatMonetary } from "@/utils/format-toMonetary.js";
import { formatPercentage } from "@/utils/format-percentage.js";
import RulePath from "./components/rule-path.vue";
import TooltipInformations from "@/Design_System/common/tooltip-informations.vue";
import BoldClusterInformationsMain from "./components/bold-cluster-informations/bold-cluster-informations-main.vue";
export default {
  props: ["product", "marginToHeader", "priceToHeader"],
  components: {
    RulePath,
    TooltipInformations,
    BoldClusterInformationsMain,
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    novaEra() {
      return this.idCompany == 3116 ? true : false;
    },

    marest() {
      return this.idCompany == 2617 ? true : false;
    },

    UPL() {
      return this.idCompany == 2858 ? true : false;
    },

    saoJoaoPharmacy() {
      return this.idCompany == 3238 ? true : false;
    },

    bold() {
      return this.idCompany == 3236 ? true : false;
    },
    ic() {
      return this.product?.competitivenessIndex
        ? formatPercentage(this.product.competitivenessIndex * 100)
        : "-";
    },
  },
  methods: {
    formatMonetary,
    formatPercentage,
    formatIdealDiscount(value) {
      if (value == null) return "-";
      const discount = Math.abs(value) * 100;
      return `${discount.toFixed(2)}%`;
    },
    switchColors(value, isInfo) {
      if (isInfo) return "neutral-value";

      if (value >= 0) return "positive-value";
      if (value < 0) return "negative-value";
    },

    setICColor(value) {
      if (!value) return "default-value";

      let valuePercentage = value * 100;

      if (valuePercentage >= 95 && valuePercentage <= 105)
        return "positive-value";
      else if (valuePercentage >= 85 && valuePercentage <= 115)
        return "warning-value";
      else return "negative-value";
    },
  },
};
</script>

<style lang="scss" scoped>
.item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .rules-validation {
    display: flex;
    align-items: center;
    color: $feedback-color-success-pure;
  }
}
.negative-value {
  color: $feedback-color-warning-pure !important;
  font-weight: bold;
}
.positive-value {
  color: $feedback-color-success-pure !important;
  font-weight: bold;
}
.neutral-value {
  color: $brand-color-primary-pure !important;
  font-weight: bold;
}
.warning-value {
  color: $feedback-color-helper-pure !important;
  font-weight: bold;
}
.default-value {
  color: black !important;
  font-weight: bold;
}
.negative-border {
  border-left: 6px solid $feedback-color-warning-pure !important;
}
.positive-border {
  border-left: 6px solid $feedback-color-success-pure !important;
}
</style>