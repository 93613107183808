<template>
  <v-card id="similarity-card" class="pa-4" :disabled="isLoading">
    <v-row no-gutters align="center" class="mb-4">
      <h4>SIMILARIDADE</h4>
      <v-spacer></v-spacer>
      <similiarity-modal-items
        :idEnterprisePricesProjection="idEnterprisePricesProjection"
        :tableSimilarity="tableSimilarity"
        @callSimiliarityTable="$emit('callSimiliarityTable')"
      />
    </v-row>
    <v-row
      v-if="tableSimilarityLoading"
      class="mt-10"
      no-gutters
      justify="center"
    >
      <v-progress-circular
        indeterminate
        :color="$prdStyles('color-primary')"
      ></v-progress-circular>
    </v-row>

    <v-data-table
      v-if="tableSimilarity.length != 0"
      :headers="headers"
      :items="tableSimilarity"
      :items-per-page="5"
      :hide-default-footer="tableSimilarity.length <= 5"
      :footer-props="footerProps"
    >
      <!-- Price -->
      <template v-slot:[`item.price`]="{ item }">
        {{ setCurrencyFujioka(item.price) }}
      </template>

      <!-- Delete item -->
      <template v-slot:[`item.deleteItem`]="{ item }">
        <v-icon @click="deleteItem(item)" :color="$prdStyles('color-warning')"
          >mdi-delete-outline</v-icon
        >
      </template>
    </v-data-table>

    <v-row v-else no-gutters justify="center" class="mt-6">
      <h4>Nenhum produto encontrado</h4>
    </v-row>
  </v-card>
</template>
<script>
import { setCurrencyFujioka } from "@/utils/format-currency-fujioka.js";

import similiarityModalItems from "./similiarity-modal-items.vue";
import service from "@/service/new-ai-pricing/panel.js";
const Service = new service();

export default {
  components: { similiarityModalItems },
  props: [
    "tableSimilarity",
    "tableSimilarityLoading",
    "idEnterprisePricesProjection",
  ],
  data() {
    return {
      headers: [
        {
          text: "Artigo",
          align: "center",
          sortable: false,
          value: "idProduct",
        },
        {
          text: "Produto",
          align: "center",
          sortable: false,
          value: "description",
        },
        {
          text: "Preço",
          align: "center",
          sortable: false,
          value: "price",
          width: "120px",
        },
        {
          text: "Similiaridade",
          align: "center",
          sortable: false,
          value: "typeSimilarity",
        },
        {
          text: "Excluir",
          align: "center",
          sortable: false,
          value: "deleteItem",
        },
      ],
      isLoading: false,
      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },
    };
  },

  methods: {
    setCurrencyFujioka,
    deleteItem(item) {
      this.isLoading = true;
      let request = {
        idEnterprisePricesProjection: this.idEnterprisePricesProjection,
        listMarketSimilarity: [
          {
            idEnterprise_Prices_Projection_MarketSimilarity:
              item.idEnterprise_Prices_Projection_MarketSimilarity,
            isDeleted: true,
          },
        ],
      };

      Service.deleteMonitoringItems(request)
        .then((res) => {
          console.log("Resposta do deletar items", res);
          this.$emit("callSimiliarityTable");
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("Erro ao deletar item", error);
          this.isLoading = false;
        });
    },
  },
};
</script>
<style lang="scss">
#similarity-card {
  margin-top: 8px;
  th {
    font-weight: 600 !important;
    color: #000 !important;
    font-size: 11px !important;
    height: 40px !important;
  }
}
</style>