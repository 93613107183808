<template>
  <div>
    <Activator-header
      :isLoading="isLoading"
      :lastUpdated="lastUpdated"
      @openModal="isOpen = true"
    />
    <v-dialog
      v-model="isOpen"
      transition="dialog-bottom-transition"
      max-width="800"
    >
      <Update-list
        :filteredList="filteredList"
        :isOpen="isOpen"
        @closeModal="isOpen = false"
        @dateSelection="selectedDate = $event"
      />
    </v-dialog>
  </div>
</template>

<script>
import UPLService from "@/service/upl";
import ActivatorHeader from "./components/activator/activator-header.vue";
import UpdateList from "./components/list/update-list.vue";
export default {
  components: { ActivatorHeader, UpdateList },
  props: {
    selectedProduct: {
      type: Object,
    },
  },
  data() {
    return {
      isOpen: false,
      selectedDate: null,
      updatedList: [],
      filteredList: [],
      isLoading: false,
      service: new UPLService(),
    };
  },

  computed: {
    lastUpdated() {
      const newList = this.updatedList.filter(
        (item) => item.productId == this.selectedProduct?.id
      );
      const lastIndex = newList.length - 1;
      return newList[lastIndex];
    },
  },
  watch: {
    selectedDate: {
      handler(value) {
        this.filteredList = this.filterDate(value);
      },
    },
    selectedProduct: {
      handler(value) {
        if (value) this.filterByProduct(value?.nome);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    filterDate(date) {
      const startDate = new Date(date[0]);
      const endDate = new Date(date[1]);

      // Adicionando um dia às datas
      startDate.setDate(startDate.getDate() + 1);
      endDate.setDate(endDate.getDate() + 1);

      // Ajustando as horas, minutos e segundos para 23:59:59 no endDate
      endDate.setHours(23, 59, 59, 999);

      const filteredObjects = this.updatedList
        .filter((obj) => {
          const objDate = new Date(obj.updateTime);
          return (
            objDate >= startDate &&
            objDate <= endDate &&
            obj.productId == this.selectedProduct.id
          );
        })
        .reverse();
      return filteredObjects;
    },
    filterByProduct(name) {
      this.filteredList = this.updatedList
        .filter((item) => item.productName == name)
        .reverse();
    },
    async getAllUpdate() {
      this.updatedList = [];
      this.filteredList = [];
      const startDate = "2023-01-01T00:00:00Z";
      const endDate = "2024-12-31T23:59:59Z";
      this.isLoading = true;
      try {
        const response = await this.service.getUpdateHistory(
          startDate,
          endDate
        );
        this.updatedList = response.data.reverse();
        this.filteredList = response.data.reverse();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },

  async mounted() {
    await this.getAllUpdate();
    this.filterByProduct(this.selectedProduct?.nome);
  },
};
</script>
