<template>
  <v-card class="pa-4">
    <v-row no-gutters justify="space-between" class="mb-6">
      <h3>EXPORTAR GRÁFICO DE COMPETIVIDADE</h3>
      <v-icon color="#292929" @click="$emit('closeModal')" :disabled="isLoading"
        >mdi-close</v-icon
      >
    </v-row>
    <Infos-header
      class="mb-6"
      :nameStore="nameStore"
      :lastRequest="lastRequest"
    />
    <span
      >Para exportar, selecione uma ou mais categorias na lista abaixo.</span
    >
    <Category-select
      class="mb-2 mt-6"
      @selectedCategories="selectedCategories = $event"
      :isDisabled="isLoading"
    />
    <v-row no-gutters justify="end" class="gap-6">
      <Prd-btn
        :outlined="true"
        :title="$t('TXT_CANCEL')"
        @click="$emit('closeModal')"
        :disabled="isLoading"
      />
      <Prd-btn
        :leftIcon="true"
        :icon="'mdi-upload-outline'"
        :title="$t('TXT_EXPORT')"
        :loading="isLoading"
        :disabled="selectedCategories.length == 0"
        @click="$emit('exportCharts')"
      />
    </v-row>
  </v-card>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import CategorySelect from "./components/category-select.vue";
import InfosHeader from "./components/infos-header.vue";
export default {
  props: ["nameStore", "lastRequest", "isLoading"],
  components: { InfosHeader, CategorySelect, PrdBtn },
  data() {
    return {
      selectedCategories: [],
    };
  },
  watch: {
    selectedCategories: {
      handler(value) {
        this.$emit("selectedCategories", value);
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style>
</style>