<template>
  <div style="background-color: #F5F5F5">
    <div
      v-for="crawler in crawlers"
      :key="crawler.idCompany_MonitoringCrawler"
      class="crawler-item pt-4"
      
    >
      <div class="font-weight-bold">
        <span>{{ crawler.crawlerName }}</span> |
        <span
          >Status:
          <span :class="setFontColor(crawler.status)">{{
            setStatusToString(crawler.status)
          }}</span></span
        >
      </div>

      <div class="flex-align mt-2">
        <v-icon :class="setFontColor(crawler.status)">{{
          setIcon(crawler.status)
        }}</v-icon>
        <span v-if="crawler.status != 0">{{ crawler.message }}</span>
        <span v-else>Na fila</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {};
  },

  computed: {
    crawlers() {
      return this.item?.listMonitoringCrawlers ?? [];
    },
  },

  methods: {
    setStatusToString(status) {
      switch (status) {
        case 0:
          return "Na fila";
        case 1:
          return "Rodando";
        case 2:
          return "Finalizado";
        case 3:
          return "Erro";
      }
    },
    setFontColor(status) {
      switch (status) {
        case 0:
          return "font-color-info";
        case 1:
          return "font-color-info-light";
        case 2:
          return "font-color-success";
        case 3:
          return "font-color-error";
      }
    },
    setIcon(status) {
      switch (status) {
        case 0:
          return "mdi-timer-sand-empty";
        case 2:
          return "mdi-check";
        case 3:
          return "mdi-alert-circle-outline";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.crawler-item {
  border-bottom: 0.5px solid rgba(59, 58, 58, 0.185);
  padding-bottom: 16px;

  .flex-align {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.font-color-info {
  color: $brand-color-primary-pure;
}
.font-color-info-light {
  color: $brand-color-primary-medium;
}
.font-color-success {
  color: $feedback-color-success-pure;
}
.font-color-error {
  color: $brand-color-secondary-pure;
}
</style>