<template>

    <v-container fluid>
      <titleBreadCrumbsComponent
        :hasTooltip="false"
        :title="'PREDIFUT'"
      />
      <router-view></router-view>
    </v-container>
 
</template>
<script>
import titleBreadCrumbsComponent from "@/Design_System/common/title.vue";

export default {
  components: {
    titleBreadCrumbsComponent,
  },
  data() {
    return {
    };
  },
};
</script>
<style lang="scss"></style>
