var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('h4',[_vm._v(_vm._s(_vm.name))]),_c('label',[_vm._v(_vm._s(_vm.subtitle))])]),_c('v-spacer'),(!_vm.isLoading)?_c('Prd-btn',{class:_vm.isAdminUpl ? 'mr-4' : '',attrs:{"title":'Extrair arquivo',"loading":_vm.reportLoading,"disabled":_vm.reportLoading || _vm.inEditMode},on:{"click":function($event){return _vm.extractMatrix(_vm.items)}}}):_vm._e(),(_vm.isAdminUpl)?_c('v-col',{attrs:{"cols":"auto"}},[(!_vm.inEditMode)?_c('Prd-btn',{attrs:{"title":'Editar Dados'},on:{"click":function($event){_vm.inEditMode = !_vm.inEditMode}}}):_c('v-row',{staticClass:"gap-4",attrs:{"no-gutters":"","align":"center"}},[(_vm.isLoading)?_c('span',[_vm._v(_vm._s(_vm.$t("TXT_SAVING_DATA")))]):_vm._e(),(_vm.isLoading)?_c('prd-loading-circular'):_vm._e(),(_vm.inEditMode)?_c('Prd-btn',{attrs:{"title":'Salvar',"disabled":_vm.isLoading || !_vm.unlockSaveButton},on:{"click":_vm.saveEditions}}):_vm._e(),(_vm.inEditMode )?_c('Prd-btn',{attrs:{"title":'Cancelar',"outlined":true,"disabled":_vm.isLoading},on:{"click":_vm.resetTable}}):_vm._e()],1)],1):_vm._e()],1),_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headers,"items":_vm.localItems,"hide-default-footer":_vm.localItems == [] || _vm.localItems.length == 0,"footer-props":_vm.footerProps,"no-data-text":_vm.$t('TXT_NO_DATA_AVAILABLE')},scopedSlots:_vm._u([{key:"item.portifolioStatus",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.setPortifolioStatus(item.portifolioStatus)))])]}},{key:"item.triangle",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.triangle + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.triangle),callback:function ($$v) {_vm.$set(item, "triangle", $$v)},expression:"item.triangle"}})]}},{key:"item.rioVerde",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.rioVerde + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.rioVerde),callback:function ($$v) {_vm.$set(item, "rioVerde", $$v)},expression:"item.rioVerde"}})]}},{key:"item.goiania",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.goiania + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.goiania),callback:function ($$v) {_vm.$set(item, "goiania", $$v)},expression:"item.goiania"}})]}},{key:"item.bahia",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.bahia + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.bahia),callback:function ($$v) {_vm.$set(item, "bahia", $$v)},expression:"item.bahia"}})]}},{key:"item.grCliCerradoLeste",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.grCliCerradoLeste + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.grCliCerradoLeste),callback:function ($$v) {_vm.$set(item, "grCliCerradoLeste", $$v)},expression:"item.grCliCerradoLeste"}})]}},{key:"item.kaCerradoOeste",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.kaCerradoOeste + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.kaCerradoOeste),callback:function ($$v) {_vm.$set(item, "kaCerradoOeste", $$v)},expression:"item.kaCerradoOeste"}})]}},{key:"item.primavera",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.primavera + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.primavera),callback:function ($$v) {_vm.$set(item, "primavera", $$v)},expression:"item.primavera"}})]}},{key:"item.vale",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.vale + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.vale),callback:function ($$v) {_vm.$set(item, "vale", $$v)},expression:"item.vale"}})]}},{key:"item.sorriso",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.sorriso + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.sorriso),callback:function ($$v) {_vm.$set(item, "sorriso", $$v)},expression:"item.sorriso"}})]}},{key:"item.tangara",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.tangara + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.tangara),callback:function ($$v) {_vm.$set(item, "tangara", $$v)},expression:"item.tangara"}})]}},{key:"item.litoralEFronteira",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.litoralEFronteira + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.litoralEFronteira),callback:function ($$v) {_vm.$set(item, "litoralEFronteira", $$v)},expression:"item.litoralEFronteira"}})]}},{key:"item.missoes",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.missoes + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.missoes),callback:function ($$v) {_vm.$set(item, "missoes", $$v)},expression:"item.missoes"}})]}},{key:"item.planalto",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.planalto + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.planalto),callback:function ($$v) {_vm.$set(item, "planalto", $$v)},expression:"item.planalto"}})]}},{key:"item.prNorte",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.prNorte + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.prNorte),callback:function ($$v) {_vm.$set(item, "prNorte", $$v)},expression:"item.prNorte"}})]}},{key:"item.prSul",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.prSul + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.prSul),callback:function ($$v) {_vm.$set(item, "prSul", $$v)},expression:"item.prSul"}})]}},{key:"item.prOeste",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.prOeste + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.prOeste),callback:function ($$v) {_vm.$set(item, "prOeste", $$v)},expression:"item.prOeste"}})]}},{key:"item.cafeECitrus",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.cafeECitrus + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.cafeECitrus),callback:function ($$v) {_vm.$set(item, "cafeECitrus", $$v)},expression:"item.cafeECitrus"}})]}},{key:"item.hfEAgroindustrias",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.hfEAgroindustrias + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.hfEAgroindustrias),callback:function ($$v) {_vm.$set(item, "hfEAgroindustrias", $$v)},expression:"item.hfEAgroindustrias"}})]}},{key:"item.nordesteEspec",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.nordesteEspec + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.nordesteEspec),callback:function ($$v) {_vm.$set(item, "nordesteEspec", $$v)},expression:"item.nordesteEspec"}})]}},{key:"item.canaSul",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.canaSul + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.canaSul),callback:function ($$v) {_vm.$set(item, "canaSul", $$v)},expression:"item.canaSul"}})]}},{key:"item.canaNorte",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.canaNorte + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.canaNorte),callback:function ($$v) {_vm.$set(item, "canaNorte", $$v)},expression:"item.canaNorte"}})]}},{key:"item.pastagemGo",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.pastagemGo + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.pastagemGo),callback:function ($$v) {_vm.$set(item, "pastagemGo", $$v)},expression:"item.pastagemGo"}})]}},{key:"item.pastagemCuiaba",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.pastagemCuiaba + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.pastagemCuiaba),callback:function ($$v) {_vm.$set(item, "pastagemCuiaba", $$v)},expression:"item.pastagemCuiaba"}})]}},{key:"item.b2b",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.b2b + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.b2b),callback:function ($$v) {_vm.$set(item, "b2b", $$v)},expression:"item.b2b"}})]}},{key:"item.mapi",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.mapi + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.mapi),callback:function ($$v) {_vm.$set(item, "mapi", $$v)},expression:"item.mapi"}})]}},{key:"item.palmas",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.palmas + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.palmas),callback:function ($$v) {_vm.$set(item, "palmas", $$v)},expression:"item.palmas"}})]}},{key:"item.sulDoMs",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.sulDoMs + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.sulDoMs),callback:function ($$v) {_vm.$set(item, "sulDoMs", $$v)},expression:"item.sulDoMs"}})]}},{key:"item.supervisorDeNegocios",fn:function(ref){
var item = ref.item;
return [(!_vm.inEditMode)?_c('span',[_vm._v(_vm._s(item.supervisorDeNegocios + "%"))]):_c('Prd-text-field',{attrs:{"suffix":'%',"type":'number',"hide-spin-button":true,"disabled":_vm.isLoading},model:{value:(item.supervisorDeNegocios),callback:function ($$v) {_vm.$set(item, "supervisorDeNegocios", $$v)},expression:"item.supervisorDeNegocios"}})]}}],null,true)}),_c('v-snackbar',{attrs:{"color":_vm.snackbar.snackbarColor,"timeout":2000},model:{value:(_vm.snackbar.snackbar),callback:function ($$v) {_vm.$set(_vm.snackbar, "snackbar", $$v)},expression:"snackbar.snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }