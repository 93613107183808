<template>
  <v-row no-gutters class="gap-x-4 my-4" align="center">
    <prd-combo-box
      :title="'Filtrar por :'"
      :items="items"
      v-model="curve"
    />
  </v-row>
</template>

<script>
import prdComboBox from "@/Design_System/common/prd-comboBox.vue";
export default {
  components: { prdComboBox },
  data() {
    return {
      curve: "Todos",
      items: [
        { text: this.$i18n.t("TXT_ALL"), value: "all" },
        { text: this.$i18n.t("TXT_CURVE") + " A", value: "A" },
        { text: this.$i18n.t("TXT_CURVE") + " B", value: "B" },
        { text: this.$i18n.t("TXT_CURVE") + " C", value: "C" },
        { text: "KVI's", value: "KVI" },
      ],
    };
  },
  methods: {
    setFilter(value) {
      let curve,
        status = "";
      if (value != "all") {
        if (value == "KVI") {
          status = value;
        } else {
          curve = value;
        }
      }
      this.$emit("getErpPreview", curve, status);
    },
  },
  watch: {
    curve: {
      handler(value) {
        if (value) {
          this.setFilter(value.value);
        }
      },
    },
  },
};
</script>
