var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"validation-button mr-4",on:{"click":function($event){$event.stopPropagation();_vm.modalOpen = true}}},[_c('v-icon',{class:_vm.setStatusColor(_vm.lastAction)},[_vm._v("mdi-traffic-light-outline")]),_c('span',{class:_vm.setStatusColor(_vm.lastAction)},[_vm._v(_vm._s(_vm.$i18n.t("TXT_RULECONDITION")))])],1),_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.modalOpen),callback:function ($$v) {_vm.modalOpen=$$v},expression:"modalOpen"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-row',{staticClass:"mb-4 pt-4",attrs:{"no-gutters":"","align":"center","justify":"space-between"}},[_c('h4',[_vm._v(_vm._s(_vm.$i18n.t("TXT_RULECONDITION").toUpperCase()))]),_c('v-icon',{on:{"click":function($event){_vm.modalOpen = false}}},[_vm._v("mdi-close")])],1),_c('div',{staticClass:"modal-title mb-4 py-2"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.productName))])]),(_vm.isLoading)?_c('Prd-loading-circular'):_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.products,"footer-props":_vm.footerProps,"hide-default-footer":_vm.products.length <= 5,"items-per-page":5},scopedSlots:_vm._u([{key:"item.oldPrice",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatMonetary(item.oldPrice))+" ")])]}},{key:"item.currentPrice",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatMonetary(item.currentPrice))+" ")])]}},{key:"item.oldMargin",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatPercentage(item.oldMargin))+" ")])]}},{key:"item.currentMargin",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatPercentage(item.currentMargin))+" ")])]}},{key:"item.nameCondition",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t(item.nameCondition))+" ")])]}},{key:"item.isBlockedMessage",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center",class:_vm.setStatusColor(item.isBlockedMessage)},[_vm._v(" "+_vm._s(_vm.$t(item.isBlockedMessage))+" ")])]}},{key:"item.messageBlockedRuleCondition",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.messageBlockedRuleCondition ? _vm.$t(item.messageBlockedRuleCondition) : "-")+" ")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }