var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('div',{staticClass:"pa-4 font-size-12 grid height-max-content"},[_c('div',{staticClass:"teste span-column-2 rounded"},[_vm._v(" "+_vm._s(this.$i18n.t("TXT_SUGGESTED_PRICE_INFO"))+" ")]),_c('div',{class:_vm.cardClasses},[_c('label',{directives:[{name:"t",rawName:"v-t",value:('TXT_MARGIN'),expression:"'TXT_MARGIN'"}]},[_vm._v("Margem")]),(_vm.margin != null)?_c('div',{staticClass:"d-flex align-center gap-1"},[_c('span',{class:_vm.makeCardValueClasses(_vm.margin)},[_vm._v(" "+_vm._s(_vm.formatPercentage(_vm.margin))+" ")]),(_vm.marginVariation !== null)?_c('span',{class:[
            _vm.marginVariation < 0 ? 'red-value' : 'green-value',
            'font-size-14' ]},[_vm._v(" ("+_vm._s(_vm.marginVariation)+") ")]):_c('span')]):_vm._e()]),_c('div',{class:_vm.cardClasses},[_c('label',{directives:[{name:"t",rawName:"v-t",value:('TXT_DEMAND_VARIABILITY'),expression:"'TXT_DEMAND_VARIABILITY'"}]}),_c('div',{staticClass:"d-flex"},[_c('span',{class:_vm.makeCardValueClasses(_vm.demandVariation)},[_vm._v(" "+_vm._s(_vm.formatNumberPercentage1(_vm.demandVariation))+" ")])])]),_c('div',{class:_vm.cardClasses},[_c('label',[_vm._v("IC")]),_c('span',{class:_vm.makeCardValueClasses(_vm.ic)},[_vm._v(_vm._s(_vm.ic))])]),_c('div',{class:_vm.cardClasses},[_c('label',{directives:[{name:"t",rawName:"v-t",value:('TXT_CURVE'),expression:"'TXT_CURVE'"}]}),_c('span',{class:_vm.makeCardValueClasses()},[_vm._v(_vm._s(_vm.curveABC))])]),_c('div',{class:_vm.cardClasses},[_c('label',{directives:[{name:"t",rawName:"v-t",value:('TXT_SALLES_VARIATION'),expression:"'TXT_SALLES_VARIATION'"}]}),_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({class:_vm.makeCardValueClasses(_vm.salesVariation)},'span',attrs,false),on),[_vm._v(_vm._s(_vm.formatMonetaryOriginalValue(_vm.salesVariation)))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.salesVariation))])])],1)]),_c('div',{class:_vm.cardClasses},[_c('label',{directives:[{name:"t",rawName:"v-t",value:('TXT_PROFIT_VARIATION_LOWER'),expression:"'TXT_PROFIT_VARIATION_LOWER'"}]}),_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({class:_vm.makeCardValueClasses(_vm.profitVariation)},'span',attrs,false),on),[_vm._v(_vm._s(_vm.formatMonetaryOriginalValue(_vm.profitVariation)))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.profitVariation))])])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }