<template>
  <v-dialog persistent max-width="600px" v-model="localIsOpen">
    <v-card flat class="pa-4">
      <h3 class="text-center mb-4">Alterar Referência de Deliberação</h3>
      <span v-if="oldDeliberation && newDeliberation" class="font-weight-bold">{{ oldDeliberation.nome }} <v-icon
          :color="$prdStyles('color-primary')">mdi-arrow-right-bold-outline</v-icon> {{
            newDeliberation.nome }}</span>
      <br />
      <span style="color: red;">* Ao selecionar uma nova referência, as alterações não salvas serão perdidas !</span>
      <br />
      <span>Você realmente deseja alterar ?</span>
      <v-row no-gutters justify="end">
        <prd-btn @click="$emit('resetDeliberation')" :title="'Cancelar'" :outlined="true" class="mr-2" />
        <prd-btn @click="$emit('saveNewDeliberation')" :title="'Alterar'" />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import prdBtn from '@/Design_System/common/prd-btn.vue'
export default {
  props: ["isOpenModal", "oldDeliberation", "newDeliberation"],
  components: { prdBtn },
  data() {
    return {
      localIsOpen: true
    }
  },
}
</script>

<style></style>