<template>
  <div>
    <v-icon @click="isModalOpen = true" color="#1D8527"> mdi-plus-box </v-icon>

    <v-dialog v-model="isModalOpen" :max-width="600" persistent>
      <v-card class="pa-4" :disabled="replicateLoading">
        <h4 class="mb-4">REPLICAR PREÇOS</h4>

        <v-row v-if="isLoading" class="mt-10" no-gutters justify="center">
          <v-progress-circular
            indeterminate
            :color="$prdStyles('color-primary')"
          ></v-progress-circular>
        </v-row>

        <div v-else>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="search"
                outlined
                dense
                hide-details
                rounded
                append-icon="mdi-magnify"
                class="mb-2"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            :search="search"
            :items="items"
            :headers="headers"
            :items-per-page="5"
            :footer-props="footerProps"
          >
            <!-- replicate -->
            <template v-slot:[`item.replicate`]="{ item }">
              <v-row no-gutters align="center" justify="center">
                <v-checkbox
                  v-if="!item.isPublished"
                  v-model="item.replicate"
                  :disabled="item.disable"
                  :ripple="false"
                  hide-details
                  class="pa-0 ma-0"
                ></v-checkbox>
              </v-row>
            </template>
          </v-data-table>
        </div>

        <v-row no-gutters>
          <v-spacer></v-spacer>
          <prd-btn
            :title="'Cancelar'"
            :outlined="true"
            :disabled="replicateLoading"
            @click="isModalOpen = false"
            class="mr-4"
          />
          <prd-btn
            :title="'Replicar'"
            :disabled="replicateLoading"
            :loading="replicateLoading"
            @click="replicatePrices"
          />
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
import panelService from "@/service/new-ai-pricing/panel.js";
import { mapMutations } from "vuex";

const PanelService = new panelService();
export default {
  props: ["idPriceGroup", "tableReply", "headerPricing", "idPriceProjection"],
  components: { prdBtn },
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
      replicateLoading: false,
      search: "",
      headers: [
        {
          text: "Artigo",
          align: "center",
          value: "product",
          sortable: true,
        },
        {
          text: "Produto",
          align: "center",
          value: "productName",
          sortable: true,
        },
        {
          text: "Replicar",
          align: "center",
          value: "replicate",
          sortable: true,
        },
      ],
      items: [],
      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },
    };
  },
  watch: {
    isModalOpen() {
      if (this.isModalOpen) this.getPricingList();
    },
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  methods: {
    ...mapMutations(["updateTeste"]),

    getPricingList() {
      this.isLoading = true;

      PanelService.listProductsToReplicate(this.idPriceProjection)
        .then((res) => {
          let response = res?.data?.result ?? [];

          response = response.filter((el) => {
            return el.canReplicate == true;
          });

          this.items = response;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("Erro ao lista de precificação", error);
          this.isLoading = false;
        });
    },

    replicatePrices() {
      this.replicateLoading = true;
      const listId = [];
      let request = {
        idEnterprisePriceProjection: this.idPriceProjection,
        price: this.headerPricing.suggestedPrice
          ? this.headerPricing.suggestedPrice
          : this.headerPricing.manualPrice,
        listIdProjectionReplicate: [],
      };

      this.items.forEach((el) => {
        if (el.replicate && !el.disable) {
          request.listIdProjectionReplicate.push({
            idProjectionReplicate: el.idEnterprisePricesProjection,
          });
          listId.push(el.idEnterprisePricesProjection);
        }
      });

      PanelService.insertReplicatePrices(request)
        .then(() => {
          this.$emit("callReplyTable");
          this.isModalOpen = false;
          this.replicateLoading = false;
          this.updateTeste(listId);
          this.$emit(
            "showSnackbar",
            "Sucesso ao replicar precificações",
            "success"
          );
        })
        .catch((error) => {
          console.log("Erro ao inserir replicação de preços", error);
          this.$emit("showSnackbar", "Erro ao replicar precificações", "error");

          this.replicateLoading = false;
        });
    },
  },
};
</script>