<template>
  <div class="header">
    <div class="nameStore">
      <p>{{ nameStore }}</p>
    </div>
    <div class="selectedItems">
      <div v-if="lastRequest.compType">
        <p class="noPaddingMargin">Tipo de Concorrente:</p>
        <p class="fontBold noPaddingMargin">
          {{ getNameByValue(compTypes, lastRequest.compType) }}
        </p>
      </div>
      <div v-if="lastRequest.period">
        <p class="noPaddingMargin">Período de Tempo:</p>
        <p class="fontBold noPaddingMargin">
          {{ getNameByValue(periods, lastRequest.period) }}
        </p>
      </div>
      <div v-if="lastRequest.state">
        <p class="noPaddingMargin">Estado:</p>
        <p class="fontBold noPaddingMargin">
          {{ getNameByValue(states, lastRequest.state) }}
        </p>
      </div>
      <div v-if="lastRequest.coordinator">
        <p class="noPaddingMargin">Coordenador:</p>
        <p class="fontBold noPaddingMargin">
          {{ getNameByValue(coordinators, lastRequest.coordinator) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["nameStore", "lastRequest"],
  data() {
    return {
      coordinators: [
        { text: "Nenhum coordenador", value: "all" },
        { text: "Ana Flávia", value: "ANA FLAVIA" },
        { text: "Flávia Guimarães", value: "FLAVIA GUIMARAES" },
        { text: "Vinicius Tyburski", value: "VINICIUS TYBURSKI" },
        { text: "Paulo Ferreira", value: "PAULO FERREIRA" },
      ],
      periods: [
        {
          text: "Semanal",
          value: "SEMANAL",
        },
        {
          text: "Mensal",
          value: "MENSAL",
        },
      ],
      compTypes: [
        {
          text: "Conveniência",
          value: "GAS STATION",
        },
        {
          text: "Super Pequeno",
          value: "SMALL SUPERMARKET",
        },
      ],
      states: [
        { text: "Brasil", value: "BRASIL" },
        { text: "Acre", value: "AC" },
        { text: "Alagoas", value: "AL" },
        { text: "Amapá", value: "AP" },
        { text: "Amazonas", value: "AM" },
        { text: "Bahia", value: "BA" },
        { text: "Ceará", value: "CE" },
        { text: "Distrito Federal", value: "DF" },
        { text: "Espírito Santo", value: "ES" },
        { text: "Goiás", value: "GO" },
        { text: "Maranhão", value: "MA" },
        { text: "Mato Grosso", value: "MT" },
        { text: "Mato Grosso do Sul", value: "MS" },
        { text: "Minas Gerais", value: "MG" },
        { text: "Pará", value: "PA" },
        { text: "Paraíba", value: "PB" },
        { text: "Paraná", value: "PR" },
        { text: "Pernambuco", value: "PE" },
        { text: "Piauí", value: "PI" },
        { text: "Rio de Janeiro", value: "RJ" },
        { text: "Rio Grande do Norte", value: "RN" },
        { text: "Rio Grande do Sul", value: "RS" },
        { text: "Rondônia", value: "RO" },
        { text: "Roraima", value: "RR" },
        { text: "Santa Catarina", value: "SC" },
        { text: "São Paulo", value: "SP" },
        { text: "Sergipe", value: "SE" },
        { text: "Tocantins", value: "TO" },
      ],
    };
  },
  methods: {
    getNameByValue(list, value) {
      return list.find((item) => item.value == value).text;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  margin: 24px 0px;
  padding: 8px 16px;
  border-radius: 6px;
  background-color: $neutral-color-high-light;
}
.nameStore {
  display: flex;
  justify-content: center;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.selectedItems {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}
.noPaddingMargin {
  padding: 0px;
  margin: 0px;
}
.fontBold {
  font-weight: bold;
}
</style>