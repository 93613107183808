<template>
  <v-card class="pa-4" elevation="4" height="100%">
    <span class="font-weight-bold" style="font-size: 15px"
      >LISTA DE PREÇOS | VALOR PRESENTE | CG</span
    >
    <table style="width: 100%" class="mt-2">
      <thead>
        <tr>
          <td></td>
          <td
            style="border-top-left-radius: 10px; border-top-right-radius: 10px"
          >
            BRL
          </td>
          <td
            style="border-top-left-radius: 10px; border-top-right-radius: 10px"
          >
            USD
          </td>
        </tr>
      </thead>
      <tbody v-if="isNotCostPrecificationType">
        <tr>
          <td>Preço unitário</td>
          <td>{{ setCurrencyUPL(this.precoUnitario, false) }}</td>
          <td>
            {{
              setCurrencyUPL(
                Math.round(this.precoUnitarioDolar * 10) / 10,
                true
              )
            }}
          </td>
        </tr>
        <tr>
          <td>MaCo%</td>
          <td>{{ maCoUnitario }}%</td>
          <td>{{ maCoDolarUnitario }}%</td>
        </tr>
        <tr>
          <td>Preço mínimo</td>
          <td>{{ setCurrencyUPL(this.precoMinimo, false) }}</td>
          <td>
            {{
              setCurrencyUPL(Math.round(this.precoMinimoDolar * 10) / 10, true)
            }}
          </td>
        </tr>
        <tr>
          <td>MaCo%</td>
          <td>{{ maCoMinimo }}%</td>
          <td>{{ maCoMinimoDolar }}%</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td>Preço unitário</td>
          <td>{{ setCurrencyUPL(precoCanalGeral, false) }}</td>
          <td>
            {{
              setCurrencyUPL(
                Math.round((precoCanalGeral / valorDolar) * 10) / 10,
                true
              )
            }}
          </td>
        </tr>
        <tr>
          <td>MaCo%</td>
          <td>{{ maCoCanalGeral }}%</td>
          <td>{{ maCoCanalGeralDolar }}%</td>
        </tr>
        <tr>
          <td>Preço mínimo</td>
          <td>
            {{ setCurrencyUPL(precoMinimoCanalGeral, false) }}
          </td>
          <td>
            {{
              setCurrencyUPL(
                Math.round((precoMinimoCanalGeral / valorDolar) * 10) / 10,
                true
              )
            }}
          </td>
        </tr>
        <tr>
          <td>MaCo%</td>
          <td>{{ maCoMinimoCanalGeral }}%</td>
          <td>{{ maCoMinimoCanalGeralDolar }}%</td>
        </tr>
      </tbody>
    </table>
  </v-card>
</template>

<script>
import ServiceUPL from "@/service/upl/index";
export default {
  props: ["product"],
  data() {
    return {
      service: new ServiceUPL(),
      vizualization: null,
      precoUnitario: "",
      precoUnitarioDolar: "",
      precoMinimo: "",
      precoMinimoDolar: "",
      unitCogs: "",
      unitCogsDolar: "",
      dolar: null,
      custoReposicao: [],
    };
  },
  methods: {
    setCurrencyUPL(value, useDollar) {
      if (value != null) {
        value = value.toString().replace(",", ".");

        let [integerPart, decimalPart] = value.split(".");

        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        decimalPart = (decimalPart || "").padEnd(2, "0").substring(0, 2); // Garante que tenha dois dígitos

        const currency = useDollar ? "$" : "R$";
        return `${currency} ${integerPart},${decimalPart}`;
      } else {
        return "-";
      }
    },
  },
  computed: {
    valorDolar() {
      return localStorage.getItem("valorDolar");
    },
    isNotCostPrecificationType() {
      return this.product?.tipoPrecificacao != "Custo" ?? false;
    },
    maCoUnitario() {
      return this.product?.maCoUnitario ?? 0;
    },
    maCoDolarUnitario() {
      return this.product?.maCoDolarUnitario ?? 0;
    },
    maCoCanalGeral() {
      return this.product?.maCoCanalGeral ?? 0;
    },
    maCoCanalGeralDolar() {
      return this.product?.maCoCanalGeralDolar ?? 0;
    },
    maCoMinimo() {
      return this.product?.maCoMinimo ?? 0;
    },
    maCoMinimoDolar() {
      return this.product?.maCoMinimoDolar ?? 0;
    },
    precoCanalGeral() {
      return this.product?.precoCanalGeral ?? 0;
    },
    precoMinimoCanalGeral() {
      return this.product?.precoMinimoCanalGeral ?? 0;
    },
    maCoMinimoCanalGeral() {
      return this.product?.maCoMinimoCanalGeral ?? 0;
    },
    maCoMinimoCanalGeralDolar() {
      return this.product?.maCoMinimoCanalGeralDolar ?? 0;
    },
  },
  async beforeMount() {
    await this.service.getCustoReposicao().then((res) => {
      this.custoReposicao = res.data;
    });
    if (this.product != null) {
      this.precoUnitario = this.product?.precoUnitario ?? 0;
      this.precoUnitarioDolar = this.product?.precoUnitarioDolar ?? 0;
      this.precoMinimo = this.product?.precoMinimo ?? 0;
      this.precoMinimoDolar = this.product?.precoMinimoDolar ?? 0;
    }
  },
};
</script>
<style lang="scss" scoped>
table {
  td {
    text-align: center;
  }

  thead {
    tr td {
      background-color: #3c5ca7;
      color: white;
      padding: 4px 16px;
      font-weight: bold;
    }

    tr td:first-child {
      background-color: transparent;
    }
  }

  tbody {
    tr td:first-child {
      background-color: #ccc;
      font-weight: bold;
      padding: 8px 16px;
      border-top-left-radius: 10px !important;
      border-bottom-left-radius: 10px !important;
    }
  }
}
</style>