import { render, staticRenderFns } from "./competitiveness-average-tooltip.vue?vue&type=template&id=2fbbc3ee&scoped=true&"
import script from "./competitiveness-average-tooltip.vue?vue&type=script&lang=js&"
export * from "./competitiveness-average-tooltip.vue?vue&type=script&lang=js&"
import style0 from "./competitiveness-average-tooltip.vue?vue&type=style&index=0&id=2fbbc3ee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fbbc3ee",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VIcon,VTooltip})
