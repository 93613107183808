<template>
  <v-dialog v-model="isOpen" persistent max-width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-icon class="personalizedIcon" v-bind="attrs" v-on="on"
        >mdi-poll</v-icon
      >
    </template>
    <v-card class="pa-4">
      <Prd-loading-circular v-if="isLoading" />
      <Variable-chart
        v-else
        @closeModal="isOpen = false"
        :categories="categories"
        :chartData="chartData"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import VariableChart from "./components/variable-chart.vue";
import UPLService from "@/service/upl";
export default {
  props: {
    idCompany: {
      type: Number,
    },
    projectionReference: {
      type: String,
    },
  },
  components: { VariableChart, PrdLoadingCircular },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      categories: [],
      chartData: [],
    };
  },
  methods: {
    async getChartData() {
      this.isLoading = true;
      try {
        const service = new UPLService();
        const response =
          await service.getVariableWightChartByProjectionReference(
            this.projectionReference,
            this.idCompany
          );
        this.categories = response.data.categories;
        this.chartData = response.data.data;
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message:
            "Houve problemas ao carregar os dados, feche o pup-op e abra novamente !",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
  watch: {
    isOpen: {
      handler(value) {
        if (value) this.getChartData();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.personalizedIcon {
  background-color: $brand-color-primary-pure;
  color: white;
  padding: 2px;
  transform: rotate(90deg);
  border-radius: 2.5px;
}
</style>