import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class CreatePricingModal extends ServiceBase {

    async getFilters(idCompany, preSelect, showInApriori, showInPricing) {
        /**
         * @param {Number} idCompany
         * @param {Boolean} preSelect - IF already have filter in main pricing
         * @param {Boolean} showInApriori - If is in apriori screen =  true
         * @param {Boolean} showInPricing - If is in ai pricing screen =  true
         */
        return await axiosInstance.get(API_URL + "api/V2/Enterprise/PriceGroups/Filter", { params: { idCompany: idCompany, preSelect: preSelect, showInApriori: showInApriori, showInPricing: showInPricing } });
    }

    async savePricing(request) {
        /**
         * @param {Number} idCompany
         * @param {Number} idEnterprisePriceGroup 
         * @param {Boolean} isSimulation 
         * @param {String} name
         * @param {Object} filters 
         */
        return await axiosInstance.post(API_URL + "api/V2/Enterprise/PriceGroups", request);
    }

    async calculatePricing(idCompany, idEnterprisePriceGroups) {
        /**
         * @param {Number} idCompany
         * @param {Number} idEnterprisePriceGroup 
         */
        return await axiosInstance.get(API_URL + `api/v2/Enterprise/PriceProjection/CalculatePrices?idCompany=${idCompany}&idEnterprisePriceGroups=${idEnterprisePriceGroups}&isManual=false`);
    }

    async extractReport(idCompany, idEnterprisePriceGroups) {
        /**
         * @param {Number} idCompany
         * @param {Number} idEnterprisePriceGroup 
         */
        return await axiosInstance.get(API_URL + `api/V2/Enterprise/PriceProjection/ListExport?idCompany=${idCompany}&IdEnterprisePriceGroups=${idEnterprisePriceGroups}&IsSimulation=false`);
    }

    async getPricingStatus(idEnterprisePriceGroups) {
        /**
         * @param {Number} idEnterprisePriceGroup 
         */
        return await axiosInstance.get(API_URL + `api/v2/Enterprise/PriceProjection/GetLoading?idEnterprisePriceGroups=${idEnterprisePriceGroups}`);
    }

}
