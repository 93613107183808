<template>
  <v-card flat width="240">
    <v-row no-gutters justify="start">
      <h4 class="mr-1">{{ $t("TXT_CI_UPPERCASE") }}</h4>
    </v-row>
    <v-row no-gutters justify="center">
      <thermometer-chart
        :ic="ic"
        :thermometerHeightProps="220"
        :scaleThermometer="[75, 85, 95, 105, 115, 125]"
      />
    </v-row>
    <v-row no-gutters>
      <span class="text-justify">
        {{ $t("TXT_CI_FIRST_PERIOD") }}
        <span class="font-weight-bold"
          >{{ ic | setIC }}
          {{
            ic > 100
              ? $t("TXT_CI_MIDDLE_PERIOD_HIGHER")
              : $t("TXT_CI_MIDDLE_PERIOD_LOWER")
          }}
        </span>
        {{ $t("TXT_CI_LAST_PERIOD") }}
      </span>
    </v-row>
  </v-card>
</template>

<script>
import ThermometerChart from "../../components/thermometer-chart-2/thermometer-chart-2.vue";
export default {
  components: {ThermometerChart },

  props: ["ic"],

  data() {
    return {
      localIC: null,
    };
  },
  filters: {
    setIC(value) {
      let ic = (value - 100).toFixed(2);
      if (ic < 0) {
        ic = ic.replace("-", "");
      }
      return ic + "%";
    },
  },
};
</script>