export function validateMatchSimulation(context) {
  const errors = [];
  if (!context.selectedPlace) errors.push("Você deve selecionar um estádio");
  if (!context.selectedCrowding) errors.push("Você deve selecionar um cenário");
  // if (!context.yellowPrice) errors.push("Você deve fornecer um preço amarelo");
  // if (!context.redPrice) errors.push("Você deve fornecer um preço vermelho");
  // if (!context.orangePrice) errors.push("Você deve fornecer um preço laranja");
  // if (!context.purplePrice) errors.push("Você deve fornecer um preço roxo");
  if (!context.publicPrediction)
    errors.push("Você deve fornecer uma previsão de público");
  if (!context.expenses)
    errors.push("Você deve fornecer um valor para despesas");

  if (context.service.crowding.error) {
    errors.push("Houve um erro ao buscar informações sobre este cenário");
  }
  if (context.service.place.error) {
    errors.push("Houve um erro ao buscar informações sobre este estádio");
  }
  if (context.service.listTickets.error) {
    errors.push("Houve um erro ao buscar a lista de ingressos");
  }
  return errors.join("\n");
}
