<template>
  <div class="header">
    <span>Categoria:</span>
    <span class="fontBold">{{ categoryText }}</span>
  </div>
</template>

<script>
export default {
  props: ["category"],
  data() {
    return {
      categories: [
        { text: "Todas", value: "TODAS" },
        { text: "Bebidas Alcoólicas", value: "BEBIDAS ALCOÓLICAS" },
        { text: "Bebidas não Alcoólicas", value: "BEBIDAS NÃO ALCOÓLICAS" },
        { text: "Biscoitos e Snacks", value: "BISCOITOS E SNACKS" },
        { text: "Bomboniere", value: "BOMBONIERE" },
        { text: "Congelados", value: "CONGELADOS" },
      ],
    };
  },
  computed: {
    categoryText() {
      return this.categories.find((category) => category.value == this.category)
        .text;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: $neutral-color-high-light;
  padding: 16px 0px;
  margin: 24px 0px;
  gap: 4px;
}
.fontBold {
  font-weight: bold;
}
</style>