<template>
  <v-container fluid>
    <TitleBreadCrumbsComponent
      :title="'PRECIFICAÇÃO'"
      :showTooltip="false"
      :hasTooltip="false"
    />
    <!-- FILTERS --->
    <Search-filters
      :recordsPerPage="recordsPerPage"
      :currentPage="currentPage"
      :productListLoading="productListLoading"
      @products="setProductsList"
      @setLoading="productListLoading = $event"
      @showSnackbar="showSnackbar"
      @cleanProducts="products = []"
      ref="seach-filters"
    />
    <!-- PRODUCT LIST -->
    <Product-list-main
      v-show="products.length != 0"
      :products="products"
      :recordsPerPage="recordsPerPage"
      :totalRecords="totalRecords"
      :productListLoading="productListLoading"
      @setRecordsPerPage="recordsPerPage = $event"
      @attNewPricing="attNewPricing"
      @attWorfklowERP="attWorfklowERP"
    />
    <Prd-pagination
      v-model="currentPage"
      v-show="products.length != 0"
      :totalRegisters="totalRecords"
      :length="totalPages"
      :showingRegistersLen="numberOfRecords"
      :resultLabel="$t('TXT_PAGINATION')"
      :pageLabel="$t('TXT_PAGE_LABEL')"
      class="mt-4"
    />

    <!-- MESSAGE COMPONENTS -->
    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.snackbarColor"
      :timeout="2000"
    >
      {{ snackbar.snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import TitleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import SearchFilters from "./components/search-filters.vue";
import ProductListMain from "./components/product-list/product-list-main.vue";
import PrdPagination from "@/Design_System/common/prd-pagination.vue";
import headerService from "@/service/new-ai-pricing/header.js";
const HeaderService = new headerService();

export default {
  name: "new-ai-pricing",
  components: {
    TitleBreadCrumbsComponent,
    SearchFilters,
    ProductListMain,
    PrdPagination,
  },
  data() {
    return {
      productListLoading: false,
      teste: true,
      // Pagination vars
      currentPage: 1,
      totalRecords: null,
      totalPages: null,
      numberOfRecords: null,
      recordsPerPage: 5,

      // Snackbar vars
      snackbar: {
        snackbar: false,
        snackbarText: null,
        snackbarColor: "#3C5CA7",
      },
      products: [],
    };
  },

  methods: {
    setProductsList(data, isFromFilter) {
      console.log(data);
      this.products = data.records;
      this.currentPage = isFromFilter ? 1 : data.currentPage;
      this.totalRecords = data.totalRecords;
      this.totalPages = data.totalPages;
      this.numberOfRecords = data.numberOfRecords;
    },

    showSnackbar(text, type) {
      let snackbar = {
        snackbar: true,
        snackbarColor: null,
        snackbarText: null,
      };

      snackbar.snackbarText = text;

      switch (type) {
        case "success":
          snackbar.snackbarColor = "#56a667";
          break;
        case "error":
          snackbar.snackbarColor = "#ec4c37";
          break;
        case "info":
          snackbar.snackbarColor = "#3c5ca7";
      }
      this.snackbar = snackbar;
    },

    async attNewPricing(updatedPricing) {
      const arrayFoundedIndex = [];
      const productsToUpdateArray = [];

      this.products.forEach((el) => {
        updatedPricing.productsToReplicate.find((idProjection) => {
          if (el.idEnterprisePricesProjection == idProjection) {
            arrayFoundedIndex.push(idProjection);
          }
        });
      });

      for (const idPriceProjection of arrayFoundedIndex) {
        try {
          const res = await HeaderService.getHeaderInfoById(idPriceProjection);

          productsToUpdateArray.push(res.data.result);
        } catch (error) {
          console.error(`Error ao buscar pelo ID ${idPriceProjection}:`, error);
        }
      }

      productsToUpdateArray.forEach((updatedProduct) => {
        const index = this.products.findIndex(
          (product) =>
            product.idEnterprisePricesProjection ===
            updatedProduct.idEnterprisePricesProjection
        );

        if (index !== -1) {
          this.products.splice(index, 1, updatedProduct);
        }
      });
    },

    productsToReplicate(idsProducts) {
      let foundedProducts = this.products
        .filter((product) =>
          idsProducts.includes(product.idEnterprisePricesProjection)
        )
        .map((product) => product.idEnterprisePricesProjection);

      return foundedProducts;
    },

    attWorfklowERP() {
      this.$refs["seach-filters"].getProductList();
    },
  },
};
</script>