<template>
  <v-card class="pa-4" :disabled="isDisabled">
    <v-row no-gutters justify="space-between" align="center">
      <v-col>
        <span>{{
          $t("TXT_ACTION_PRODUCT").replace(
            "*ACTION*",
            product && product.id
              ? $t("TXT_TO_ALTER")
              : $t("TXT_ADD").toLowerCase()
          )
        }}</span>
      </v-col>
      <v-col>
        <v-row no-gutters justify="end" class="gap-4">
          <Prd-btn
            :title="$t('TXT_CANCEL')"
            :outlined="true"
            @click="$emit('cleanProduct')"
          />

          <Save-changes-main
            :product="product"
            :originalProduct="originalProduct"
            :productsUPL="productsUPL"
            :isDisabled="isDisabled"
          />
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import SaveChangesMain from "./components/save-changes-main.vue";
export default {
  components: { PrdBtn, SaveChangesMain },
  props: {
    product: {
      type: Object,
    },
    originalProduct: {
      type: Object,
    },
    productsUPL: {
      type: Array,
    },
    isDisabled: {
      type: Boolean,
      default: true,
    },
  },
};
</script>