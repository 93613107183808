<template>
  <v-row no-gutters justify="center">
    <v-progress-circular
      indeterminate
      :color="$prdStyles('color-primary')"
      v-bind="$attrs"
    ></v-progress-circular>
  </v-row>
</template>

<script>
export default {};
</script>

<style>
</style>