<template>
  <div class="mb-4">
    <div style="display: flex">
      <!-- TOTAL RECIPE -->
      <v-card style="width: 50%" class="pa-4 mr-6" :disabled="isLoading">
        <v-row no-gutters>
          <h4>RECEITA TOTAL</h4>
        </v-row>

        <!-- RECIPE  -->
        <v-row no-gutters class="mt-4">
          <v-col>
            <label>Receita Diária</label>
            <v-skeleton-loader
              v-if="loadingProjection"
              type="card"
              height="22px"
              :width="80"
            />
            <h4 v-else class="money-destaq">{{ formatMonetary(lastWeek) }}</h4>
          </v-col>

          <v-col>
            <label>Projeção 7 dias</label>
            <v-skeleton-loader
              v-if="loadingProjection"
              type="card"
              height="22px"
              :width="80"
            />
            <h4 v-else class="money-destaq">
              {{ formatMonetary(weekProjection) }}
            </h4>
          </v-col>

          <v-col>
            <label>Projeção mês atual</label>
            <v-skeleton-loader
              v-if="loadingProjection"
              type="card"
              height="22px"
              :width="80"
            />
            <h4 v-else class="money-destaq">
              {{ formatMonetary(monthProjection) }}
            </h4>
          </v-col>

          <v-col v-if="idCompany == 3026">
            <label>Receita último mês</label>
            <v-skeleton-loader
              v-if="loadingProjection"
              type="card"
              height="22px"
              :width="80"
            />
            <h4 v-else class="money-destaq">
              {{ formatMonetary(lastMonthProjection) }}
            </h4>
          </v-col>
        </v-row>

        <!-- RECIPE IA -->
        <v-row no-gutters class="mt-4">
          <v-col class="mr-6">
            <label>Receita projetada Diária - IA</label>
            <v-skeleton-loader
              v-if="loadingProjection"
              type="card"
              height="22px"
              :width="80"
            />
            <h4 v-else class="money-destaq">
              {{ formatMonetary(lastWeekIA) }}
            </h4>
          </v-col>
          <v-col>
            <label>Projeção para 7 dias - IA</label>
            <v-skeleton-loader
              v-if="loadingProjection"
              type="card"
              height="22px"
              :width="80"
            />
            <h4 v-else class="money-destaq">
              {{ formatMonetary(weekProjectionIA) }}
            </h4>
          </v-col>
          <v-col>
            <label>Projeção do mês atual - IA</label>
            <v-skeleton-loader
              v-if="loadingProjection"
              type="card"
              height="22px"
              :width="80"
            />
            <h4 v-else class="money-destaq">
              {{ formatMonetary(monthProjectionIA) }}
            </h4>
          </v-col>
        </v-row>
      </v-card>

      <!-- TOTAL MARGIN -->
      <v-card style="width: 50%" class="pa-4" :disabled="isLoading">
        <v-row no-gutters>
          <h4>MARGEM TOTAL</h4>
        </v-row>

        <div class="mt-4" style="display: flex">
          <div style="flex-grow: 1">
            <label>Venda com margem atual</label>
            <v-skeleton-loader
              v-if="loadingProjection"
              type="card"
              height="22px"
              :width="80"
            />
            <h4 v-else class="money-destaq">
              {{ formatPercentage(marginLastWeek) }}
            </h4>
          </div>

          <div style="flex-grow: 1">
            <label>Venda com margem Projetada </label>
            <v-skeleton-loader
              v-if="loadingProjection"
              type="card"
              height="22px"
              :width="80"
            />
            <h4 v-else class="money-destaq">
              {{ formatPercentage(marginWeekProjection) }}
            </h4>
          </div>

          <div style="flex-grow: 1">
            <label>Variação</label>
            <v-skeleton-loader
              v-if="loadingProjection"
              type="card"
              height="22px"
              :width="80"
            />
            <h4 v-else class="money-destaq">
              {{ formatPercentage(marginVariation) }}
            </h4>
          </div>
        </div>
      </v-card>
    </div>

    <Chart-adoption-main
      v-if="hasResource('home.graph.impact') || isPredifyAdmin"
      :selectedAffiliate="selectedAffiliate"
      :getChartAdoptionDataFlag="getChartAdoptionDataFlag"
    />
    <total-revenue-main
      v-if="!VEM"
      :listRevenueGraphic="listRevenueGraphic"
      :isLoadingRevenueGraphic="isLoadingRevenueGraphic"
    />
  </div>
</template>

<script>
import { formatMonetary } from "@/utils/format-toMonetary";
import { formatPercentage } from "@/utils/format-percentage";
import TotalRevenueMain from "./total-revenue/total-revenue-main.vue";
import ChartAdoptionMain from "./chart-adoption-ia/chart-adoption-main.vue";

export default {
  props: [
    "geralVisionData",
    "isLoading",
    "loadingProjection",
    "totalProjectionData",
    "chartIALoading",
    "idCompany",
    "listRevenueGraphic",
    "isLoadingRevenueGraphic",
    "selectedAffiliate",
    "getChartAdoptionDataFlag",
  ],
  components: { TotalRevenueMain, ChartAdoptionMain },
  data() {
    return {
      charts: [],
    };
  },

  computed: {
    VEM() {
      return this.idCompany == 3026 ? true : false;
    },
    isPredifyAdmin() {
      return this.$store?.getters?.isPredifyAdmin ?? false;
    },
    lastWeek() {
      return this.totalProjectionData?.totalRecipe?.lastWeek ?? null;
    },
    weekProjection() {
      return this.totalProjectionData?.totalRecipe?.weekProjection ?? null;
    },
    monthProjection() {
      return this.totalProjectionData?.totalRecipe?.monthProjection ?? null;
    },
    lastWeekIA() {
      return this.totalProjectionData?.totalRecipe?.lastWeekIA ?? null;
    },
    weekProjectionIA() {
      return this.totalProjectionData?.totalRecipe?.weekProjectionIA ?? null;
    },
    monthProjectionIA() {
      return this.totalProjectionData?.totalRecipe?.monthProjectionIA ?? null;
    },

    marginLastWeek() {
      return this.totalProjectionData?.totalMargin?.lastWeek ?? null;
    },
    marginWeekProjection() {
      return this.totalProjectionData?.totalMargin?.weekProjection ?? null;
    },
    lastMonthProjection() {
      return this.totalProjectionData?.lastMonthProjection ?? null;
    },

    marginVariation() {
      return this.marginWeekProjection - this.marginLastWeek ?? null;
    },
    dayAdoptionChart() {
      return this.geralVisionData?.dayAdoption ?? null;
    },
  },

  methods: {
    formatMonetary,
    formatPercentage,
    hasResource(resource) {
      return this.$store.getters.hasResource(resource);
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  font-size: 14px;
}
.money-destaq {
  color: $brand-color-primary-pure;
  font-size: 16px;
  font-weight: 600;
}

.group-box {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

.group-filters {
  border-bottom: 1px solid #00000048;
}

.v-tab {
  font-size: 14px !important;
  text-transform: inherit;
  font-weight: 600;
}

.v-tabs {
  width: max-content !important;
}
</style>