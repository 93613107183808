<template>
  <v-data-table
    class="mt-4"
    :headers="headers"
    :items="items"
    :hide-default-footer="items.length == 0"
    :footer-props="footerProps"
    :no-data-text="$t('TXT_NO_DATA_AVAILABLE')"
  >
    <template v-slot:[`item.agMacro`]="{ item }">
      <v-row
        no-gutters
        align="center"
        justify="center"
        v-if="item.agMacro != '' && item.agMacro != null"
      >
        <span class="mr-4">{{ item.agMacro }}</span>
        <v-icon
          :color="$prdStyles('color-primary')"
          @click="$emit('updateAgroupment', item.idAgroupment)"
          >mdi-pencil-box</v-icon
        >
        <DeleteAgroupmentModal
          :id="item.idAgroupment"
          @setSnackbarType="$emit('setSnackbarType', $event)"
          @refreshTargetMarketSegmentationTable="
            $emit('refreshTargetMarketSegmentationTable')
          "
        />
      </v-row>
    </template>
    <template v-slot:[`item.variation`]="{ item }">
      <span>{{ setVariation(item.variation) }}</span>
    </template>
  </v-data-table>
</template>

<script>
import DeleteAgroupmentModal from "./delete-agroupment-modal/delete-agroupment-modal.vue";

export default {
  components: { DeleteAgroupmentModal },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },
    };
  },

  watch: {
    addEditAgroupmentModal: {
      handler(value) {
        if (!value) this.selectedAgroupmentId = null;
      },
    },
  },

  methods: {
    setVariation(value) {
      return `${value && value != 0 ? value.toFixed(2) : "-"} %`;
    },
  },
};
</script>