<template>
	<!-- ACTION INFO -->
	<v-card class="pa-4 pt-0" :disabled="promotionalCalendarLoading">
		<div class="flex mr-4 pt-4">
			<v-tabs v-model="actionTab" color="black" slider-color="#EC4C37" background-color="transparent"
				class="group-filters mb-4 mr-4" height="25px">
				<v-tab class="mr-6">
					<h4>VIGENTES ({{ currentActions.length }})</h4>
				</v-tab>
				<v-tab class="mr-6">
					<h4>PRÓXIMAS ({{ nexActions.length }})</h4>
				</v-tab>
			</v-tabs>
			<prd-btn @click="exportData" :title="'Exportar'" :outlined="true" :icon="'mdi-download-outline'" :smallIcon="true"
				:leftIcon="true" />
		</div>

		<v-row v-if="promotionalCalendarLoading" no-gutters justify="center">
			<v-progress-circular :color="$prdStyles('color-primary')" indeterminate></v-progress-circular>
		</v-row>

		<v-tabs-items v-else v-model="actionTab">
			<v-tab-item>
				<carousel-item v-if="currentActions != null" :actionData="currentActions" />
			</v-tab-item>
			<v-tab-item>
				<carousel-item :actionData="nexActions" />
			</v-tab-item>
		</v-tabs-items>
	</v-card>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
import carouselItem from './components/carousel-item.vue';
import ServiceHome20 from "@/service/home20/index";

export default {
	components: { prdBtn, carouselItem },
	props: ["promotionalCalendarData", "promotionalCalendarLoading", "idCompany"],
	data() {
		return {
			tab: 0,
			service: new ServiceHome20(),
			actionTab: 0,
			currentActions: [],
			nexActions: [],
		};
	},
	watch: {
		promotionalCalendarData: {
			handler(newValue) {
				this.currentActions = newValue.currentActions;
				this.nexActions = newValue.nextActions;
			},
			deep: true,
		},
	},
	methods: {
		exportData() {
			this.service
				.getRelatorioAcaoPromocional(this.idCompany)
				.then((res) => {
					const url = window.URL.createObjectURL(
						new Blob([res.data], { type: "application/vnd.ms-excel" })
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", "RelatoriosPromocionais.xlsx");
					document.body.appendChild(link);
					link.click();
					URL.revokeObjectURL(link.href);
				})
				.catch((error) => {
					console.log(error);
				});
		},
	}
}
</script>

<style></style>