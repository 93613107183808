<template>
  <v-container fluid>
    <v-row no-gutters align="center">
      <titleBreadCrumbsComponent
        :breadCrumbs="breadcrumbs"
        :title="$t('TXT_TAG_CONFIGURATION_UPPERCASE')"
        :showTooltip="false"
        :hasTooltip="false"
      />
      <v-spacer></v-spacer>
      <prd-btn
        @click="backPredimonitor"
        :icon="'mdi-arrow-left'"
        :leftIcon="true"
        :title="$t('TXT_BACK')"
        class="mt-14"
      />
    </v-row>

    <v-card
      :class="`pa-4 ${isLoading ? 'pt-0' : ''}`"
      :loading="isLoading"
      :disabled="isLoading"
    >
      <v-row
        no-gutters
        align="center"
        justify="space-between"
        :class="`${isLoading ? 'mt-4' : ''}`"
      >
        <h3>TAGS</h3>
        <v-col cols="auto">
          <v-row no-gutters class="gap-4">
            <prd-btn
              @click="exportTags"
              :leftIcon="true"
              :icon="'mdi-download-outline'"
              :title="$t('TXT_EXPORT')"
              :loading="disableTag"
              :disabled="disableTag || disableTagImport || isLoading"
            />

            <!-- IMPORT FILE BTN -->
            <prd-btn
              @click="$refs.fileInput.click()"
              :leftIcon="true"
              :icon="'mdi-upload-outline'"
              :title="$t('TXT_TOIMPORT')"
              :disabled="disableTagImport || disableTag || isLoading"
              :loading="disableTagImport"
            />
            <input
              ref="fileInput"
              type="file"
              style="display: none"
              @change="importFile"
            />
          </v-row>
        </v-col>
      </v-row>

      <v-row no-gutters justify="space-between" align="end" class="my-6">
        <prd-search
          v-model="search"
          :searchLabel="$t('TXT_SEARCH_TAGS')"
          :searchPlaceholder="'Buscar'"
          :disabled="products.length == 0"
        />
        <prd-combo-box
          v-model="recordsPerPage"
          :title="$t('TXT_RESULTS_BY_PAGE')"
          :disabled="products.length == 0"
          :placeholder="$t('TXT_SELECT')"
          :items="itemsPerPage"
          class="paginationSize"
        />
      </v-row>

      <product-card
        v-for="(product, index) in products"
        :key="index"
        :product="product"
        @callGet="getMonitoringTags"
        @setLoading="isLoading = $event"
        @showSnackbar="showSnackbar"
      />

      <!-- PAGINATION -->
      <prd-pagination
        v-model="currentPage"
        v-show="products.length != 0"
        :totalRegisters="totalRecords"
        :length="totalPages"
        :showingRegistersLen="numberOfRecords"
        :resultLabel="$t('TXT_PAGINATION')"
        :pageLabel="$t('TXT_PAGE_LABEL')"
        class="mt-4"
      />

      <!-- SNACKBAR -->
      <v-snackbar
        v-model="snackbar.snackbar"
        :color="snackbar.snackbarColor"
        :timeout="2000"
      >
        {{ snackbar.snackbarText }}
      </v-snackbar>

      <Errors-modal ref="ErrorsModal" />
    </v-card>
  </v-container>
</template>

<script>
import titleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import PrdSearch from "@/Design_System/common/prd-search.vue";
import ProductCard from "./components/product-card.vue";
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";
import PrdPagination from "@/Design_System/common/prd-pagination.vue";
import service from "@/service/tags-config/index.js";
import PrdBtn from "../../../Design_System/common/prd-btn.vue";
import ErrorsModal from "./components/errors-modal.vue";

export default {
  name: "tags-config",
  components: {
    titleBreadCrumbsComponent,
    PrdSearch,
    ProductCard,
    PrdComboBox,
    PrdPagination,
    PrdBtn,
    ErrorsModal,
  },

  data() {
    return {
      service: new service(),
      snackbar: {
        snackbar: false,
        snackbarText: null,
        snackbarColor: "#3C5CA7",
      },

      itemsPerPage: [5, 10, 15, 20],

      isLoading: false,
      search: "",
      disableTagImport: false,
      disableTag: false,

      // Pagination vars
      recordsPerPage: 10,
      currentPage: 1,
      totalRecords: null,
      totalPages: null,
      numberOfRecords: null,
      file: null,
      products: [],
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    breadcrumbs() {
      return [
        {
          text: "Predimonitor",
          href:
            this.idCompany == 2652
              ? "/main/predimonitor-V2"
              : "/main/predimonitor",
        },
        {
          text: this.$i18n.t("TXT_TAG_CONFIGURATION"),
          href: "/main/tags-config",
        },
      ];
    },
  },

  watch: {
    currentPage() {
      this.getMonitoringTags();
    },

    search() {
      this.getMonitoringTags();
    },

    recordsPerPage() {
      this.getMonitoringTags();
    },
  },

  methods: {
    hasResource(value) {
      return this.$store.getters.hasResource(value);
    },

    getMonitoringTags() {
      this.isLoading = true;

      let request = {
        includeLeadInfo: true,
        includeConfigInfo: true,
        monitoringItemCreateStartDate: null,
        monitoringItemCreateEndDate: null,
        onlyForSearch: false,
        onlyWithLead: false,
        filters: {
          description: this.search,
        },
        page: this.currentPage,
        recordsPerPage: this.recordsPerPage,
        idCompany: this.idCompany,
      };

      this.service
        .getMonitoringItems(request)
        .then((res) => {
          if (res.status != 500) {
            let response = res.data.result;
            this.products = response.records;

            this.totalRecords = response.totalRecords;
            this.recordsPerPage = response.recordsPerPage;
            this.currentPage = response.currentPage;
            this.totalPages = response.totalPages;
            this.numberOfRecords = response.numberOfRecords;
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          } else {
            this.showSnackbar("Erro ao buscar tags", "error");
          }

          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },

    backPredimonitor() {
      if (this.hasResource("predimonitor.v2")) {
        this.$router.push("/main/predimonitor-V2");
      } else this.$router.push("/main/predimonitor");
    },

    showSnackbar(text, type) {
      let snackbar = {
        snackbar: true,
        snackbarColor: null,
        snackbarText: null,
      };

      snackbar.snackbarText = text;

      switch (type) {
        case "success":
          snackbar.snackbarColor = "#56a667";
          break;
        case "error":
          snackbar.snackbarColor = "#ec4c37";
          break;
        case "info":
          snackbar.snackbarColor = "#3c5ca7";
      }
      this.snackbar = snackbar;
    },

    exportTags() {
      this.disableTag = true;
      this.service
        .exportTags(this.idCompany)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "exportTags");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          this.disableTag = false;
        })
        .catch((error) => {
          console.log("Erro ao exportar tags", error);
          this.disableTag = false;
        });
    },

    // When you click on the import button, it opens the file type input
    handleImportFile() {
      this.$refs.fileInput.click();
    },

    async importFile(event) {
      try {
        this.disableTagImport = true;

        const file = event.target.files[0];

        if (!this.checkFileType(file)) return;

        const formData = new FormData();

        formData.append("file", file);

        formData.append("idCompany", this.idCompany);

        let response = await this.service.importTags(formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response?.data?.success) {
          this.showSnackbar(this.$i18n.t("TXT_IMPORT_TAGS_SUCCESS"), "success");
          this.getMonitoringTags();
        } else {
          this.showSnackbar(this.$i18n.t("TXT_IMPORT_TAGS_ERROR"), "error");
          console.log(response.data);
        }
      } catch (error) {
        if (error?.response?.status == 400 || error?.response?.status == 415)
          this.handlerErrors(error);
        else this.showSnackbar(this.$i18n.t("TXT_IMPORT_TAGS_ERROR"), "error");
      } finally {
        this.disableTagImport = false;
        this.$refs.fileInput.value = null;
      }
    },
    checkFileType(file) {
      if (!file) return;
      const acceptedType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const fileType = file.type;
      const result = acceptedType == fileType;
      if (!result)
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_INVALID_FILE_TYPE", {
            fileType: "'.xlsx'",
          }),
          type: "info",
        });
      return result;
    },
    handlerErrors(error) {
      this.$refs.ErrorsModal.openModal(
        error?.response?.message ?? error?.response?.data?.message ?? null
      );
    },
  },

  mounted() {
    this.getMonitoringTags();
    document.title = `PREDIFY | ${this.$i18n.t("TXT_TAG_CONFIGURATION")}`;
  },
};
</script>
<style scoped>
.searchSize {
  width: 38%;
}
</style>