<template>
  <v-container fluid>
    <titleBreadCrumbs
      :title="$t('TXT_CHURN_ANALYSIS')"
      :tooltipText="'tooltip'"
    />

    <v-card>
      <churn-filters
        @churnData="setChurnPaginated"
        @setLoading="isLoading = $event"
        @showSnackbar="showSnackbar"
        :currentPage="currentPage"
        :isLoading="isLoading"
      />
      <churn-table
        v-if="churnData.length != 0"
        :churnData="churnData"
        :isLoading="isLoading"
        :currency="currency"
      />
    </v-card>

    <prd-pagination
      v-model="currentPage"
      v-show="churnData.length != 0"
      :totalRegisters="totalRecords"
      :length="totalPages"
      :showingRegistersLen="numberOfRecords"
      :resultLabel="$t('TXT_PAGINATION')"
      :pageLabel="$t('TXT_PAGE_LABEL')"
      class="mt-4"
    />

    <!-- MESSAGE COMPONENTS -->
    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.snackbarColor"
      :timeout="2000"
    >
      {{ snackbar.snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import titleBreadCrumbs from "@/Design_System/common/title.vue";
import churnFilters from "./components/churn-filters.vue";
import ChurnTable from "./components/churn-table.vue";
import PrdPagination from "@/Design_System/common/prd-pagination.vue";
import companyService from "@/service/company-service.js";
const Service = new companyService();
export default {
  components: { churnFilters, titleBreadCrumbs, ChurnTable, PrdPagination },
  name: "churn-analysis",
  data() {
    return {
      churnData: [],
      isLoading: false,

      // Pagination vars
      currentPage: 1,
      totalRecords: null,
      totalPages: null,
      numberOfRecords: null,

      // Snackbar vars
      snackbar: {
        snackbar: false,
        snackbarText: null,
        snackbarColor: "#3C5CA7",
      },
    };
  },
  computed: {
    currency() {
      return localStorage.getItem("currency") || "R$";
    },
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },
  methods: {
    setChurnPaginated(data) {
      this.churnData = data.records;
      this.totalRecords = data.totalRecords;
      this.totalPages = data.totalPages;
      this.numberOfRecords = data.numberOfRecords;
    },

    showSnackbar(text, type) {
      let snackbar = {
        snackbar: true,
        snackbarColor: null,
        snackbarText: null,
      };

      snackbar.snackbarText = text;

      switch (type) {
        case "success":
          snackbar.snackbarColor = "#56a667";
          break;
        case "error":
          snackbar.snackbarColor = "#ec4c37";
          break;
        case "info":
          snackbar.snackbarColor = "#3c5ca7";
      }
      this.snackbar = snackbar;
    },
    setCurrency() {
      Service.getCurrency(this.idCompany)
        .then((res) => {
          if (res.status != 500) {
            let currency = res.data.currencySymbol;
            if (!currency) {
              localStorage.setItem("currency", "R$");
            } else {
              localStorage.setItem("currency", currency);
            }
          }
        })
        .catch((error) => {
          console.log("Erro no buscar currency", error);
          localStorage.setItem("currency", "R$");
        });
    },
  },

  created() {
    document.title = "PREDIFY | ANÁLISE DE CHURN";
    this.setCurrency();
  },
};
</script>
<style lang="scss" scoped>
</style>