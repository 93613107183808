<template>
  <v-menu v-model="menu" :close-on-content-click="false" :return-value.sync="date" ref="menu"
    transition="scale-transition" offset-y min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-icon :disabled="disabledIcon" size="32" v-bind="attrs" v-on="on"
        :class="`calendar-icon pa-1 ${disabledIcon ? 'disabled' : ''}`">mdi-calendar</v-icon>
    </template>
    <!-- TODO - properly use user's locale -->
    <v-date-picker v-model="date" no-title scrollable range :min="minDay" :max="maxDay">
      <v-spacer></v-spacer>

      <v-btn text color="error" @click="menu = false && dateRangeText == null" v-t="'TXT_CANCEL'"></v-btn>
      <v-btn outlined color="primary" @click="date = [null, null]">Reset</v-btn>

      <v-btn color="primary" @click="saveSelection" v-t="'TXT_OK'"></v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: ['disabledIcon'],
  data() {
    return {
      menu: false,
      date: [null, null],
      minDay: null,
      maxDay: null,
    }
  },
  methods: {
    saveSelection() {
      this.$refs.menu.save(this.date)
      this.prepareDate();
    },
    setDate() {
      this.minDay = null;
      this.maxDay = null;
      var startDay = new Date();
      startDay.setDate(startDay.getDate() - 6);
      var minRange = startDay.toISOString().slice(0, 10);
      this.maxDay = new Date().toISOString().slice(0, 10);
      this.date[0] = minRange;
      this.date[1] = new Date().toISOString().slice(0, 10);
    },
    organizeDate() {
      var firstPick = this.date[0]
      var secondPick = this.date[1]
      if (firstPick > secondPick) {
        return [secondPick, firstPick];
      } else if (firstPick < secondPick) {
        return [firstPick, secondPick];
      } else {
        const date = firstPick ?? secondPick
        return [date, date];
      }
    },
    prepareDate() {
      let [start_date, end_date] = this.organizeDate();
      start_date += 'T00:01:00'
      end_date += 'T23:59:59'
      this.$emit('selectDate', { start_date, end_date })
    }
  },
}
</script>

<style lang="scss" scoped>
.calendar-icon {
  background-color: $brand-color-primary-pure;
  color: white;
  border-radius: 5px;
}

.disabled {
  background-color: $neutral-color-high-dark;
}
</style>