<template>
  <div>
    <v-card class="pa-4 mb-4">
      <h4 class="mb-4">FILTROS</h4>
      <v-row no-gutters class="mb-4">
        <v-col cols="12" md="4" v-for="(filter, index) in filters" :key="index">
          <label>{{ filter.label }}</label>
          <v-combobox
            v-model="filter.selected"
            :items="filter.items"
            :class="filter.margin"
            :disabled="
              isLoading || products.length == 0 || filter.value == 'price'
            "
            @change="applyFilters"
            deletable-chips
            small-chips
            outlined
            dense
            hide-details
            append-icon="mdi-chevron-down"
            class="mt-1"
          >
          </v-combobox>
        </v-col>
      </v-row>
      <Prd-btn
        :title="'Extrair relatório'"
        @click="extractReport(products)"
        :disabled="isLoading || products.length == 0"
        class="mr-4"
      />
      <Prd-btn
        :title="'Extrair histórico'"
        @click="getDataToHistory(products)"
        :disabled="isLoading || extractLoading || products.length == 0"
        :loading="extractLoading"
      />
    </v-card>

    <v-card class="pa-4">
      <v-row no-gutters align="center">
        <h4 class="mr-4">RESULTADOS</h4>
        <Prd-loading-msg
          v-if="refreshLoading"
          :messageInFront="true"
          :text="'Atualizando precificação'"
        />
      </v-row>

      <Prd-loading-circular v-if="isLoadingGroup" />

      <v-expansion-panels accordion>
        <Product-panel-content
          v-for="product in filteredProducts"
          :key="product.idMonitoringItem"
          :product="product"
          :dateRange="dateRange"
          @refreshProduct="$emit('refreshProduct', $event)"
          class="mt-2"
        />
      </v-expansion-panels>
      <div v-if="isLoading && !isLoadingGroup">
        <v-skeleton-loader
          v-for="(skeleton, index) in totalProducts"
          :key="index"
          type="card"
          height="40px"
          tile
          class="mx-auto mt-4"
        ></v-skeleton-loader>
      </div>
    </v-card>
  </div>
</template>

<script>
import ProductPanelContent from "./product-panel-content.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import service from "@/service/predimonitor-V2/index.js";
import { formatMonetary } from "@/utils/format-toMonetary.js";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import PrdLoadingMsg from "@/components/common/prd-loading-msg.vue";
const Service = new service();
import { formatDate } from "@/utils/prd-format-date.js";
import * as XLSX from "xlsx";
export default {
  props: [
    "products",
    "isLoading",
    "isLoadingGroup",
    "totalProductsLoading",
    "productsLoading",
    "dateRange",
    "refreshLoading",
  ],
  components: {
    ProductPanelContent,
    PrdBtn,
    PrdLoadingCircular,
    PrdLoadingMsg,
  },
  data() {
    return {
      filters: [
        {
          label: "Marketplace",
          value: "crawler_name",
          margin: "mr-4",
          selected: null,
          items: [],
        },
        {
          label: "Seller",
          value: "seller_name",
          margin: "mr-4",
          selected: null,
          items: [],
        },
        // {
        //   label: "Marca",
        //   value: "price",
        //   margin: "",
        //   selected: null,
        //   items: [],
        // },
      ],
      filteredProducts: [],
      originalItems: [],
      productsToLoad: [],
      extractLoading: false,
    };
  },

  computed: {
    totalProducts() {
      return this.totalProductsLoading - this.products.length;
    },
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  watch: {
    products: {
      handler(values) {
        if (!values) return;

        this.filterProductsToShow();

        this.filters.forEach((filter) => {
          filter.items = [];
          filter.selected = null;
        });

        this.fillFiltersItems(this.products, this.filters);
        this.filteredProducts = this.products;
      },
      deep: true,
      immediate: true,
    },

    // filteredProducts: {
    //   handler(values) {
    //     this.$store.commit("saveProductToChart", values);
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },

  methods: {
    formatDate,
    formatMonetary,
    fillFiltersItems(data, filters) {
      data.forEach(({ tableData }) => {
        tableData.forEach((item) => {
          filters.forEach((filter) => {
            const filterValue = item[filter.value];
            const existingItem = filter.items.find(
              (i) => i.value === filterValue
            );
            if (!existingItem) {
              filter.items.push(filterValue);
            }
          });
        });
      });
    },

    applyFilters() {
      const filteredData = this.products.map((dataItem) => {
        const filteredTableData = dataItem.tableData.filter((product) => {
          return this.filters.every((filter) => {
            const selectedValue = filter.selected;
            const productValue = product[filter.value];
            if (selectedValue === null) {
              return true;
            } else {
              return String(productValue) === String(selectedValue);
            }
          });
        });

        return {
          ...dataItem,
          tableData: filteredTableData,
        };
      });

      this.filteredProducts = filteredData;
    },

    extractReport(data) {
      data.forEach((el) => {
        el.tableData.forEach((result) => {
          result.product_reference = el.productName;
          result.product_name = result.product_name
            .replace(/\\/g, " ")
            .replace(/"/g, " ")
            .replace(/\n/g, " ");
          result.product_link = result.product_link
            .replace(/\\/g, " ")
            .replace(/"/g, " ")
            .replace(/\n/g, " ");
        });
      });

      let groupedData = [];
      data.forEach((el) => {
        el.tableData.forEach((data) => {
          groupedData.push(data);
        });
      });
      console.log(data);
      const filteredData = groupedData.map((item) => ({
        "Data da Coleta": this.formatDate(item.crawler_date),
        Site: item.crawler_name,
        "Link do Produto": item.product_link,
        Referência: item.product_reference,
        "Nome do Produto": item.product_name,
        Seller: item.seller_name,
        Manual: !item.isManual ? "Falso" : "Verdadeiro",
        idMonitoringItemProductManualResult:
          item.idMonitoringItemProductManualResult,
        Preço: this.formatMonetary(item.price),
        "Meio Pagamento": item.price_payment_type,
        Desconsiderado: !item.disregarded ? "Falso" : "Verdadeiro",
        idMonitoringItem: item.idMonitoringItem,
        on_request: !item.on_request ? "Falso" : "Verdadeiro",
      }));
      const ws = XLSX.utils.json_to_sheet(filteredData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const filename = "predimonitorExport.xlsx";

      XLSX.writeFile(wb, filename);
      this.extractLoading = false;
    },

    async getDataToHistory(products) {
      this.extractLoading = true;
      const itemsToExtract = [];

      const requestArray = products.map((product) => {
        const productLinks = product.tableData.map((el) => el.product_link);

        return {
          idMonitoringItem: product.idMonitoringItem,
          startDate: this.dateRange[0],
          endDate: this.pushOneMoreDay(
            this.dateRange[this.dateRange.length - 1]
          ),
          product_Links: productLinks,
          idCompany: this.idCompany,
        };
      });

      try {
        for (const el of requestArray) {
          let res = await Service.getDataToChart(el);
          res = res?.data?.result ?? null;

          if (res) {
            res.forEach((el) => {
              let newObject = {
                Artigo: el.productName.split(" - ")[0],
                Descrição: el.description,
                "Nome do produto": el.productName
                  .replace(/^\d+\s*-\s*/, "")
                  .replace(/\\/g, " ")
                  .replace(/"/g, " ")
                  .replace(/\n/g, " "),
                Site: el.sellerName,
                Seller: el.crawlerName,
                Estoque: el.stock,
                Preço: this.formatMonetary(el.price),
                "Parc.": el.quantity,
                "Preço deflator": this.formatMonetary(el.deflatedValue),
                "Meio Pagamento": el.paymentType ?? "N/A",
                "Tipo de preço": el.priceType,
                "Data da coleta": this.formatDate(el.crawlerDate),
                "Link do produto": el.productLink
                  .replace(/\\/g, " ")
                  .replace(/"/g, " ")
                  .replace(/\n/g, " "),
              };
              itemsToExtract.push(newObject);
            });
          }
        }

        this.extractHistory(itemsToExtract);
      } catch (error) {
        console.log(error);
        this.extractLoading = false;
        this.$store.commit("snackbarV2/set", {
          message: "Erro ao extraír histórico",
          type: "error",
        });
      }
    },

    extractHistory(newObject) {
      const ws = XLSX.utils.json_to_sheet(newObject);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const filename = "predimonitorHistory.xlsx";

      XLSX.writeFile(wb, filename);
      this.extractLoading = false;
    },

    filterProductsToShow() {
      if (this.productsToLoad.length == 0 || !this.productsToLoad) return [];
      this.productsToLoad = this.products.filter((product) => {
        return !this.productsLoading.some((loadingProduct) => {
          return product.idMonitoringItem === loadingProduct.idMonitoringItem;
        });
      });
    },

    pushOneMoreDay(dataString) {
      const data = new Date(dataString);
      data.setDate(data.getDate() + 2);
      const ano = data.getFullYear();
      const mes = String(data.getMonth() + 1).padStart(2, "0");
      const dia = String(data.getDate()).padStart(2, "0");
      return `${ano}-${mes}-${dia}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.product-item {
  font-weight: bold;
  font-size: 16px;
  background-color: white;
  opacity: 0.7;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
</style>

