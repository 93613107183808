<template>
  <v-expansion-panels>
    <Prd-loading-circular v-if="isLoading" class="my-6" />

    <v-row v-else no-gutters>
      <v-row
        v-if="products.length <= 0 && !isLoading"
        no-gutters
        justify="center"
        align="center"
      >
        <h4>Não foram encontrados resultados</h4>
        <v-icon @click="getTable" right :color="$prdStyles('color-primary')"
          >mdi-refresh</v-icon
        >
      </v-row>
      <v-expansion-panel
        v-else
        v-for="(product, index) in products"
        :key="index"
      > 
        <div>
          <Table-header
            :product="product"
            :item="item"
            @refreshProduct="refreshProduct"
          />
          <Table-body :product="product" />
        </div>
      </v-expansion-panel>
    </v-row>
  </v-expansion-panels>
</template>

<script>
import TableBody from "./components/table-body.vue";
import TableHeader from "./components/table-header.vue";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import service from "@/service/predimonitor/predimonitor-service";
const Service = new service();
import moment from "moment";
export default {
  components: { TableHeader, TableBody, PrdLoadingCircular },
  props: ["item"],
  data() {
    return {
      products: [],
      isLoading: false,
      productLoading: false,
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  methods: {
    async getTable() {
      this.isLoading = true;
      let request = this.buildRequest();

      try {
        let response = await Service.AvgInfoByProduct(request);
        let data = response?.data?.answer?.registers ?? [];

        let products = this.findEmptyProducts(this.item, data);
        this.products = data.concat(products);
      } catch (error) {
        console.log(error);
      }

      this.isLoading = false;
    },

    async refreshProduct() {
      this.getTable(false);
      // let request = this.buildRequest(idMonitoringItem);

      // try {
      //   let response = await Service.AvgInfoByProduct(request);
      //   let data = response?.data?.answer?.registers ?? [];

      //   this.products.forEach((el, index) => {
      //     if (data.length > 0) {
      //       console.log(data);
      //       if (el.idMonitoringItem === idMonitoringItem) {
      //         this.$set(this.products, index, data[0]);
      //       }
      //     } else {
      //       if (el.idMonitoringItem === idMonitoringItem) {
      //         this.products[index].priceResults = [];
      //         this.products[index] = {
      //           idMonitoringItem: idMonitoringItem,
      //           productName: el.productName,
      //         };
      //       }
      //     }
      //   });
      // } catch (error) {
      //   console.log(error);
      // }
    },

    buildRequest(idMonitoringItem) {
      let request = {
        idsTask: this.item.listMonitoringCrawlers.map(
          (crawler) => crawler.idTask
        ),
        startDate: moment(this.item.createDate).format("YYYY-MM-DD"),
        endDate: moment(this.item.updateDate).format("YYYY-MM-DD"),
        groupBy: 1,
        idCompany: this.idCompany,
        filters: {
          idProducts: idMonitoringItem
            ? [idMonitoringItem]
            : this.item.listMonitoringItems.map((el) => el.idMonitoringItem),
        },
      };
      return request;
    },

    findEmptyProducts(item, product) {
      const idProduct = product.map((p) => p.idMonitoringItem);

      const notFoundItems = item.listMonitoringItems.filter(
        (monitoringItem) => {
          return !idProduct.includes(monitoringItem.idMonitoringItem);
        }
      );

      return notFoundItems;
    },
  },
  mounted() {
    this.getTable();
  },
};
</script>

<style>
</style>