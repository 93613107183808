<template>
  <v-dialog persistent v-model="isOpenLocal" max-width="600px">
    <v-card class="pa-4" flat elevation="0">
      <v-row no-gutters justify="center" class="mb-1">
        <h2>{{ $i18n.t("TXT_INFORMATIONS_PRICING_SELECTED") }}</h2>
      </v-row>
      <v-row no-gutters class="infosPublishedModal pa-4 mb-2">
        <v-col cols="12">
          <v-row no-gutters align="center">
            <h4>ID:</h4>
            <span class="ml-1">
              {{
                precificationData
                  ? precificationData.idEnterprisePriceGroups
                  : ""
              }}
            </span>
          </v-row>
          <v-row no-gutters align="center">
            <h4>{{ $i18n.t("TXT_NAME") + " :" }}</h4>
            <span class="ml-1">
              {{ precificationData ? precificationData.name : "" }}
            </span>
          </v-row>
        </v-col>
      </v-row>
      <h4>{{ $i18n.t("TXT_REALLY_USE_PRECIFICATION") }}</h4>
      <span
        ><span class="colorWarning">*</span> {{$i18n.t('TXT_NOT_POSSIBLE_CHANGES')}}
        <span class="colorPredify font-weight-bold">PREDIFY</span>.</span
      >
      <v-row no-gutters justify="end" align="center" class="mt-2">
        <prd-btn
          :disabled="isLoading"
          :title="$t('TXT_CANCEL')"
          :outlined="true"
          class="mr-2"
          @click="$emit('closeModalPublishedPrecification')"
        />
        <prd-btn-loading
          :isLoading="isLoading"
          :disabled="isLoading"
          :title="$t('TXT_CONFIRM')"
          @click="
            $emit('publishPricing', precificationData.idEnterprisePriceGroups)
          "
        />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import prdBtnLoading from "@/Design_System/common/prd-btn-loading.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
export default {
  components: { prdBtnLoading, PrdBtn },
  props: ["isOpen", "precificationData", "isLoading"],
  data() {
    return {
      isOpenLocal: false,
    };
  },
  watch: {
    isOpen: {
      handler(value) {
        this.isOpenLocal = value;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.infosPublishedModal {
  border-radius: 10px;
  border: 2px solid $brand-color-primary-pure;
}
.colorPredify {
  color: $brand-color-primary-pure;
}
.colorWarning {
  color: $feedback-color-warning-pure;
}
</style>