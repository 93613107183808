<template>
  <v-card class="pa-4" elevation="4" v-if="this.product != null">
    <highcharts  :options="chartData"></highcharts>
  </v-card>
</template>

<script>
import Highcharts from "highcharts";

// Exporta data in PNG, JPEG, PDF, SVG, imprime and fullscreen
import exportingInit from "highcharts/modules/exporting";
exportingInit(Highcharts);

// export data CSV, XLS, e change vizualization per table
import exportData from "highcharts/modules/export-data";
exportData(Highcharts);
import ServiceUPL from "@/service/upl/index";

export default {
  props: ["product"],
  data() {
    return {
      service: new ServiceUPL(),
      vol: null,
      sales: null,
      gp: null,
      volAtingimento: null,
      salesAtingimento: null,
      gpAtingimento: null,
      chartData: {
        chart: {
          type: "column",
          height: 300,
        },
        title: {
          text: "<b>ATINGIMENTO x FIN. REVIEW</b>",
          align: "left",
        },
        subtitle: {
          text: "Fonte: Finance Review & Qlik",
          align: "left",
        },

        xAxis: {
          categories: ["Vol", "Sales", "GP"],
        },
        yAxis: [
          {
            min: 0,
            title: {
              text: "",
            },
            labels: {
              formatter: function () {
                return this.name;
              },
              decimals: 2,
            },
          },
          {
            title: {
              text: "",
            },
            opposite: true,
          },
        ],
        legend: {
          shadow: false,
        },
        tooltip: {
          shared: false,
          valueSuffix: "%"
        },
        plotOptions: {
          column: {
            grouping: false,
            shadow: false,
            borderWidth: 0,
          },
          series: {
            colorByPoint: false,
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              formatter: function () {
                if (this.series.name === "Review") {
                  return this.point.title.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                } else {
                  return (
                    this.point.title.toString().split(".")[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') +
                    "<br>" +
                    `${this.point.title.toString().split(".")[1]}
                    %`
                  );
                }
              },
            },
          },
        },

        series: [
          {
            name: "Review",
            color: "#3c5ca7",
            data: [this.vol, this.sales, this.gp],
            pointPadding: 0.2,
          },
          {
            name: "Atingimento",
            color: "#1E3363",
            data: [
              this.volAtingimento,
              this.salesAtingimento,
              this.gpAtingimento,
            ],
            pointPadding: 0.3,
          },
        ],
      },
    };
  },
  async beforeMount() {
    if (this.product != null) {
      this.chartData.yAxis[0].title.text = this.product.nome;

      await this.service
        .getGraficoAtingimentoFinReview(this.product.nome)
        .then((res) => {
          this.vol = res.data.volFinanceReview;
          this.sales = res.data.salesFinanceReview;
          this.gp = res.data.gpFinanceReview;

          const volumeData = { y: 100, title: this.vol, color: "#3c5ca7" };
          const salesData = { y: 100, title: this.sales, color: "#797979" };
          const gpData = { y: 100, title: this.gp, color: "#215816" };
          this.chartData.series[0].data = [volumeData, salesData, gpData];

          const resultadoVol = parseInt(
            (res.data.volAtingimento * 100) / this.vol
          );
          const resultadoSales = parseInt(
            (res.data.salesAtingimento * 100) / this.sales
          );
          const resultadoGp = parseInt(
            (res.data.gpAtingimento * 100) / this.gp
          );

          this.volAtingimento = `${res.data.volAtingimento}.${resultadoVol}`

          this.salesAtingimento = `${res.data.salesAtingimento}.${resultadoSales}`

          this.gpAtingimento = `${res.data.gpAtingimento}.${resultadoGp}`

          const volumeDataAtingimento = {
            y: resultadoVol,
            title: this.volAtingimento,
            color: "#6389d9",
          };
          const salesDataAtingimento = {
            y: resultadoSales,
            title: this.salesAtingimento,
            color: "#a29b9b",
          };
          const gpDataAtingimento = {
            y: resultadoGp,
            title: this.gpAtingimento,
            color: "#4fa12b",
          };

          this.chartData.series[1].data = [
            volumeDataAtingimento,
            salesDataAtingimento,
            gpDataAtingimento,
          ];
        });
    }
  },
};
</script>