<template>
  <div class="grayBackground pa-2">
    <p>
      {{ $t("TXT_PRODUCT") }}: <strong>{{ product.nome }}</strong>
    </p>
    <p>
      {{ $t("TXT_DOSE") }}: <strong>{{ product.dose }}</strong> |
      {{ $t("TXT_POSITIONING") }}:
      <strong>{{ setDecimal(product.posicionamento) }}</strong> |
      {{ $t("TXT_REMUNERATION_CHANNEL") }}:
      <strong>{{ product.remuneracaoPorCanal }}</strong> | Corredor
      {{ $t("TXT_PRICE") }}: <strong>{{ product.corredorPreco }}</strong> |
      {{ $t("TXT_CONTRIBUTION_MARGIN") }}:
      <strong>{{ product.margemContribuicao }}</strong>
    </p>
    <p>
      {{ $t("TXT_TYPE_PRECIFICATION") }}:
      <strong>{{ product.tipoPrecificacao }}</strong>
    </p>
    <v-row
      no-gutters
      v-for="(cap, index) in product.capturas"
      :key="index"
      class="mb-4"
    >
      <v-col>
        <v-row no-gutters>
          <span class="font-weight-bold">{{ cap.nomeCaptura }}</span>
        </v-row>
        <v-row no-gutters>
          <span>
            {{ $t("TXT_TYPE_CAPTURE") }}:
            <strong>{{ $t("TXT_EXTERNAL") }}</strong> ({{ $t("TXT_PRICE") }}:
            {{ formatMonetary(cap.mediaCapturaExterna) }} |
            {{ $t("TXT_DOSE") }}: {{ cap.doseCompetidor }} |
            {{ $t("TXT_WEIGHT") }}: {{ cap.pesoCapturaExterna }} %)
          </span>
        </v-row>

        <v-row no-gutters>
          <span>
            {{ $t("TXT_TYPE_CAPTURE") }}:
            <strong>{{ $t("TXT_INTERNAL") }}:</strong> ({{ $t("TXT_PRICE") }}:
            {{ formatMonetary(cap.mediaCapturaInterna) }} |
            {{ $t("TXT_DOSE") }}: {{ cap.doseCompetidor }} |
            {{ $t("TXT_WEIGHT") }}: {{ cap.pesoCapturaInterna }} %)
          </span>
        </v-row>

        <div v-if="cap.idProdutoUPL">
          <v-row no-gutters class="mt-4">
            <span class="font-weight-bold">{{
              setNameProductUPL(cap.idProdutoUPL)
            }}</span>
          </v-row>
          <v-row no-gutters>
            <span>
              {{ $t("TXT_PRICE") }}:
              <strong>{{ formatMonetary(cap.precoProdutoUPL) }} </strong> |
              {{ $t("TXT_DOSE") }}:
              <strong>{{ cap.doseProdutoUPL }} </strong>
            </span>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { formatMonetary } from "@/utils/format-toMonetary";

export default {
  props: {
    product: {
      type: Object,
    },
    productsUPL: {
      type: Array,
    },
  },
  methods: {
    formatMonetary,
    setDecimal(value) {
      if (value.toString().includes(".")) {
        let [integer, decimal] = value.toString().split(".");
        decimal = decimal.slice(0, 2);
        return `${integer}.${decimal}`;
      }
      return value;
    },
    setNameProductUPL(id) {
      return this.productsUPL.find((product) => product.id == id).nome;
    },
  },
};
</script>
<style lang="scss" scoped>
.grayBackground {
  background-color: $neutral-color-high-light;
}
</style>