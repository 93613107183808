<template>
  <v-card class="pa-4" :disabled="isLoading">
    <v-row no-gutters class="gap-4">
      <v-col cols="auto">
        <h3>{{ $t("TXT_PRODUCT").toUpperCase() }}</h3>
      </v-col>
      <v-spacer />
      <v-col cols="auto" v-if="mqStatDate">
        <p>
          <span class="font-weight-bold mr-2">MQ Stat:</span
          >{{ formatDate(new Date(mqStatDate), "dd/MM/yyyy") }}
        </p>
      </v-col>
      <v-col cols="auto" v-if="dateGoal">
        <p>
          <span class="font-weight-bold mr-2">{{
            `${$t("TXT_DATE")} Goal:`
          }}</span
          >{{ formatDate(new Date(dateGoal), "dd/MM/yyyy") }}
        </p>
      </v-col>
      <v-col cols="auto" v-if="replacementCostDate">
        <p>
          <span class="font-weight-bold mr-2">{{
            `${$t("TXT_REPLACEMENT_COST")}:`
          }}</span
          >{{ formatDate(new Date(replacementCostDate), "dd/MM/yyyy") }}
        </p>
      </v-col>
    </v-row>
    <v-row no-gutters align="end" justify="space-between" class="mb-4">
      <v-col cols="12" sm="6">
        <v-row no-gutters class="gap-4">
          <Prd-combo-box
            v-model="selectedProduct"
            :title="$t('TXT_PRODUCT_NAME')"
            :placeholder="$t('TXT_SELECT')"
            :items="productsUPL"
            :disabled="productForEdition && productForEdition.id != null"
          />
          <Prd-text-field
            v-model="generalInputs.sobre.classificacao"
            :title="$t('TXT_CLASSIFICATION')"
            :disabled="!selectedProduct"
            class="pb-1"
          />
          <Prd-text-field
            v-model="generalInputs.sobre.culturaDriver"
            :title="$t('TXT_DRIVER_CULTURE')"
            :disabled="!selectedProduct"
            class="pb-1"
          />
        </v-row>
      </v-col>
      <v-col
        cols="auto"
        v-if="productForEdition && productForEdition.id != null"
      >
        <Exclude-product-main
          :product="productForEdition"
          :productsUPL="productsUPL"
        />
      </v-col>
    </v-row>
    <h3>{{ $t("TXT_INFORMATIONS").toUpperCase() }}</h3>
    <v-row no-gutters class="gap-4" align="end">
      <v-col>
        <Prd-text-field
          v-model="generalInputs.dose"
          :title="`${$t('TXT_DOSE')} *`"
          :type="'number'"
          :min="0"
          :disabled="!selectedProduct || lockPosicionamentoAndDose"
          :readonly="lockPosicionamentoAndDose"
          :filled="lockPosicionamentoAndDose"
          :suffix="'%'"
          :hide-spin-buttons="true"
        />
      </v-col>
      <v-col>
        <Prd-text-field
          v-model="generalInputs.posicionamento"
          :title="$t('TXT_POSITIONING')"
          :type="'number'"
          :min="0"
          :disabled="!selectedProduct || lockPosicionamentoAndDose"
          :readonly="lockPosicionamentoAndDose"
          :filled="lockPosicionamentoAndDose"
          :suffix="'%'"
          :hide-spin-buttons="true"
        />
      </v-col>
      <v-col>
        <Prd-text-field
          v-model="generalInputs.remuneracaoPorCanal"
          :title="$t('TXT_REMUNERATION_CHANNEL')"
          :type="'number'"
          :min="0"
          :disabled="!selectedProduct"
          :suffix="'%'"
          :hide-spin-buttons="true"
        />
      </v-col>
      <v-col>
        <Prd-text-field
          v-model="generalInputs.corredorPreco"
          :title="$t('TXT_CORRIDOR_PRICE')"
          :type="'number'"
          :min="0"
          :disabled="!selectedProduct"
          :suffix="'%'"
          :hide-spin-buttons="true"
        />
      </v-col>
      <v-col>
        <Prd-text-field
          v-model="generalInputs.margemContribuicao"
          :title="$t('TXT_CONTRIBUTION_MARGIN')"
          :type="'number'"
          :min="0"
          :disabled="!selectedProduct"
          :suffix="'%'"
          :hide-spin-buttons="true"
        />
      </v-col>
      <v-col>
        <Prd-text-field
          v-model="generalInputs.descricaoUnificada"
          :title="$t('TXT_UNIFIED_DESCRIPTION')"
          readonly
          filled
          :disabled="!selectedProduct"
        />
      </v-col>
      <v-col>
        <Prd-text-field
          v-model="generalInputs.unitCogs"
          :title="'Unit Cogs'"
          readonly
          filled
          :disabled="!selectedProduct"
          :suffix="'%'"
          :hide-spin-buttons="true"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";
import PrdTextField from "@/components/common/prd-text-field.vue";
import { formatDate } from "@/utils/format-date";
import ExcludeProductMain from "../components/actions/components/exclude-product-main.vue";
export default {
  components: { PrdComboBox, PrdTextField, ExcludeProductMain },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    mqStatDate: {
      type: String,
    },
    dateGoal: {
      type: String,
    },
    replacementCostDate: {
      type: String,
    },
    productsUPL: {
      type: Array,
    },
    productForEdition: {
      type: Object,
    },
  },
  data() {
    return {
      selectedProduct: null,
      generalInputs: {
        nome: null,
        dose: 0,
        posicionamento: 0,
        remuneracaoPorCanal: 0,
        corredorPreco: 0,
        margemContribuicao: 0,
        descricaoUnificada: null,
        unitCogs: 0,
        sobre: {
          classificacao: null,
          culturaDriver: null,
        },
      },
    };
  },

  computed: {
    lockPosicionamentoAndDose() {
      return (
        this.productForEdition.capturas == 0 &&
        this.productForEdition.tipoPrecificacao.toLowerCase() == "custo"
      );
    },
  },
  watch: {
    selectedProduct: {
      handler(value) {
        if (value) {
          this.generalInputs.nome = value.brand ?? value.nome;
          this.generalInputs.descricaoUnificada = value.descricaoUnificada;
          this.generalInputs.unitCogs = value.unitCogs ?? 0;
          this.generalInputs.dose = value.dose ?? 0;
          this.generalInputs.sobre.classificacao =
            value?.sobre?.classificacao ?? null;
          this.generalInputs.sobre.culturaDriver =
            value?.sobre?.culturaDriver ?? null;
        }
      },
    },
    generalInputs: {
      handler(value) {
        this.$emit("setGeneralInputs", value);
      },
      deep: true,
    },
    productForEdition: {
      handler(value) {
        if (!value.nome) this.resetGerenalInputs();
        if (this.lockPosicionamentoAndDose) {
          this.generalInputs.dose = 0;
          this.generalInputs.posicionamento = 0;
        }
      },
      deep: true,
    },
    productsUPL: {
      handler(value) {
        if (this.productForEdition?.id && value) {
          this.selectedProduct = value.find(
            (product) =>
              product.nome.toLowerCase() ==
              this.productForEdition.nome.toLowerCase()
          );
          this.generalInputs.nome = this.productForEdition.nome;
          this.generalInputs.descricaoUnificada =
            this.productForEdition.descricaoUnificada;
          this.generalInputs.unitCogs = this.productForEdition.unitCogs;
          this.generalInputs.dose = this.lockPosicionamentoAndDose
            ? 0
            : this.productForEdition.dose;
          this.generalInputs.posicionamento = this.lockPosicionamentoAndDose
            ? 0
            : this.productForEdition.posicionamento;
          this.generalInputs.remuneracaoPorCanal =
            this.productForEdition.remuneracaoPorCanal;
          this.generalInputs.corredorPreco =
            this.productForEdition.corredorPreco;
          this.generalInputs.margemContribuicao =
            this.productForEdition.margemContribuicao;
          this.generalInputs.sobre.classificacao =
            this.productForEdition.sobre.classificacao;
          this.generalInputs.sobre.culturaDriver =
            this.productForEdition.sobre.culturaDriver;
        }
      },
    },
  },

  methods: {
    formatDate,
    resetGerenalInputs() {
      this.selectedProduct = null;
      this.generalInputs = {
        nome: null,
        dose: 0,
        posicionamento: 0,
        remuneracaoPorCanal: 0,
        corredorPreco: 0,
        margemContribuicao: 0,
        descricaoUnificada: null,
        unitCogs: 0,
        sobre: {
          classificacao: null,
          culturaDriver: null,
        },
      };
    },
  },
};
</script>