var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"100%","max-width":"1224"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"right":"","color":_vm.$prdStyles('color-primary')},on:{"click":_vm.listMonitoringMarketResultItem}},on),[_vm._v(" mdi-arrow-right-bold-box ")])]}}]),model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c('v-card',{staticClass:"pa-4"},[_c('h4',{staticClass:"mb-6"},[_vm._v(_vm._s(this.$i18n.t("TXT_COMPETITIVE_PRICES")))]),_c('loading-div',{attrs:{"is-loading":_vm.isLoading,"error":_vm.service.listMonitoringMarketResultItem.error}},[_c('div',[_c('h3',{directives:[{name:"t",rawName:"v-t",value:(this.productRegion.price.productName),expression:"this.productRegion.price.productName"}],staticClass:"mb-6 font-size-15"}),[_c('v-data-table',{staticClass:"custom-table",attrs:{"headers":_vm.headers,"items":_vm.beta_productsToShow,"footer-props":_vm.footerProps,"hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(){return _vm._l((_vm.headers),function(header,index){return _c('td',{key:index,staticClass:"original-header-table"},[_c('v-row',{attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(header.text)+" ")]),(header.value == 'cityState' && _vm.idCompany == 3026)?_c('tooltip-informations',{attrs:{"title":_vm.textTooltipVem}}):_vm._e()],1)],1)})},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSeeDisregarded || !item.disregarded),expression:"isSeeDisregarded || !item.disregarded"}],staticClass:"custom-table"},[_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpenOrCloseDisregarded),expression:"isOpenOrCloseDisregarded"}],staticClass:"custom-table",class:{ 'background-color': item.disregarded }},[_c('v-simple-checkbox',{attrs:{"ripple":false,"disabled":!_vm.isOpenOrCloseDisregarded,"outlined":"","color":"#3C5CA7"},model:{value:(item.disregarded),callback:function ($$v) {_vm.$set(item, "disregarded", $$v)},expression:"item.disregarded"}})],1),_c('td',{staticClass:"custom-table",class:{ 'background-color': item.disregarded }},[(item.productLink)?_c('div',[_c('a',{attrs:{"target":"_blank","href":item.productLink}},[_vm._v(_vm._s(item.productName))])]):_c('span',[_vm._v(" "+_vm._s(item.productName)+" ")])]),_c('td',{staticClass:"custom-table",class:{ 'background-color': item.disregarded }},[_vm._v(" "+_vm._s(item.brand)+" ")]),_c('td',{staticClass:"custom-table",class:{ 'background-color': item.disregarded }},[_vm._v(" "+_vm._s(item.crawlerName)+" ")]),_c('td',{staticClass:"custom-table",class:{ 'background-color': item.disregarded }},[(item.sellerLink)?_c('span',[_c('a',{attrs:{"target":"_blank","href":item.sellerLink}},[_vm._v(_vm._s(item.sellerName))])]):_c('span',[_vm._v(_vm._s(item.sellerName))])]),_c('td',{class:("custom-table " + (item.disregarded == true ? 'background-color' : '') + " " + (_vm.verifyPriceAndSeller(item)
                      ? 'font-weight-bold background-color-equals-price'
                      : '') + " ")},[_vm._v(" "+_vm._s(_vm._f("setToIsoString")(item.price))+" ")]),_c('td',{staticClass:"custom-table",class:{ 'background-color': item.disregarded }},[_vm._v(" "+_vm._s(item.cityState)+" ")]),_c('td',{staticClass:"custom-table",class:{ 'background-color': item.disregarded }},[_vm._v(" "+_vm._s(_vm._f("setDate")(item.refDate))+" ")])])]}}])})]],2)]),_c('div',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('loading-div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpenOrCloseDisregarded),expression:"isOpenOrCloseDisregarded"}],attrs:{"is-loading":_vm.isLoading}},[_c('v-btn',{staticClass:"white--text mr-4 mb-6",attrs:{"color":"#56a667"},on:{"click":function($event){return _vm.saveConsideredProducts(
                _vm.beta_productsToShow,
                _vm.productRegion.price.idMonitoringItem
              )}}},[_vm._v(_vm._s(this.$i18n.t("TXT_SAVE_EDITIONS"))+" ")]),_c('v-btn',{staticClass:"white--text mr-4 mb-6",attrs:{"color":"#ec4c37"},on:{"click":function($event){return _vm.closeDisregarded()}}},[_vm._v(_vm._s(this.$i18n.t("TXT_BACK"))+" ")])],1)],1),_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.isOpenOrCloseDisregarded,"outlined":"","color":"#3c5ca7"},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(this.$i18n.t("TXT_CANCEL")))])],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.snackbarColor,"timeout":2000},model:{value:(_vm.snackbar.snackbar),callback:function ($$v) {_vm.$set(_vm.snackbar, "snackbar", $$v)},expression:"snackbar.snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar.snackbarText)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }