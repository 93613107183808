<template>
  <highcharts :options="mapOptions" />
</template>

<script>
import Highcharts from "highcharts";

// Exporta dados em PNG, JPEG, PDF, SVG, imprime e visualiza tela cheia
import exportingInit from "highcharts/modules/exporting";
exportingInit(Highcharts);

// Exporta dados CSV, XLS, e muda visualização por tabela
import exportData from "highcharts/modules/export-data";
exportData(Highcharts);
export default {
  props: ["dataGraphic"],
  data() {
    return {
      mapOptions: {
        title: {
          text: "",
        },
        tooltip: {
          enabled: false,
        },
        lang: {
          noData: this.$i18n.t("TXT_NO_DATA_AVAILABLE"),
        },
        exporting: {
          enabled: false,
        },
        yAxis: {
          title: {
            text: this.$i18n.t("TXT_COMPETITIVENESS"),
          },
          labels: {
            format: "{value}%",
          },
          gridLineColor: "#BAB8B8",
        },
        xAxis: {
          categories: [],
        },
        series: [
          {
            type: "spline",
            name: this.$i18n.t("TXT_PRATICED_AVERAGE"),
            data: [],

            dataLabels: {
              enabled: true,
              borderRadius: 3,
              backgroundColor: "#3C5CA7",
              color: "white",
              formatter: function () {
                return this.y + "%";
              },
              padding: 4,
              style: {
                textOutline: "none", // Remove a borda do texto
              },
            },
            color: "#3C5CA7",
            lineWidth: 2,
          },
        ],
      },
    };
  },
  methods: {
    roundValue(value) {
      const newValue = value.toString().split(".");
      const integerPart = newValue[0];
      const decimalPart = newValue[1].substring(0, 2);
      const formatedValue = `${integerPart}.${decimalPart}`;
      return Number(formatedValue);
    },

    prepareDataForGraphic(listData) {
      const newList = listData.map((data) => {
        return this.roundValue(data.ic);
      });
      this.mapOptions.series[0].data = newList;
    },

    convertDate(date) {
      const splitedDate = date.split("T")[0].split("-");
      const newDate = `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
      return newDate;
    },

    prepareXAxisCategoriesForGraphic(listData) {
      const newList = listData.map((data) => {
        return this.convertDate(data.date);
      });
      this.mapOptions.xAxis.categories = newList;
    },
  },
  watch: {
    dataGraphic: {
      handler(value) {
        this.prepareDataForGraphic(value);
        this.prepareXAxisCategoriesForGraphic(value);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>