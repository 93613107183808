<template>
  <v-container fluid>
    <Home-main :idCompany="idCompany" />
  </v-container>
</template>

<script>
import HomeMain from "./home/home-main.vue";
export default {
  name: "home-container",
  components: { HomeMain },
  data() {
    return {};
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },
};
</script>