<template>
  <v-tooltip class="pa-0" left color="white">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        class="primary-color"
        v-bind="attrs"
        v-on="on"
        @click.stop=""
      >
        mdi-thermometer
      </v-icon>
    </template>
    <tooltip-card :ic="ic" />
  </v-tooltip>
</template>

<script>
import tooltipCard from "./components/tooltip-card.vue";
export default {
  components: { tooltipCard },
  props: ["ic", "isDisable"],
};
</script>

<style lang="scss" scoped>
.primary-color {
  color: $brand-color-primary-pure;
}
</style>