<template>
  <v-card class="pa-4">
    <h3>{{ `${$t("TXT_CAPTURE")} ${typeCaptureTitle}` }}</h3>
    <v-row no-gutters justify="space-between" class="gap-4 mt-2">
      <v-col>
        <Prd-text-field
          v-model="localPreco"
          :title="$t('TXT_PRICE')"
          :prefix="currency"
          :type="'number'"
          :min="0"
          :disabled="disableFields"
        />
      </v-col>
      <v-col>
        <Prd-text-field
          v-model="localDose"
          :title="$t('TXT_DOSE')"
          :type="'number'"
          :min="0"
          :disabled="disableFields"
        />
      </v-col>
      <v-col>
        <Prd-text-field
          v-model="localPeso"
          :title="$t('TXT_WEIGHT')"
          :type="'number'"
          :min="0"
          :max="100"
          :disabled="disableFields || typeCapture != 0"
          @blur="setPeso(localPeso, true)"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";
export default {
  components: { PrdTextField },
  props: {
    typeCaptureTitle: {
      type: String,
    },
    typeCapture: {
      type: Number,
    },
    disableFields: {
      type: Boolean,
      default: true,
    },
    peso: {
      type: Number,
    },
    preco: {
      type: Number,
    },
    dose: {
      type: Number,
    },
  },
  data() {
    return {
      localPeso: 0,
      localPreco: 0,
      localDose: 0,
    };
  },
  computed: {
    currency() {
      return localStorage.getItem("currency") || "R$";
    },
  },

  watch: {
    localPeso: {
      handler(value) {
        this.setPeso(value);
      },
    },
    localPreco: {
      handler(value) {
        this.$emit("setPreco", parseFloat(value));
      },
    },
    localDose: {
      handler(value) {
        this.$emit("setDose", parseFloat(value));
      },
    },

    peso: {
      handler(value) {
        this.localPeso = value;
      },
      immediate: true,
    },
    dose: {
      handler(value) {
        this.localDose = value;
      },
      immediate: true,
    },
    preco: {
      handler(value) {
        this.localPreco = value;
      },
      immediate: true,
    },
  },

  methods: {
    setPeso(value, isBlur = false) {
      const realValue = parseFloat(value) <= 100 ? parseFloat(value) : 100;
      if (isBlur) this.localPeso = realValue;

      this.$emit("setPeso", realValue);
    },
  },
};
</script>