<template>
  <v-card class="pa-4" elevation="4">
    <v-row no-gutters class="mb-2" justify="space-between" align="center">
      <v-col cols="auto">
        <v-row no-gutters justify="center" align="center" class="gap-2">
          <h4>{{ title }}</h4>
          <tooltip-informations v-if="tooltipText" :title="tooltipText" />
        </v-row>
      </v-col>
      <v-col cols="auto" v-if="!isConsultivo">
        <v-icon
          v-if="!isEditOpen"
          @click="isEditOpen = true"
          :color="$prdStyles('color-primary')"
          >mdi-pencil-box</v-icon
        >
        <div v-if="isEditOpen">
          <v-icon
            v-if="false"
            @click="addLastBreakLine"
            :color="$prdStyles('color-primary')"
            :disabled="isLoading"
            >mdi-plus-box</v-icon
          >
          <v-icon
            v-if="false"
            @click="removeLastBreakLine(false)"
            :color="$prdStyles('color-primary')"
            :disabled="isLoading"
            >mdi-minus-box</v-icon
          >

          <v-icon
            @click="confirNewTextValue"
            color="#1d8527"
            :disabled="isLoading || newText == text"
            >mdi-checkbox-marked</v-icon
          >
          <v-icon
            @click="closeWhitoutEdition"
            color="#b00020"
            :disabled="isLoading"
            >mdi-close-box</v-icon
          >
          <v-progress-circular
            indeterminate
            :color="$prdStyles('color-primary')"
            size="25"
            v-if="isLoading"
          />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters style="overflow-y: auto; height: 80%">
      <span v-if="!isEditOpen" style="width: 99.3%" v-html="formatedText" />
      <v-textarea
        no-resize
        outlined
        v-model="newText"
        v-if="isEditOpen"
        :disabled="isLoading"
        @keydown="handlerBreakLine"
      ></v-textarea>
    </v-row>
    <!-- MESSAGE COMPONENTS -->
    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.snackbarColor"
      :timeout="2000"
    >
      {{ snackbar.snackbarText }}
    </v-snackbar>
  </v-card>
</template>

<script>
import ServiceUPL from "@/service/upl/index";
import tooltipInformations from "@/Design_System/common/tooltip-informations.vue";
export default {
  components: { tooltipInformations },
  props: {
    text: {
      type: String,
    },
    title: {
      type: String,
    },
    cardType: {
      type: String,
    },
    productId: {
      type: String,
    },
    tooltipText: {
      type: String,
    },
  },
  data() {
    return {
      newText: null,
      isEditOpen: false,
      isLoading: false,
      service: new ServiceUPL(),
      snackbar: {
        snackbar: false,
        snackbarText: null,
        snackbarColor: "#3C5CA7",
      },
    };
  },
  computed: {
    isConsultivo() {
      return this.$store.getters.Acesso_UPL_Leitor;
    },
    formatedText() {
      return this.newText?.replace(/\n|{breakLine}/g, "<br>") ?? null;
    },
  },
  methods: {
    showSnackbar(text, type) {
      let snackbar = {
        snackbar: true,
        snackbarColor: null,
        snackbarText: null,
      };

      snackbar.snackbarText = text;

      switch (type) {
        case "success":
          snackbar.snackbarColor = "#56a667";
          break;
        case "error":
          snackbar.snackbarColor = "#ec4c37";
          break;
        case "info":
          snackbar.snackbarColor = "#3c5ca7";
      }
      this.snackbar = snackbar;
    },
    async confirNewTextValue() {
      this.isLoading = true;
      let message,
        type = null;
      this.service
        .updateInfo(this.productId, this.cardType, this.newText)
        .then((res) => {
          if (res.status == 204) {
            message = "Informação alterada com sucesso !";
            type = "success";
            this.isEditOpen = false;
            this.$emit("newValueatt", this.newText);
          } else {
            message = "Problemas ao alterar a informação, tente novamente !";
            type = "info";
          }
        })
        .catch((error) => {
          console.log(error);
          message =
            "Erro ao alterar a informação, tente novamente, se o problema persistir, contate a PREDIFY !";
          type = "error";
        })
        .finally(() => {
          this.isLoading = false;
          this.showSnackbar(message, type)
          // this.$store.commit("snackbarV2/set", { message, type });
        });
    },
    closeWhitoutEdition() {
      this.newText = this.text;
      this.isEditOpen = false;
    },
    removeLastBreakLine(isDeleteKey) {
      let lastIndex = this.newText.lastIndexOf("{breakLine}");
      if (lastIndex !== -1) {
        const indexForSum = isDeleteKey ? 1 : 0;
        this.newText = this.newText.substring(0, lastIndex + indexForSum);
      }
    },
    addLastBreakLine() {
      this.newText += "{breakLine}";
    },
    handlerBreakLine(event) {
      if (event.keyCode == 13) this.addLastBreakLine();
      else if (event.keyCode == 8 && this.newText.endsWith("{breakLine}"))
        this.removeLastBreakLine(true);
    },
  },
  watch: {
    text: {
      handler(value) {
        this.newText = value;
      },
      immediate: true,
    },
  },
};
</script>
