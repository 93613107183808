<template>
  <div>
    <v-row no-gutters class="mt-4">
      <v-spacer></v-spacer>
      <prd-btn
        :outlined="true"
        :title="'Ver desconsiderados'"
        :disabled="isLoading || disableConsideredButton"
        @click="$emit('openModal', true)"
        class="mr-4"
      />
      <prd-btn
        :title="'Salvar'"
        :loading="isLoading"
        :disabled="isLoading || disableSaveButton"
        @click="$emit('saveProducts', 'considerar')"
      />
    </v-row>
    <v-dialog v-model="modalOpen" persistent>
      <v-card class="pa-4" elevation="1">
        <h4 class="mb-4">PRODUTOS DESCONSIDERADOS</h4>

        <v-data-table
          :headers="headers"
          :items="disregardedProducts.priceResults"
          :no-data-text="$t('TXT_NO_DATA_AVAILABLE')"
          :hide-default-footer="disregardedProducts.priceResults.length == 0"
          :footer-props="footerProps"
        >
          <!-- product name -->
          <template v-slot:[`item.productName`]="{ item }">
            <td class="pa-4">
              <div class="copy-box">
                <a :href="item.productLink" target="_blank">{{
                  item.productName
                }}</a>
              </div>
            </td>
          </template>

          <!-- seller name -->
          <template v-slot:[`item.sellerName`]="{ item }">
            <td class="table-product-name">
              <div class="copy-box">
                {{ item.sellerName }}
              </div>
            </td>
          </template>
          <!-- Ano de fabricação -->
          <template
            v-if="hasResource('marketplace.machinery')"
            v-slot:[`item.productManufactureYear`]="{ item }"
          >
            <td class="text-center">
              {{
                `${
                  item.productManufactureYear
                    ? item.productManufactureYear
                    : "-"
                }`
              }}
            </td>
          </template>
          <!-- Horas de Uso -->
          <template
            v-if="hasResource('marketplace.machinery')"
            v-slot:[`item.productUsedHours`]="{ item }"
          >
            <td class="text-center">
              {{ item.productUsedHours | setDotEveryThreeCharacters }}
            </td>
          </template>

          <!-- price -->
          <template v-slot:[`item.price`]="{ item }">
            <td class="text-center">
              {{ item.price | formatCurrency }}
            </td>
          </template>

          <!-- Crawler date -->
          <template v-slot:[`item.crawlerDate`]="{ item }">
            <td class="text-center">
              {{ item.crawlerDate.replace("-", "/").replace("-", "/") }}
            </td>
          </template>

          <!-- disregarded -->
          <template v-slot:[`item.disregarded`]="{ item }">
            <td class="text-center">
              <v-simple-checkbox
                v-model="item.disregarded"
                :ripple="false"
                @click="disregardItem(item)"
                color="#3C5CA7"
              ></v-simple-checkbox>
            </td>
          </template>

          <!-- price_unit_type -->
          <template v-slot:[`item.price_unit_type`]="{ item }">
            {{ item.price_unit_type | unityToString }}
          </template>
        </v-data-table>

        <!-- SAVE BUTTON -->
        <v-row no-gutters class="mx-4">
          <v-spacer></v-spacer>
          <prd-btn
            :outlined="true"
            :title="$t('TXT_CANCEL')"
            @click="$emit('openModal', false)"
            class="mr-4"
          />
          <prd-btn
            :title="$t('TXT_SAVE')"
            :disabled="disableLocalButton"
            @click="saveProducts"
          />
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
import PredimonitorService from "@/service/predimonitor/predimonitor-service";

export default {
  props: [
    "disregardedProducts",
    "headers",
    "isLoading",
    "isModalOpen",
    "disableSaveButton",
    "product",
  ],
  components: { prdBtn },
  data() {
    return {
      predimonitorService: new PredimonitorService(),
      modalOpen: false,
      disableLocalButton: true,
      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },
    };
  },

  computed: {
    disableConsideredButton() {
      let disregarded = this.disregardedProducts?.priceResults ?? [];
      if (disregarded.length > 0) return false;
      return true;
    },
  },

  watch: {
    isModalOpen: {
      handler(value) {
        this.modalOpen = value;
      },
      immediate: true,
    },
  },
  methods: {
    hasResource(value) {
      return this.$store.getters.hasResource(value);
    },
    disregardItem() {
      this.disableLocalButton = this.setSaveButton();
    },

    setSaveButton() {
      let originalItems = this.product.priceResults.filter(
        (item) => item.disregarded === true
      );

      let copyItems = this.disregardedProducts.priceResults;

      for (let i = 0; i < originalItems.length; i++) {
        if (originalItems[i].disregarded !== copyItems[i].disregarded) {
          return false;
        }
      }

      return true;
    },

    saveProducts() {
      this.disableLocalButton = true;
      this.$emit("saveProducts", "desconsiderar");
    },
  },
};
</script>

<style lang="scss" scoped>
.copy-box {
  max-height: 40px !important;
  overflow: hidden !important;
}

.table-product-name {
  max-width: 350px !important;
  text-align: center !important;
}

.text-center {
  text-align: center !important;
}
</style>