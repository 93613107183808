<template>
  <v-card class="pa-4" flat :disabled="isLoading">
    <span>{{ this.$i18n.t("TXT_NEW_OR_SAVED_SIMULATION") }}</span>
    <v-row no-gutters class="mt-4">
      <div>
        <h4>{{ this.$i18n.t("TXT_NEW_SIMULATION_UPPERCASE") }}</h4>
        <v-row no-gutters class="mt-4">
          <!-- Combobox de período de referência -->
          <prd-combo-box
            v-model="referencePeriod"
            :title="$t('TXT_REFERENCE_PERIOD')"
            :items="referencePeriodItems"
            class="me-8"
            :placeholder="`${$t('TXT_SELECT')}...`"
            :loading="isLoadingAffiliate"
            :disabled="isLoadingAffiliate"
            @keydown.native="$event.preventDefault()"
          />
          <!-- Combobox de filial -->
          <prd-combo-box
            v-model="affiliate"
            :title="$t('TXT_AFFILIATE')"
            :items="affiliateItems"
            class="me-8"
            :placeholder="`${$t('TXT_SELECT')}...`"
            :loading="isLoadingAffiliate"
            :disabled="isLoadingAffiliate || affiliateItems.length == 0"
          />
          <!-- botão de nova simulação -->
          <prd-btn
            :title="$t('TXT_SIMULATE')"
            class="mt-7"
            @click="newSimulation"
            :disabled="isLoadingAffiliate"
          />
          <!-- botão de salvar simulação -->
          <PrdBtnLoading
            :title="$t('TXT_SAVE')"
            class="mt-7 ms-4"
            @click="saveSimulation"
            :disabled="isLoadingAffiliate || isLoadingBtnSavedSimulation"
            :loading="isLoadingBtnSavedSimulation"
          />
        </v-row>
      </div>
      <v-spacer></v-spacer>
      <div>
        <h4>{{ this.$i18n.t("TXT_SAVED_SIMULATIONS_UPPERCASE") }}</h4>
        <!-- combobox de pesquisar simulações salvas -->
        <prd-combo-box
          v-model="savedSimulationData"
          :title="$t('TXT_SELECT')"
          :items="savedSimulationsItems"
          class="mt-4"
          :placeholder="`${$t('TXT_SELECT')}...`"
          @input="savedSimulation(savedSimulationData)"
          :loading="isLoadingSavedSimulation"
          :disabled="
            isLoadingSavedSimulation || savedSimulationsItems.length == 0
          "
          @keydown.native="$event.preventDefault()"
        />
      </div>
    </v-row>
  </v-card>
</template>

<script>
import prdComboBox from "@/Design_System/common/prd-comboBox.vue";
import prdBtn from "@/Design_System/common/prd-btn.vue";
import mixProductsAprioriService from "@/service/mix-product-apriori/mix-product-apriori-service";
import PrdBtnLoading from "@/Design_System/common/prd-btn-loading.vue";
export default {
  components: { prdComboBox, prdBtn, PrdBtnLoading },
  props: {
    showButtonSaveSimulation: {
      type: Boolean,
      default: false,
    },
    idEnterpriseAprioriResultGroup: {
      type: Number,
      default: null,
    },
    isLoading: {
      type: Boolean,
    },
  },
  data() {
    return {
      isLoadingAffiliate: false,
      showButtonSaveSimulationLocal: false,
      isLoadingSavedSimulation: false,
      isLoadingBtnSavedSimulation: false,
      dates: null,
      referencePeriod: { value: 30, text: `30 ${this.$i18n.t("TXT_DAYS")}` },
      referencePeriodItems: [
        { value: 5, text: `5 ${this.$i18n.t("TXT_DAYS")}` },
        { value: 10, text: `10 ${this.$i18n.t("TXT_DAYS")}` },
        { value: 15, text: `15 ${this.$i18n.t("TXT_DAYS")}` },
        { value: 30, text: `30 ${this.$i18n.t("TXT_DAYS")}` },
        { value: 60, text: `60 ${this.$i18n.t("TXT_DAYS")}` },
      ],
      affiliate: null,
      affiliateItems: [],
      savedSimulationData: null,
      savedSimulationsItems: [],
      service: new mixProductsAprioriService(),
    };
  },
  watch: {
    showButtonSaveSimulation() {
      this.showButtonSaveSimulationLocal = this.showButtonSaveSimulation;
    },
  },
  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },
  methods: {
    getAffiliate() {
      this.isLoadingAffiliate = true;
      this.service
        .getAffiliate(this.idCompany, undefined, true, false)
        .then((response) => {
          if (response.status != 500) {
            let affiliate = response.data.result.find(
              (element) => element.dbField == 7
            );

            if (affiliate == undefined) {
              this.$emit(
                "showSnackbar",
                this.$i18n.t("TXT_CONFIGURATION_FILTER"),
                "error"
              );
            }

            this.affiliateItems = affiliate.values.map((element) => {
              return {
                text: element.description,
                code: element.value ?? element.description,
              };
            });
            this.affiliateItems.forEach((element, index) => {
              this.$set(element, "value", index);
            });
          }
          this.isLoadingAffiliate = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoadingAffiliate = false;
        });
    },

    getSavedSimulation() {
      this.isLoadingSavedSimulation = true;
      this.service
        .getListGroup(this.idCompany)
        .then((response) => {
          if (response.status !== 500) {
            this.savedSimulationsItems = response.data.answer.map((element) => {
              return {
                code_affiliate: element.code_affiliate,
                end_date: element.end_date,
                idCompany: element.idCompany,
                idEnterpriseResultGroup: element.idEnterpriseResultGroup,
                is_temp: element.is_temp,
                text: element.name,
                start_date: element.start_date,
              };
            });
          }
          this.isLoadingSavedSimulation = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoadingSavedSimulation = false;
        });
    },

    organizeDate(date) {
      let splitedDate = date.split("/");
      let organizedDate = `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
      return organizedDate;
    },

    createFinalAndInitialDate(period) {
      const format = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };
      let finalDate = new Date();
      const diference = period * 24 * 60 * 60 * 1000;
      let initialDate = new Date(finalDate.getTime() - diference);
      let initialDateTemp = initialDate.toLocaleDateString(format);
      let finalDateTemp = finalDate.toLocaleDateString(format);
      let initialDateFormated = this.organizeDate(initialDateTemp);
      let finalDateFormated = this.organizeDate(finalDateTemp);
      return { initialDateFormated, finalDateFormated };
    },

    newSimulation() {
      this.showButtonSaveSimulationLocal = false;
      this.dates = this.createFinalAndInitialDate(this.referencePeriod.value);
      let request = {
        idCompany: this.idCompany,
        start_date: this.dates.initialDateFormated,
        end_date: this.dates.finalDateFormated,
        code_affiliate: this.affiliate?.code || null,
      };
      // Item abaixo é para teste de dados.
      // let request = {
      //   idCompany: 2417,
      //   codeAffiliate: "",
      //   start_date: "2023-04-03",
      //   end_date: "2023-04-10",
      // };
      if (this.savedSimulationData != null) this.savedSimulationData = null;
      this.$emit("simulationData", "newSimulation", request);
    },

    savedSimulation(data) {
      if (this.affiliate != null) this.affiliate = null;

      this.$emit("simulationData", "savedSimulation", data);
    },

    buildRequestSaveSimulation() {
      this.dates = this.createFinalAndInitialDate(this.referencePeriod.value);
      const nameNewSavedSimulation = `${this.dates.initialDateFormated} - ${
        this.dates.finalDateFormated
      } | ${this.affiliate?.text ?? ""}`;
      let request = {
        idEnterpriseResultGroup: this.idEnterpriseAprioriResultGroup,
        name: nameNewSavedSimulation,
        idCompany: this.idCompany,
      };
      return request;
    },

    saveSimulation() {
      this.isLoadingBtnSavedSimulation = true;
      this.service
        .insertSimulation(this.buildRequestSaveSimulation())
        .then((response) => {
          if (response.status !== 500) {
            this.getSavedSimulation();
            this.$emit(
              "showSnackbar",
              this.$i18n.t("TXT_SAVE_SIMULATION_SUCCESS"),
              "success"
            );
            this.isLoadingBtnSavedSimulation = false;
            setTimeout(() => {
              this.showButtonSaveSimulationLocal = false;
            }, 100);
          } else {
            this.$emit(
              "showSnackbar",
              this.$i18n.t("TXT_SAVE_SIMULATION_PROBLEM"),
              "error"
            );
            this.isLoadingBtnSavedSimulation = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$emit(
            "showSnackbar",
            this.$i18n.t("TXT_SAVE_SIMULATION_PROBLEM"),
            "error"
          );
          this.isLoadingBtnSavedSimulation = false;
        });
    },
  },
  mounted() {
    this.getAffiliate();
    this.getSavedSimulation();
    this.showButtonSaveSimulationLocal = this.showButtonSaveSimulation;
  },
};
</script>