<template>
  <v-container fluid id="predi-monitor">
    <v-row class="ma-0 pa-0 mb-2" align="center">
      <titleBreadCrumbsComponent
        :breadCrumbs="breadCrumbs"
        :title="'PREDIMONITOR'"
        :hasTooltip="false"
      />
      <v-spacer></v-spacer>
    </v-row>

    <!--SEARCH -->
    <searchProducts
      :updateData="updateData"
      :isLoading="isLoading"
      :infoQuery="infoQuery"
      :allActualPriceModifiedItems="allActualPriceModifiedItems"
      @monitoringItems="monitoringItems = $event"
      @setLoading="setLoading"
      @search="getResults"
      @showSnackbar="showSnackbar"
      ref="search-products"
      class="mb-6"
    />
    <v-row no-gutters
      ><label class="font-weight-bold" v-t="'TXT_RESULTS_UPPERCASE'"></label>
      <v-spacer></v-spacer>
      <router-link to="/main/tags-config">
        <prd-btn :title="$t('TXT_TAG_CONFIGURATION')" :outlined="true" />
      </router-link>
    </v-row>

    <!-- TABLE RESULTS -->
    <table-results
      :tableResultData="tableResultData"
      :isDisable="isDisable"
      :closePanel="closePanel"
      :idCompraAgora="idCompraAgora"
      :resourceGroup="resourceGroup"
      :userList="userList"
      :priceAlertList="priceAlertList"
      :priceCardLoading="priceCardLoading"
      :monitoringItems="monitoringItems"
      @updateGraphics="updateGraphics"
      @showSnackbar="showSnackbar"
      @seila="seila"
      @showSnackBarItemsSave="showSnackBarItemsSave"
      @attData="attData"
      @actualPriceModified="setActualPriceModified"
      @setGeneralIC="setIC"
      @teste="teste = $event"
      ref="table-result"
    />

    <!-- HITORICAL PRICE CHART-->
    <temporalEvolutionChart
      v-if="!hasResource('predimonitor.table.sellerpercent')"
      :priceTrendsData="priceTrendsData"
      :dateCategories="dateCategories"
      :isLoading="isLoading"
      :isDisable="isDisable"
      :closePanel="closePanel"
      @setLoading="setLoading"
      :showY2="showY2"
      class="mb-6"
    />

    <!-- competitiveness index -->
    <competitiveness-index
      :ic="ic"
      :dataProducts="allActualPriceModifiedItems"
      :isDisable="isDisableCompetitivenessIndex || isDisable"
      :selectedPeriod="selectedPeriod"
    />

    <!-- PRODUCT COMPARISON --->
    <productComparison
      class="mb-6"
      :boxplotData="boxplotData"
      :search="search"
    />

    <announcement-map-v2 ref="announcementMapV2" :externalLoading="isLoading" />

    <!-- PRICE MAP BR --->
    <priceBrMapChart
      v-if="
        hasResource('predimonitor') &&
        hasResource('predimonitor.map.br') &&
        !hasResource('marketplace.machinery')
      "
      :mapBrLoading="mapBrLoading"
    />

    <!-- PRICE MAP USA --->
    <priceUsaMapChart
      v-if="hasResource('predimonitor.map.usa')"
      :mapUsaLoading="mapUsaLoading"
      class="mb-6"
    />

    <Loading-products
      :controlModal="loadingModal"
      @closeLoadingModal="loadingModal = false"
      ref="loadingProducts"
    />

    <!-- MESSAGE COMPONENTS -->
    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.snackbarColor"
      :timeout="2000"
    >
      {{ snackbar.snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
// import resultTable from "./components/table-results/table-results.vue";
import titleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import searchProducts from "./components/new-search/new-search.vue";
import tableResults from "./components/table-results/table-results-main.vue";
import temporalEvolutionChart from "./components/charts/temporal-evolution-chart.vue";
import productComparison from "./components/product-comparison.vue";

import priceUsaMapChart from "./components/charts/priceUsa-map-chart.vue";
import priceBrMapChart from "./components/charts/priceBr-map-chart.vue";

// Service
import PredimonitorService from "@/service/predimonitor/predimonitor-service";
import PriceAlertService from "@/service/predimonitor/price-alert-service";

// Lib to set the data range
import moment from "moment";
import PrdBtn from "@/Design_System/common/prd-btn.vue";

import CompetitivenessIndex from "./components/competitivenes-index/competitiveness-index-main.vue";

import companyService from "@/service/company-service.js";
import LoadingProducts from "./components/loading-products.vue";
import AnnouncementMapV2 from "./components/charts/annoouncement-map-v2/announcement-map-v2-main.vue";
const Service = new companyService();

export default {
  components: {
    productComparison,
    temporalEvolutionChart,
    priceUsaMapChart,
    priceBrMapChart,
    tableResults,
    PrdBtn,
    searchProducts,
    titleBreadCrumbsComponent,
    CompetitivenessIndex,
    LoadingProducts,
    AnnouncementMapV2,
  },
  data() {
    return {
      predimonitorService: new PredimonitorService(),
      priceAlertService: new PriceAlertService(),
      tableResultData: [],
      priceTrendsData: [],
      boxplotData: [],
      announcementMapData: [],

      dateCategories: [],

      breadCrumbs: [
        {
          text: "Predimonitor",
        },
      ],
      loadingModal: false,
      isLoading: false,
      isDisable: true,
      closePanel: false,
      mapUsaLoading: true,
      mapBrLoading: true,
      mapEuLoading: true,

      infoQuery: null,
      search: null,
      // Snackbar vars
      snackbar: {
        snackbar: false,
        snackbarText: null,
        snackbarColor: "#3C5CA7",
      },

      // BAMAQ vars
      idBamaq: false,
      showY2: false, // Add a annoucement axis for bamaq

      // COMPRA AGORA vars
      idCompraAgora: false,

      // Props that are used in all components
      requestData: null,
      updateData: null,
      resourceGroup: null,
      userList: null,

      priceAlertList: [],
      osIds: [],
      priceCardLoading: false,
      selectedPeriod: null,

      allActualPriceModifiedItems: [],
      monitoringItems: null,

      ic: null,
      isDisableCompetitivenessIndex: true,
    };
  },
  methods: {
    showSnackBarItemsSave(snackbarStatus) {
      if (snackbarStatus) {
        this.showSnackbar(this.$i18n.t("TXT_ITEM_SAVE"), "success");
      } else {
        this.showSnackbar(this.$i18n.t("TXT_ITEM_SAVE_PROBLEM"), "error");
      }
    },
    seila() {
      this.getPriceAlert(this.osIds);
    },
    attData() {
      this.$refs["search-products"].searchProducts();
    },
    getResults(data, search) {
      this.loadingModal = true;
      this.selectedPeriod = null;
      this.ic = null;
      this.selectedPeriod = {
        startDate: data.startDate,
        endDate: data.endDate,
      };
      this.mapUsaLoading = true;
      this.mapBrLoading = true;
      this.mapEuLoading = true;

      this.getChartTableResults(data, search);
      this.getBoxPlotResults(data, search);
      this.getAnnouncementMapData(data, search);
      this.$refs.announcementMapV2.getAffiliateMapList(data);
    },

    getChartTableResults(request, search) {
      this.dateCategories = this.getDateInterval(
        request.startDate,
        request.endDate
      );
      let searchAPI = null;
      if (this.hasResource("predimonitor.table.sellerpercent")) {
        searchAPI = "AvgInfoByProductMaskedSeller";
      } else {
        searchAPI = search === "v2" ? "AvgInfoByProductV2" : "AvgInfoByProduct";
      }
      this.predimonitorService[searchAPI](request)
        .then((res) => {
          this.infoQuery = res.data.answer.infoQueries;
          if (res.data.answerType != 0) {
            if (res.data.answerType == 3) {
              this.showSnackbar(this.$i18n.t("TXT_NO_DATA_AVAILABLE"), "info");
              this.loadingModal = false;
            } else {
              this.showSnackbar(
                this.$i18n.t("TXT_FAIL_SEARCH_PRODUCT"),
                "error"
              );
              this.loadingModal = false;
            }
            this.isLoading = false;
            this.isDisable = true;
            this.closePanel = true;
          } else {
            if (res.data.answer.registers.length != 0) {
              this.$refs.loadingProducts.finishLoading();
              this.priceTrendsData = res.data.answer.registers;
              this.allActualPriceModifiedItems = [];
              this.tableResultData = res.data.answer.registers;
              this.$store.dispatch(
                "setPredimonitorResults",
                res.data.answer.registers
              );
              // this.getPriceAlert(request.filters.idProducts);
              if (this.hasResource("predimonitor.table.sellerpercent")) {
                this.isLoading = false;
                this.isDisable = false;
              }
              this.closePanel = false;
              this.mapUsaLoading = false;
              this.mapBrLoading = false;
              this.mapEuLoading = false;
            } else {
              this.showSnackbar(this.$i18n.t("TXT_NO_DATA_AVAILABLE"), "info");
              this.loadingModal = false;
              this.isLoading = false;
              this.isDisable = true;
              this.closePanel = true;
              this.$store.commit("setPredimonitorResults", []);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.isDisable = true;
          this.closePanel = true;
          this.$store.commit("setPredimonitorResults", []);
        });
    },

    getBoxPlotResults(request, search) {
      this.boxplotData = request;
      this.search = search;
    },

    getAnnouncementMapData(request) {
      this.announcementMapData = request;
    },

    getDateInterval(dateStart, dateEnd) {
      let startDate = moment(dateStart);
      let endDate = moment(dateEnd);

      let dateArray = [];

      dateArray.push(startDate.format("YYYY-MM-DD"));

      while (startDate.isBefore(endDate)) {
        startDate.add(1, "days");
        dateArray.push(startDate.format("YYYY-MM-DD"));
      }

      return dateArray;
    },

    updateGraphics(data) {
      this.updateData = data;
    },

    hasResource(value) {
      return this.$store.getters.hasResource(value);
    },

    setLoading(data) {
      this.isLoading = data;
      this.isDisable = data;
    },

    verifyCompany() {
      // Verify the idCompany to set some features

      // idBamaq: 2087 - idSicredi: 2087 - Sicred nova: 2918
      if (this.idCompany == 2087 || this.idCompany == 2918) {
        this.idBamaq = true;
        this.showY2 = true;
      }

      // idCompraAgora: 2647
      if (
        this.$store.getters.userData.idCompany == 2647 ||
        this.$store.getters.userData.idCompany == 2648
      ) {
        this.idCompraAgora = true;
      }
    },

    getResourceGroup() {
      this.predimonitorService
        .getResourceGroup(this.idCompany)
        .then((res) => {
          if (res.status != 500) {
            this.resourceGroup = res.data.answer;
          } else {
            this.resourceGroup = null;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getUsers() {
      this.predimonitorService
        .getUsers(this.idCompany)
        .then((res) => {
          if (res.status != 500) {
            this.userList = res.data;
          } else {
            this.userList = null;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    showSnackbar(text, type) {
      let snackbar = {
        snackbar: true,
        snackbarColor: null,
        snackbarText: null,
      };

      snackbar.snackbarText = text;

      switch (type) {
        case "success":
          snackbar.snackbarColor = "#56a667";
          break;
        case "error":
          snackbar.snackbarColor = "#ec4c37";
          break;
        case "info":
          snackbar.snackbarColor = "#3c5ca7";
      }
      this.snackbar = snackbar;
    },

    setCurrency() {
      Service.getCurrency(this.idCompany)
        .then((res) => {
          if (res.status != 500) {
            let currency = res.data.currencySymbol;

            if (!currency) {
              localStorage.setItem("currency", "R$");
            } else {
              localStorage.setItem("currency", currency);
            }
          }
        })
        .catch((error) => {
          console.log("Erro no buscar currency", error);
          localStorage.setItem("currency", "R$");
        });
    },

    async getMOCKEDData() {
      const req = await fetch("http://localhost:3000/products");
      const data = await req.json();
      data.forEach((el) => {
        el.editMonitoringModal = false;
      });
      this.tableResultData = data;
    },

    setActualPriceModified(data) {
      this.isDisableCompetitivenessIndex = true;
      const foundedItemIndex = this.allActualPriceModifiedItems.findIndex(
        (element) => element.monitoringId == data.monitoringId
      );
      if (foundedItemIndex == -1) {
        let completeItem = this.tableResultData.find(
          (el) => el.idMonitoringItem == data.monitoringId
        );
        data.productName = completeItem.productName;
        data.priceResults = completeItem.priceResults;
        data.avgPrice = completeItem.avgPrice;
        this.allActualPriceModifiedItems.push(data);
      } else {
        if (data.value != "-") {
          this.allActualPriceModifiedItems[foundedItemIndex].actualPrice =
            data.actualPrice;
        } else {
          this.allActualPriceModifiedItems.splice(foundedItemIndex, 1);
        }
      }
      if (
        this.verifyValueIntoArray(
          this.allActualPriceModifiedItems,
          "actualPrice"
        )
      ) {
        this.isDisableCompetitivenessIndex = false;
      }
    },

    verifyValueIntoArray(array, property) {
      let isContains = false;
      array.forEach((element) => {
        if (element[property] > 0) {
          isContains = true;
        }
      });
      return isContains;
    },

    maxMinAvg(data) {
      let copyData = JSON.parse(JSON.stringify(data));
      // for (let i in copyData) {
      //   if (copyData[i].priceResults != 0) {
      //     let pricesTemp = [];
      //     let sum = 0;
      //     let max = null;
      //     let min = null;
      //     copyData[i].priceResults.forEach((el) => {
      //       if (el.on_request == true || el.disregarded == true) return;
      //       pricesTemp.push(el.price);
      //       sum += el.price;
      //       if (min == null || min > el.price) min = el.price;

      //       if (max == null || max < el.price) max = el.price;
      //     });
      //     copyData[i].maxPrice = max;
      //     copyData[i].minPrice = min;
      //     copyData[i].avgPrice = sum / pricesTemp.length;
      //   } else {
      //     copyData[i].maxPrice = 0;
      //     copyData[i].minPrice = 0;
      //     copyData[i].avgPrice = 0;
      //   }
      // }
      return copyData;
    },

    setIC() {
      this.ic = 0;
      let averageCurrentPrice = 0;
      let averageProductsPrice = 0;
      let count = 0;
      const newDAtaArray = this.maxMinAvg(this.tableResultData);
      newDAtaArray.forEach((element) => {
        let currentPrice = this.allActualPriceModifiedItems.find(
          (el) => el.monitoringId == element.idMonitoringItem
        )?.actualPrice;
        if (currentPrice > 0) {
          averageCurrentPrice += Number(currentPrice);
          averageProductsPrice += element.avgPrice;
          count += 1;
        }
      });
      averageCurrentPrice = averageCurrentPrice / count;
      averageProductsPrice = averageProductsPrice / count;
      this.ic = (
        ((averageCurrentPrice - averageProductsPrice) / averageProductsPrice) *
          100 +
        100
      ).toFixed(2);
    },
  },
  computed: {
    // Displays other components if the company has the resources
    resources() {
      return this.$store.state.resources;
    },

    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    currency() {
      return localStorage.getItem("currency") || "R$";
    },
  },

  created() {
    // this.getMOCKEDData();
    this.verifyCompany();
    this.getResourceGroup();
    this.getUsers();
    this.setCurrency();

    document.title = "PREDIFY | PREDIMONITOR";
  },
};
</script>

<style scoped lang="scss">
.page-title {
  font-size: 24px;
  font-weight: 600;
}

.page-breadcrumbs {
  font-size: 14px;
  opacity: 0.5;
}

.v-tooltip__content .menuable_content_active {
  border: 1px solid black;
}

.group-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.281);
}
</style>
