<template>
  <v-card
    class="pa-4 pt-0"
    :disabled="productListLoading"
    :loading="productListLoading"
  >
    <v-row no-gutters class="pt-4">
      <!--Pricing select-->
      <v-col cols="12" lg="4" md="3" sm="6">
        <Prd-combo-box
          v-model="selectedPricing"
          @input="setPricingJob"
          :title="'Selecione uma precificação'"
          :items="pricingItems"
          :disabled="pricingGroupLoading || pricingItems.length == 0"
          :loading="pricingGroupLoading"
        />
      </v-col>

      <!-- Pricing modal -->
      <Pricing-modal class="mt-6" @setSavePricingJob="getPricingGroup(true)" />

      <!-- Calculate prices -->
      <Prd-btn
        :title="'Calcular Preços'"
        :disabled="
          pricingGroupLoading || !selectedPricing || checkPricingLoading
        "
        @click="calculatePrices"
        class="mt-7 ml-4"
      />

      <!-- Calculate rules -->
      <Prd-btn
        v-if="isPredifyAdmin"
        :disabled="
          pricingGroupLoading || !selectedPricing || checkPricingLoading
        "
        :title="'Calcular Regras'"
        :outlined="true"
        :icon="'mdi-shield-account-outline'"
        :leftIcon="true"
        :smallIcon="true"
        :iconColor="$prdStyles('color-warning')"
        @click="calculateRules"
        class="mt-7 ml-4 mr-4"
      />

      <Prd-loading-msg
        v-show="calculateRulesLoading || checkPricingLoading"
        :text="
          checkPricingLoading
            ? 'Verificando Precificação'
            : 'Calculando Precificação'
        "
      />
    </v-row>

    <div class="mt-6">
      <div
        v-for="(message, index) in errorMessages"
        :key="index"
        class="error-messages"
      >
        <v-row no-gutters align="center">
          <v-icon color="#B00020" class="mr-4"> mdi-alert-outline </v-icon>
          <span>{{ message.message }}</span>
          <v-spacer></v-spacer>
          <v-icon @click="closeMessage(index)" color="#B00020"
            >mdi-close</v-icon
          >
        </v-row>
      </div>
    </div>

    <div>
      <Prd-loading-msg v-show="filterLoading" :text="'Carregando filtros'" />

      <h4 v-show="!filterLoading && filterItems.length != 0" class="mt-6">
        FILTROS
      </h4>
    </div>

    <!-- FILTERS -->
    <v-row v-show="!filterLoading" no-gutters>
      <v-col
        v-for="filter in filterItems"
        :key="filter.idEnterprisePriceProjection_DefaultFilter"
        cols="12"
        md="3"
      >
        <Prd-combo-box
          v-model="filter.selected"
          :title="filter.fieldName"
          :items="filter.values"
          :disabled="filter.values.length == 0 || pricingGroupLoading"
          :hasOuterIcon="true"
          :deletable-chips="filter.dbField !== 100001"
          @input="getProductList(true)"
          small-chips
          label="Selecione"
          single-line
          class="mr-4 mt-2"
        />
      </v-col>
    </v-row>

    <invalid-products-modal
      :invalidProductsModal="invalidProductsModal"
      :invalidProductsList="invalidProductsList"
      :idPriceGroup="selectedPricing"
      @disagreeTerms="closeInvalidProductsModal"
      @acceptedTerms="getFilters"
      @closeModal="invalidProductsModal = false"
    />
  </v-card>
</template>

<script>
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import PricingModal from "./pricing-modal.vue";

import searchService from "@/service/new-ai-pricing/search-filters.js";
import PrdLoadingMsg from "@/components/common/prd-loading-msg.vue";
import InvalidProductsModal from "./invalid-products-modal.vue";
const SearchService = new searchService();

export default {
  components: {
    PrdComboBox,
    PrdBtn,
    PricingModal,
    PrdLoadingMsg,
    InvalidProductsModal,
  },
  props: ["recordsPerPage", "currentPage", "productListLoading"],
  data() {
    return {
      pricingGroupLoading: false,
      filterLoading: false,
      calculateRulesLoading: false,
      checkPricingLoading: false,

      invalidProductsModal: false,
      invalidProductsList: [],

      selectedPricing: null,
      pricingItems: [],
      filterItems: [],
      errorMessages: [],
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    isPredifyAdmin() {
      return this.$store.getters.isPredifyAdmin;
    },
  },

  watch: {
    recordsPerPage() {
      this.getProductList();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },

    currentPage() {
      this.getProductList();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },

  methods: {
    calculatePrices() {
      this.$emit("setLoading", true);
      this.calculateRulesLoading = true;
      this.errorMessages = [];

      let cleanResponse = {
        records: [],
        currentPage: 1,
        totalRecords: null,
        totalPages: null,
        numberOfRecords: null,
      };

      this.filterItems = [];

      this.$emit("products", cleanResponse);

      SearchService.calculatePrices(this.idCompany, this.selectedPricing.value)
        .then((res) => {
          if (res.status != 500) {
            this.$emit(
              "showSnackbar",
              "Sucesso ao calcular precificação",
              "success"
            );

            this.setPricingJob();
            this.$emit("setLoading", false);
            this.calculateRulesLoading = false;
          }
        })
        .catch((error) => {
          console.log("Erro ao gerar precificação", error);
          this.$emit("showSnackbar", "Erro ao calcular precificação", "error");
          this.$emit("setLoading", false);
          this.calculateRulesLoading = false;
        });
    },

    calculateRules() {
      this.$emit("setLoading", true);
      this.errorMessages = [];

      let cleanResponse = {
        records: [],
        currentPage: 1,
        totalRecords: null,
        totalPages: null,
        numberOfRecords: null,
      };

      this.filterItems = [];

      this.$emit("products", cleanResponse);
      SearchService.calculateRules(this.idCompany, this.selectedPricing.value)
        .then((res) => {
          if (res.status != 500) {
            this.$emit("showSnackbar", "Sucesso ao calcular regras", "success");
            this.$emit("setLoading", false);
            this.setPricingJob();
          }
        })
        .catch((error) => {
          console.log("Erro ao gerar regras", error);
          this.$emit("showSnackbar", "Erro ao calcular regras", "error");
          this.$emit("setLoading", false);
        });
    },

    getPricingGroup(isnewPricing) {
      this.pricingGroupLoading = true;

      let request = {
        idCompany: this.idCompany,
        filters: [],
        simulation: false,
      };

      SearchService.getPricingGroup(request)
        .then((res) => {
          if (res.status != 500) {
            let response = res.data.result;

            response = response.map((el) => ({
              text: el.name,
              value: el.idEnterprisePriceGroups,
              idAITraining: el.idAITraining,
              filters: el.filters,
            }));

            this.pricingItems = response;

            if (isnewPricing) {
              this.selectedPricing = this.pricingItems[0];
              this.calculatePrices();
            }
          }
          this.pricingGroupLoading = false;
        })
        .catch((error) => {
          console.log("Erro ao precificação ", error);
          this.$emit("showSnackbar", "Erro ao listar precificações", "error");

          this.pricingGroupLoading = false;
        });
    },

    setPricingJob() {
      this.filterItems = [];
      this.errorMessages = [];
      this.$emit("cleanProducts");
      this.getWarningToAprove(this.selectedPricing.value);
    },

    getProductList(isFromFilter) {
      this.$emit("setLoading", true);

      let request = this.buildRequest();

      SearchService.getProductList(request)
        .then((res) => {
          let response = res?.data?.result ?? [];
          this.$emit("products", response, isFromFilter);
          this.$emit("setLoading", false);
        })
        .catch((error) => {
          console.log("Erro ao buscar produtos", error);
          this.$emit("showSnackbar", "Erro ao listar produtos", "error");
          this.$emit("setLoading", false);
        });
    },

    getFilters() {
      this.getErrorMessages();

      this.filters = [];
      this.filterLoading = true;
      this.checkPricingLoading = false;
      SearchService.getFilters(this.idCompany, this.selectedPricing.value)
        .then((res) => {
          let response = res.data.result.customFilters;
          response.forEach((filter) => {
            if (filter.dbField === 900000) {
              filter.values = filter.values.map((el) => ({
                text: this.$i18n.t(el.description),
                value: el.value,
              }));
            } else if (filter.dbField === 900001) {
              filter.values = filter.values.map((el) => ({
                text: this.$i18n.t(el.description),
                value: el.value,
              }));
            } else {
              filter.values = filter.values.map((el) => ({
                text: el.description,
                value: el.value,
              }));
            }
          });

          response = response.map((el) => ({
            ...el,
            selected: null,
          }));

          response.filter((el) => {
            if (el.dbField === 100001) {
              el.selected = { text: "GOxDF", value: "GOxDF" };
            }
          });

          this.filterItems = response;
          this.filterLoading = false;
          this.getProductList();
        })
        .catch((error) => {
          console.log("Erro ao buscar filtros", error);
          this.$emit("showSnackbar", "Erro ao listar filtros", "error");

          this.filterLoading = false;
        });
    },

    getErrorMessages() {
      this.errorMessages = [];

      SearchService.getErrorMessages(this.selectedPricing.value)
        .then((res) => {
          const response = res?.data?.result ?? [];
          this.errorMessages = response;
        })
        .catch((error) => {
          console.log("Erro ao listar mensagens", error);
          this.$emit("showSnackbar", "Erro ao conferir precificação", "error");
        });
    },

    getWarningToAprove(idEnterprisePriceGroup) {
      this.checkPricingLoading = true;
      SearchService.getWarningToAprove(idEnterprisePriceGroup)
        .then((res) => {
          let response = res?.data?.result ?? null;

          if (response) {
            this.getFilters();
          } else {
            this.getWarningList(idEnterprisePriceGroup);
          }
        })
        .catch((error) => {
          console.log("Erro ao consultar avisos", error);
        });
    },

    getWarningList(idEnterprisePriceGroup) {
      SearchService.getWarningList(idEnterprisePriceGroup)
        .then((res) => {
          console.log(res);
          let response = res?.data?.result ?? [];
          // let response = [
          //   {
          //     IdEnterprisePriceProjection: 2018,
          //     description: "Produto cujo qual não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          //   {
          //     IdEnterprisePriceProjection: 2044,
          //     description: "Outro produto que não sei o nome",
          //     isInelastic: true,
          //   },
          // ];
          this.invalidProductsList = response;
          this.invalidProductsModal = true;
          this.checkPricingLoading = false;
        })
        .catch((error) => {
          console.log("Erro ao buscar lista de avisos", error);
          this.checkPricingLoading = false;
        });
    },

    buildRequest() {
      let request = {
        filter: [],
        idCompany: this.idCompany,
        idEnterprisePriceGroups: this.selectedPricing.value,
        isSimulation: false,
        page: this.currentPage,
        recordsPerPage: this.recordsPerPage,
      };

      let selectedFilterList = [];
      this.filterItems.forEach((el) => {
        if (el.selected) {
          let filterModel = {
            dbField: el.dbField,
            idEnterprisePriceProjectionDefaultFilter:
              el.idEnterprisePriceProjection_DefaultFilter,
            value: el.selected.value,
          };

          selectedFilterList.push(filterModel);
        }
      });

      request.filter = selectedFilterList;

      return request;
    },

    closeMessage(index) {
      this.errorMessages.splice(index, 1);
    },

    closeInvalidProductsModal() {
      this.selectedPricing = null;
      this.invalidProductsList = [];
      this.filterItems = [];
      this.errorMessages = [];
      this.invalidProductsModal = false;
      this.$emit(
        "showSnackbar",
        "Aceite os termos para listar a precificação",
        "info"
      );
    },
  },

  created() {
    document.title = "PREDIFY | Precificação por IA";

    this.getPricingGroup();
  },
};
</script>

<style lang="scss" scoped>
.filters-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.my-flex {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  gap: 1rem;
}

.error-messages {
  border: 1px solid #b00020;
  border-radius: 10px;
  padding: 0.5rem;
  margin-bottom: 1rem;
}
</style>