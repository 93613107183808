<template>
  <div>
    <Prd-loading-circular v-if="isLoading" size="50" class="mt-6" />

    <div v-else>
      <v-row class="mb-4 mt-6">
        <v-col
          v-for="(filter, index) in filters"
          :key="index"
          :cols="filter.tag == 'consultor' ? 12 : ''"
          :md="filter.tag == 'consultor' ? 12 : ''"
          :lg="filter.tag == 'consultor' ? 12 : ''"
          v-show="showHierarchyFilterToVEM(filter)"
          style="display: flex"
        >
          <Prd-combo-box
            v-model="filter.selected"
            :title="showTitleToVEM(filter)"
            :items="filter.items"
            :disabled="filter.items.length == 0"
            :itemText="'description'"
            :itemValue="'value'"
            :loading="showLoadingToVEM(filter.tag)"
            :label="$i18n.t('TXT_SELECT')"
            :multiple="filter.isMultiple"
            :chips="true"
            :deletable-chips="true"
            @change="handleFilterChange(filter)"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import service from "@/service/home-vem/index.js";
const Service = new service();
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
export default {
  components: { PrdComboBox, PrdLoadingCircular },
  props: ["type", "selectedAffiliate"],
  data() {
    return {
      filters: [
        {
          title: "Responsável",
          items: [],
          selected: [],
          tag: "consultor",
          isMultiple: true,
          extractor: null,
        },
        {
          title:
            this.type == "impact"
              ? this.$i18n.t("TXT_STORE")
              : this.$i18n.t("TXT_BRANCH"),
          items: [],
          selected: [],
          tag: "affiliates",
          isMultiple: true,
          extractor: (report) =>
            report.affiliates.map((affiliate) => ({
              description: affiliate.description || affiliate.value,
              value: affiliate.value,
              idEnterprisePriceGroups: report.idEnterprisePriceGroups,
            })),
        },
        {
          title: this.$i18n.t("TXT_YEARS"),
          items: [],
          selected: null,
          tag: "publishedDateYear",
          isMultiple: false,
          extractor: (report, tag) => this.filterExtractorDefault(report, tag),
        },
        {
          title: this.$i18n.t("TXT_MONTH"),
          items: [],
          selected: [],
          tag: "publishedDateMonth",
          isMultiple: true,
          extractor: (report, tag) => this.filterExtractorDefault(report, tag),
        },
        {
          title: this.$i18n.t("TXT_PRICING"),
          items: [],
          selected: [],
          tag: "name",
          isMultiple: true,
          extractor: (report, tag) => this.filterExtractorDefault(report, tag),
        },
      ],

      reportFiltersData: [],
      isLoading: false,
      isLoadingSuperiorsFilters: false,
      timer: null,
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    VEM() {
      return this.idCompany == 3026 ? true : false;
    },
  },

  watch: {
    isLoading: {
      handler(value) {
        this.$emit("setFiltersLoading", value);
      },
      immediate: true,
    },
    filters: {
      handler(value) {
        this.checkItems(value);
      },
      deep: true,
    },
  },

  methods: {
    filterExtractorDefault(report, tag) {
      return {
        description: report[tag],
        value: report[tag],
        idEnterprisePriceGroups: report.idEnterprisePriceGroups,
      };
    },
    checkItems(filters) {
      const yearFilter = filters.find((el) => el.tag == "publishedDateYear");
      this.$emit(
        "disableDownloadButton",
        !yearFilter.selected ||
          yearFilter.selected.length == 0 ||
          this.reportFiltersData.length == 0
      );
    },

    async getFilters() {
      this.isLoading = true;

      try {
        let response = await Service.getImpactReportFilters(this.idCompany);
        this.reportFiltersData = response?.data?.result ?? [];
        if (this.VEM) this.getHierarchyFilter();

        this.populateFilters(this.reportFiltersData);
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_FIND_FILTERS"),
          type: "error",
        });
        this.reportFiltersData = [];
      }

      this.isLoading = false;
    },

    async getHierarchyFilter() {
      this.isLoadingSuperiorsFilters = true;

      try {
        let response = await Service.getHierarchy(this.idCompany);
        let data = response?.data?.result ?? [];

        if (!data.description || !data.users) return;

        this.filters.forEach((filter) => {
          if (filter.tag == "consultor") {
            filter.title = data.description;

            filter.items = [];

            data.users.forEach((user) => {
              const item = { description: user, value: user };
              filter.items.push(item);
            });
          }
        });
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_FIND_FILTERS"),
          type: "error",
        });
        this.filters.splice(0, 1);
      } finally {
        this.isLoadingSuperiorsFilters = false;
      }
    },

    populateFilters(filtersContent) {
      this.updateFilterItems(filtersContent);

      this.selectAffiliate();

      this.selectCloserYear();
    },

    selectAffiliate() {
      if (!this.selectedAffiliate) return;

      const foundedFilter = this.filters.find(
        (filter) => filter.tag == "affiliates"
      );

      const foundedItem = foundedFilter.items.find(
        (item) => item.value == this.selectedAffiliate.value
      );

      const itsSelectedAffiliate = foundedFilter.selected.some(
        (affiliate) => affiliate.value == this.selectedAffiliate.value
      );

      if (foundedFilter && foundedItem && !itsSelectedAffiliate)
        foundedFilter.selected.push(foundedItem);

      this.handleFilterChange(foundedFilter);
    },

    selectCloserYear() {
      let yearFilter = this.filters.find((el) => el.tag == "publishedDateYear");
      const existentYearsInField = yearFilter.items.map((yearObj) =>
        parseInt(yearObj.value)
      );

      const currentYear = new Date().getFullYear();
      let currentCloserYear = existentYearsInField[0];
      let currentSmallestDifference = Math.abs(
        currentYear - existentYearsInField[0]
      );

      existentYearsInField.forEach((year) => {
        const smallestDifference = Math.abs(currentYear - year);
        if (smallestDifference < currentSmallestDifference) {
          currentCloserYear = year;
          currentSmallestDifference = smallestDifference;
        }
      });

      const objCloserYear = yearFilter.items.find(
        (yearObj) => yearObj.value == currentCloserYear
      );

      yearFilter.selected = objCloserYear;
      this.handleFilterChange(yearFilter);
    },

    handleFilterChange(filter) {
      this.removeInvalidSelections(filter);
      this.checkSelectedFilter(filter.tag);
    },

    removeInvalidSelections(filter) {
      if (Array.isArray(filter.selected))
        filter.selected = filter.selected.filter(
          (item) => typeof item == "object"
        );
      else if (typeof filter.selected == "string") filter.selected = null;
    },

    checkSelectedFilter(currentTag) {
      const filteredData = this.reportFiltersData.filter((report) => {
        return this.filters.every((filter) => {
          if (
            !filter.selected ||
            (Array.isArray(filter.selected) && filter.selected.length === 0)
          )
            return true;

          const selectedValues = Array.isArray(filter.selected)
            ? filter.selected.map((item) => item.value)
            : [filter.selected.value];

          if (filter.tag === "affiliates") {
            const result = report.affiliates.some((affiliate) =>
              selectedValues.includes(affiliate.value)
            );
            return result;
          } else if (filter.tag === "consultor") {
            const result = report.users.some((user) =>
              selectedValues.includes(user)
            );
            return result;
          } else {
            const result = selectedValues.includes(report[filter.tag]);
            return result;
          }
        });
      });

      this.updateFilterItems(filteredData, currentTag);
    },

    updateFilterItems(filteredData, currentTag) {
      const allFiltersNoSelect = this.filters.every((filter) =>
        filter.isMultiple ? filter.selected.length == 0 : !filter.selected
      );

      const listReport = allFiltersNoSelect
        ? structuredClone(this.reportFiltersData)
        : filteredData;

      this.filters.forEach((filter) => {
        const hasSelectItem = filter.isMultiple
          ? filter.selected.length > 0
          : filter.selected;
        if (
          (currentTag && filter.tag == currentTag && hasSelectItem) ||
          filter.extractor == null
        )
          return;

        filter.items = [];

        listReport.forEach((report) => {
          const extractedItem = filter.extractor(report, filter.tag);
          if (Array.isArray(extractedItem)) filter.items.push(...extractedItem);
          else filter.items.push(extractedItem);
        });

        filter.items = filter.items.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.value === item.value)
        );
      });

      const onlyYearSelected = this.checkIfOnlyYearSelected();
      if (onlyYearSelected) this.prepareItensForYearFilter();

      const onlyStoreSelected = this.checkIfOnlyStoreAndYearSelected();

      if (onlyStoreSelected) this.prepareItensForStoreFilter();

      this.removeNonExistentSelectedItem();
    },

    checkIfOnlyYearSelected() {
      const yearFilter = this.filters.find(
        (el) => el.tag === "publishedDateYear"
      );
      if (!yearFilter.selected) return false;

      const selectedTags = this.filters
        .filter((el) =>
          el.tag === "publishedDateYear" ? el.selected : el.selected.length > 0
        )
        .map((el) => el.tag);

      return (
        selectedTags.length === 1 && selectedTags[0] == "publishedDateYear"
      );
    },

    prepareItensForYearFilter() {
      const yearFilter = this.filters.find(
        (el) => el.tag === "publishedDateYear"
      );

      this.reportFiltersData.forEach((report) => {
        const extractedItem = yearFilter.extractor(report, yearFilter.tag);
        yearFilter.items.push(extractedItem);
      });

      yearFilter.items = yearFilter.items.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.value === item.value)
      );
    },

    checkIfOnlyStoreAndYearSelected() {
      const yearFilter = this.filters.find(
        (el) => el.tag === "publishedDateYear"
      );

      const affiliateFilter = this.filters.find(
        (el) => el.tag === "affiliates"
      );

      if (!yearFilter.selected || affiliateFilter.selected.length == 0)
        return false;

      const selectedTags = this.filters
        .filter((el) =>
          el.tag === "publishedDateYear" ? el.selected : el.selected.length > 0
        )
        .map((el) => el.tag);

      return (
        selectedTags.length === 2 &&
        selectedTags.includes("publishedDateYear") &&
        selectedTags.includes("affiliates")
      );
    },

    prepareItensForStoreFilter() {
      const storeFilter = this.filters.find((el) => el.tag === "affiliates");
      storeFilter.items = [];

      const yearFilter = this.filters.find(
        (el) => el.tag === "publishedDateYear"
      );

      this.reportFiltersData
        .filter(
          (report) => report.publishedDateYear == yearFilter.selected.value
        )
        .forEach((report) => {
          const extractedItem = storeFilter.extractor(report, storeFilter.tag);
          storeFilter.items.push(...extractedItem);
        });

      storeFilter.items = storeFilter.items.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.value === item.value)
      );
    },

    removeNonExistentSelectedItem() {
      const bugedFilters = this.filters
        .filter((filter) => filter.isMultiple)
        .filter((filter) => filter.selected.length > filter.items.length);

      bugedFilters.forEach((filter) => {
        filter.selected = filter.selected.filter((selection) =>
          filter.items.map((item) => item.value).includes(selection.value)
        );
      });
    },

    showHierarchyFilterToVEM(filter) {
      if (filter.tag !== "consultor") {
        return true;
      }
      return this.VEM && filter.items.length > 0;
    },

    showTitleToVEM(filter) {
      if (!this.VEM) return filter.title;

      if (this.VEM && filter.tag != "consultor") return filter.title;

      if (this.VEM && filter.title == "Gerente") return "Coordenador";
      if (this.VEM && filter.title == "Coordenador") return "Consultor";
    },

    showLoadingToVEM(tag) {
      if (tag == "consultor" && this.isLoadingSuperiorsFilters) return true;
      if (tag == "consultor" && !this.isLoadingSuperiorsFilters) return false;
    },

    emitFilters() {
      return this.filters;
    },

    emitFiltersResponse() {
      return this.reportFiltersData;
    },
  },

  created() {
    this.getFilters();
  },
};
</script>