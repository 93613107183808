<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :hide-default-footer="items == [] || items.length == 0"
    :footer-props="footerProps"
    :no-data-text="$t('TXT_NO_DATA_AVAILABLE')"
  >
    <template v-if="isCostPredimonitor" v-slot:[`item.state`]="{ item }">
      <span class="clickable" @click="setSelectedState(item.state)">{{
        item.state ? item.state : $t("TXT_UNKNOW")
      }}</span>
    </template>

    <template v-else v-slot:[`item.state`]="{ item }">
      {{ item.state ? item.state.toUpperCase() : $t("TXT_UNKNOW") }}
    </template>

    <template v-slot:[`item.city`]="{ item }">
      {{ item.city ? item.city.toUpperCase() : $t("TXT_UNKNOW") }}
    </template>

    <template v-slot:[`item.price`]="{ item }">
      {{ formatMonetary(item.price) }}
    </template>
  </v-data-table>
</template>

<script>
import { formatMonetary } from "@/utils/format-toMonetary";

export default {
  data() {
    return {
      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },
      headers: [
        { text: this.$i18n.t("TXT_STATE"), value: "state", align: "center" },
        {
          text: this.$i18n.t("TXT_AVERAGE_PRICE"),
          value: "price",
          align: "center",
        },
        {
          text: this.$i18n.t("TXT_AMOUNT_STORES"),
          value: "amountStore",
          align: "center",
        },
        {
          text: this.$i18n.t("TXT_AMOUNT_ADS"),
          value: "amountAnnouncement",
          align: "center",
        },
      ],
      items: [],
    };
  },
  computed: {
    listForTable() {
      return this.$store?.getters?.getListForTable ?? [];
    },
    isCostPredimonitor() {
      const isCostPredimonitor =
        this.$route.query.type?.includes("cost") ?? false;
      this.$store.dispatch("setIsCostPredimonitor", isCostPredimonitor);
      return isCostPredimonitor;
    },
    selectedState() {
      return this.$store?.getters?.getSelectedState ?? null;
    },
  },

  watch: {
    listForTable: {
      handler(value) {
        if (value && value.length > 0) this.prepareItemsForTable(value);
      },
      deep: true,
      immediate: true,
    },
    selectedState: {
      handler(value) {
        if (value) {
          this.headers[0].text = this.$i18n.t("TXT_CITY");
          this.headers[0].value = "city";
        } else {
          this.headers[0].text = this.$i18n.t("TXT_STATE");
          this.headers[0].value = "state";
        }
      },
    },
  },

  methods: {
    formatMonetary,

    prepareItemsForTable(searchResult) {
      this.items = [];

      const consideredAnnouncements = this.filterAnnouncements(searchResult);

      if (consideredAnnouncements.length == 0) return;

      let lastSeller = consideredAnnouncements[0].sellerName;

      consideredAnnouncements.forEach((itemOutTable) => {
        const foundedIndex = this.items.findIndex((itemInTable) => {
          return this.selectedState
            ? itemInTable.city == itemOutTable.city
            : itemInTable.state == itemOutTable.state;
        });

        if (foundedIndex == -1) {
          const newItem = this.getBodyToTable(
            itemOutTable.state,
            itemOutTable.city,
            itemOutTable.price,
            1,
            1
          );
          this.items.push(newItem);
        } else {
          this.items[foundedIndex].amountAnnouncement += 1;
          this.items[foundedIndex].price += itemOutTable.price;
          const currentSeller = itemOutTable.sellerName;
          if (lastSeller != currentSeller) {
            this.items[foundedIndex].amountStore += 1;
            lastSeller = currentSeller;
          }
        }
      });

      this.items = this.items.map((item) => {
        return {
          ...item,
          price: item.price / item.amountAnnouncement,
        };
      });
    },

    filterAnnouncements(searchResult) {
      const announcements = [].concat(
        ...searchResult.map((result) => result.priceResults)
      );

      let consideredAnnouncements = announcements.filter(
        (result) => result.disregarded == false && result.coordinates != null
      );

      if (this.isCostPredimonitor && this.selectedState) {
        consideredAnnouncements = consideredAnnouncements.filter(
          (ann) => ann.state.toUpperCase() == this.selectedState.toUpperCase()
        );
      }
      return consideredAnnouncements;
    },

    getBodyToTable(state, city, price, amountStore, amountAnnouncement) {
      return {
        state,
        city,
        price,
        amountStore,
        amountAnnouncement,
      };
    },

    async setSelectedState(state) {
      if (!state) return;
      await this.$store.dispatch("setState", state);
    },
  },
};
</script>

<style lang="scss" scoped>
.clickable {
  cursor: pointer;
  color: #3c5ca7;
  &:hover {
    font-size: large;
    font-weight: bold;
    transition: 0.2s;
  }
}
</style>