<template>
  <v-expansion-panel :class="setBorderColor(item.status)" class="mb-2">
    <v-expansion-panel-header class="item">
      <div class="header-item">
        <div class="my-flex">
          <div>
            <span class="font-weight-bold">
              Lote: {{ item.idMonitoringBatch }} -

              <span
                v-for="(crawler, index) in item.listMonitoringCrawlers"
                :key="index"
                >{{ crawler.crawlerName }}
              </span>

              <span class="font-weight-400">|</span>
            </span>
            <span class="font-weight-bold">
              Status:
              <span :class="setFontColor(item.status)">{{
                setStatusToString(item.status)
              }}</span>
            </span>
          </div>

          <div v-show="item.message" class="font-weight-bold mt-2">
            <v-icon
              v-if="item.status != 1"
              :class="setFontColor(item.status)"
              class="mr-2"
              >{{ setIcon(item.status) }}</v-icon
            >
            <v-progress-circular
              v-else
              :width="1"
              :size="20"
              color="#6792f8"
              indeterminate
              class="mr-2"
            />
            {{ item.message }}
          </div>
        </div>

        <div class="date-item">
          <div
            v-if="(item.status == 2 || item.status == 3) && !item.loading"
            class="mt-n1 mr-4"
          >
            <v-icon
              :color="$prdStyles('color-primary')"
              @click.stop="executeCrawler(item)"
              >mdi-reload</v-icon
            >
          </div>
          <v-progress-circular
            v-show="item.status != 0 && item.loading"
            :color="$prdStyles('color-primary')"
            indeterminate
            class="mt-n1 mr-4"
          ></v-progress-circular>
          <span class="font-weight-bold mr-4"
            >Data da execução: {{ formatDate(item.updateDate) }}</span
          >
        </div>
      </div>
    </v-expansion-panel-header>

    <v-expansion-panel-content color="#f5f5f5">
      <v-tabs
        v-model="selectedTab"
        background-color="#f5f5f5"
        :slider-color="$prdStyles('color-secundary')"
        :slider-size="5"
        color="black"
        height="40px"
        class="border-destaq my-4"
      >
        <v-tab class="pa-0"> CRAWLERS - STATUS </v-tab>
        <v-tab> PRODUTOS EXECUTADOS </v-tab>
      </v-tabs>

      <v-tabs-items v-model="selectedTab">
        <v-tab-item>
          <Crawler-table :item="item" />
        </v-tab-item>

        <v-tab-item>
          <Product-table :item="item" />
        </v-tab-item>
      </v-tabs-items>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { formatDate } from "@/utils/prd-format-date.js";
import ProductTable from "./crawler-table/product-table.vue";
const Service = new service();
import service from "@/service/crawlers/crawler-execute.js";
import CrawlerTable from "./crawler-table.vue";

export default {
  components: { ProductTable, CrawlerTable },
  props: ["item"],
  data() {
    return {
      selectedTab: 0,
    };
  },

  methods: {
    formatDate,
    executeCrawler(item) {
      item.loading = true;
      if (!item) return;

      Service.executeCrawlerById(item.idMonitoringBatch)
        .then((res) => {
          if (res.status !== 500) {
            this.$store.commit("snackbarV2/set", {
              message: "Produtos enviados para a execução no crawler!",
              type: "success",
            });
            item.status = 0;
            item.message = "Aguardando execução";
          }

          item.loading = false;
        })
        .catch((error) => {
          console.log(error);
          item.loading = false;
        });
    },
    setBorderColor(status) {
      switch (status) {
        case 0:
          return "border-status-info";
        case 1:
          return "border-status-info-light";
        case 2:
          return "border-status-success";
        case 3:
          return "border-status-error";
      }
    },
    setStatusToString(status) {
      switch (status) {
        case 0:
          return "Na fila";
        case 1:
          return "Rodando";
        case 2:
          return "Finalizado";
        case 3:
          return "Erro";
      }
    },
    setFontColor(status) {
      switch (status) {
        case 0:
          return "font-color-info";
        case 1:
          return "font-color-info-light";
        case 2:
          return "font-color-success";
        case 3:
          return "font-color-error";
      }
    },
    setIcon(status) {
      switch (status) {
        case 0:
          return "mdi-timer-sand-empty";
        case 2:
          return "mdi-check";
        case 3:
          return "mdi-alert-circle-outline";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  background-color: $neutral-color-high-light;
  border-radius: 5px;
}
.header-item {
  display: flex;
  justify-content: space-between;
}
.date-item {
  display: flex;
}
.product-item {
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
  padding-top: 8px;
  margin-bottom: 4px;
}

.my-flex {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.font-weight-400 {
  font-weight: 300 !important;
}

.font-color-info {
  color: $brand-color-primary-pure;
}
.font-color-info-light {
  color: $brand-color-primary-medium;
}
.font-color-success {
  color: $feedback-color-success-pure;
}
.font-color-error {
  color: $brand-color-secondary-pure;
}

.border-status-info {
  border-left: 6px solid $brand-color-primary-pure;
}
.border-status-info-light {
  border-left: 6px solid $brand-color-primary-medium;
}
.border-status-success {
  border-left: 6px solid $feedback-color-success-pure;
}
.border-status-error {
  border-left: 6px solid $brand-color-secondary-pure;
}

.v-tab {
  font-size: 14px !important;
  font-weight: 600;
}
.border-destaq {
  border-bottom: 0.5px solid rgba(59, 58, 58, 0.185);
}
</style>