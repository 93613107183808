<template>
  <v-expansion-panel class="product-item">
    <Product-panel
      :product="product"
      :idMonitoringItem="idMonitoringItem"
      :idTask="idTask"
      @deleteItem="updateOrDeleteItems"
      @updateProduct="updateOrDeleteItems"
      @cancelChanges="$emit('cancelChanges')"
    />
  </v-expansion-panel>
</template>

<script>
import ProductPanel from "./product-panel.vue";
export default {
  props: ["idMonitoringItem", "product","idTask"],
  components: { ProductPanel },
  data() {
    return {};
  },
  methods: {
    updateOrDeleteItems(productObj, isForDelete) {
      this.$emit("updateOrDeleteItems", productObj, isForDelete);
    },
  },
};
</script>

<style lang="scss" scoped>
.product-item {
  background-color: #f5f5f5;
  margin-bottom: 8px;
}
</style>