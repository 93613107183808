<template>
  <v-row no-gutters align="center" v-if="show">
    <v-col cols="auto">
      <Cluster-data :title="'Ouro'" :value="gold" :margin="goldMargin" />
    </v-col>
    <v-col cols="auto">
      <Cluster-data :title="'Prata'" :value="silver" :margin="silverMargin" />
    </v-col>
    <v-col cols="auto">
      <Cluster-data :title="'Bronze'" :value="bronze" :margin="bronzeMargin" />
    </v-col>
  </v-row>
</template>

<script>
import { formatMonetary } from "@/utils/format-toMonetary";
import { formatPercentage } from "@/utils/format-percentage";
import ClusterData from "./components/cluster-data.vue";
export default {
  components: { ClusterData },
  props: {
    headerData: {
      type: Object,
      default: () => ({
        price1: null,
        price2: null,
        price3: null,
        price1_Margin: null,
        price2_Margin: null,
        price3_Margin: null,
      }),
    },
  },
  computed: {
    show() {
      return (
        (this.gold && this.gold != "-") ||
        (this.silver && this.silver != "-") ||
        (this.bronze && this.bronze != "-")
      );
    },
    gold() {
      return formatMonetary(this.headerData.price1);
    },
    silver() {
      return formatMonetary(this.headerData.price2);
    },
    bronze() {
      return formatMonetary(this.headerData.price3);
    },
    goldMargin() {
      return formatPercentage(this.headerData.price1_Margin);
    },
    silverMargin() {
      return formatPercentage(this.headerData.price2_Margin);
    },
    bronzeMargin() {
      return formatPercentage(this.headerData.price3_Margin);
    },
  },
};
</script>