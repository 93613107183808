import axiosInstance, { API_URL_UPL, API_URL, API_DATA_INSIGHT_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class HomeVemService extends ServiceBase {
    async getGeralVision() {
        return await axiosInstance.get(API_URL_UPL + "");
    }

    async getOportunities() {
        return await axiosInstance.get(API_URL_UPL + "");
    }

    async getAlerts() {
        return await axiosInstance.get(API_URL_UPL + "");
    }

    async getPromotionalCalendar() {
        return await axiosInstance.get(API_URL_UPL + "");
    }

    // MOCKED
    // async getChartAdoption() {
    //     return await axiosInstance.get("http://localhost:3000/chartAdoptionData");
    // }
    // async getChartDemand() {
    //     return await axiosInstance.get("http://localhost:3000/chartDemandData");
    // }
    // async getChartRecipe() {
    //     return await axiosInstance.get("http://localhost:3000/chartRecipeData");
    // }
    // async getChartMargin() {
    //     return await axiosInstance.get("http://localhost:3000/chartMarginData");
    // }

    async getChartAdoption(request) {
        return await axiosInstance.post(API_DATA_INSIGHT_URL + `api/ImpactGraph/GetAdoption`,request);
    }
    async getChartDemand(request) {
        return await axiosInstance.post(API_DATA_INSIGHT_URL + `api/ImpactGraph/GetDemand`,request);
    }
    async getChartMargin(request) {
        return await axiosInstance.post(API_DATA_INSIGHT_URL + `api/ImpactGraph/GetMargin`,request);
    }
    async getChartRecipe(request) {
        return await axiosInstance.post(API_DATA_INSIGHT_URL + `api/ImpactGraph/GetRevenue`,request);
    }

    async extractApprovationReport(request) {
        return await axiosInstance.post(API_URL + `api/v2/Enterprise/PriceGroups/ExportGroups`, request, { responseType: 'blob' });
    }

    async extractConsolidatedReport(request) {
        return await axiosInstance.post(API_DATA_INSIGHT_URL + `api/ImpactReport/DownloadConsolidatedReport`, request, { responseType: 'blob' });
    }

    async extractRawReport(request) {
        return await axiosInstance.post(API_DATA_INSIGHT_URL + `api/ImpactReport/DownloadRawReport`, request, { responseType: 'blob' });
    }

    async getImpactReportFilters(idCompany) {
        return await axiosInstance.get(API_URL + `api/v2/Home/ListFilterImpact?idCompany=${idCompany}`);
    }

    async getHierarchy(idCompany) {
        return await axiosInstance.get(API_URL + `api/v2/Home/ListFilterHierarchy?idCompany=${idCompany}`);
    }
}

