import axios from "axios";
import store from "@/store";

function GetURL() {
  if (process.env.NODE_ENV === "development")
    return "https://pre-prod-service.predify.me/"

  else if (window.location.host.includes("appv3.predify.me"))
    return "https://hom-service.predify.me/"

  else if (window.location.host.includes("monitor-vue.predify.me"))
    return "https://mon-service.predify.me/"

  else if (window.location.host.includes("pre-prod-vue.predify.me"))
    return "https://pre-prod-service.predify.me/"

  else if (window.location.host.includes("app-predify-vue-dev-westus3.azurewebsites.net"))
    return "https://app-predifybackapi-dev-westus3.azurewebsites.net/"

  else
    return "https://web-service.predify.me/"
}

function GetAPIURL() {
  if (process.env.NODE_ENV === "development")
    return "https://app-data-insight-api-pre-prod-westus3.azurewebsites.net/"

  else if (window.location.host.includes("appv3.predify.me"))
    return "https://app-data-insight-api-pre-prod-westus3.azurewebsites.net/"

  else if (window.location.host.includes("monitor-vue.predify.me"))
    return "https://app-data-insight-api-pre-prod-westus3.azurewebsites.net/"

  else if (window.location.host.includes("pre-prod-vue.predify.me"))
    return "https://app-data-insight-api-pre-prod-westus3.azurewebsites.net/"

  else if (window.location.host.includes("app-predify-vue-dev-westus3.azurewebsites.net"))
    return "https://app-data-insight-api-pre-prod-westus3.azurewebsites.net/"

  else
    return "https://app-data-insight-api-prod-westus3.azurewebsites.net/"
}

const API_URL = GetURL()
const API_DATA_INSIGHT_URL = GetAPIURL()
const API_URL_UPL = "https://apiuplpredify.azurewebsites.net/";
const API_URL_HOME_20 = "https://predifyhom20.azurewebsites.net/";

const api_v1 = "api/v1/";

var axiosInstance = axios.create({
  baseURL: API_URL,
});

var axiosRaw = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use(function (config) {
  const auth = store.getters.userData;
  if (auth) config.headers["Authorization"] = "Bearer " + auth.token;
  return config;
});



//cria axios personalizado
export { axiosRaw, api_v1, API_URL, API_URL_UPL, API_URL_HOME_20, API_DATA_INSIGHT_URL };

export default axiosInstance;
