<template>
  <div>
    <v-icon
      :color="$prdStyles('color-success')"
      :disabled="loadingProductList"
      @click="isModalOpen = true"
      large
      class="mt-7 mr-4"
    >
      mdi-plus-box
    </v-icon>

    <v-dialog v-model="isModalOpen" :width="900" persistent>
      <v-card class="pa-4">
        <v-row no-gutters justify="center">
          <div class="header">
            <v-icon :color="$prdStyles('color-success')" large>
              mdi-checkbox-marked-circle-outline
            </v-icon>
            <h4>{{ $t("TXT_NEW_PRICING") }}</h4>
            <span>{{ $t("TXT_CREATE_NEW_PRICING") }}</span>
          </div>
        </v-row>

        <v-row no-gutters align="center" class="mt-6">
          <v-col class="mr-4">
            <Prd-text-field
              v-model="pricingName"
              :title="$t('TXT_PRICING_NAME')"
              :disabled="isLoading"
            />
          </v-col>

          <v-col v-if="company" cols="12" md="2" class="mr-4">
            <Prd-text-field
              v-model="company"
              :title="$t('TXT_BRANCH')"
              :filled="true"
              :disabled="true"
            />
          </v-col>

          <v-col cols="12" md="2">
            <Prd-text-field
              v-model="creationDate"
              :title="$t('TXT_CREATION_DATE')"
              :filled="true"
              :disabled="true"
            />
          </v-col>
        </v-row>
        <Prd-loading-msg
          v-if="loadingFilters"
          :messageInFront="true"
          :text="$t('TXT_LOADING_FILTERS')"
          class="my-6"
        />

        <v-row no-gutters class="mt-4">
          <v-col
            v-for="filter in filters"
            :key="filter.dbField"
            :md="filter.dbField == 38 ? 6 : 3"
            v-show="!filter.hide"
            cols="12"
          >
            <label>{{ filter.fieldName }}</label>

            <v-combobox
              v-model="filter.selected"
              :items="filter.values"
              :disabled="isLoading"
              item-text="description"
              item-value="value"
              multiple
              outlined
              dense
              append-icon="mdi-chevron-down"
              small-chips
              deletable-chips
              chips
              clear-icon
              class="mr-4 mt-2"
            />
          </v-col>
        </v-row>

        <v-row no-gutters justify="center" class="my-4">
          <span>{{ $t("TXT_SAVE_AND_CALCULATE") }}</span>
        </v-row>

        <v-row no-gutters justify="end">
          <Prd-btn
            :title="$t('TXT_CANCEL')"
            :outlined="true"
            :disabled="isLoading"
            @click="closeModal"
            class="mr-4"
          />
          <Prd-btn
            :title="$t('TXT_SAVE_PRICING')"
            :disabled="loadingFilters || isLoading || !pricingName"
            :loading="isLoading"
            @click="savePricing"
          />
        </v-row>
      </v-card>
    </v-dialog>

    <Calculate-pricing-modal
      :calculateModal="calculateModal"
      :pricing="pricing"
      @closeCalculateModal="calculateModal = false"
      @calculatePricing="calculatePricing"
    />

    <Calculate-loading
      :controlModal="calculateLoadingModal"
      :idEnterprisePriceGroups="idEnterprisePriceGroups"
      :isFromLoading="isFromLoading"
      @calculateFinish="calculateFinish"
    />
  </div>
</template>


<script>
import PrdTextField from "@/components/common/prd-text-field.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import service from "@/service/ai-pricing/create-pricing.js";
import PrdLoadingMsg from "@/components/common/prd-loading-msg.vue";
import CalculatePricingModal from "./components/calculate-pricing-modal.vue";
import CalculateLoading from "./calculate-loading.vue";

const Service = new service();
export default {
  props: ["preSelect", "loadingProductList", "selectedPreFilters"],
  components: {
    PrdTextField,
    PrdBtn,
    PrdLoadingMsg,
    CalculatePricingModal,
    CalculateLoading,
  },
  data() {
    return {
      isModalOpen: false,
      calculateModal: false,
      calculateLoadingModal: false,

      loadingFilters: false,
      isLoading: false,

      filters: [],

      pricingName: null,
      company: null,
      creationDate: this.getCurrentDate(),

      pricing: {},
      idEnterprisePriceGroups: null,

      isFromLoading: false,
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  watch: {
    isModalOpen() {
      if (this.isModalOpen) {
        this.getFilters();
      }
    },
  },

  methods: {
    async getFilters() {
      this.loadingFilters = true;

      try {
        let response = await Service.getFilters(
          this.idCompany,
          undefined,
          false,
          true
        );

        let data = response?.data?.result ?? [];
        let selectedFilter = this.selectedPreFilters[0] ?? null;
        data.forEach((el) => {
          el.selected = [];

          el.values.forEach((value) => {
            value.text = value.description;
            if (selectedFilter) {
              const current =
                value.idEnterprisePriceGroupDefaultFilterValue != 0
                  ? value.idEnterprisePriceGroupDefaultFilterValue
                  : value.value;
              const selected =
                selectedFilter.idEnterprisePriceGroupDefaultFilterValue != 0
                  ? selectedFilter.idEnterprisePriceGroupDefaultFilterValue
                  : selectedFilter.value;
              if (current == selected) {
                el.selected.push(selectedFilter);
                el.hide = true;
              }
            }
          });
        });

        this.filters = data;
        this.filterListItemFilters(data);
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$t("TXT_ERROR_FETCHING_FILTERS"),
          type: "error",
        });
      }

      this.loadingFilters = false;
    },

    filterListItemFilters(list) {
      const indexFilterDbField34 = list.findIndex(
        (filter) => filter.dbField == 34
      );
      if (indexFilterDbField34 != -1) {
        const filter = list[indexFilterDbField34];
        const items = filter.values;
        const filteredItems = items.filter((item) =>
          item.description.toLowerCase().includes("pdv")
        );
        this.filters[indexFilterDbField34].values = [];
        this.filters[indexFilterDbField34].values = filteredItems;
      }
    },

    setListForUPL() {
      if (this.idCompany != 2858) return;
      const valueRequiredByPreSelect = this.preSelect.find(
        (filter) => filter.dbField == 17
      ).value;
      const foundedFilter = this.filters.find((filter) => filter.dbField == 17);
      const item = foundedFilter.values.find(
        (value) => value.value == valueRequiredByPreSelect
      );
      foundedFilter.selected = [item];
    },

    async savePricing() {
      this.isLoading = true;
      let request = this.buildRequest();

      try {
        let response = await Service.savePricing(request);
        this.idEnterprisePriceGroups =
          response?.data?.result?.idEnterprisePriceGroups ?? null;
        this.postSaveFilters();
      } catch (error) {
        console.log("erro ao salvar precificação", error);
        this.$store.commit("snackbarV2/set", {
          message: this.$t("TXT_ERROR_SAVING_PRICING"),
          type: "error",
        });
      }

      this.isLoading = false;
    },

    calculatePricing() {
      if (this.idEnterprisePriceGroups) this.calculateLoadingModal = true;
      else
        this.$store.commit("snackbarV2/set", {
          message: this.$t("TXT_ERROR_SAVING_PRICING"),
          type: "error",
        });

      this.calculateModal = false;
    },

    calculateFinish(idEnterprisePriceGroups, hasCorrect) {
      this.calculateLoadingModal = false;
      if (hasCorrect) this.$emit("calculateFinish", idEnterprisePriceGroups);
    },

    buildRequest() {
      let request = {
        idCompany: this.idCompany,
        idEnterprisePriceGroup: 0,
        isSimulation: false,
        name: this.pricingName,
        filters: [],
      };

      this.filters.forEach((el) => {
        el.selected.forEach((value) => {
          request.filters.push({
            idEnterprisePriceGroup_Filter: 0,
            idEnterprisePriceGroup_DefaultFilter:
              el.idEnterprisePriceGroupDefaultFilter,
            value: value.value,
            isDeleted: false,
          });
        });
      });

      return request;
    },

    postSaveFilters() {
      this.pricing = {
        pricingName: this.pricingName,
        company: this.company,
        filters: this.formatFilters(this.filters),
      };

      this.cleanData();

      this.isModalOpen = false;
      this.calculateModal = true;
    },

    formatFilters(filters) {
      const newArray = [];

      filters.forEach((filter) => {
        newArray.push({
          fieldName: filter.fieldName,
          dbField: filter.dbField,
          idEnterprisePriceGroupDefaultFilter:
            filter.idEnterprisePriceGroupDefaultFilter,
          values: filter.selected,
        });
      });

      return newArray;
    },

    cleanData() {
      this.pricingName = null;
      this.company = null;
      this.filters = [];
    },

    getCurrentDate() {
      let actualDate = new Date();

      let day = actualDate.getDate();
      let month = actualDate.getMonth() + 1;
      let year = actualDate.getFullYear();

      return (
        (day < 10 ? "0" : "") +
        day +
        "/" +
        (month < 10 ? "0" : "") +
        month +
        "/" +
        year
      );
    },

    openModal(pricing, fromRecalc) {
      this.idEnterprisePriceGroups = pricing.idEnterprisePriceGroups;

      this.pricing = {
        pricingName: pricing.name,
      };

      this.cleanData();
      if (fromRecalc) this.calculatePricing();
      this.isModalOpen = false;
      this.calculateModal = true;
    },

    openLoadingModal(pricing) {
      this.isFromLoading = true;
      this.idEnterprisePriceGroups = pricing.idEnterprisePriceGroups;
      this.calculateLoadingModal = true;
    },

    closeModal() {
      this.cleanData();
      this.isModalOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}
</style>