import { render, staticRenderFns } from "./disregard-table.vue?vue&type=template&id=380496de&scoped=true&"
import script from "./disregard-table.vue?vue&type=script&lang=js&"
export * from "./disregard-table.vue?vue&type=script&lang=js&"
import style0 from "./disregard-table.vue?vue&type=style&index=0&id=380496de&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "380496de",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VDataTable,VDialog,VRow,VSimpleCheckbox,VSpacer})
