<template>
  <div class="login-container">
    <div class="login-card">
      <img
        src="../../assets/logo_predify_blue.png"
        alt="Company Logo"
        class="logo"
      />
      <h2 class="title">ENTRAR</h2>
      <div v-if="isLoading">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h4>Por favor, aguarde</h4>
      </div>

      <a v-else @click="loginMSAL" class="button">SSO UPL</a>
    </div>
  </div>
</template>

<script>
import service from "@/service/upl/login-sso.js";
const Service = new service();
export default {
  name: "upl-sso",
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async loginMSAL() {
      try {
        let response = await this.$msalInstance.loginPopup();
        console.log("usuário autenticado", response);
        this.loginPredify(response.idToken.rawIdToken);
      } catch (error) {
        console.error("Erro de autenticação:", error);
      }
    },

    async loginPredify(token) {
      this.isLoading = true;

      try {
        let response = await Service.getPredifyLogin(token);
        console.log("MSAL response", response);

        let userData = {
          email: response.data.userName,
          hasUser: true,
          rememberLogin: this.rememberLogin,
          token: response.data.access_token,
          adminRoles: [],
          idCompany: null,
        };

        this.$store.dispatch("setUser", userData);

        const userRoles = await Service.getUserRoles(this.email);

        userData = {
          email: response.data.userName,
          hasUser: true,
          rememberLogin: this.rememberLogin,
          token: response.data.access_token,
          adminRoles: userRoles?.data?.adminRoles || [],
          idCompany: null,
        };

        this.$store.dispatch("setUser", userData);
        this.$router.push("/main");
      } catch (error) {
        console.log("Error", error);
      }

      this.isLoading = false;
    },
  },

  created() {
    document.title = "PREDIFY | LOGIN";
  },
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f4f4;
}

.login-card {
  width: 320px;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.logo {
  width: 180px;
  height: auto;
  margin-bottom: 20px;
}

.title {
  margin: 0;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 20px;
  color: #3c5ca7;
  text-transform: uppercase;
}

.button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  background-color: #3c5ca7;
  color: white;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #2b4573;
}

.button:active {
  background-color: #1f3254;
}

/* Additional styling for responsiveness */
@media (max-width: 768px) {
  .login-card {
    width: 80%;
  }
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #3c5ca7;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #3c5ca7 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
