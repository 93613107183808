<template>
  <div>
    <v-card class="pa-4">
      <v-expansion-panels
        v-model="panel"
        accordion
        flat
        multiple
        :disabled="isLoading"
      >
        <v-expansion-panel>
          <v-expansion-panel-header
            class="ma-0 pa-0"
            expand-icon="mdi-chevron-down"
          >
            <v-row class="ma-0 pa-0" justify="space-between" align="center">
              <div style="display: flex; align-items: center">
                <label
                  class="card-subtitle"
                  v-t="'TXT_PRODUCT_COMPARISON_UPPERCASE'"
                ></label>

                <v-tooltip
                  max-width="556px"
                  color="#F5F5F5"
                  style="pointer-events: all"
                  right
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="cursor: pointer; pointer-events: all"
                      v-bind="attrs"
                      v-on="on"
                      color="#6792F8"
                      right
                      small
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span
                    style="
                      color: #000;
                      font-size: 14px !important;
                      line-height: 1.4;
                    "
                    v-html="$t('TXT_PRODUCT_COMPARISON_TOOLTIP')"
                  ></span>
                </v-tooltip>
              </div>
            </v-row>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-row class="ma-0 pa-0 mb-6" justify="space-between">
              <div class="group-box">
                <p class="pa-0 ma-0 mr-5" v-t="'TXT_GROUP_BY'"></p>
                <v-tabs
                  color="black"
                  :slider-color="$prdStyles('color-secundary')"
                  class="group-filters ml-n3"
                  height="25px"
                  v-model="tab"
                >
                  <v-tab
                    class="mr-4"
                    @click="(selectedTab = 1), historicalPrice()"
                  >
                    {{ $t("TXT_PRODUCT") }}
                    <v-tooltip
                      max-width="470px"
                      color="#F5F5F5"
                      style="pointer-events: all"
                      right
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          style="cursor: pointer; pointer-events: all"
                          v-bind="attrs"
                          v-on="on"
                          color="#6792F8"
                          right
                          small
                        >
                          mdi-information-outline</v-icon
                        >
                      </template>
                      <span
                        style="color: #000"
                        v-html="$t('TXT_COMPARISON_TOOLTIP_PRODUCT')"
                      ></span>
                    </v-tooltip>
                  </v-tab>
                  <v-tab
                    class="mr-4"
                    @click="(selectedTab = 2), historicalPrice()"
                  >
                    {{ $t("TXT_RETAILER") }}
                    <v-tooltip
                      max-width="470px"
                      color="#F5F5F5"
                      style="pointer-events: all"
                      right
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          style="cursor: pointer; pointer-events: all"
                          v-bind="attrs"
                          v-on="on"
                          color="#6792F8"
                          right
                          small
                        >
                          mdi-information-outline</v-icon
                        >
                      </template>
                      <span
                        style="color: #000"
                        v-html="$t('TXT_TEMPORAL_TOOLTIP_ORIGIN')"
                      ></span>
                    </v-tooltip>
                  </v-tab>
                  <v-tab @click="(selectedTab = 3), historicalPrice()">
                    {{ $t("TXT_MANUFACTURER") }}
                    <v-tooltip
                      max-width="470px"
                      color="#F5F5F5"
                      style="pointer-events: all"
                      right
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          style="cursor: pointer; pointer-events: all"
                          v-bind="attrs"
                          v-on="on"
                          color="#6792F8"
                          right
                          small
                        >
                          mdi-information-outline</v-icon
                        >
                      </template>
                      <span
                        style="color: #000"
                        v-html="$t('TXT_TEMPORAL_TOOLTIP_RETAILER_2')"
                      ></span>
                    </v-tooltip>
                  </v-tab>
                  <!-- <v-tab
                    v-t="'TXT_PRODUCT_AND_NETWORK'"
                    @click="(selectedTab = 4), historicalPrice()"
                  ></v-tab> -->
                </v-tabs>
              </div>
              <div class="group-box">
                <p class="pa-0 ma-0 teste mr-4">
                  {{ $t("TXT_VISUALIZATION") }}:
                </p>
                <v-tabs
                  color="black"
                  :slider-color="$prdStyles('color-secundary')"
                  class="group-filters ml-n3"
                  height="25px"
                >
                  <v-tab @click="showList = true" v-t="'TXT_LIST'"></v-tab>
                  <v-tab @click="showList = false" v-t="'TXT_CHART'"></v-tab>
                </v-tabs>
              </div>
            </v-row>

            <div v-if="isLoading">
              <v-skeleton-loader
                class="my-6"
                type="image, table-row, text"
              ></v-skeleton-loader>
            </div>

            <!---------------- TABLE ------------------->
            <div v-else>
              <v-tabs-items v-model="tab">
                <div v-if="showList">
                  <!--PRODUCT--->
                  <v-tab-item>
                    <v-data-table
                      :headers="productHeaders"
                      :items="crawlers"
                      :footer-props="footerProps"
                      :hide-default-footer="crawlers.length < 5"
                    >
                    </v-data-table>
                  </v-tab-item>
                  <!--NETWORK--->
                  <v-tab-item>
                    <v-data-table
                      :headers="networkHeaders"
                      :items="crawlers"
                      :footer-props="footerProps"
                      :hide-default-footer="crawlers.length < 5"
                    >
                    </v-data-table>
                  </v-tab-item>
                  <!--NETWORK AND PRODUCT--->
                  <v-tab-item>
                    <v-data-table
                      :headers="networkProductHeaders"
                      :items="crawlers"
                      :footer-props="footerProps"
                      :hide-default-footer="crawlers.length < 5"
                    >
                    </v-data-table>
                  </v-tab-item>
                  <!--MANUFACTURE--->
                  <v-tab-item>
                    <v-data-table
                      :headers="manufactureHeaders"
                      :items="crawlers"
                      :footer-props="footerProps"
                      :hide-default-footer="crawlers.length < 5"
                    >
                    </v-data-table>
                  </v-tab-item>
                </div>

                <!---------------- GRAPHIC ------------------->
                <div v-else>
                  <!--PRODUCT--->
                  <v-tab-item
                    ><highcharts
                      class="hc"
                      :options="chartBoxplotOptions"
                    ></highcharts>
                  </v-tab-item>
                  <!--NETWORK--->
                  <v-tab-item>
                    <highcharts
                      class="hc"
                      :options="chartBoxplotOptions"
                    ></highcharts>
                  </v-tab-item>
                  <!--PRODUCT NETWORK--->
                  <v-tab-item>
                    <highcharts
                      class="hc"
                      :options="chartBoxplotOptions"
                    ></highcharts>
                  </v-tab-item>
                  <!--MANUFACTURE--->
                  <v-tab-item>
                    <highcharts
                      class="hc"
                      :options="chartBoxplotOptions"
                    ></highcharts>
                  </v-tab-item>
                </div>
              </v-tabs-items>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
import Highcharts from "highcharts";

// Exporta dados em PNG, JPEG, PDF, SVG, imprime e visualiza tela cheia
import exportingInit from "highcharts/modules/exporting";
exportingInit(Highcharts);

// Exporta dados CSV, XLS, e muda visualização por tabela
import exportData from "highcharts/modules/export-data";
exportData(Highcharts);

import HighchartsMore from "highcharts/highcharts-more";
HighchartsMore(Highcharts);

// When chart have no data
import noData from "highcharts/modules/no-data-to-display";
noData(Highcharts);

import PredimonitorService from "@/service/predimonitor/predimonitor-service";

export default {
  name: "product-comparison",
  props: ["boxplotData"],
  data() {
    return {
      selectedTab: 1,
      tab: null,
      rotateIcon: false,
      showList: true,
      isLoading: true,
      panel: [],
      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },

      chartBoxplotOptions: {
        chart: {
          type: "boxplot", // template do gráfico,
          inverted: true,
        },

        title: {
          text: null,
        },

        legend: {
          enabled: false,
        },
        lang: {
          // variáveis para renomear as ações de exportação de dados
          viewFullscreen: this.$i18n.t("TXT_VISUALIZE_FULLSCREEN"),
          printChart: this.$i18n.t("TXT_PRINT_CHART"),
          downloadCSV: this.$i18n.t("TXT_DOWNLOAD_CSV_FILE"),
          downloadJPEG: this.$i18n.t("TXT_DOWNLOAD_JPEG_IMAGE"),
          downloadPDF: this.$i18n.t("TXT_DOWNLOAD_PDF"),
          downloadPNG: this.$i18n.t("TXT_DOWNLOAD_PNG_IMAGE"),
          downloadSVG: this.$i18n.t("TXT_DOWNLOAD_SVG_IMAGE"),
          downloadXLS: this.$i18n.t("TXT_DOWNLOAD_XLS_FILE"),
          exitFullscreen: this.$i18n.t("TXT_LEAVE_FULLSCREEN"),
          viewData: this.$i18n.t("TXT_DISPLAY_DATA_IN_TABLE"),
          // Msg when have no data
          noData: this.$i18n.t("TXT_NO_DATA_AVAILABLE"),
        },

        // When chart have no data
        noData: {
          style: {
            fontWeight: "bold",
            fontSize: "15px",
            color: "#303030",
          },
        },

        // Eixo dos produtos
        xAxis: {
          categories: [],
          title: {
            text: this.$i18n.t("TXT_PRODUCT"),
          },
        },

        yAxis: {
          title: {
            text: this.$i18n.t("TXT_PRICE"),
          },
          labels: {
            formatter: function () {
              let currency = localStorage.getItem("currency");
              let value = this.value;
              if (!value) return "-";
              let rounded = (Math.trunc(value * 100) / 100).toFixed(2);
              let formated = rounded.replace(".", ",");
              return currency + " " + formated;
            },
            decimals: 2,
          },
          allowDecimals: false,
          crosshair: true,
        },

        tooltip: {
          shared: true,
          valueDecimals: 2,
          valuePrefix: "",
          borderColor: "transparent",
        },

        // Opções para estilizção do boxplot
        plotOptions: {
          boxplot: {
            fillColor: "#76D680",
            lineWidth: 1,
            lineColor: "#00000048",
            medianColor: "#00000048",
            medianWidth: 4,
            stemColor: "#00000048",
            stemWidth: 2,
            whiskerColor: "#00000048",
            whiskerLength: "25%",
            whiskerWidth: 3,
            maxPointWidth: 35,
          },
        },

        series: [],
      },

      // ------------- TABLES ---------------

      //--------Crawlers-----
      crawlers: [],

      //-------Headers-------
      productHeaders: [
        {
          text: this.$i18n.t("TXT_PRODUCT"),
          align: "center",
          sortable: true,
          value: "productName",
          width: "400px",
        },
        {
          text: this.$i18n.t("TXT_MAXIMUM_PRICE"),
          value: "maxPrice",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_UPPER_QUARTILE"),
          value: "topQuartile",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_MEDIAN"),
          value: "median",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_LOWER_QUARTILE"),
          value: "bottomQuartile",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_MIN_PRICE"),
          value: "minPrice",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_AVERAGE_PRICE"),
          value: "mediaPrice",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_MOST_FREQUENT_PRICE"),
          value: "frequentPrice",
          align: "center",
          sortable: true,
        },
        // {
        //   text: this.$i18n.t("TXT_NUMBER_OF_STORES"),
        //   value: "storeQtde",
        //   align: "center",
        //   sortable: true,
        // },
        // {
        //   text: this.$i18n.t("TXT_NUMBER_OF_NETWORKS"),
        //   value: "networkQtde",
        //   align: "center",
        //   sortable: true,
        // },
      ],
      networkHeaders: [
        {
          text: this.$i18n.t("TXT_RETAILER"),
          align: "center",
          sortable: true,
          value: "productName",
          width: "400px",
        },
        {
          text: this.$i18n.t("TXT_MAXIMUM_PRICE"),
          value: "maxPrice",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_UPPER_QUARTILE"),
          value: "topQuartile",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_MEDIAN"),
          value: "median",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_LOWER_QUARTILE"),
          value: "bottomQuartile",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_MIN_PRICE"),
          value: "minPrice",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_AVERAGE_PRICE"),
          value: "mediaPrice",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_MOST_FREQUENT_PRICE"),
          value: "frequentPrice",
          align: "center",
          sortable: true,
        },
        // {
        //   text: this.$i18n.t("TXT_NUMBER_OF_STORES"),
        //   value: "storeQtde",
        //   align: "center",
        //   sortable: true,
        // },
      ],
      networkProductHeaders: [
        {
          text: this.$i18n.t("TXT_NETWORK"),
          align: "center",
          sortable: true,
          value: "productName",
          width: "400px",
        },
        {
          text: this.$i18n.t("TXT_MAXIMUM_PRICE"),
          value: "maxPrice",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_UPPER_QUARTILE"),
          value: "topQuartile",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_MEDIAN"),
          value: "median",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_LOWER_QUARTILE"),
          value: "bottomQuartile",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_MIN_PRICE"),
          value: "minPrice",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_AVERAGE_PRICE"),
          value: "mediaPrice",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_MOST_FREQUENT_PRICE"),
          value: "frequentPrice",
          align: "center",
          sortable: true,
        },
        // {
        //   text: this.$i18n.t("TXT_NUMBER_OF_STORES"),
        //   value: "storeQtde",
        //   align: "center",
        //   sortable: true,
        // },
      ],
      manufactureHeaders: [
        {
          text: this.$i18n.t("TXT_MANUFACTURER"),
          align: "center",
          sortable: true,
          value: "productName",
          width: "400px",
        },
        {
          text: this.$i18n.t("TXT_MAXIMUM_PRICE"),
          value: "maxPrice",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_UPPER_QUARTILE"),
          value: "topQuartile",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_MEDIAN"),
          value: "median",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_LOWER_QUARTILE"),
          value: "bottomQuartile",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_MIN_PRICE"),
          value: "minPrice",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_AVERAGE_PRICE"),
          value: "mediaPrice",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_MOST_FREQUENT_PRICE"),
          value: "frequentPrice",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_NUMBER_OF_STORES"),
          value: "storeQtde",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_NUMBER_OF_NETWORKS"),
          value: "networkQtde",
          align: "center",
          sortable: true,
        },
      ],
      // -----------------------------------
    };
  },

  computed: {
    currency() {
      return localStorage.getItem("currency") || "R$";
    },
  },

  methods: {
    historicalPrice() {
      this.$emit("onLoadingStarted");
      this.crawlers = [];
      this.chartBoxplotOptions.xAxis.categories = [];
      this.chartBoxplotOptions.series = [];
      this.isLoading = true;

      let data = this.boxplotData;
      data.groupBy = this.selectedTab;

      let predimonitorService = new PredimonitorService();
      predimonitorService
        .BoxPlotGraph(data)
        .then((response) => {
          if (response.data.answer.series[0].data.length != 0) {
            // MONTAGEM DA TABELA
            this.crawlers = response.data.answer.series[0].data.map((serie) => {
              return {
                productName: serie.description,
                maxPrice: this.$options.filters.formatCurrency(serie.max),
                topQuartile: this.$options.filters.formatCurrency(serie.q3),

                median: this.$options.filters.formatCurrency(serie.median),

                bottomQuartile: this.$options.filters.formatCurrency(serie.q1),

                minPrice: this.$options.filters.formatCurrency(serie.min),

                mediaPrice: this.$options.filters.formatCurrency(serie.avg),

                frequentPrice: this.$options.filters.formatCurrency(
                  serie.mostFrequently
                ),
              };
            });

            // MONTAGEM DO GRÁFICO
            this.chartBoxplotOptions.xAxis.categories =
              response.data.answer.categories;

            let outliers = {
              type: "scatter",
              data: response.data.answer.outliers,
              marker: {
                fillColor: "white",
                lineWidth: 1,
                lineColor: Highcharts.getOptions().colors[0],
              },
              tooltip: {
                pointFormat: "Observation: {point.y}",
              },
            };

            this.chartBoxplotOptions.series = response.data.answer.series.map(
              (serie) => ({
                name: serie.name,
                data: serie.data,
              })
            );

            if (this.selectedTab === 3) {
              let sortedData = this.chartBoxplotOptions.series[0].data
                .slice()
                .sort((a, b) => b.avg - a.avg);

              let top10Data = sortedData.slice(0, 10);

              let newCategories =
                this.chartBoxplotOptions.xAxis.categories.filter((cat) => {
                  return top10Data.some((el) => el.description === cat);
                });

              this.chartBoxplotOptions.series[0].data = top10Data;
              this.chartBoxplotOptions.xAxis.categories = newCategories;
            } else {
              this.chartBoxplotOptions.series.push(outliers);
            }

            this.isLoading = false;
          } else {
            this.panel = [];
          }
        })
        .catch((error) => {
          console.log("erro", error);
        })
        .finally(() => {
          this.$emit("onLoadingFinished");
        });
    },

    getTopThreeAverages(data) {
      const averages = data.map((group) => ({
        ...group,
        average: group.avg,
      }));

      const bestAverage = averages.sort((a, b) => b.average - a.average);
      const top10 = bestAverage.slice(0, 10);

      return top10;
    },
  },

  watch: {
    boxplotData() {
      this.historicalPrice();
      this.chartBoxplotOptions.tooltip.valuePrefix = this.currency;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-subtitle {
  font-size: 16px;
  font-weight: 500;
}
.group-box {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

.group-filters {
  border-bottom: 1px solid #00000048;
}

.isActive {
  border-bottom: 4px solid red;
}

.rotateIcon {
  transform: rotate(180deg);
}

.v-tab {
  font-size: 14px !important;
  text-transform: inherit;
  font-weight: 600;
}

.v-tabs {
  width: max-content !important;
}

.v-btn {
  font-weight: 500 !important;
  font-size: 13px;
  letter-spacing: 0.3px;
  text-transform: none;
}
</style>
