 
<template>
  <v-row no-gutters justify="center">
    <div
      class="numbers"
      :style="`height: ${thermometerHeight + 25}px; gap: ${
        thermometerHeight / (scaleThermometer.length * 1.66)
      }px;`"
    >
      <span
        :style="`font-size: ${thermometerHeight / 15}px`"
        v-for="number in scaleThermometer"
        :key="number"
      >
        {{ number + "%" }} -
      </span>
    </div>
    <div
      class="background-thermometer"
      :style="`width: ${thermometerHeight / 8}px; height: ${
        thermometerHeight + 2
      }px; margin-top: ${thermometerHeight / 20}px`"
    ></div>
    <div
      :style="`backgroud-color: light-blue; position: relative; top: ${
        ic <= 75
          ? '-' + thermometerHeight / 50 + 'px'
          : ic >= 125
          ? thermometerHeight - thermometerHeight / 60 + 'px'
          : (ic - minRangeValue) * proportion + 'px'
      }`"
    >
      <span
        class="primary-color font-weight-bold"
        :style="`font-size: ${thermometerHeight / 12}px`"
      >
        <v-icon
          :size="thermometerHeight / 10"
          class="primary-color"
          style="transform: rotate(180deg)"
        >
          mdi-play
        </v-icon>
        {{ ic + "%" }}
      </span>
    </div>
  </v-row>
</template>

<script>
export default {
  props: ["ic", "scaleThermometer", "thermometerHeightProps"],
  data() {
    return {
      minRangeValue: null,
      maxRangeValue: null,
      proportion: null,
    };
  },
  methods: {
    setMinRangeValue() {
      this.minRangeValue = Math.min(...this.scaleThermometer);
    },
    setMaxRangeValue() {
      this.maxRangeValue = Math.max(...this.scaleThermometer);
    },
    setProportion() {
      let interval = this.maxRangeValue - this.minRangeValue;
      this.proportion = this.thermometerHeight / interval;
    },
  },
  created() {
    if (
      this.thermometerHeightProps > 0 ||
      this.thermometerHeightProps != null
    ) {
      this.thermometerHeight = this.thermometerHeightProps;
    } else {
      this.thermometerHeight = 40 * this.scaleThermometer.length;
    }
    this.setMaxRangeValue();
    this.setMinRangeValue();
    this.setProportion();
  },
};
</script>
<style lang="scss" scoped>
.primary-color {
  color: $brand-color-primary-pure;
}
.numbers {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-weight: bold;
}
.background-thermometer {
  background: linear-gradient(
    180deg,
    #b00020 0%,
    #fedf01 20.83%,
    #fedf01 35.94%,
    #009b3a 51.04%,
    #fedf01 66.67%,
    #fedf01 80.73%,
    #b00020 100%
  );
  border-left: 2px solid black;
}
</style>
