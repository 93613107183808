export default {
    state: () => ({
        productsToChart: [],
    }),
    getters: {
        productsToChart: (state) => state.productsToChart,

    },
    mutations: {
        saveProductToChart(state, newProducts) {
            state.productsToChart = newProducts
        },


    },
};
