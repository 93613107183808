<template>
  <v-expansion-panel-content
    id="product-body-predimonitor"
    style="width: 100%; overflow: scroll"
  >
    <v-data-table
      :headers="headers"
      :items="consideredProducts"
      :items-per-page="5"
    >
      <!-- product name -->
      <template v-slot:[`item.product_name`]="{ item }">
        <td class="table-product-name">
          <div class="copy-box">
            <v-icon
              @click.stop="copyFunction(item.product_name)"
              :color="$prdStyles('color-primary')"
              left
              small
              class="copy-button"
              >mdi-content-copy</v-icon
            >
            <a :href="item.product_link" target="_blank">
              {{ item.product_name }}</a
            >
          </div>
        </td>
      </template>

      <!-- Price -->
      <template v-slot:[`item.price`]="{ item }">
        {{ setCurrency(item.price) }}
      </template>

      <!-- Installsment -->
      <template v-slot:[`item.quantity`]="{ item }">
        {{ item.quantity ? item.quantity + "x" : "-" }}
      </template>

      <!-- Indice deflator -->
      <template v-slot:[`item.deflated_value`]="{ item }">
        {{ setCurrency(item.deflated_value) }}
      </template>

      <!-- Crawler date -->
      <template v-slot:[`item.crawler_date`]="{ item }">
        <td class="text-center">
          {{
            item.crawler_date
              ? item.crawler_date
                  .slice(0, 10)
                  .replace("-", "/")
                  .replace("-", "/")
              : null
          }}
        </td>
      </template>

      <!-- tax ST -->
      <template v-slot:[`item.price_tax_st`]="{ item }">
        <td class="text-center">
          {{ item.price_tax_st ? item.price_tax_st : "-" }}
        </td>
      </template>

      <!-- Disregard -->
      <template v-slot:[`item.disregarded`]="{ item }">
        <td class="text-center">
          <v-icon
            v-if="!item.markToDisregard"
            :color="$prdStyles('color-primary')"
            :disabled="isLoading"
            @click="setItem(item)"
            >mdi-checkbox-outline</v-icon
          >
          <v-icon
            v-if="item.markToDisregard"
            :color="$prdStyles('color-primary')"
            :disabled="isLoading"
            @click="setItem(item)"
            >mdi-checkbox-marked</v-icon
          >
        </td>
      </template>
    </v-data-table>

    <v-row v-if="!disablePanel" no-gutters justify="end" class="mt-2">
      <prd-btn
        :title="'Salvar'"
        :disabled="isLoading"
        :loading="isLoading"
        @click="setDisregard"
        class="mr-4"
      />
      <disregarded-products-modal
        :disregardedProducts="disregardedProducts"
        :loading="isLoading"
        @showSnackbar="showSnackbar"
      />
    </v-row>
  </v-expansion-panel-content>
</template>

<script>
import { setCurrency } from "@/utils/format-currency";
import DisregardedProductsModal from "./disregarded-products-modal.vue";
import service from "@/service/predimonitor-V2/index.js";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
const Service = new service();
export default {
  components: { DisregardedProductsModal, PrdBtn },
  props: ["disregardedProducts", "consideredProducts", "disablePanel"],
  data() {
    return {
      isLoading: false,

      headers: [
        {
          text: "Produto",
          align: "center",
          sortable: true,
          value: "product_name",
          width: "300px",
        },
        {
          text: "Loja",
          align: "center",
          sortable: true,
          value: "crawler_name",
        },
        {
          text: "Vendedor",
          align: "center",
          sortable: true,
          value: "seller_name",
        },
        {
          text: "Preço",
          align: "center",
          sortable: true,
          value: "price",
          width: "120px",
        },
        {
          text: "Parcelas",
          align: "center",
          sortable: true,
          value: "quantity",
        },
        {
          text: "Valor ST",
          align: "center",
          sortable: true,
          value: "price_tax_st",
        },
        {
          text: "Indice deflator",
          align: "center",
          sortable: true,
          value: "deflated_value",
          width: "120px",
        },
        {
          text: "Pagamento",
          align: "center",
          sortable: true,
          value: "price_payment_type",
        },
        {
          text: "Coleta",
          align: "center",
          sortable: true,
          value: "crawler_date",
        },
        {
          text: "Desconsiderar",
          align: "center",
          sortable: true,
          value: "disregarded",
        },
      ],
    };
  },

  computed: {
    idMonitoringItem() {
      return this.consideredProducts[0]?.idMonitoringItem ?? null;
    },
  },

  methods: {
    setCurrency,

    copyFunction(text) {
      var textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      setTimeout(() => {}, 1000);
    },

    setDisregard() {
      this.isLoading = true;

      let request = [];

      this.consideredProducts.forEach((el) => {
        if (el.markToDisregard) {
          request.push({
            disregarded: true,
            idMonitoringItem: this.idMonitoringItem,
            productLink: el.product_link,
            productSeller: el.seller_name,
          });
        }
      });

      Service.disregardItem(request)
        .then(() => {
          this.consideredProducts.forEach((el) => {
            if (el.markToDisregard) {
              el.disregarded = true;
            }
          });

          this.$emit(
            "showSnackbar",
            "Produtos atualizados com sucesso!",
            "success"
          );

          this.isLoading = false;
        })
        .catch((error) => {
          console.log("Erro ao desconsiderar", error);
          this.$emit("showSnackbar", "Erro ao atualizar produtos!", "error");

          this.isLoading = false;
        });
    },

    setItem(item) {
      this.consideredProducts.forEach((el) => {
        if (
          el.crawler_name == item.crawler_name &&
          el.product_link == item.product_link &&
          el.product_name == item.product_name &&
          el.seller_name == item.seller_name
        ) {
          el.markToDisregard = !el.markToDisregard;
        }
      });
    },

    showSnackbar(text, color) {
      this.$emit("showSnackbar", text, color);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-product-name {
  max-width: 350px !important;
  padding: 0.8rem !important;
}
.copy-box {
  max-height: 40px !important;
  overflow: hidden !important;
}
</style>