var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel-content',{staticStyle:{"width":"100%","overflow":"scroll"},attrs:{"id":"product-body-predimonitor"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.consideredProducts,"items-per-page":5},scopedSlots:_vm._u([{key:"item.product_name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"table-product-name"},[_c('div',{staticClass:"copy-box"},[_c('v-icon',{staticClass:"copy-button",attrs:{"color":_vm.$prdStyles('color-primary'),"left":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.copyFunction(item.product_name)}}},[_vm._v("mdi-content-copy")]),_c('a',{attrs:{"href":item.product_link,"target":"_blank"}},[_vm._v(" "+_vm._s(item.product_name))])],1)])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrency(item.price))+" ")]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantity ? item.quantity + "x" : "-")+" ")]}},{key:"item.deflated_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrency(item.deflated_value))+" ")]}},{key:"item.crawler_date",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.crawler_date ? item.crawler_date .slice(0, 10) .replace("-", "/") .replace("-", "/") : null)+" ")])]}},{key:"item.price_tax_st",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.price_tax_st ? item.price_tax_st : "-")+" ")])]}},{key:"item.disregarded",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[(!item.markToDisregard)?_c('v-icon',{attrs:{"color":_vm.$prdStyles('color-primary'),"disabled":_vm.isLoading},on:{"click":function($event){return _vm.setItem(item)}}},[_vm._v("mdi-checkbox-outline")]):_vm._e(),(item.markToDisregard)?_c('v-icon',{attrs:{"color":_vm.$prdStyles('color-primary'),"disabled":_vm.isLoading},on:{"click":function($event){return _vm.setItem(item)}}},[_vm._v("mdi-checkbox-marked")]):_vm._e()],1)]}}],null,true)}),(!_vm.disablePanel)?_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":"","justify":"end"}},[_c('prd-btn',{staticClass:"mr-4",attrs:{"title":'Salvar',"disabled":_vm.isLoading,"loading":_vm.isLoading},on:{"click":_vm.setDisregard}}),_c('disregarded-products-modal',{attrs:{"disregardedProducts":_vm.disregardedProducts,"loading":_vm.isLoading},on:{"showSnackbar":_vm.showSnackbar}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }