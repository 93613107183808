<template>
  <v-expansion-panel :disabled="disableCard" :class="cardStatus">
    <product-panel-header
      :product="product"
      :index="index"
      @openPanel="isPanelOpen = true"
      @setProductContent="setProductContent = true"
      @headerPricing="headerPricing = $event"
      @setLoading="disableCard = $event"
      @attNewPricing="$emit('attNewPricing', $event)"
    />

    <product-panel-content
      v-show="isPanelOpen"
      :product="product"
      :isPanelOpen="isPanelOpen"
      :setProductContent="setProductContent"
      :headerPricing="headerPricing"
      @setProductContent="setProductContent = false"
      ref="product-panel"
    />
  </v-expansion-panel>
</template>

<script>
import productPanelHeader from "./product-panel-header.vue";
import ProductPanelContent from "./product-panel.content/product-panel-content.vue";
export default {
  props: ["product", "index"],
  components: { productPanelHeader, ProductPanelContent },
  data() {
    return {
      isPanelOpen: false,
      setProductContent: false,
      headerPricing: null,
      disableCard: false,
    };
  },

  computed: {
    cardStatus() {
      if (!this.product) return "card-status-error";

      let workFlow = this.product?.workflowApproval?.lastAction?.action ?? null;

      let userType =
        this.product?.workflowApproval?.lastAction?.userType ?? null;

      if (workFlow == 0 && userType == 0) return "card-status-info";
      if (workFlow == 0 && userType == 1) return "card-status-approved";
      if (workFlow == 1) return "card-status-reproved";

      return "card-status-error";
    },
  },
};
</script>

<style lang="scss" scoped>
.card-status-reproved {
  border-left: 6px solid $feedback-color-warning-pure;
}
.card-status-approved {
  border-left: 6px solid $feedback-color-success-pure;
}
.card-status-info {
  border-left: 6px solid $brand-color-primary-pure;
}
.card-status-error {
  border-left: 6px solid $neutral-color-low-light;
}
</style>