import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class CrawlerExecuteService extends ServiceBase {
    async getProductList(request) {
        return await axiosInstance.post(API_URL + "api/V2/Monitoring/ListMonitoringItems", request);
    }

    async executeCrawler(request) {
        return await axiosInstance.post(API_URL + "api/V2/MonitoringBatch/Insert", request);
    }

    async getHistory(idCompany, currentPage, recordsPerPage) {
        return await axiosInstance.get(API_URL + "api/V2/MonitoringBatch/ListMonitoringBatchPaged", { params: { idCompany: idCompany, page: currentPage, recordsPerPage: recordsPerPage } });
    }

    async getProductGroupList(idCompany) {
        return await axiosInstance.get(API_URL + "api/V2/Monitoring/ListMonitoringGroup", { params: { idCompany: idCompany } });
    }

    async setProductGroup(idCompany, idMonitoringGroup) {
        return await axiosInstance.get(API_URL + "api/V2/Monitoring/MonitoringGroup", { params: { idCompany: idCompany, idMonitoringGroup: idMonitoringGroup } });
    }

    async getCrawlers(idCompany) {
        return await axiosInstance.get(API_URL + "api/V2/Company_MonitoringCrawler/ListCompanyCrawlers", { params: { idCompany: idCompany } });
    }

    async executeCrawlerById(idMonitoringBatch) {
        return await axiosInstance.get(API_URL + "api/V2/MonitoringBatch/ExecuteCrawler", { params: { id: idMonitoringBatch } });
    }


}
