<template>
  <v-row no-gutters align="center" justify="space-between" class="gap-4">
    <v-col cols="8">
      <prd-text-field
        v-model="localAgroupmentMicro.nome"
        :title="'Nome'"
        :disabled="itsDisabled"
        :rules="[notHasDuplicatedNamesRule]"
      />
    </v-col>
    <v-col>
      <prd-text-field
        v-model="localAgroupmentMicro.variacao"
        :title="'Variação'"
        :type="'number'"
        :hide-spin-buttons="true"
        :suffix="'%'"
        :disabled="itsDisabled"
      />
    </v-col>
    <v-icon
      v-if="index > 0"
      class="mt-7"
      size="28"
      :color="$prdStyles('color-error')"
      :disabled="itsDisabled"
      @click="$emit('removeAgroupmentMicro')"
      >mdi-trash-can-outline</v-icon
    >
  </v-row>
</template>

<script>
import prdTextField from "@/components/common/prd-text-field.vue";
export default {
  components: { prdTextField },
  props: {
    index: { type: Number, default: null },
    itsDisabled: {
      type: Boolean,
      default: false,
    },
    agroupmentMicro: {
      type: Object,
      default: () => {},
    },
    agroupmentMicroNames: { type: Array, default: () => [] },
  },

  data() {
    return {
      localAgroupmentMicro: { id: null, nome: null, variacao: null },
    };
  },

  computed: {
    hasDuplicatedNames() {

      const hasDuplicatedNames = this.agroupmentMicroNames.find(
        (name) =>
          name?.toLowerCase() == this.localAgroupmentMicro?.nome?.toLowerCase()
      )
        ? true
        : false;

      this.$emit("hasDuplicatedNamesMicroFlag", hasDuplicatedNames);

      return hasDuplicatedNames;
    },
  },

  watch: {
    localAgroupmentMicro: {
      handler(value) {
        this.$emit("updateAgroupmentMicro", value);
      },
      deep: true,
    },
    agroupmentMicro: {
      handler(value) {
        this.localAgroupmentMicro.id = value.id;
        this.localAgroupmentMicro.nome = value.nome;
        this.localAgroupmentMicro.variacao = value.variacao;
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    notHasDuplicatedNamesRule() {
      return !this.hasDuplicatedNames || "Esse nome já está sendo utilizado";
    },
  },
};
</script>