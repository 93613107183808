<template>
  <v-card class="d-flex flex-column gap-2 font-size-12 pa-4" flat>
    <ul class="pa-0">
      <li v-for="(item, index) in mappingInfo" :key="index">
        <p v-if="item.isTitle" class="font-weight-medium mb-2">
          <b>
            {{ getPropertyValue(item.propertyName) }}
          </b>
        </p>
        <span v-if="!item.isTitle" class="font-weight-medium" >{{
          item.fieldName }}</span>
        <span v-if="!item.hideFieldName" style="text-transform: capitalize !important"> : {{ getPropertyValue(item.propertyName) }} </span>
      </li>
    </ul>
  </v-card>
</template>
<script>
// import {
//   formatNumberMoney,
//   formatNumberPercentage,
// } from "@/utils/format-numbers";
export default {
  props: ["productRegion", "productName", "listMapViewInfo"],
  components: {},
  data: () => ({}),
  methods: {
    getPropertyValue(propertyName) {
      return this.productRegion?.price[propertyName];
    },

  },
  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    ic() {
      if (typeof this.productRegion?.price.getAvgPriceMarketResult !== "number")
        return null;
      const price = this.productRegion?.price?.lastSalePrice ?? null;
      if (price === null) return null;
      return (price * 100) / this.productRegion.price.getAvgPriceMarketResult;
    },
    currentmargin() {
      if (this.idCompany == 2857)
        //Feito temporariamente para a Suzano
        return null;
      return this.productRegion?.price?.lastSalePrice_Margin;
    },

    mappingInfo() {
      return this.listMapViewInfo;
    },

    showFilterPropertys() {
      if (this.idCompany == 2857) return true;
      else return false;
    },
  },
  watch: {},
  created() { },
};
</script>
<style lang="scss" scoped>
ul li {
  color: $neutral-color-low-medium;
  list-style: none;
}
</style>
