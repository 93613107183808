<template>
  <v-container fluid class="mt-6 pa-0">
    <div class="my-flex">
      <v-tabs
        color="black"
        slider-color="#3c5ca7"
        background-color="transparent"
        class="group-filters mb-4 mr-4"
        height="25px"
      >
        <v-tab>
          <h4>{{ $t("TXT_COMPETITIVENESS") }}</h4>
        </v-tab>
      </v-tabs>
    </div>
    <graphic-card
      v-if="unlockIcGraphicVEM == true"
      :stateByIdPriceGroup="stateByIdPriceGroup"
      :nameStore="nameStore"
    />
  </v-container>
</template>

<script>
import GraphicCard from "./components/graphic-card.vue";

export default {
  props: ["unlockIcGraphicVEM", "stateByIdPriceGroup", "nameStore"],
  components: {
    GraphicCard,
  },
};
</script>
<style lang="scss" scoped>
.my-flex {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.group-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.281);
  display: flex;
}
</style>