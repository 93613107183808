<template>
  <div v-if="product">
    <v-row no-gutters class="mt-4">
      <v-col cols="6" class="pr-2">
        <hectar-cost
          v-bind:key="this.product.id"
          v-bind:product="this.product"
        />
      </v-col>
      <v-col cols="6" class="pl-2">
        <pricing-cost
          v-bind:key="this.product.id"
          v-bind:product="this.product"
        />
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-4">
      <performance-history
        v-bind:key="this.product.id"
        v-bind:product="this.product"
      />
    </v-row>
  </div>
</template>

<script>
import hectarCost from "./components/hectar-cost.vue";
import PerformanceHistory from "./components/performance-history.vue";
import PricingCost from "./components/pricing-cost.vue";
export default {
  components: { hectarCost, PerformanceHistory, PricingCost },
  props: ["product"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  gap: 16px;
}
</style>