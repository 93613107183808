<template>
  <v-dialog v-model="localItsOpen" persistent width="600">
    <v-card class="pa-4">
      <v-row no-gutters justify="space-between" align="center" class="mb-6">
        <h3>{{ action + ` Agrupamento` }}</h3>
        <v-icon
          @click="resetModal"
          :disabled="itsLoading || itsLoadingAgroupment"
          >mdi-close</v-icon
        >
      </v-row>

      <prd-loading-msg v-if="itsLoadingAgroupment" />

      <div v-else>
        <Prd-text-field
          v-model="agroupment.nomeAgrupamentoMacro"
          :title="'Nome do agrupamento MACRO'"
          class="mb-3"
          :rules="[notHasDuplicatedNamesRule]"
          :disabled="itsLoading"
        />
        <Agroupment-micro-list
          :list="agroupment.agrupamentosMicro"
          :itsDisabled="itsLoading"
          :agroupmentMicroNames="agroupmentMicroNames"
          class="mb-4"
          @addAgroupmentMicro="addAgroupmentMicro"
          @removeAgroupmentMicro="removeAgroupmentMicro"
          @updateAgroupmentMicro="updateAgroupmentMicro"
          @hasDuplicatedNamesMicroFlag="hasDuplicatedNamesMicro = $event"
        />
        <v-row no-gutters align="center" justify="end" class="gap-4">
          <Prd-btn
            :title="'Cancelar'"
            :outlined="true"
            @click="resetModal"
            :disabled="itsLoading"
          />
          <Prd-btn
            :title="action"
            :disabled="lockSaveButton"
            :loading="itsLoading"
            @click="handlerAction"
          />
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";
import AgroupmentMicroList from "./components/agroupment-micro-list.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import UPLService from "@/service/upl";
import PrdLoadingMsg from "@/components/common/prd-loading-msg.vue";
export default {
  components: { AgroupmentMicroList, PrdBtn, PrdTextField, PrdLoadingMsg },
  props: {
    itsOpen: { type: Boolean, default: false },
    agroupmentMacroNames: { type: Array, default: () => [] },
    agroupmentMicroNames: { type: Array, default: () => [] },
    selectedAgroupmentId: { type: String, default: null },
  },

  data() {
    return {
      localItsOpen: false,
      agroupment: {
        nomeAgrupamentoMacro: null,
        agrupamentosMicro: [{ id: null, nome: null, variacao: null }],
      },
      originalAgroupment: null,
      service: new UPLService(),
      itsLoading: false,
      itsLoadingAgroupment: false,
      listOfIdsToRemoveMicroAgroupment: [],
      listOfUpdateMicroAgroupment: [],
      hasDuplicatedNamesMicro: false,
    };
  },

  computed: {
    action() {
      return this.selectedAgroupmentId != null ? "Editar" : "Adicionar";
    },
    lockSaveButton() {
      return (
        this.agroupment.agrupamentosMicro.length == 0 ||
        !this.agroupment.nomeAgrupamentoMacro ||
        !this.agroupment.agrupamentosMicro[0].nome ||
        this.hasDuplicatedNames ||
        this.hasDuplicatedNamesMicro ||
        !this.HasNewChanges
      );
    },

    hasDuplicatedNames() {
      return this.agroupmentMacroNames.find(
        (name) =>
          name?.toLowerCase() ==
          this.agroupment?.nomeAgrupamentoMacro?.toLowerCase()
      ) && this.selectedAgroupmentId == null
        ? true
        : false;
    },

    HasNewChanges() {
      return this.selectedAgroupmentId != null
        ? JSON.stringify(this.agroupment) !=
            JSON.stringify(this.originalAgroupment)
        : true;
    },
  },

  watch: {
    itsOpen: {
      handler(value) {
        this.localItsOpen = value;
        if (value && this.selectedAgroupmentId != null) this.getAgroupment();
      },
      immediate: true,
    },
  },

  methods: {
    addAgroupmentMicro() {
      this.agroupment.agrupamentosMicro.push({
        id: null,
        nome: null,
        variacao: null,
      });
    },
    removeAgroupmentMicro(indexToRemove) {
      this.insertIntoRemoveList(indexToRemove);
      this.agroupment.agrupamentosMicro.splice(indexToRemove, 1);
    },
    insertIntoRemoveList(indexToRemove) {
      const foundedMicro = this.agroupment.agrupamentosMicro.find(
        (obj, index) => index == indexToRemove
      );
      if (foundedMicro?.id)
        this.listOfIdsToRemoveMicroAgroupment.push(foundedMicro.id);
    },
    resetModal() {
      this.agroupment.nomeAgrupamentoMacro = null;
      this.agroupment.agrupamentosMicro = [
        { id: null, nome: null, variacao: null },
      ];
      this.listOfIdsToRemoveMicroAgroupment = [];
      this.listOfUpdateMicroAgroupment = [];
      this.$emit("closeModal");
    },
    updateAgroupmentMicro(index, agroupmentMicro) {
      if (agroupmentMicro.id != null)
        this.insertIntoUpdateList(
          agroupmentMicro,
          this.agroupment.agrupamentosMicro[index]
        );
      this.agroupment.agrupamentosMicro[index].id = agroupmentMicro.id;
      this.agroupment.agrupamentosMicro[index].nome = agroupmentMicro.nome;
      this.agroupment.agrupamentosMicro[index].variacao =
        agroupmentMicro.variacao;
    },

    insertIntoUpdateList(updatedMicro, original) {
      if (
        updatedMicro.nome != original.nome ||
        updatedMicro.variacao != original.variacao
      ) {
        const foundedIndex = this.listOfUpdateMicroAgroupment.findIndex(
          (micro) => micro.id == updatedMicro.id
        );
        if (foundedIndex == -1)
          this.listOfUpdateMicroAgroupment.push(updatedMicro);
        else {
          this.listOfUpdateMicroAgroupment[foundedIndex].nome =
            updatedMicro.nome;
          this.listOfUpdateMicroAgroupment[foundedIndex].variacao =
            updatedMicro.variacao;
        }
      }
    },
    notHasDuplicatedNamesRule() {
      return !this.hasDuplicatedNames || "Esse nome já está sendo utilizado";
    },

    handlerAction() {
      if (this.action == "Adicionar") this.addAgroupment();
      else this.updateAgroupment();
    },

    async addAgroupment() {
      this.itsLoading = true;
      const request = this.prepareRequestforNewAgroupment();
      try {
        await this.service.addAgroupment(request);
        this.$emit("setSnackbarType", 1);
        this.resetModal();
        this.$emit("refreshTargetMarketSegmentationTable");
      } catch (error) {
        console.log(error);
        this.$emit("setSnackbarType", 2);
      } finally {
        this.itsLoading = false;
      }
    },

    prepareRequestforNewAgroupment() {
      let request = JSON.parse(JSON.stringify(this.agroupment));

      request.agrupamentosMicro = request.agrupamentosMicro.map((item) => {
        return {
          nome: item.nome,
          variacao: Number(item.variacao) ?? 0,
        };
      });

      return request;
    },

    async getAgroupment() {
      this.itsLoadingAgroupment = true;
      try {
        const response = await this.service.getAgroupmentById(
          this.selectedAgroupmentId
        );
        this.agroupment = JSON.parse(JSON.stringify(response.data));
        this.originalAgroupment = JSON.parse(JSON.stringify(response.data));
      } catch (error) {
        console.log(error);
      } finally {
        this.itsLoadingAgroupment = false;
      }
    },

    prepareRequestForUpdateAgroupment() {
      let request = {};
      if (
        this.agroupment?.nomeAgrupamentoMacro?.toString() !=
        this.originalAgroupment?.nomeAgrupamentoMacro?.toString()
      )
        request.nomeAgrupamentoMacro =
          this.agroupment.nomeAgrupamentoMacro?.toString();

      const listOfNewMicroAgroupment = this.agroupment.agrupamentosMicro.filter(
        (micro) => micro.id == null
      );
      if (listOfNewMicroAgroupment.length > 0)
        request.listOfNewMicroAgroupment = listOfNewMicroAgroupment.map(
          (newMicro) => {
            return { nome: newMicro.nome, variacao: newMicro.variacao ?? 0 };
          }
        );

      if (this.listOfIdsToRemoveMicroAgroupment.length > 0)
        request.listOfIdsToRemoveMicroAgroupment =
          this.listOfIdsToRemoveMicroAgroupment;

      if (this.listOfUpdateMicroAgroupment.length > 0)
        request.listOfUpdateMicroAgroupment = this.listOfUpdateMicroAgroupment;

      return request;
    },

    async updateAgroupment() {
      this.itsLoading = true;
      const request = this.prepareRequestForUpdateAgroupment();
      try {
        await this.service.updateAgroupment(this.selectedAgroupmentId, request);
        this.$emit("setSnackbarType", 3);
        this.resetModal();
        this.$emit("refreshTargetMarketSegmentationTable");
      } catch (error) {
        console.log(error);
        this.$emit("setSnackbarType", 4);
      } finally {
        this.itsLoading = false;
      }
    },
  },
};
</script>