<template>
  <v-row no-gutters align="center" class="mr-1">
    <p class="ma-0">
      | {{ title }}:
      <span class="font-weight-bold"> {{ value }}</span>
    </p>
    <Prd-tooltip-informations v-if="showtooltip" :text="tooltipContent" />
  </v-row>
</template>

<script>
import PrdTooltipInformations from "@/components/common/prd-tooltip.vue";

export default {
  components: { PrdTooltipInformations },
  props: {
    title: { type: String, default: "Desconhecido" },
    value: { type: String, default: "R$ 00,00" },
    margin: { type: String, default: "0%" },
  },

  computed: {
    tooltipContent() {
      return `<p class="text-subtitle-1 ma-0 font-weight-bold">${this.$i18n.t(
        "TXT_MARGIN"
      )}: ${this.margin}</p>`;
    },
    showtooltip(){
      return this.margin && this.margin != '-'
    }
  },
};
</script>