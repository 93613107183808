<template>
  <v-expansion-panel-header
    :expand-icon="disablePanel ? '' : 'mdi-chevron-down'"
    :class="{ noEvents: disablePanel }"
  >
    <div class="flex-space-between">
      <div class="my-flex">
        <v-badge
          :value="showCopyBadge"
          mode="out-in"
          overlap
          content="Copiado!"
          class="eventsAll"
        >
          <v-icon
            @click.stop="copyFunction(productName.productName)"
            :color="$prdStyles('color-primary')"
            left
            small
            class="copy-button"
            >mdi-content-copy</v-icon
          >
        </v-badge>
        <h4 class="mr-4">{{ productName.productName }}</h4>
        <Add-product-manual-modal
          :productName="productName"
          :idMonitoringItem="idMonitoringItem"
          :dateRange="dateRange"
          :disablePanel="disablePanel"
          @refreshProduct="refreshProduct"
        />
        <v-icon
          v-if="productName.hasError && !productName.isLoading"
          :color="$prdStyles('color-primary')"
          @click.stop="refreshProduct"
          style="pointer-events: all"
          class="ml-2 "
          >mdi-refresh</v-icon
        >
         <Prd-loading-circular v-if="productName.isLoading" class="ml-4"/>
      </div>

      <!-- HEADER ACTIONS -->
      <div class="my-flex font-weight-bold">
        <div class="my-flex mr-4">
          <v-icon color="#37B352" left small>mdi-arrow-up-bold-outline</v-icon>
          {{ setCurrency(maxPrice) }}
        </div>
        <div class="my-flex mr-4">
          <v-icon :color="$prdStyles('color-primary')" left small
            >mdi-minus-circle-outline</v-icon
          >
          {{ setCurrency(avgPrice) }}
        </div>
        <div class="my-flex mr-4">
          <v-icon color="#B00020" left small
            >mdi-arrow-down-bold-outline</v-icon
          >
          {{ setCurrency(minPrice) }}
        </div>
      </div>
    </div>
  </v-expansion-panel-header>
</template>

<script>
import { setCurrency } from "@/utils/format-currency";
import AddProductManualModal from "./add-product-manual/add-product-manual-modal.vue";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";

export default {
  components: { AddProductManualModal, PrdLoadingCircular },
  props: ["product", "productName", "disablePanel", "dateRange", "closePanel"],
  data() {
    return {
      showCopyBadge: false,
    };
  },

  computed: {
    maxPrice() {
      let maxPrice =
        Math.max(
          ...this.product
            .filter((x) => x.on_request == false)
            .map((item) => item.price)
        ) ?? null;

      if (
        !maxPrice ||
        maxPrice == undefined ||
        maxPrice == Infinity ||
        maxPrice == -Infinity
      )
        return null;

      return maxPrice;
    },
    minPrice() {
      let minPrice =
        Math.min(
          ...this.product
            .filter((x) => x.on_request == false)
            .map((item) => item.price)
        ) ?? null;
      if (
        !minPrice ||
        minPrice == undefined ||
        minPrice == Infinity ||
        minPrice == -Infinity
      )
        return null;

      return minPrice;
    },
    avgPrice() {
      const sum =
        this.product
          .filter((x) => x.on_request == false)
          .reduce((total, item) => total + item.price, 0) ?? null;
      const averagePrice =
        sum / this.product.filter((x) => x.on_request == false).length ?? null;
      if (
        !averagePrice ||
        averagePrice == undefined ||
        averagePrice == Infinity ||
        averagePrice == -Infinity
      )
        return null;

      return averagePrice;
    },
    idMonitoringItem() {
      return this.productName?.idMonitoringItem ?? null;
    },
  },
  methods: {
    copyFunction(text) {
      this.showCopyBadge = true;
      var textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);

      setTimeout(() => {
        this.showCopyBadge = false;
      }, 1000);
    },

    refreshProduct() {
      this.$emit("refreshProduct", this.idMonitoringItem);
    },

    setCurrency,
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex !important;
  align-items: center !important;
}

.flex-space-between {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.noEvents {
  pointer-events: none;
  opacity: 0.5 !important;
}

.eventsAll {
  pointer-events: all !important;
}
</style>