<template>
  <highcharts class="hc" :options="charOptions"></highcharts>
</template>

<script>
import Highcharts from "highcharts";

// Exporta dados em PNG, JPEG, PDF, SVG, imprime e visualiza tela cheia
import exportingInit from "highcharts/modules/exporting";
exportingInit(Highcharts);

// Exporta dados CSV, XLS, e muda visualização por tabela
import exportData from "highcharts/modules/export-data";
exportData(Highcharts);

export default {
  props: ['allData', "superiorLimit", "inferiorLimit"],
  data() {
    return {
      charOptions: {
        chart: {
          type: 'scatter'
        },

        lang: {
          // variáveis para renomear as ações de exportação de dados
          viewFullscreen: this.$i18n.t("TXT_VISUALIZE_FULLSCREEN"),
          printChart: this.$i18n.t("TXT_PRINT_CHART"),
          downloadCSV: this.$i18n.t("TXT_DOWNLOAD_CSV_FILE"),
          downloadJPEG: this.$i18n.t("TXT_DOWNLOAD_JPEG_IMAGE"),
          downloadPDF: this.$i18n.t("TXT_DOWNLOAD_PDF"),
          downloadPNG: this.$i18n.t("TXT_DOWNLOAD_PNG_IMAGE"),
          downloadSVG: this.$i18n.t("TXT_DOWNLOAD_SVG_IMAGE"),
          downloadXLS: this.$i18n.t("TXT_DOWNLOAD_XLS_FILE"),
          exitFullscreen: this.$i18n.t("TXT_LEAVE_FULLSCREEN"),
          viewData: this.$i18n.t("TXT_DISPLAY_DATA_IN_TABLE"),
        },

        title: {
          text: ''
        },

        xAxis: {
          title: {
            text: '<b>Data</b>'
          },
          categories: [],
        },

        yAxis: {
          min: 0,
          max: 110,
          title: {
            text: '<b>Margem</b>'
          },
          labels: {
            format: '{value}%'
          },
          plotLines: [{
            value: 0,
            color: 'red',
            dashStyle: 'solid',
            width: 1,
            zIndex: 2,
            label: {
              formatter: function () {
                return `<span style="color: red;"><b>${parseInt(this.options.value)} %</b><span>`;
              },
              align: 'left',
              rotation: 0,
              x: 0,
              y: -5
            }
          }, {
            value: 0,
            color: 'red',
            dashStyle: 'solid',
            width: 1,
            zIndex: 2,
            label: {
              formatter: function () {
                return `<span style="color: red;"><b>${parseInt(this.options.value)} %</b><span>`;
              },
              align: 'left',
              rotation: 0,
              x: 0,
              y: -5
            }
          }]
        },

        tooltip: {
          formatter: function () {
            let name = null
            const foundElement = this.point.series.userOptions.data.find(element => this.point.x === element[0] && this.point.y === element[1]);
            if (foundElement) {
              name = foundElement[2];
            }
            return '<b>Produto</b>: ' + name +
              '<br><b>Margem</b>: ' + this.y.toFixed(2) + '%<br>' +
              '<b>Data</b>: ' + this.x
          }
        },

        plotOptions: {
          scatter: {
            showInLegend: false,
            jitter: {
              x: 0.24,
              y: 0
            },
            marker: {
              radius: 2,
              symbol: 'circle'
            },

          },
          series: []
        }
      }
    }
  },

  methods: {

    formatDate(value) {
      const splitedDateOnT = value.split('T')
      const splitedDateOnDash = splitedDateOnT[0].split('-')
      const formatedDate = `${splitedDateOnDash[2]}/${splitedDateOnDash[1]}/${splitedDateOnDash[0]}`
      return formatedDate
    },
    bodyForSerie() {
      return {
        name: null,
        data: []
      }
    },
    groupByDate(list) {
      const groupedObject = list.reduce((accumulator, object) => {
        const formatedDate = this.formatDate(object.date)
        if (!accumulator[formatedDate]) accumulator[formatedDate] = []
        accumulator[formatedDate].push(object)
        return accumulator
      }, {})
      return groupedObject
    },

    getMarginsAndMinMaxYAxis(list) {
      const newList = JSON.parse(JSON.stringify(list));

      newList.sort((objectA, objectB) => objectA.margin - objectB.margin);

      // const amoutItemsSepared = Math.floor(newList.length / 4);

      // const q1 = newList[amoutItemsSepared].margin

      // const q3 = newList[3 * amoutItemsSepared].margin

      // const iqr = q3 - q1

      // const constant = 2.5


      // const inferiorLimit = q1 - (constant * iqr)
      const inferiorLimitAmortized = this.inferiorLimit < 15 ? 15 : this.inferiorLimit
      this.charOptions.yAxis.plotLines[0].value = inferiorLimitAmortized
      const minValue = newList.reduce((min, object) => (object.margin < min ? object.margin : min), newList[0].margin);
      const realMinValue = Math.min(minValue, inferiorLimitAmortized)
      this.charOptions.yAxis.min = realMinValue
      // this.$emit('inferiorLimit', inferiorLimitAmortized)


      // const superiorLimit = q3 + (constant * iqr)
      const superiorLimitAmortized = this.superiorLimit > 75 ? 75 : this.superiorLimit
      this.charOptions.yAxis.plotLines[1].value = superiorLimitAmortized
      const maxValue = newList.reduce((max, object) => (object.margin > max ? object.margin : max), newList[0].margin);
      const realMaxValue = Math.max(maxValue, superiorLimitAmortized)
      this.charOptions.yAxis.max = realMaxValue + (this.superiorLimit >= 100 ? 30 : 0)
      // this.$emit('superiorLimit', superiorLimitAmortized)

    },

    prepareDataForChart(list) {
      this.getMarginsAndMinMaxYAxis(list)
      this.charOptions.series = []
      this.charOptions.xAxis.categories = []
      const groupedObject = this.groupByDate(list)
      const xAxis = Object.keys(groupedObject)
      xAxis.forEach((element, index) => {
        this.charOptions.xAxis.categories.push(element)
        const newSerieData = this.bodyForSerie()
        newSerieData.name = element
        const data = groupedObject[element].map((data) => { return [index, data.margin, data.description] })
        newSerieData.data = data
        this.charOptions.series.push(newSerieData)
      })
    }
  },

  watch: {
    allData: {
      handler(value) {
        this.prepareDataForChart(value)
      }
    },
    superiorLimit: {
      handler(value) {
        this.charOptions.yAxis.plotLines[1].value = value
      }
    },
    inferiorLimit: {
      handler(value) {
        this.charOptions.yAxis.plotLines[0].value = value
      }
    }

  }

}
</script>
