<template>
  <div>
    <v-expansion-panel class="mx-4 pa-0">
      <v-expansion-panel-header
        append-icon="meid-chevron-down"
        color="#F5F5F5"
        class="py-0 px-4"
      >
        <div class="flex-space-between">
          <!-- PRODUCT NAME -->
          <div
            v-if="hasResource('predimonitor.table.sellerpercent')"
            class="my-flex"
          >
            <v-icon
              @click.stop="copyFunction(product.productName)"
              :color="$prdStyles('color-primary')"
              left
              small
              class="copy-button"
              >mdi-content-copy</v-icon
            >
            <h4>{{ product.productName.replace("/", "-") }}</h4>
          </div>
          <div v-else class="my-flex">
            <v-icon
              @click.stop="copyFunction(product.productName)"
              :color="$prdStyles('color-primary')"
              left
              small
              class="copy-button"
              >mdi-content-copy</v-icon
            >
            <h4>{{ product.productName }}</h4>
          </div>

          <!-- HEADER ACTIONS -->
          <div class="my-flex font-weight-bold">
            <!-- Current Price field -->
            <!-- Not Edit mode -->
            <div class="my-flex mr-4" v-if="!isEditCurrentPrice">
              <span class="mr-2">
                {{ `${$t(currentPriceLabel)} :` }}
                <span>{{ currentPrice | formatCurrency }}</span>
              </span>
              <v-icon
                :color="$prdStyles('color-primary')"
                @click.stop="setCurrentPrice(false, true)"
              >
                mdi-pencil-box
              </v-icon>
            </div>
            <!-- Edit mode -->
            <div class="my-flex mr-4" v-if="isEditCurrentPrice">
              <v-text-field
                v-model="currentPrice"
                dense
                outlined
                hide-details
                style="width: 100px"
                :prefix="currency"
                type="number"
                @click.stop=""
                @keyup.enter="setCurrentPrice(true, true)"
              />
              <v-icon
                @click.stop="setCurrentPrice(true, true)"
                :color="$prdStyles('color-success')"
              >
                mdi-checkbox-marked
              </v-icon>
              <v-icon
                :color="$prdStyles('color-warning')"
                @click.stop="setCurrentPrice(false, true)"
              >
                mdi-alpha-x-box
              </v-icon>
            </div>

            <!-- Competitiveness-Index -->
            <div class="my-flex mr-4" v-if="!isDisableTooltipThermometer">
              <competitiveness-average-tooltip :ic="ic" />
            </div>

            <!-- Higher price -->
            <div class="my-flex mr-4">
              <!-- Higher price -->
              <div
                v-if="hasResource('predimonitor.table.sellerpercent')"
                class="mr-2"
              >
                <span class="d-flex">
                  <span class="mr-1">
                    {{ $t("TXT_AVERAGE_PRICE") + ": "
                    }}{{ product.avgPrice | formatCurrency }}
                  </span>
                  <tooltip-informations
                    :title="$t('TXT_AVERAGE_PRICE_SELECTED_PERIOD')"
                  />
                </span>
              </div>
              <div class="my-flex" v-else>
                <div class="my-flex mr-4">
                  <v-icon color="#37B352" left small
                    >mdi-arrow-up-bold-outline</v-icon
                  >
                  {{ product.maxPrice | formatCurrency }}
                </div>

                <!-- Current Price -->
                <div class="my-flex mr-4">
                  <!-- Current Price -->
                  <div class="my-flex mr-4">
                    <v-icon :color="$prdStyles('color-primary')" left small
                      >mdi-minus-circle-outline</v-icon
                    >
                    {{ product.avgPrice | formatCurrency }}
                  </div>

                  <!-- Lower Price -->
                  <div class="my-flex mr-4">
                    <!-- Lower Price -->
                    <div class="my-flex mr-4">
                      <v-icon color="#B00020" left small
                        >mdi-arrow-down-bold-outline</v-icon
                      >
                      {{ product.minPrice | formatCurrency }}
                    </div>

                    <!-- <div class="my-flex mr-4">
            <v-row no-gutters justify="center" align="center">
              <v-switch
                v-model="temAlerta"
                @click.stop="openModalProductMonitoring(product)"
                :color="$prdStyles('color-primary')"
                dense
                inset
                flat
              ></v-switch>

              <v-icon
                @click.stop="modalProductMonitoring = true"
                :color="$prdStyles('color-primary')"
                >mdi-cog-outline</v-icon
              >
            </v-row>
          </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-expansion-panel-header>

      <v-expansion-panel-content class="pa-0">
        <!-- Table Percentage -->
        <percentage-table
          v-if="hasResource('predimonitor.table.sellerpercent')"
          :product="product"
          class="mt-2"
        />

        <div v-else>
          <!-- TABLE -->
          <v-data-table
            :headers="headers"
            :items="consideredProducts.priceResults"
            :footer-props="footerProps"
            :hide-default-footer="consideredProducts.priceResults.length == 0"
            :no-data-text="$t('TXT_NO_DATA_AVAILABLE')"
            class="elevation-1"
          >
            <!-- product name -->
            <template v-slot:[`item.productName`]="{ item }">
              <td class="table-product-name">
                <div class="copy-box">
                  <v-icon
                    left
                    @click.stop="copyFunction(item.productName)"
                    small
                    :color="$prdStyles('color-primary')"
                    class="copy-button"
                    >mdi-content-copy</v-icon
                  >

                  <a
                    v-if="verifyLink(item.productLink)"
                    :href="item.productLink"
                    target="_blank"
                  >
                    {{ item.productName }}</a
                  >
                  <span v-else>
                    {{ item.productName }}
                  </span>
                </div>
              </td>
            </template>

            <!-- seller name -->
            <template v-slot:[`item.sellerName`]="{ item }">
              <td class="text-center">
                <div
                  style="max-height: 60px; overflow: hidden; text-align: center"
                >
                  {{ item.sellerName }}
                </div>
              </td>
            </template>

            <!-- pricefrom -->
            <template v-slot:[`item.priceFrom`]="{ item }">
              <td class="text-center">
                {{ item.priceFrom | formatCurrency }}
              </td>
            </template>

            <!-- price -->
            <template v-slot:[`item.price`]="{ item }">
              <td class="text-center">
                {{ item.price | formatCurrency }}
              </td>
            </template>

            <!-- Crawler date -->
            <template v-slot:[`item.crawlerDate`]="{ item }">
              <td class="text-center">
                {{ item.crawlerDate.replace("-", "/").replace("-", "/") }}
              </td>
            </template>

            <!-- Ano de fabricação -- Campo obsoleto
            <template
              v-if="hasResource('marketplace.machinery')"
              v-slot:[`item.productManufactureYear`]="{ item }"
            >
              <td class="text-center">
                {{
                  `${
                    item.productManufactureYear
                      ? item.productManufactureYear
                      : "-"
                  }`
                }}
              </td>
            </template> -->

            <!-- Horas de Uso -->
            <template v-slot:[`item.productUsedHours`]="{ item }">
              <td class="text-center">
                {{ item.productUsedHours | setDotEveryThreeCharacters }}
              </td>
            </template>

            <!-- disregarded -->
            <template v-slot:[`item.disregarded`]="{ item }">
              <td class="text-center">
                <v-simple-checkbox
                  v-model="item.disregarded"
                  :ripple="false"
                  @click="disregardItem(item)"
                  color="#3C5CA7"
                ></v-simple-checkbox>
              </td>
            </template>

            <!-- isMonitored -->
            <!-- <template v-slot:[`item.isMonitored`]="{ item }">
            <td class="text-center">
              <v-row justify="center" align="center">
                <v-switch
                  v-model="item.priceAlert"
                  @click.stop="openModalAddTracking(item)"
                  :color="$prdStyles('color-primary')"
                  dense
                  inset
                  flat
                ></v-switch>
  
                <v-icon
                  @click.stop="openModalAddTracking(item, 'cog')"
                  :color="$prdStyles('color-primary')"
                  >mdi-cog-outline</v-icon
                >
              </v-row>
            </td>
          </template> -->

            <!-- price_unit_type -->
            <template v-slot:[`item.price_unit_type`]="{ item }">
              {{ item.price_unit_type | unityToString }}
            </template>

            <!-- productVehicleYearModel -->
            <template v-slot:[`item.productVehicleYearModel`]="{ item }">
              {{
                item.productVehicleYearModel
                  ? item.productVehicleYearModel
                  : "-"
              }}
            </template>

            <!-- productAxles -->
            <template v-slot:[`item.productAxles`]="{ item }">
              {{ item.productAxles ? item.productAxles : "-" }}
            </template>

            <!-- productMileage -->
            <template v-slot:[`item.productMileage`]="{ item }">
              {{
                item.productMileage
                  ? `${formatValues(item.productMileage)} ${
                      item.productMileageSymbol
                    }`
                  : "-"
              }}
            </template>

            <!-- productFuelTankCapacity -->
            <template v-slot:[`item.productFuelTankCapacity`]="{ item }">
              {{
                item.productFuelTankCapacity
                  ? `${formatValues(item.productFuelTankCapacity)} ${
                      item.productFuelTankCapacitySymbol
                    }`
                  : "-"
              }}
            </template>

            <!-- productTruckBodyType -->
            <template v-slot:[`item.productTruckBodyType`]="{ item }">
              {{ item.productTruckBodyType ? item.productTruckBodyType : "-" }}
            </template>

            <!-- productManufacturer -->
            <template v-slot:[`item.productManufacturer`]="{ item }">
              {{ item.productManufacturer ? item.productManufacturer : "-" }}
            </template>
          </v-data-table>

          <!--DESCONSIDERED TABLE -->
          <Disregard-table
            :disregardedProducts="disregardedProducts"
            :product="product"
            :headers="headers"
            :disableSaveButton="disableSaveButton"
            :isLoading="isLoading"
            :isModalOpen="isModalOpen"
            @saveProducts="saveProducts"
            @openModal="isModalOpen = $event"
          />
        </div>
      </v-expansion-panel-content>

      <v-dialog
        :max-width="500"
        persistent
        v-model="modalAddTracking"
        v-if="modalAddTracking"
      >
        <ad-tracking-dialog
          :add="addTrackingParams"
          :idMonitoring="product.idMonitoringItem"
          :isModalOpen="modalAddTracking"
          :resourceGroup="resourceGroup"
          :userList="userList"
          :priceCardLoading="priceCardLoading"
          :listaDeAlertas="addAlert !== null ? addAlert : null"
          @closeModal="closeModalAddTracking"
          @seila="$emit('seila')"
          @showSnackBarItemsSave="$emit('showSnackBarItemsSave', $event)"
          ref="add-monitoring-dialog"
          class="mb-1"
        />
      </v-dialog>

      <v-dialog
        v-model="modalProductMonitoring"
        v-if="modalProductMonitoring"
        :max-width="1100"
        persistent
      >
        <product-monitoring-dialog
          :product="product"
          :resourceGroup="resourceGroup"
          :userList="userList"
          :priceCardLoading="priceCardLoading"
          :listaDeAlertas="
            listaDeAlertas !== null ? listaDeAlertas.products : []
          "
          @closeModal="closeModalProductMonitoring"
          @seila="$emit('seila')"
          @showSnackBarItemsSave="$emit('showSnackBarItemsSave', $event)"
          ref="product-monitoring-dialog"
        />
      </v-dialog>
    </v-expansion-panel>
  </div>
</template>

<script>
import DisregardTable from "./disregard-table.vue";
import PredimonitorService from "@/service/predimonitor/predimonitor-service";
import PriceAlertService from "@/service/predimonitor/price-alert-service";
import ProductMonitoringDialog from "./monitoring/product-monitoring-dialog.vue";
import AdTrackingDialog from "./monitoring/components/ad-tracking-dialog.vue";
import PercentageTable from "./percentage-table.vue";
import TooltipInformations from "@/Design_System/common/tooltip-informations.vue";
import competitivenessAverageTooltip from "../../competitivenes-index/components/competitiveness-average/tooltip/competitiveness-average-tooltip.vue";

export default {
  components: {
    DisregardTable,
    ProductMonitoringDialog,
    AdTrackingDialog,
    PercentageTable,
    TooltipInformations,
    competitivenessAverageTooltip,
  },
  props: [
    "productItem",
    "resourceGroup",
    "userList",
    "priceCardLoading",
    "listaDeAlertas",
    "isDisable",
  ],
  data() {
    return {
      newPredimonitorService: new PredimonitorService(),
      priceAlertService: new PriceAlertService(),
      headers: [],
      incomingHeaders: [
        {
          text: this.$i18n.t("TXT_PRODUCT"),
          align: "center",
          sortable: true,
          value: "productName",
          width: "300px",
        },
        {
          text: this.$i18n.t("TXT_BRAND"),
          align: "center",
          sortable: true,
          value: "productBrand",
          width: "170px",
        },
        {
          text: this.$i18n.t("TXT_RETAILER"),
          align: "center",
          sortable: true,
          value: "marketplace",
          width: "200px",
        },
        {
          text: this.$i18n.t("TXT_NETWORK"),
          align: "center",
          sortable: true,
          value: "sellerName",
          width: "180px",
        },

        {
          text: this.$i18n.t("TXT_PRICE"),
          align: "center",
          sortable: true,
          value: "price",
          width: "120px",
        },

        {
          text: this.$i18n.t("TXT_COLECT_DATE"),
          align: "center",
          sortable: true,
          value: "crawlerDate",
          width: "180px",
        },
        {
          text: this.$i18n.t("TXT_DISREGARD"),
          align: "center",
          sortable: true,
          value: "disregarded",
          width: "180px",
        },
        // {
        //   text: this.$i18n.t("TXT_TO_MONITOR"),
        //   align: "center",
        //   sortable: true,
        //   value: "isMonitored",
        //   width: "180px",
        // },
      ],
      items: [],
      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },
      product: {},

      modalAddTracking: false,
      addTrackingParams: null,
      addAlert: null,
      isLoading: false,
      isModalOpen: false,
      modalProductMonitoring: false,

      temAlerta: false,
      disableSaveButton: true,

      isEditCurrentPrice: false,
      isDisableTooltipThermometer: true,
      currentPrice: null,
      ic: null,
    };
  },

  watch: {
    productItem: {
      handler(newValue) {
        this.product = newValue;

        // let pricesTemp = [];
        // let sum = 0;
        // let max = null;
        // let min = null;
        // this.product.priceResults.forEach((e) => {
        //   if (e.on_request == true || e.disregarded == true) return;

        //   if (min == null || min > e.price) min = e.price;

        //   if (max == null || max < e.price) max = e.price;

        //   sum += e.price;
        //   pricesTemp.push(e);
        // });

        // if (pricesTemp.length > 0) {
        //   this.product.maxPrice = max;
        //   this.product.minPrice = min;
        //   this.product.avgPrice = sum / pricesTemp.length;
        // } else {
        //   this.product.avgPrice = 0;
        //   this.product.minPrice = 0;
        //   this.product.maxPrice = 0;
        // }

        this.currentPrice = null;
        this.ic = null;
        this.isDisableTooltipThermometer = true;
        if (
          this.product.actualPrice == undefined ||
          this.product.actualPrice == null
        ) {
          this.currentPrice = "-";
        } else {
          this.currentPrice = Number(this.product.actualPrice).toFixed(2);
          this.setIC();
          this.isDisableTooltipThermometer = false;
        }
      },
      immediate: true,
      deep: true,
    },

    listaDeAlertas: {
      handler() {
        this.verifyAlert();
      },
      immediate: true,
      deep: true,
    },

    // consideredProducts: {
    //   handler() {
    //     this.consideredProducts.priceResults.forEach((el) => {
    //       if (!this.verifyLink(el.productLink)) {
    //         el.productLink = null;
    //       }
    //     });
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    consideredProducts: function () {
      let products = [];

      let newProducts = Object.assign({}, this.product);
      newProducts.priceResults = [];

      this.product.priceResults.forEach((item) => {
        if (!item.disregarded) {
          newProducts.priceResults.push(Object.assign({}, item));
        }
      });

      products.push(newProducts);
      this.maxMinAvg(products);

      return products[0];
    },

    disregardedProducts: function () {
      let products = [];

      let newProducts = Object.assign({}, this.product);

      newProducts.priceResults = [];

      this.product.priceResults.forEach((item) => {
        if (item.disregarded) {
          newProducts.priceResults.push(Object.assign({}, item));
        }
      });
      products.push(newProducts);

      this.maxMinAvg(products);

      return products[0];
    },

    currency() {
      return localStorage.getItem("currency") || "R$";
    },
    currentPriceLabel() {
      return `TXT_CURRENT_${
        this.$route.query.type?.includes("cost") ? "COST" : "PRICE"
      }`;
    },
  },

  methods: {
    disregardItem() {
      this.disableSaveButton = this.setSaveButton();
    },

    setSaveButton() {
      let originalItems = this.product.priceResults.filter(
        (item) => item.disregarded === false
      );

      let copyItems = this.consideredProducts.priceResults;

      for (let i = 0; i < originalItems.length; i++) {
        if (originalItems[i].disregarded !== copyItems[i].disregarded) {
          return false;
        }
      }

      return true;
    },

    verifyLink(url) {
      const validUrl = require("valid-url");
      return validUrl.isWebUri(url);
    },

    setCurrentPrice(confirmChanges, editMode) {
      if (editMode) {
        this.isEditCurrentPrice = !this.isEditCurrentPrice;
        this.setIC();
      }
      if (confirmChanges) {
        if (this.currentPrice == 0 || this.currentPrice == "-") {
          this.currentPrice = "-";
          this.isDisableTooltipThermometer = true;
        }
        let request = {
          monitoringId: this.product.idMonitoringItem,
          actualPrice: this.currentPrice,
          ic: this.ic,
        };
        this.$emit("actualPriceModified", request);
      }
    },

    setIC() {
      if (this.currentPrice != "-") {
        this.ic = (
          ((this.currentPrice - this.product.avgPrice) /
            this.product.avgPrice) *
            100 +
          100
        ).toFixed(2);
        this.isDisableTooltipThermometer = false;
        this.setCurrentPrice(true, false);
        this.$emit("setGeneralIC");
      }
    },

    openModalProductMonitoring() {
      if (this.temAlerta == true) {
        this.modalProductMonitoring = true;
      } else {
        this.saveChanges("product");
      }
    },

    closeModalProductMonitoring() {
      this.modalProductMonitoring = false;
      this.$emit("seila");
    },

    openModalAddTracking(item, cog) {
      if (cog) {
        this.modalAddTracking = true;
        this.addTrackingParams = item;
        let foundedAdd = this.listaDeAlertas?.adds.find((el) => {
          return (
            el.productLink === item.productLink &&
            el.productSeller === item.sellerName
          );
        });
        this.addAlert = foundedAdd;
      } else {
        if (item.priceAlert == true) {
          this.modalAddTracking = true;
          this.addTrackingParams = item;
          let foundedAdd = this.listaDeAlertas?.adds.find((el) => {
            return (
              el.productLink === item.productLink &&
              el.productSeller === item.sellerName
            );
          });
          this.addAlert = foundedAdd;
        } else {
          this.saveChanges("add");
        }
      }
    },

    closeModalAddTracking() {
      this.addTrackingParams = null;
      this.modalAddTracking = false;
    },

    maxMinAvg(data) {
      for (let i in data) {
        var prices = [];
        if (data[i].priceResults != 0) {
          data[i].priceResults.forEach((el) => {
            if (el.disregarded == false) {
              prices.push(el.price);

              let soma = 0;
              for (let s in prices) {
                soma += prices[s];
              }

              data[i].maxPrice = Math.max(...prices);
              data[i].minPrice = Math.min(...prices);
              data[i].avgPrice = soma / prices.length;
            }
          });
        }
      }
    },

    copyFunction(text) {
      var textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      setTimeout(() => {}, 1000);

      this.$emit("showSnackbar", this.$i18n.t("TXT_COPY"), "info");
    },

    saveProducts(type) {
      this.isLoading = true;
      this.isModalOpen = false;

      let tipo =
        type == "considerar"
          ? this.consideredProducts
          : this.disregardedProducts;

      let data = tipo.priceResults.map((serie) => ({
        idMonitoringItem: this.disregardedProducts.idMonitoringItem,
        productSeller: serie.sellerName,
        productLink: serie.productLink,
        disregarded: serie.disregarded,
      }));

      let newPredimonitorService = new PredimonitorService();
      newPredimonitorService
        .tableResultsDisregarded(data)
        .then((res) => {
          this.disableSaveButton = true;
          if (res.data == "Items salvos com sucesso.") {
            this.$emit(
              "showSnackbar",
              `${this.$i18n.t("TXT_ADD_UPDATE_SUCCESS")}`,
              "success"
            );
            this.$emit("attData");
            this.isLoading = false;
          } else {
            this.$emit(
              "showSnackbar",
              `${this.$i18n.t("TXT_ADD_UPDATE_ERROR")}`,
              "error"
            );
            this.isLoading = false;
            this.isModalOpen = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        })
        .finally(this.$emit("updateGraphics", this.requestData));
    },

    verifyAlert() {
      if (this.product.listaDeAlertas) {
        if (this.product.listaDeAlertas.products.length != 0) {
          this.temAlerta = true;
        } else {
          this.temAlerta = false;
        }
      } else {
        this.temAlerta = false;
      }
    },

    saveChanges(tipo) {
      const type = tipo === "product" ? "products" : "adds";
      let priceAlertService = new PriceAlertService();
      let requests = this.listaDeAlertas[type]
        .filter((el) => el)
        .map((el) => this.buildRequest(el));

      Promise.all(
        requests.map((request) => priceAlertService.savePriceAlert(request))
      )
        .then(() => {
          this.$emit("seila");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    buildRequest(el) {
      let request = {};
      let resourceGroup = [];

      request = {
        idMonitoringItemPriceAlert: el.idMonitoringItemPriceAlert
          ? el.idMonitoringItemPriceAlert
          : 0,
        idMonitoringItem: this.productItem.idMonitoringItem,
        productLink: null,
        productSeller: null,
        variationType: el.variationType.value,
        monitoringType: this.setMonitoringType(el.monitoringType),
        increaseValue: parseFloat(el.increaseValue),
        decreaseValue: parseFloat(el.decreaseValue),
        isParentItem: true,
        userOrResouceGroup: [],
        isDeleted: true,
      };

      el.users?.forEach((item) => {
        let teste = {};
        teste = {
          idUser: item.idResourceGroup ? null : item.idUsuario,
          idResourceGroup: item.idResourceGroup ? item.idResourceGroup : null,
          idMonitoringItemPriceAlert: el.idMonitoringItemPriceAlert
            ? el.idMonitoringItemPriceAlert
            : 0,
          idMonitoringItemPriceAlertUsers: item.idMonitoringItemPriceAlertUsers
            ? item.idMonitoringItemPriceAlertUsers
            : 0,
          isDeleted: true,
        };

        resourceGroup.push(teste);
      });

      request.userOrResouceGroup = resourceGroup;
      return request;
    },

    setMonitoringType(item) {
      switch (item) {
        case "maxPrice":
          return 1;
        case "avgPrice":
          return 2;
        case "minPrice":
          return 3;
        default:
          return null;
      }
    },

    hasResource(value) {
      return this.$store.getters.hasResource(value);
    },

    buildBodyToHeaders(title, value) {
      return {
        text: title,
        align: "center",
        sortable: true,
        value: value,
        width: "120px",
      };
    },

    reorganizeHeaderTables() {
      const addNewColumns = (columns, resourceKey) => {
        if (this.hasResource(resourceKey)) {
          columns.forEach((column) => {
            const newColumn = this.buildBodyToHeaders(
              column.text,
              column.value
            );
            this.incomingHeaders.splice(column.position, 0, newColumn);
          });
        }
      };

      const machineryColumns = [
        {
          text: this.$i18n.t("TXT_VEHICLE_YEAR_MODEL"),
          value: "productVehicleYearModel",
          position: 2,
        },
        {
          text: this.$i18n.t("TXT_USED_HOURS"),
          value: "productUsedHours",
          position: 4,
        },
        {
          text: this.$i18n.t("TXT_PRODUCT_AXLES"),
          value: "productAxles",
          position: 5,
        },
        {
          text: this.$i18n.t("TXT_MILEAGE"),
          value: "productMileage",
          position: 6,
        },
        {
          text: this.$i18n.t("TXT_FUEL_TANK_CAPACITY"),
          value: "productFuelTankCapacity",
          position: 7,
        },
        {
          text: this.$i18n.t("TXT_TRUCK_BODY_TYPE"),
          value: "productTruckBodyType",
          position: 8,
        },
        {
          text: this.$i18n.t("TXT_PRODUCT_MANUFACTURER"),
          value: "productManufacturer",
          position: 9,
        },
      ];

      const predimonitorPriceFrom = [
        {
          text: this.$i18n.t("TXT_PRICE_FROM"),
          value: "priceFrom",
          position: 5,
        },
        {
          text: this.$i18n.t("TXT_PAYMENT"),
          value: "paymentType",
          position: 6,
        },
      ];

      addNewColumns(machineryColumns, "marketplace.machinery");
      addNewColumns(predimonitorPriceFrom, "predimonitor.priceFrom");
    },

    formatValues(value) {
      let valueString = value.toString();

      let parts = valueString.split(".");

      if (parts[0].length > 3) {
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }

      let result = parts.join(",");

      return result;
    },
  },

  created() {
    this.setIC();
    this.reorganizeHeaderTables();
    this.headers = this.incomingHeaders;
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex !important;
  align-items: center !important;
}

.flex-space-between {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.text-center {
  text-align: center !important;
}

.table-product-name {
  max-width: 350px !important;
  padding: 0.8rem !important;
}

.copy-box {
  max-height: 40px !important;
  overflow: hidden !important;
}
</style>