var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","color":"var(--very-light-gray)"}},[_c('v-app-bar-nav-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndDown),expression:"$vuetify.breakpoint.smAndDown"}],on:{"click":function($event){return _vm.showOrHideMenu()}}}),_c('span',{staticClass:"  ml-8"},[_vm._v(" Company: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.idCompany))])]),_c('v-spacer'),[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","plain":"","fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-translate")])],1)]}}])},[_c('v-item-group',{model:{value:(_vm.lang),callback:function ($$v) {_vm.lang=$$v},expression:"lang"}},[_c('v-container',_vm._l((_vm.supportedLanguages),function(item){return _c('v-row',{key:item.code},[_c('v-item',{attrs:{"value":item.code},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"justify-start",attrs:{"color":active ? 'primary' : '',"block":"","elevation":"0"},on:{"click":toggle}},[_c('v-img',{staticClass:"mr-2",attrs:{"height":"12px","max-width":"16px","width":"16px","src":("/images/icons/flags/" + (item.flag) + ".svg")}}),_vm._v(" "+_vm._s(item.text)+" ")],1)]}}],null,true)})],1)}),1)],1)],1)],[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","plain":"","fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-currency-btc")])],1)]}}])},[_c('v-item-group',{model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}},[_c('v-container',_vm._l((_vm.currencyItems),function(item){return _c('v-row',{key:item.code},[_c('v-item',{attrs:{"value":item.code},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"justify-start",attrs:{"color":active ? 'primary' : '',"block":"","elevation":"0"},on:{"click":toggle}},[_vm._v(" "+_vm._s(item.code)+" "+_vm._s(item.text)+" ")])]}}],null,true)})],1)}),1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }