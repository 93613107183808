<template>
  <div>
    <Prd-btn
      :title="`${$t('TXT_EXCLUDE')} ${$t('TXT_PRODUCT')}`"
      :outlined="true"
      :color="$prdStyles('color-error')"
      :icon="'mdi-delete-outline'"
      @click="isOpen = true"
    />
    <v-dialog v-model="isOpen" max-width="800" persistent>
      <v-card flat class="pa-4">
        <v-row no-gutters justify="center">
          <v-icon size="40" :color="$prdStyles('color-error')"
            >mdi-alert-outline</v-icon
          >
        </v-row>
        <h3 class="text-center my-4">
          {{ $t("TXT_CONFIRM_EXCLUDE_PRODUCT").toUpperCase() }}
        </h3>
        <p class="text-center my-4">
          {{ $t("TXT_CONFIRM_EXCLUDE_PRODUCT_QUESTION") }}
        </p>
        <Product-data :product="product" :productsUPL="productsUPL" />
        <Prd-loading-circular v-if="!productsUPL" />
        <p class="text-center red--text mt-4">
          {{ $t("TXT_ALERT_EXCLUDE_PRODUCT") }}
        </p>
        <v-row no-gutters justify="end" class="gap-4">
          <Prd-btn
            :title="$t('TXT_CANCEL')"
            :outlined="true"
            :color="$prdStyles('color-error')"
            :disabled="isLoading"
            @click="isOpen = false"
          />
          <Prd-btn
            :title="$t('TXT_CONFIRM')"
            :color="$prdStyles('color-error')"
            :loading="isLoading"
            @click="deleteProduct"
          />
        </v-row>
      </v-card>
    </v-dialog>
    <!-- MESSAGE COMPONENTS -->
    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.snackbarColor"
      :timeout="2000"
    >
      {{ snackbar.snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import ServiceUPL from "@/service/upl/index";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import ProductData from "./product-data.vue";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";

export default {
  components: { PrdBtn, ProductData, PrdLoadingCircular },
  props: {
    product: {
      type: Object,
    },
    productsUPL: {
      type: Array,
    },
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      service: new ServiceUPL(),
      snackbar: {
        snackbar: false,
        snackbarText: null,
        snackbarColor: "#3C5CA7",
      },
    };
  },
  methods: {
    showSnackbar(text, type) {
      let snackbar = {
        snackbar: true,
        snackbarColor: null,
        snackbarText: null,
      };

      snackbar.snackbarText = text;

      switch (type) {
        case "success":
          snackbar.snackbarColor = "#56a667";
          break;
        case "error":
          snackbar.snackbarColor = "#ec4c37";
          break;
        case "info":
          snackbar.snackbarColor = "#3c5ca7";
      }
      this.snackbar = snackbar;
    },
    deleteProduct() {
      this.isLoading = true;
      let message,
        type = null;
      this.service
        .deleteProduct(this.product.id)
        .then((res) => {
          if (res.status == 204) {
            message = this.$i18n
              .t("TXT_EXCLUDED_PRODUCT_SUCCESS")
              .replace("*NAME*", this.product.nome);
            type = "success";
            this.isOpen = false;
            setTimeout(() => {
              this.$router.go("main/upl-main");
            }, 500);
          } else {
            message = this.$i18n
              .t("TXT_EXCLUDED_PRODUCT_PROBLEM")
              .replace("*NAME*", this.product.nome);
            type = "info";
          }
        })
        .catch((error) => {
          message = this.$i18n
            .t("TXT_EXCLUDED_PRODUCT_ERROR")
            .replace("*NAME*", this.product.nome);
          type = "error";
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.showSnackbar(message, type);

          // this.$store.commit("snackbarV2/set", {
          //   message,
          //   type,
          // });
        });
    },
  },
};
</script>