import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class DashboardBamaqService extends ServiceBase {

  async getMonitoringItems(data) {
    return await axiosInstance.post(API_URL + "api/V2/Monitoring/ListMonitoringItemPaginated", data);
  }

  async editMonitoringItems(data) {
    return await axiosInstance.post(API_URL + "api/V2/Monitoring/MonitoringItem", data);
  }

  async exportTags(idCompany) {
    return await axiosInstance.get(API_URL + "api/V2/Monitoring/ListExport", { params: { idCompany: idCompany }, responseType: 'blob' });
  }

  async importTags(request) {
    return await axiosInstance.post(API_URL + "api/V2/Monitoring/ImportTags", request);
  }

  async getProductGroupList(idCompany) {
    return await axiosInstance.get(API_URL + "api/V2/Monitoring/ListMonitoringGroup", { params: { idCompany: idCompany } });
  }



}