import { render, staticRenderFns } from "./crawler-history.vue?vue&type=template&id=4732141b&scoped=true&"
import script from "./crawler-history.vue?vue&type=script&lang=js&"
export * from "./crawler-history.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4732141b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VExpansionPanels,VRow})
