<template>
  <v-container fluid>
    <v-row no-gutters align="center">
      <titleBreadCrumbsComponent
        :title="title"
        :tooltipText="'Tooltip'"
      />
      <v-spacer />

      <crawler-rotine />

      <prd-btn
        class="mt-10"
        :title="$t('TXT_CONFIGURATION_CRAWLER')"
        :icon="'mdi-robot'"
        :leftIcon="true"
        @click="callAnotherPage"
      />
    </v-row>

    <div class="my-flex">
      <!-- COMPANY LIST -->
      <company-list
        style="width: 50%"
        @invertCard="invertCard = $event"
        @dataCrawlersLinked="dataCrawlersLinked = $event"
        :isDisableFirstCard="invertCard"
      />

      <!-- COMPANY CRAWLERS -->
      <company-crawlers
        style="width: 50%"
        @invertCard="invertCard = $event"
        @showSnackbar="showSnackbar($event.text, $event.type)"
        :dataCrawlersLinked="dataCrawlersLinked"
        @dataCrawlersLinked="dataCrawlersLinked = $event"
        :isDisableSecondCard="!invertCard"
      />
    </div>
    <!-- MESSAGE COMPONENTS -->
    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.snackbarColor"
      :timeout="2000"
    >
      {{ snackbar.snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import titleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import CompanyList from "./components/management-company-list.vue";
import CompanyCrawlers from "./components/management-company-crawlers.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import router from "@/router/index.js";
import CrawlerRotine from "./components/crawler-rotine.vue";

export default {
  name: "crawler-main",
  components: {
    titleBreadCrumbsComponent,
    CompanyList,
    CompanyCrawlers,
    PrdBtn,
    CrawlerRotine,
  },
  data() {
    return {
      invertCard: false,
      title: this.$i18n.t("TXT_MANAGEMENT_CRAWLERS_UPPERCASE"),
      
      dataCrawlersLinked: null,
      // Snackbar vars
      snackbar: {
        snackbar: false,
        snackbarText: null,
        snackbarColor: "#3C5CA7",
      },
    };
  },

  methods: {
    callAnotherPage() {
      router.push("/main/crawler-config");
    },
    showSnackbar(text, type) {
      let snackbar = {
        snackbar: true,
        snackbarColor: null,
        snackbarText: null,
      };

      snackbar.snackbarText = text;

      switch (type) {
        case "success":
          snackbar.snackbarColor = "#56a667";
          break;
        case "error":
          snackbar.snackbarColor = "#ec4c37";
          break;
        case "info":
          snackbar.snackbarColor = "#3c5ca7";
      }
      this.snackbar = snackbar;
    },
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  gap: 24px;
}
</style>