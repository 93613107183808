import { render, staticRenderFns } from "./average-chart-to-pdf.vue?vue&type=template&id=128c2b59&scoped=true&"
import script from "./average-chart-to-pdf.vue?vue&type=script&lang=js&"
export * from "./average-chart-to-pdf.vue?vue&type=script&lang=js&"
import style0 from "./average-chart-to-pdf.vue?vue&type=style&index=0&id=128c2b59&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "128c2b59",
  null
  
)

export default component.exports