<template>
  <v-data-table
    :headers="header"
    :items="items"
    hide-default-footer
    id="competitiveness-index-table"
  >
    <template v-slot:[`item.actualPrice`]="{ item }">
      {{
        item.actualPrice
          ? `${currency} ${Number(item.actualPrice)
              .toFixed(2)
              .replace(".", ",")}`
          : "-"
      }}
    </template>
    <template v-slot:[`item.competitorPrice`]="{ item }">
      {{
        item.competitorPrice
          ? `${currency} ${Number(item.competitorPrice)
              .toFixed(2)
              .replace(".", ",")}`
          : "-"
      }}
    </template>
    <template v-slot:[`item.competitivenessIndex`]="{ item }">
      <td
        :class="`text-center font-weight-bold ${
          item.competitivenessIndex <= 85 || item.competitivenessIndex > 115
            ? 'red'
            : ''
        }
        ${
          (item.competitivenessIndex > 85 && item.competitivenessIndex <= 95) ||
          (item.competitivenessIndex > 105 && item.competitivenessIndex <= 115)
            ? 'yellow'
            : ''
        }
        ${
          item.competitivenessIndex > 95 && item.competitivenessIndex <= 105
            ? 'green'
            : ''
        }
        `"
      >
        {{
          !isNaN(item.competitivenessIndex)
            ? item.competitivenessIndex.replace(".", ",") + " %"
            : "-"
        }}
      </td>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ["selectedProducts"],
  data() {
    return {
      header: [
        {
          value: "nameProduct",
          text: this.$i18n.t("TXT_PRODUCT"),
          align: "center",
        },
        {
          value: "actualPrice",
          text: this.$i18n.t("TXT_CURRENT_PRICE"),
          align: "center",
        },
        {
          value: "competitorPrice",
          text: this.$i18n.t("TXT_COMPETITOR_PRICE"),
          align: "center",
        },
        {
          value: "competitivenessIndex",
          text: this.$i18n.t("TXT_COMPETITIVENESS_INDEX"),
          align: "center",
        },
      ],
      items: [],
    };
  },

  methods: {
    buildBodyToItemTable(
      nameProduct,
      actualPrice,
      competitorPrice,
      competitivenessIndex
    ) {
      return {
        nameProduct: nameProduct,
        actualPrice: actualPrice,
        competitorPrice: competitorPrice,
        competitivenessIndex: competitivenessIndex,
      };
    },

    setIC(currentPrice, averagePrice) {
      return (
        ((currentPrice - averagePrice) / averagePrice) * 100 +
        100
      ).toFixed(2);
    },
  },

  watch: {
    selectedProducts: {
      handler(value) {
        this.items = [];
        if (value.length > 0) {
          value.forEach((element) => {
            this.items.push(
              this.buildBodyToItemTable(
                element.text,
                element.completeData.actualPrice,
                element.completeData.avgPrice,
                this.setIC(
                  element.completeData.actualPrice,
                  element.completeData.avgPrice
                )
              )
            );
          });
        } else {
          this.items.push(
            this.buildBodyToItemTable(
              value.text,
              value.completeData.actualPrice,
              value.completeData.avgPrice,
              this.setIC(
                value.completeData.actualPrice,
                value.completeData.avgPrice
              )
            )
          );
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    currency() {
      return localStorage.getItem("currency") || "R$";
    },
  },
};
</script>

<style lang="scss">
#competitiveness-index-table {
  .green {
    background-color: rgba(29, 133, 39, 0.25) !important;
  }
  .yellow {
    background-color: rgba(255, 200, 0, 0.5) !important;
  }
  .red {
    background-color: rgba(176, 0, 32, 0.25) !important;
  }
}
</style>
