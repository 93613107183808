<template>
  <v-card class="pa-4">
    <v-row no-gutters justify="start">
      <h4 class="mr-1">{{ $t("TXT_HISTORY_COMPETITIVENESS") }}</h4>
      <v-tooltip
        max-width="470px"
        color="#F5F5F5"
        style="pointer-events: all"
        right
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            style="cursor: pointer; pointer-events: all"
            v-bind="attrs"
            v-on="on"
            color="#6792F8"
            right
            small
          >
            mdi-information-outline</v-icon
          >
        </template>
        <span
          style="color: #000; font-size: 14px !important; line-height: 1.4"
          v-html="$t('TXT_HISTORY_GRAPHIC_COMPETITIVENESS')"
        ></span>
      </v-tooltip>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="d-flex align-center">
        <p class="pa-0 ma-0 mr-4">{{ $t("TXT_VISUALIZATION") }}:</p>
        <v-tabs
          color="black"
          :slider-color="$prdStyles('color-secundary')"
          class="ml-n3"
          height="25px"
        >
          <v-tab
            v-for="tab in tabItems"
            :key="tab.tab"
            class="tab-item"
            @click="selectedTab = tab.tab"
            >{{ tab.title }}</v-tab
          >
        </v-tabs>
        <v-spacer />
        <prd-combo-box
          v-model="selectedProducts"
          :title="$t('TXT_PRODUCTS')"
          :useTooltipInformation="false"
          :placeholder="$t('TXT_ALL')"
          :items="allProducts"
        />
      </v-col>
    </v-row>
    <v-row no-gutters justify="center">
      <history-graphic
        v-show="selectedTab == 1"
        :xAxisCategories="xAxisCategories"
        :selectedProducts="selectedProductsForGraphic"
      />
      <list-history
        v-show="selectedTab == 2"
        class="mt-4"
        :selectedProducts="selectedProductsForGraphic"
      />
    </v-row>
  </v-card>
</template>
  
<script>
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";
import ListHistory from "./components/list-history.vue";
import HistoryGraphic from "./components/history-graphic.vue";
export default {
  components: { PrdComboBox, ListHistory, HistoryGraphic },
  props: ["selectedPeriod", "products"],
  data() {
    return {
      selectedTab: 1,
      tabItems: [
        {
          title: this.$i18n.t("TXT_GRAPHIC"),
          tab: 1,
        },
        {
          title: this.$i18n.t("TXT_LIST"),
          tab: 2,
        },
      ],
      xAxisCategories: [],
      allProducts: [],
      selectedProducts: null,
      selectedProductsForGraphic: null,
    };
  },

  methods: {
    getAllDate(dates) {
      let listDates = [];
      const startDate = new Date(dates.startDate);
      const endDate = new Date(dates.endDate);
      if (startDate > endDate) {
        return;
      }
      while (startDate <= endDate) {
        listDates.push(startDate.toISOString().split("T")[0]);
        startDate.setDate(startDate.getDate() + 1);
      }
      return listDates;
    },

    buildBodyToComboBox(name, data, completeData) {
      return { text: name, name: name, data: data, completeData: completeData };
    },

    buildDataToGraphic(actualPrice, historyPrices) {
      let createdArray = [];
      this.xAxisCategories.forEach((element) => {
        const items = historyPrices.filter(
          (el) =>
            el.crawlerDate.split("T")[0] == element && el.disregarded == false
        );
        if (items.length > 0) {
          const averagePrice =
            items.reduce((accumulator, value) => {
              return accumulator + value.price;
            }, 0) / items.length;
          const ic = (
            ((actualPrice - averagePrice) / averagePrice) * 100 +
            100
          ).toFixed(2);
          createdArray.push(Number(ic));
        } else {
          createdArray.push(null);
        }
      });
      return createdArray;
    },

    setSelectedProduct(value) {
      if (value == null || value.data == "no data") {
        this.selectedProductsForGraphic = this.allProducts.filter(
          (element) => element.data != "no data"
        );
      } else {
        this.selectedProductsForGraphic = value;
      }
    },
  },

  watch: {
    selectedPeriod: {
      handler(value) {
        this.setSelectedProduct(null);
        this.xAxisCategories = [];
        this.xAxisCategories = this.getAllDate(value);
      },
      immediate: true,
      deep: true,
    },
    products: {
      handler(value) {
        this.selectedProductsForGraphic = null;
        this.allProducts = [];
        this.allProducts.push(
          this.buildBodyToComboBox(
            this.$i18n.t("TXT_ALL"),
            "no data",
            "no data"
          )
        );
        value.forEach((element) => {
          if (element.actualPrice != "-") {
            this.allProducts.push(
              this.buildBodyToComboBox(
                element.productName,
                this.buildDataToGraphic(
                  element.actualPrice,
                  element.priceResults
                ),
                element
              )
            );
          }
        });
        this.setSelectedProduct(this.selectedProducts);
      },
      immediate: true,
      deep: true,
    },

    selectedProducts: {
      handler(value) {
        if (value) {
          if (value.name != "All") {
            this.$emit("selectedProductIC", value.completeData.ic);
          } else {
            this.$emit("selectedProductIC", 0);
          }
        }
        this.setSelectedProduct(value);
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
  
  <style scoped>
.tab-item {
  color: #000;
  font-size: 16px !important;
  text-transform: capitalize;
}
</style>