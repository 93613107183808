<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="localData"
      hide-default-header
      :hide-default-footer="localData == [] || localData.length == 0"
      :footer-props="footerProps"
      :no-data-text="$t('TXT_NO_DATA_AVAILABLE')"
    >
      <template v-slot:header>
        <td
          v-for="(header, index) in headers"
          :key="index"
          class="original-header-table text-center"
        >
          {{ header.text }}
          <tooltip-informations :title="header.tooltipText" />
        </td>
      </template>

      <template v-slot:[`item.frequency`]="{ item }">
        <span>{{ item.frequency | roundUp }}</span>
      </template>

      <template v-slot:[`item.support`]="{ item }">
        <span>{{ item.support | supportFilter }}</span>
      </template>
    </v-data-table>
  </div>
</template> 

<script>
import TooltipInformations from "@/Design_System/common/tooltip-informations.vue";
export default {
  components: { TooltipInformations },
  props: ["dataTable"],
  data() {
    return {
      localData: [],
      headers: [
        {
          text: this.$i18n.t("TXT_PRODUCT"),
          value: "mix",
          align: "center",
          tooltipText: this.$i18n.t("TXT_TOOLTIP_PRODUCT_MIX"),
        },
        {
          text: this.$i18n.t("TXT_FREQUENCY"),
          value: "frequency",
          align: "center",
          tooltipText: this.$i18n.t("TXT_TOOLTIP_FREQUENCY_MIX"),
        },

        {
          text: this.$i18n.t("TXT_SUPPORT"),
          value: "support",
          align: "center",
          tooltipText: this.$i18n.t("TXT_TOOLTIP_SUPPORT_MIX"),
        },
      ],
      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },
    };
  },
  filters: {
    roundUp(value) {
      return Math.ceil(value);
    },
    supportFilter(value) {
      if (!value || value == 0 || value == undefined) return "-";
      let newValue = value * 100;
      const splitedValue = newValue.toString().split(".");
      const integerPart = splitedValue[0];
      let formattedValue = null;
      if (splitedValue[1]) {
        const partialPart = splitedValue[1].split("").slice(0, 2).join("");
        formattedValue = integerPart + "," + partialPart + " %";
      } else {
        formattedValue = integerPart + ",00 %";
      }
      return formattedValue;
    },
  },
  watch: {
    dataTable: {
      handler(value) {
        this.localData = [];
        if (value) this.localData = JSON.parse(JSON.stringify(value));
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.original-header-table {
  background-color: rgba(224, 224, 224, 255);
  padding: 12px;
  font-weight: bold;
  font-size: 13px;
  border-right: 2px solid white;
}
</style>