<template>
  <!--desktop sidebar-->
  <v-navigation-drawer
    v-if="$vuetify.breakpoint.smAndUp"
    style="min-width: 88px"
    v-model="drawer"
    :mini-variant.sync="mini"
    expand-on-hover
    permanent
    app
    id="sidebar"
    color="var(--dark-moderate-blue)"
  >
    <v-list-item class="pa-0 mt-0" style="height: 88px">
      <v-list-item class="pa-1" style="max-width: 88px">
        <v-img
          class="my-0 mx-auto"
          style="max-width: 46px"
          src="../../assets/seta-logo.png"
        ></v-img>
      </v-list-item>
      <v-list-item class="pa-0">
        <v-img
          style="max-width: 126px"
          src="../../assets/name-logo.png"
        ></v-img>
      </v-list-item>
    </v-list-item>

    <v-list dense>
      <div v-for="item in items" :key="item.title">
        <v-list-item
          class="ma-1"
          link
          :to="item.linkTo"
          :style="{ color: 'var(--white)' }"
        >
          <v-list-item-icon center class="my-0 mx-auto pl-2 pr-3 py-3">
            <font-awesome-icon
              :icon="item.icon"
              :style="{ color: 'var(--white)' }"
            />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </v-navigation-drawer>

  <!--mobile sidebar-->
  <v-navigation-drawer
    v-model="mobile"
    v-else-if="$vuetify.breakpoint.smAndDown"
    style="min-width: 86vw"
    temporary
    absolute
    app
    color="var(--dark-moderate-blue)"
  >
    <v-list-item class="pa-0 mt-0" style="height: 88px">
      <v-list-item class="pa-1" style="max-width: 88px">
        <v-img
          class="my-0 mx-auto"
          style="max-width: 46px"
          src="../../assets/logo_predify_icon.png"
        ></v-img>
      </v-list-item>
      <v-list-item class="pa-0">
        <v-img
          style="max-width: 126px"
          src="../../assets/name-logo.png"
        ></v-img>
      </v-list-item>
      <v-btn icon>
        <v-icon :color="'var(--white)'" @click="hideMenu()"
          >mdi-chevron-left</v-icon
        >
      </v-btn>
    </v-list-item>
    <v-list dense>
      <v-list-item
        class="ma-1"
        v-for="item in items"
        :key="item.title"
        link
        :to="item.linkTo"
        :style="{ color: 'var(--white)' }"
      >
        <v-list-item-icon center class="my-0 mx-auto pl-2 pr-3 py-3">
          <font-awesome-icon
            :icon="item.icon"
            :style="{ color: 'var(--white)' }"
          />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "appBarComponent",
  props: {
    drawerMobile: Boolean,
  },
  data: () => ({
    items: [
      {
        title: "Home",
        icon: "fa-solid fa-house-user",
        linkTo: "/main/home",
        hasCompany: true,
        empresa: [],
      },
      {
        title: "Selecionar empresa",
        icon: "fa-solid fa-building",
        linkTo: "/main/company-select",
        hasCompany: false,
        empresa: [],
      },
      {
        title: "PrediMonitor",
        icon: "fa-solid fa-chart-line",
        linkTo: "/main/predimonitor",
        hasCompany: true,
        empresa: [],
      },
      {
        title: "PrediMonitor V2",
        icon: "fa-solid fa-chart-line",
        linkTo: "/main/predimonitor-V2",
        hasCompany: true,
        empresa: [],
      },
      {
        title: "Precificação IA",
        icon: "fa-solid fa-money-bill-trend-up",
        linkTo: "/main/pricing/ai-pricing",
        hasCompany: true,
        empresa: [],
      },
      {
        title: "Pricer",
        icon: "fa-solid fa-money-bill-trend-up",
        linkTo: "/main/pricer",
        hasCompany: true,
        empresa: [],
      },
      {
        title: "Ai pricing-fujioka",
        icon: "fa-solid fa-money-bill-trend-up",
        linkTo: "/main/ai-pricing-fujioka",
      },
      {
        title: "Executar crawler manualmente",
        icon: "fa-solid fa-wrench",
        linkTo: "/main/crawler-execute",
        hasCompany: false,
        empresa: [],
      },

      {
        title: "Análise de churn",
        icon: "fa-solid fa-clipboard-list",
        linkTo: "/main/churn-analysis",
        hasCompany: true,
        empresa: [],
      },

      {
        title: "Predifut",
        icon: "fa-solid fa-futbol",
        linkTo: "/main/predifut/select-game",
        hasCompany: true,
        empresa: [2454],
      },
      {
        title: "UPL - One Page",
        icon: "fa-solid fa-hotel",
        linkTo: "/main/upl-main",
        hasCompany: true,
        empresa: [2858],
      },
      {
        title: "UPL - Deliberation",
        icon: "fa-solid fa-business-time",
        linkTo: "/main/deliberation-main",
        hasCompany: true,
        empresa: [2858],
      },
      {
        title: "Configuração de crawlers",
        icon: "fa-solid fa-robot",
        linkTo: "/main/crawler-config",
      },
      {
        title: "Mix de Produtos - Apriori",
        icon: "fa-solid fa-table-columns",
        linkTo: "/main/mix-products-apriori",
        hasCompany: true,
        empresa: [],
      },

      {
        title: "Logout",
        icon: "fa-solid fa-user-gear",
        linkTo: "/login/sign-out",
        hasCompany: false,
        empresa: [],
      },
    ],
    mini: true,
    drawer: true,
    mobile: false,
  }),
  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },
  watch: {
    drawerMobile(newvalue) {
      this.mobile = newvalue;
    },
  },
  methods: {
    hideMenu() {
      this.$emit("showOrHideMenu");
    },
  },
};
</script>

<style scoped lang="scss">
#sidebar ::-webkit-scrollbar {
  width: 0 !important;
  margin-right: 80px;
}
</style>
