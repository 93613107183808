<template>
  <div>
    <Prd-btn
      :title="$t('TXT_SAVE')"
      @click="isOpen = true"
      :disabled="isDisabled"
    />
    <v-dialog v-model="isOpen" max-width="800" persistent>
      <v-card flat class="pa-4">
        <v-row no-gutters justify="center">
          <v-icon size="40" :color="$prdStyles('color-success')"
            >mdi-check-circle-outline</v-icon
          >
        </v-row>
        <p class="text-center my-4">
          {{
            $t("TXT_CONFIRM_SAVE_ACTION_PRODUCT").replace("*ACTION*", txtAction)
          }}
        </p>
        <h3 class="text-center my-4">
          {{ $t("TXT_CONFIRM_ACTION_PRODUCT").replace("*ACTION*", txtAction2) }}
        </h3>
        <Product-data :product="product" :productsUPL="productsUPL" />
        <Prd-loading-circular v-if="!productsUPL" />
        <p class="text-center mt-4">
          {{ $t("TXT_ALERT_CONFIRM_CHANGE") }}
        </p>
        <v-row no-gutters justify="end" class="gap-4">
          <Prd-btn
            :title="$t('TXT_CANCEL')"
            :outlined="true"
            @click="isOpen = false"
            :disabled="isLoading"
          />
          <Prd-btn
            :title="$t('TXT_CONFIRM')"
            @click="handlerSaveChanges"
            :loading="isLoading"
          />
        </v-row>
      </v-card>
    </v-dialog>
    <!-- MESSAGE COMPONENTS -->
    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.snackbarColor"
      :timeout="2000"
    >
      {{ snackbar.snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import ServiceUPL from "@/service/upl/index";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import ProductData from "./product-data.vue";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import { formatMonetary } from "@/utils/format-toMonetary";
import LoginService from "@/service/login-service";
const loginService = new LoginService();
export default {
  components: { PrdBtn, ProductData, PrdLoadingCircular },
  props: {
    product: {
      type: Object,
    },
    originalProduct: {
      type: Object,
    },
    productsUPL: {
      type: Array,
    },
    isDisabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      service: new ServiceUPL(),
      snackbar: {
        snackbar: false,
        snackbarText: null,
        snackbarColor: "#3C5CA7",
      },
    };
  },
  computed: {
    txtAction() {
      const text = this.product?.id
        ? this.$i18n.t("TXT_TO_ALTER")
        : this.$i18n.t("TXT_ADD");
      return text.toLowerCase();
    },

    txtAction2() {
      const text = this.product?.id
        ? this.$i18n.t("TXT_TO_ALTER_2")
        : this.$i18n.t("TXT_ADD_2");
      return text.toLowerCase();
    },
  },
  methods: {
    showSnackbar(text, type) {
      let snackbar = {
        snackbar: true,
        snackbarColor: null,
        snackbarText: null,
      };

      snackbar.snackbarText = text;

      switch (type) {
        case "success":
          snackbar.snackbarColor = "#56a667";
          break;
        case "error":
          snackbar.snackbarColor = "#ec4c37";
          break;
        case "info":
          snackbar.snackbarColor = "#3c5ca7";
      }
      this.snackbar = snackbar;
    },
    handlerSaveChanges() {
      const request = this.prepareRequest();
      if (request.id) this.saveEditedProduct(request);
      else this.saveNewProduct(request);
    },
    prepareRequest() {
      const type = this.product.tipoPrecificacao;
      const priceBook = this.product.priceBook;

      let request = JSON.parse(JSON.stringify(this.product));
      if (
        type == "Value Price" ||
        type == "Down Price" ||
        type == "Preço de competição" ||
        type == "Oportunidade de Mercado" ||
        type == "Produto Líder"
      ) {
        request.tipoPrecificacao = "Concorrente";
        request.estrategiaPrecificacao = type;
      }

      request.priceBook = priceBook ?? "";
      request.sobre = request.sobre ?? {};
      request.sobre.segmento = request.sobre.segmento ?? "";
      request.sobre.classificacao = request.sobre.classificacao ?? "";
      request.sobre.culturaDriver = request.sobre.culturaDriver ?? "";

      return request;
    },

    async createBodyToUpdateRegister(changeLog) {
      let userName = null;
      let userEmail = null;

      try {
        const response = await loginService.getUserRoles();
        userEmail = response.data.email;
        userName = response.data.nome;
      } catch (error) {
        console.log(error);
        userEmail = "Usuário Desconhecido";
        userName = "Usuário Desconhecido";
      }

      const productId = this.product.id ?? null;
      const productName = this.product.nome;

      return {
        userName,
        userEmail,
        productId,
        productName,
        changeLog,
      };
    },
    setTemplateforChangeLogCap(index, name, oldValue, newValue) {
      const fieldName = {
        doseCompetidor: "Dose",
        nomeCaptura: "Nome",
        mediaCapturaExterna: "Preço da Captura Externa",
        pesoCapturaExterna: "Peso da Captura Externa",
        mediaCapturaInterna: "Preço  da Captura Interna",
        pesoCapturaInterna: "Peso  da Captura Interna",
        precoProdutoUPL: "Preço do produto UPL",
        doseProdutoUPL: "Dose do produto UPL",
        idProdutoUPL: "Produto UPL",
      };
      const findProductUPL = (isOldValue, id) => {
        if (!id) return "";
        return `${isOldValue ? " de " : ""} ${
          this.productsUPL.find((product) => product.id == id).nome ?? ""
        }`;
      };

      return `${fieldName[name]}, da captura ${index + 1}, alterado ${
        name == "mediaCapturaExterna" ||
        name == "mediaCapturaInterna" ||
        name == "precoProdutoUPL"
          ? " de " + formatMonetary(parseFloat(oldValue))
          : name == "idProdutoUPL"
          ? findProductUPL(true, oldValue)
          : oldValue
          ? oldValue
          : ""
      } para ${
        name == "mediaCapturaExterna" ||
        name == "mediaCapturaInterna" ||
        name == "precoProdutoUPL"
          ? formatMonetary(parseFloat(newValue))
          : name == "idProdutoUPL"
          ? findProductUPL(false, newValue)
          : newValue
      }`;
    },

    setTemplateforChangeLog(name, oldValue, newValue) {
      const fieldName = {
        segmento: "Segmento",
        "sobre.classificacao": "Classificação",
        "sobre.culturaDriver": "Cultura Driver",
        marketShare: "Market Share",
        potencialAreaTratada: "Potência da área tratada",
        dose: "Dose",
        posicionamento: "Posicionamento",
        margemContribuicao: "Margem de Contribuição",
        remuneracaoPorCanal: "Remuneração por Canal",
        corredorPreco: "Corredor Preço",
        tipoPrecificacao: "Tipo de Precificação",
        nome: "Nome",
        descricaoUnificada: "Descrição Unificada",
        unitCogs: "Unit cogs",
      };
      return `${fieldName[name]} alterado de ${oldValue} para ${newValue}`;
    },

    async getUpdatedFields() {
      let request = await this.createBodyToUpdateRegister("");

      const isObject = (item) =>
        typeof item === "object" && item !== null && !Array.isArray(item);

      const isDifferent = (value1, value2) =>
        JSON.stringify(value1) !== JSON.stringify(value2);

      const processField = (field, oldValue, newValue) => {
        if (isDifferent(oldValue, newValue)) {
          const newChangeLog = this.createChangeLogEntry(
            field,
            oldValue,
            newValue
          );
          request.changeLog += `${
            request.changeLog == "" ? "" : " | "
          }${newChangeLog}`;
        }
      };

      Object.keys(this.originalProduct).forEach((field) => {
        const originalField = this.originalProduct[field];
        const currentField = this.product[field];

        if (!isObject(originalField) && !Array.isArray(originalField)) {
          processField(field, originalField, currentField);
        } else if (
          Array.isArray(originalField) &&
          Array.isArray(currentField)
        ) {
          currentField.forEach((item, index) => {
            const originalItem = originalField[index];
            if (originalItem) {
              Object.keys(item).forEach((subField) => {
                processField(
                  `${field}[${index}].${subField}`,
                  originalItem[subField],
                  item[subField]
                );
              });
            }
          });
        } else if (isObject(currentField)) {
          Object.keys(currentField).forEach((internalField) => {
            processField(
              `${field}.${internalField}`,
              originalField[internalField],
              currentField[internalField]
            );
          });
        }
      });

      if (
        this.originalProduct.capturas.length != this.product.capturas.length
      ) {
        request.changeLog += `${
          request.changeLog == "" ? "" : " | "
        }${this.getEntryExitCaptures(
          this.originalProduct.capturas,
          this.product.capturas
        )}`;
      }

      return request;
    },

    getEntryExitCaptures(originalCaps, modifiedCaps) {
      let newChangeLogs = "";
      let entry = [];
      let exit = [];

      const setNewChangeLog = (isRemoved, cap) => {
        const action = isRemoved ? "removida" : "adicionada";

        return `Captura ${cap.nomeCaptura} ${action}`;
      };

      if (originalCaps.length > 0) {
        originalCaps.forEach((cap) => {
          const foundedModifiedCap = modifiedCaps.find(
            (modCap) => modCap.id === cap.id
          );
          if (!foundedModifiedCap) exit.push(cap);
        });

        exit.forEach((cap) => {
          newChangeLogs += `${
            newChangeLogs == "" ? "" : " | "
          }${setNewChangeLog(true, cap)}`;
        });
      }

      if (modifiedCaps.length > 0) {
        modifiedCaps.forEach((cap) => {
          const foundedOriginalCap = originalCaps.find(
            (oriCap) => oriCap.id === cap.id
          );
          if (!foundedOriginalCap) entry.push(cap);
        });

        entry.forEach((cap) => {
          newChangeLogs += `${
            newChangeLogs == "" ? "" : " | "
          }${setNewChangeLog(false, cap)}`;
        });
      }
      return newChangeLogs;
    },
    createChangeLogEntry(field, oldValue, newValue) {
      let newEntry = null;
      if (field.includes("capturas")) {
        const index = field.split("[")[1].split("]")[0];
        const capField = field.split(".")[1];
        newEntry = this.setTemplateforChangeLogCap(
          index,
          capField,
          oldValue,
          newValue
        );
      } else {
        newEntry = this.setTemplateforChangeLog(field, oldValue, newValue);
      }
      return newEntry;
    },

    saveEditedProduct(request) {
      this.isLoading = true;
      let message,
        type = null;
      this.service
        .updateProduct(request)
        .then((res) => {
          if (res.status == 204) {
            message = this.$i18n.t("TXT_UPDATED_PRODUCT_SUCCESS");
            type = "success";
            this.saveUpdateHistory();
          } else {
            message = this.$i18n.t("TXT_UPDATED_PRODUCT_PROBLEM");
            type = "info";
          }
        })
        .catch((error) => {
          message = this.$i18n.t("TXT_UPDATED_PRODUCT_ERROR");
          type = "error";
          console.log(error);
          this.isLoading = false;
        })
        .finally(() => {
          this.showSnackbar(message, type);
          // this.$store.commit("snackbarV2/set", {
          //   message,
          //   type,
          // });
        });
    },

    async saveUpdateHistory() {
      this.isLoading = true;
      const request = await this.getUpdatedFields();
      if (!request.changeLog) {
        this.showSnackbar(this.$i18n.t("TXT_UPDATED_HISTORY_ERROR"), "error");

        // this.$store.commit("snackbarV2/set", {
        //   message: this.$i18n.t("TXT_UPDATED_HISTORY_ERROR"),
        //   type: "error",
        // });
        this.isLoading = false;
        return;
      }
      let message,
        type = null;
      try {
        const response = await this.service.saveUpdateHistory(request);
        if (response.status == 201) {
          message = this.$i18n.t("TXT_UPDATED_HISTORY_SUCCESS");
          type = "success";
          setTimeout(() => {
            this.$router.go("main/upl-main");
          }, 500);
        } else {
          message = this.$i18n.t("TXT_UPDATED_HISTORY_PROBLEM");
          type = "info";
        }
      } catch (error) {
        console.log(error);
        message = this.$i18n.t("TXT_UPDATED_HISTORY_ERROR");
        type = "error";
      } finally {
        this.isLoading = false;
        this.showSnackbar(message, type);

        // this.$store.commit("snackbarV2/set", {
        //   message,
        //   type,
        // });
      }
    },
    saveNewProduct(request) {
      this.isLoading = true;
      let message,
        type = null;
      this.service
        .createProduct(request)
        .then((res) => {
          if (res.status == 201) {
            message = this.$i18n.t("TXT_SAVE_PRODUCT_SUCCESS");
            type = "success";
            setTimeout(() => {
              this.$router.go("main/upl-main");
            }, 500);
          } else {
            message = this.$i18n.t("TXT_SAVE_PRODUCT_PROBLEM");
            type = "info";
          }
        })
        .catch((error) => {
          message = this.$i18n.t("TXT_SAVE_PRODUCT_ERROR");
          type = "error";
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.showSnackbar(message, type);
          // this.$store.commit("snackbarV2/set", {
          //   message,
          //   type,
          // });
        });
    },
  },
};
</script>