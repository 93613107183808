<template>
  <v-expansion-panel :class="productBorder(product.semaphore)">
    <v-expansion-panel-header>
      <v-row no-gutters justify="space-between">
        <span class="font-weight-bold">{{ product.displayName }}</span>
        <span class="font-weight-bold mr-4 header-info"
          >{{ $i18n.t("TXT_SUGGESTED_CHANGES") }}: {{ productLength }} |
          {{ $i18n.t("TXT_APPROVEDS") }}: {{ productAproved }}</span
        >
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <Product-group-main :pricingName="product.displayName" :products="product.values" />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import ProductGroupMain from "./product-group-main.vue";

export default {
  components: { ProductGroupMain },
  props: ["product"],

  computed: {
    productLength() {
      return this.product?.values?.length ?? "-";
    },
    productAproved() {
      if (!this.product) return "-";

      let approvedProducts = [];
      this.product.values.forEach((el) => {
        if (el.lastAction == 0) approvedProducts.push(el);
      });

      return approvedProducts.length;
    },
  },

  methods: {
    productBorder(semaphore) {
      switch (semaphore) {
        case 0:
          return "info-products";

        case 1:
          return "approved-products";

        case 2:
          return "manual-products";

        case 3:
          return "negative-products";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-info {
  font-size: 14px;
}

.approved-products {
  border-left: 6px solid $feedback-color-success-pure !important;
}
.negative-products {
  border-left: 6px solid $feedback-color-warning-pure !important;
}
.manual-products {
  border-left: 6px solid $feedback-color-helper-pure !important;
}
.info-products {
  border-left: 6px solid $brand-color-primary-pure !important;
}
</style>