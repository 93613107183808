<template>
  <div>
    <v-card class="pa-4">
      <v-row no-gutters align="center">
        <!-- PRÉ FILTERS -->
        <v-col
          cols="12"
          md="3"
          :class="idCompany == 2858 ? 'mr-3' : 'mr-6'"
          v-if="hasPreFilters"
        >
          <combo-box
            v-model="item.selected"
            :key="item.idEnterprisePriceGroupDefaultFilter"
            :title="$t('TXT_SELECT_A') + item.fieldName"
            :items="item.values"
            :text="'description'"
            :loading="service.getAllFilterGroups.isLoading"
            :disabled="
              loadingProductList ||
              service.getAllFilterGroups.isLoading ||
              service.calculateProducts.isLoading ||
              service.listPriceGroups.isLoading ||
              service.calculateProducts.isLoading ||
              item.values.length == 0
            "
            :multiple="false"
            @input="selectPreFilter($event, item)"
            v-for="item in preFilters"
            hideIcon
          >
          </combo-box>
        </v-col>

        <v-col
          v-if="idCompany == 2858"
          cols="auto"
          class="mr-3"
          align-self="end"
        >
          <date-select
            :disabledIcon="
              loadingProductList ||
              service.getAllFilterGroups.isLoading ||
              service.calculateProducts.isLoading ||
              service.listPriceGroups.isLoading ||
              service.calculateProducts.isLoading
            "
            @selectDate="selectDate = $event"
          />
        </v-col>

        <!-- PRECIFICAÇÃO FILTsER -->
        <v-col cols="12" md="3" class="mr-4">
          <label v-t="'TXT_SELECT_PRICING'"></label>
          <v-combobox
            v-model="selectedPriceGroup"
            :items="priceGroups"
            :loading="service.listPriceGroups.isLoading"
            :disabled="
              loadingProductList ||
              service.listProducts.isLoading ||
              service.calculateProducts.isLoading ||
              !isAllPreFiltersSelected
            "
            item-text="name"
            item-value="filter"
            dense
            outlined
            append-icon="mdi-chevron-down"
            hide-details
            class="mt-2"
          >
            <template v-slot:item="{ item }">
              <v-row
                v-if="
                  idCompany == 3026 ||
                  idCompany == 3210 ||
                  idCompany == 3236 ||
                  idCompany == 3202
                "
                no-gutters
                :class="{ 'light-green lighten-3': item.published }"
                align="center"
                justify="space-between"
                class="pa-3 my-1"
                style="border-radius: 1rem"
              >
                <span>
                  {{ item.name }}
                </span>
                <v-btn icon @click.stop.prevent="checkPrecification(item)">
                  <v-icon :color="$prdStyles('color-primary')">
                    {{
                      item.published
                        ? "mdi-checkbox-marked-outline"
                        : "mdi-checkbox-blank-outline"
                    }}
                  </v-icon>
                </v-btn>
              </v-row>

              <v-row
                v-else-if="idCompany == 2858"
                no-gutters
                justify="space-between"
                align="center"
              >
                <span>
                  {{ item.name }}
                </span>
                <delete-precification :data="item" :idCompany="idCompany" />
              </v-row>
              <v-row v-else no-gutters>
                <span>
                  {{ item.name }}
                </span>
              </v-row>
            </template>
          </v-combobox>
        </v-col>

        <Create-modal-main
          v-if="
            isAllPreFiltersSelected &&
            !service.listPriceGroups.isLoading &&
            !service.getAllFilterGroups.isLoading
          "
          :preSelect="generateLockedFilters"
          :loadingProductList="loadingProductList"
          :selectedPreFilters="selectedPreFilters"
          @calculateFinish="calculateFinish"
          @refreshPriceGroups="fetchPriceGroups"
          ref="createPricingModal"
        />

        <!-- CALCULATE PRICES BTN -->
        <Prd-btn-loading
          v-if="showCalculateToPeralta"
          :title="$t('TXT_RECALCULATE_PRICING_LOWER')"
          :disabled="
            loadingProductList ||
            !selectedPriceGroup ||
            (selectedPriceGroup && selectedPriceGroup.archive) ||
            service.getAllFilterGroups.isLoading ||
            service.calculateProductsRules.isLoading ||
            service.calculateProducts.isLoading ||
            (selectedPriceGroup && selectedPriceGroup.published) ||
            selectedPriceGroup.isProjectionPublished
          "
          :is-loading="
            service.calculateProducts.isLoading ||
            service.calculateProducts.isLoading
          "
          @click="controlRecalculateModal = true"
          outlined
          :class="`mt-7 ${idCompany == 2858 ? 'mr-2' : 'mr-4'}`"
        />

        <!-- CALCULATE RULES BTN -->
        <Prd-btn-loading
          :title="$t('TXT_RECALCULATE_RULES')"
          :disabled="
            loadingProductList ||
            !selectedPriceGroup ||
            service.getAllFilterGroups.isLoading ||
            service.calculateProductsRules.isLoading ||
            service.listPriceGroups.isLoading ||
            service.calculateProductsRules.isLoading ||
            (selectedPriceGroup && selectedPriceGroup.published) ||
            selectedPriceGroup.isProjectionPublished
          "
          :is-loading="
            service.calculateProductsRules.isLoading ||
            service.calculateProductsRules.isLoading
          "
          @click="calculateRules"
          outlined
          class="mt-7"
        />
      </v-row>

      <div class="color-error" v-if="service.getAllFilterGroups.error">
        {{ this.$i18n.t("TXT_ERROR_FIND_PRICING") }}:
        {{ service.getAllFilterGroups.error }}
      </div>

      <v-row v-if="idCompany == 2858" no-gutters align="end" class="mt-4">
        <Variavel-tempo-ia />
      </v-row>

      <h2
        v-t="'TXT_FILTER'"
        class="font-size-16 font-weight-bold mt-6 mb-4"
      ></h2>
      <Filter-groups
        :calcDate="calcDate"
        :isCalculating="isCalculating"
        :idPricingGroup="idEnterprisePriceGroups"
        @input="projectionFilters = $event"
        @openCalcModal="openCalcModal"
        @openPricingLoading="openLoadingModal"
        ref="filterGroup"
      />
    </v-card>
    <Products-list
      ref="produtcsList"
      :idEnterprisePriceGroups="idEnterprisePriceGroups"
      :filters="projectionFilters"
      :idPriceGroup="
        selectedPriceGroup && selectedPriceGroup.idEnterprisePriceGroups
      "
      :dataByRedirect="dataByRedirect"
      :isDisabledManualPrice="
        selectedPriceGroup && selectedPriceGroup.published
      "
      :calcDate="calcDate"
      @loadingProductList="loadingProductList = $event"
    />

    <Precification-modal
      :isOpen="isOpendModalConfirmChanges"
      :isLoading="isLoadingConfirmPublish"
      :precificationData="selectedPrecification"
      @publishPricing="setPublishedPrecification"
      @closeModalPublishedPrecification="closeModalPublishedPrecification"
    />

    <Recalculate-modal
      :recalculateModal="controlRecalculateModal"
      :selectedPriceGroup="selectedPriceGroup"
      @closeRecalculateModal="controlRecalculateModal = false"
      @recalculatePricing="openCalcModal(true)"
    />
  </div>
</template>

<script>
import comboBox from "@/Design_System/common/comboBox";
import PrdBtnLoading from "@/Design_System/common/prd-btn-loading";
import AiPricingService from "@/service/ai-pricing";
import FilterGroups from "./components/filter-groups";
import ProductsList from "./components/products-list/products-list";

import companyService from "@/service/company-service.js";
import PrecificationModal from "./components/precification-modal.vue";
import DateSelect from "./components/date-select.vue";
import DeletePrecification from "./components/delete-precification.vue";
import VariavelTempoIa from "../upl/components/info-product/variavel-tempo-ia.vue";
import CreateModalMain from "./components/create-pricing-modal-v2/create-modal-main.vue";
import RecalculateModal from "./components/create-pricing-modal-v2/recalculate-modal.vue";
const Service = new companyService();

export default {
  components: {
    comboBox,
    FilterGroups,
    PrdBtnLoading,
    ProductsList,
    PrecificationModal,
    DateSelect,
    DeletePrecification,
    VariavelTempoIa,
    CreateModalMain,
    RecalculateModal,
  },
  data: () => ({
    service: new AiPricingService(),

    loadingProductList: false,
    controlRecalculateModal: false,

    // variaveis de pre filtro
    preFilters: [],
    selectedPreFilters: [],

    // variaveis relacionada a pricegroup
    priceGroups: [],
    selectedPriceGroup: null,

    projectionFilters: [],

    // idEnterprisePriceGroups: null,
    isLoadingConfirmPublish: false,
    selectedPrecification: null,
    isOpendModalConfirmChanges: false,
    selectDate: null,
  }),

  computed: {
    idEnterprisePriceGroups() {
      if (this.selectedPriceGroup == null) return null;

      return this.selectedPriceGroup.idEnterprisePriceGroups;
    },
    calcDate() {
      return this.selectedPriceGroup?.calcDate ?? null;
    },
    isCalculating() {
      return this.selectedPriceGroup?.isCalculating ?? null;
    },
    currency() {
      return localStorage.getItem("currency") || "R$";
    },
    hasPreFilters() {
      return this.preFilters.length > 0;
    },

    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    generateLockedFilters() {
      return this.selectedPreFilters;
    },

    showCalculateToPeralta() {
      if (this.isPredifyAdmin) {
        return true;
      }

      if (this.idCompany == 2941) {
        return false;
      }

      return true;
    },

    isAllPreFiltersSelected() {
      for (let i = 0; i < this.preFilters.length; i++) {
        const filter = this.selectedPreFilters.find(
          (e) =>
            e.idEnterprisePriceGroupDefaultFilter ===
            this.preFilters[i].idEnterprisePriceGroupDefaultFilter
        );

        if (filter == null) {
          return false; // Objeto pareado não foi encontrado na lista2
        }
      }
      return true; // Todos os objetos foram pareados
    },

    isPredifyAdmin() {
      return this.$store.getters.isPredifyAdmin;
    },

    dataByRedirect() {
      return this.$route.params.data;
    },
  },

  watch: {
    async selectedPreFilters() {
      if (!this.isAllPreFiltersSelected) return;

      await this.fetchPriceGroups();
    },

    selectedPriceGroup() {
      this.$refs.filterGroup.selectedFilters = [];
      this.$refs.produtcsList.currentPage = 1;
      this.projectionFilters = [];
    },
    selectDate: {
      handler() {
        this.fetchPriceGroups();
      },
    },
  },

  methods: {
    openCalcModal(fromRecalc) {
      if (fromRecalc) this.controlRecalculateModal = false;

      this.$refs.createPricingModal.openModal(
        this.selectedPriceGroup,
        fromRecalc
      );
    },

    async calculateFinish(idEnterprisePriceGroups) {
      await this.fetchPriceGroups();
      let foundedGroup = this.priceGroups.find(
        (el) => (el.idEnterprisePriceGroups = idEnterprisePriceGroups)
      );
      if (foundedGroup) this.selectedPriceGroup = foundedGroup;
    },

    openLoadingModal() {
      this.$refs.createPricingModal.openLoadingModal(this.selectedPriceGroup);
    },

    // calcula o price groups
    async calculateRules() {
      let selectedPricing = this.selectedPriceGroup;

      if (!this.idEnterprisePriceGroups) {
        return this.$store.commit(
          "snackbar-error",
          this.$i18n.t("TXT_SELECT_A_PRICING")
        );
      }

      try {
        await this.service.calculateProductsRules.call(
          this.idCompany,
          this.idEnterprisePriceGroups
        );

        this.$refs.produtcsList.fetchProducts();
        this.$refs.filterGroup.setFilters();
        this.fetchPriceGroups();

        this.selectedPriceGroup = selectedPricing;
      } catch (e) {
        console.error(e);
        return this.$store.commit(
          "snackbar-error",
          this.$i18n.t("TXT_CALCULATE_PRODUCTS_ERROR")
        );
      }
    },

    // função que é ativada na seleção de combobox de pre filtro
    selectPreFilter(event, item) {
      // remove os values já existente de tal filtro
      this.selectedPreFilters = this.selectedPreFilters.filter(
        (x) =>
          x.idEnterprisePriceGroupDefaultFilter !=
          item.idEnterprisePriceGroupDefaultFilter
      );

      // adiciona o novo valor
      this.selectedPreFilters.push({
        idEnterprisePriceGroupDefaultFilter:
          item.idEnterprisePriceGroupDefaultFilter,
        dbField: item.dbField,

        idEnterprisePriceGroupDefaultFilterValue:
          event.idEnterprisePriceGroupDefaultFilterValue,
        idParent: event.idParent,
        description: event.description,
        value: event.value,
      });
    },

    getProductsByRedirect() {
      if (this.dataByRedirect) {
        this.$refs.produtcsList.fetchProducts(this.dataByRedirect);
      }
    },

    // filtros de pré seleção obrigatorio para selecionar o filter group
    async fetchPreFilterGroups() {
      try {
        this.preFilters = await this.service.getAllFilterGroups.call(
          this.idCompany,
          true,
          false,
          true
        );
        if (this.preFilters.length == 0) this.fetchPriceGroups();
      } catch (e) {
        console.error(e);
        this.$store.commit(
          "snackbar-error",
          this.$i18n.t("TXT_ERROR_SEARCHING_PRICING_FILTERS")
        );
      }
    },

    // busca os pricegroups de acordo com os filtros caso haja
    async fetchPriceGroups() {
      try {
        this.selectedPriceGroup = null;
        this.priceGroups = await this.service.listPriceGroups.call(
          this.idCompany,
          this.selectedPreFilters,
          this.selectDate?.start_date,
          this.selectDate?.end_date
        );
      } catch (e) {
        console.error(e);
        this.$store.commit("snackbar-error", "Falha ao buscar as price groups");
      }
    },

    // função de execução inicial
    async init() {
      this.fetchPreFilterGroups();
      this.getProductsByRedirect();
    },

    setCurrency() {
      Service.getCurrency(this.idCompany)
        .then((res) => {
          // console.log("resposta do buscar moeda", res);
          if (res.status != 500) {
            let currency = res.data.currencySymbol;

            if (!currency) {
              localStorage.setItem("currency", "R$");
            } else {
              localStorage.setItem("currency", currency);
            }
          }
        })
        .catch((error) => {
          console.log("Erro no buscar currency", error);
          localStorage.setItem("currency", "R$");
        });
    },

    closeModalPublishedPrecification() {
      this.isOpendModalConfirmChanges = false;
      this.selectedPrecification = null;
      this.isLoadingConfirmPublish = false;
    },

    setPublishedPrecification(idEnterprisePriceGroups) {
      const foundedIndex = this.priceGroups.findIndex(
        (group) => group.idEnterprisePriceGroups === idEnterprisePriceGroups
      );
      if (foundedIndex !== -1) {
        this.isLoadingConfirmPublish = true;

        const request = {
          isSimulation: false,
          idEnterprisePriceGroups: idEnterprisePriceGroups,
          idCompany: this.idCompany,
        };
        try {
          this.service
            .setPublishedPrecification(request)
            .then((res) => {
              if (res.data.result == true) {
                if (foundedIndex !== -1) {
                  this.priceGroups[foundedIndex].published = true;
                }
                this.$store.commit(
                  "snackbar-success",
                  this.$i18n.t("TXT_PUBLISHED_PRECIFICATION_SUCCESS")
                );
              } else {
                this.$store.commit(
                  "snackbar-error",
                  this.$i18n.t("TXT_PUBLISHED_PRECIFICATION_ERROR")
                );
              }
              this.closeModalPublishedPrecification();
            })
            .catch((error) => {
              console.log("Erro do setPublishedPrecification: ", error);
              this.$store.commit(
                "snackbar-error",
                this.$i18n.t("TXT_PUBLISHED_PRECIFICATION_ERROR")
              );
              this.closeModalPublishedPrecification();
            });
        } catch (e) {
          console.log("Erro do setPublishedPrecification: ", e);
          this.$store.commit(
            "snackbar-error",
            this.$i18n.t("TXT_PUBLISHED_PRECIFICATION_ERROR")
          );
          this.closeModalPublishedPrecification();
        }
      }
    },

    checkPrecification(precificationData) {
      if (precificationData.published == false) {
        this.selectedPrecification = precificationData;
        this.isOpendModalConfirmChanges = true;
      } else {
        this.closeModalPublishedPrecification();
        this.$store.commit(
          "snackbar",
          this.$i18n.t("TXT_NOT_POSSIBLE_USE_PRECIFICATION")
        );
      }
    },
  },

  created() {
    this.setCurrency();
  },

  mounted() {
    this.init();
  },
};
</script>
<style lang="scss" scoped>
.full-width {
  width: 100%;
}

.gap-6 {
  column-gap: 24px;
  row-gap: 24px;
}

.flex-3 {
  flex: 3;
}

.flex-2 {
  flex: 2;
}

.color-error {
  color: $brand-color-secondary-pure;
}

.max-content {
  width: max-content;
}
</style>
