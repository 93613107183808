<template>
  <v-card id="similarity-card" class="pa-4" :disabled="disablePanel">
    <v-row no-gutters align="center" class="mb-4">
      <h4>REPLICAR PRECIFICAÇÃO PARA</h4>
      <v-spacer></v-spacer>
      <reply-pricing-modal
        :idPriceGroup="idPriceGroup"
        :tableReply="tableReply"
        :headerPricing="headerPricing"
        :idPriceProjection="idPriceProjection"
        @callReplyTable="$emit('callReplyTable')"
        @showSnackbar="showSnackbar"
      />
    </v-row>

    <v-row v-if="tableReplyLoading" class="mt-10" no-gutters justify="center">
      <v-progress-circular
        indeterminate
        :color="$prdStyles('color-primary')"
      ></v-progress-circular>
    </v-row>

    <v-data-table
      v-if="!tableReplyLoading && tableReply.length != 0"
      :headers="headers"
      :items="tableReply"
      :hide-default-footer="tableReply.length <= 5"
      :items-per-page="5"
      :footer-props="footerProps"
    >
      <!-- Father item -->
      <template v-slot:[`item.productFather`]="{ item }">
        <v-icon v-if="!item.productFather" color="#EC4C37"
          >mdi-close-box-outline</v-icon
        >
        <v-icon v-if="item.productFather" color="#1D8527"
          >mdi-checkbox-marked</v-icon
        >
      </template>

      <!-- Delete item -->
      <template v-slot:[`item.deleteItem`]="{ item }">
        <v-icon
          @click="deleteItem(item)"
          :disabled="item.productFather"
          :color="$prdStyles('color-warning')"
          >mdi-delete-outline</v-icon
        >
      </template>
    </v-data-table>

    <v-row
      v-if="!tableReplyLoading && tableReply.length == 0"
      no-gutters
      justify="center"
      class="mt-6"
    >
      <h4>Nenhum produto encontrado</h4>
    </v-row>
  </v-card>
</template>
<script>
import replyPricingModal from "./reply-pricing-modal.vue";
import service from "@/service/new-ai-pricing/header.js";
import { mapMutations } from "vuex";
const Service = new service();
export default {
  props: [
    "tableReplyLoading",
    "tableReply",
    "idPriceGroup",
    "headerPricing",
    "idPriceProjection",
  ],
  components: { replyPricingModal },
  data() {
    return {
      headers: [
        {
          text: "Artigo",
          align: "center",
          sortable: false,
          value: "idProduct",
        },
        {
          text: "Produto",
          align: "center",
          sortable: false,
          value: "description",
        },
        {
          text: "Replicado",
          align: "center",
          sortable: false,
          value: "productFather",
        },
        {
          text: "Excluir",
          align: "center",
          sortable: false,
          value: "deleteItem",
        },
      ],
      items: [],
      disablePanel: false,
      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },
    };
  },

  methods: {
    ...mapMutations(["updateTeste"]),

    deleteItem(item) {
      this.disablePanel = true;

      let request = {
        idEnterprisePriceProjection: this.idPriceProjection,
        listIdProjectionReplicate: [
          {
            idProjectionReplicate: item.idPriceReplicate,
          },
        ],
      };

      Service.deleteReplyPricing(request)
        .then(() => {
          this.disablePanel = false;
          this.$emit("callReplyTable");
          this.updateTeste([
            request.listIdProjectionReplicate[0].idProjectionReplicate,
          ]);
          this.showSnackbar("Sucesso ao excluir precificação", "success");
        })
        .catch((error) => {
          console.log("erro no deleteItem", error);
          this.$emit("Falha ao excluir precificação", "error");

          this.disablePanel = false;
        });
    },
    showSnackbar(text, type) {
      this.$emit("showSnackbar", text, type);
    },
  },
};
</script>
<style lang="scss">
#similarity-card {
  margin-top: 8px;
  th {
    font-weight: 600 !important;
    color: #000 !important;
    font-size: 11px !important;
    height: 40px !important;
  }
}
</style>