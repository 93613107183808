var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.service.listProducts.state() !== 'NOT_CALLED')?_c('div',{staticClass:"mt-6"},[_c('h3',{directives:[{name:"t",rawName:"v-t",value:('TXT_PRODUCTS_UPPERCASE'),expression:"'TXT_PRODUCTS_UPPERCASE'"}],staticClass:"mb-6"},[(_vm.service.listProducts.state() === 'DATA_READY')?_c('span',[_vm._v(" ("+_vm._s(_vm.service.listProducts.data.totalRecords)+") ")]):_vm._e()]),_c('div',{staticClass:"d-flex align-end justify-space-between gap-4"},[_c('div',[_c('product-color-caption',{attrs:{"colorClass":"blue","text":_vm.idCompany == 3026
            ? _vm.$t('TXT_PRICES_UNCHANGED')
            : _vm.$t('TXT_SEMAPHORE_APPROVAL_REPROVAL'),"tooltipText":""}}),_c('product-color-caption',{attrs:{"colorClass":"green","text":_vm.$t('TXT_SEMAPHORE_ALL_APPROVAL'),"tooltipText":""}}),_c('product-color-caption',{attrs:{"colorClass":"red","text":_vm.$t('TXT_SEMAPHORE_ALL_REPROVAL'),"tooltipText":""}})],1),_c('dialog-pricing',{attrs:{"idPriceGroup":_vm.localIdEnterprisePriceGroups}}),_c('div',{staticClass:"d-flex gap-6 align-end"},[_c('combo-box',{staticClass:"max-width-200",attrs:{"hideIcon":true,"title":_vm.$t('TXT_ORDERING'),"placeholder":_vm.$t('TXT_ALPHABETICAL'),"items":[
          { text: _vm.$t('TXT_ALPHABETICAL'), value: 0 },
          { text: _vm.$t('TXT_MARGIN'), value: 1 },
          { text: _vm.$t('TXT_PROFIT'), value: 2 },
          { text: _vm.$t('TXT_REVENUE'), value: 3 } ],"multiple":false},model:{value:(_vm.selectedOrder),callback:function ($$v) {_vm.selectedOrder=$$v},expression:"selectedOrder"}}),_c('combo-box',{staticClass:"max-width-200",attrs:{"hideIcon":true,"title":_vm.$t('TXT_RESULTS_BY_PAGE'),"placeholder":_vm.$t('TXT_SELECT'),"items":[
          { text: 5, value: 5 },
          { text: 10, value: 10 },
          { text: 20, value: 20 },
          { text: 50, value: 50 },
          { text: 100, value: 100 } ],"multiple":false,"value":_vm.recordsPerPage},on:{"input":function($event){_vm.recordsPerPage = $event.value}}})],1)],1),(_vm.service.listProducts.state() !== 'NOT_CALLED')?_c('loading-div',{staticClass:"mt-6",attrs:{"is-loading":_vm.service.listProducts.isLoading,"error":_vm.service.listProducts.error}},[(_vm.service.listProducts.state() === 'DATA_READY')?_c('v-expansion-panels',{staticClass:"d-flex flex-column"},_vm._l((_vm.service.listProducts.data.records),function(item){return _c('product-item',{key:item.key,attrs:{"product":item,"listMapViewInfo":_vm.listMapViewInfo,"isDisabledManualPrice":_vm.isDisabledManualPrice}})}),1):_vm._e(),(_vm.service.listProducts.state() === 'DATA_READY')?_c('div',{staticClass:"d-flex align-center justify-space-between text-primary"},[_c('div',[_vm._v(" "+_vm._s(this.$i18n.t("TXT_SHOWING"))+": "+_vm._s(_vm.service.listProducts.data.numberOfRecords)+" "+_vm._s(this.$i18n.t("TXT_OF"))+" "+_vm._s(_vm.service.listProducts.data.totalRecords)+" "+_vm._s(this.$i18n.t("TXT_RESULT"))+" ")]),_c('v-pagination',{attrs:{"length":_vm.service.listProducts.data.totalPages,"total-visible":"8"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('div',[_vm._v(" "+_vm._s(this.$i18n.t("TXT_PAGINA"))+" "),_c('span',{staticClass:"px-3 py-2 bordered rounded-lg"},[_vm._v(_vm._s(_vm.service.listProducts.data.currentPage))]),_vm._v(" "+_vm._s(this.$i18n.t("TXT_OF"))+" "+_vm._s(_vm.service.listProducts.data.totalPages)+" ")])],1):_vm._e()],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }