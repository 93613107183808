<template>
  <expandable-card :borderColor="semaphoreColor">
    <template v-slot:header-left>
      <strong>{{ product.key }}</strong>
    </template>
    <template v-slot:header-right>
      <strong>
        {{ $t("TXT_CHANGES_SUGGESTED") }}: {{ product.values.length }} |
        {{ $t("TXT_APPROVEDS") }}:
        {{ $store.getters.getApprovedRegionsCount(product.key) }}
      </strong>
    </template>
    <template v-slot:content>
      <v-expansion-panels>
        <region-item
          v-for="region in product.values"
          :key="
            region.price.idEnterprisePricesProjection +
            '_' +
            region.price.affiliate
          "
          :region="region"
          :productName="product.key"
          :listMapViewInfo="listMapViewInfo"
          :isDisabledManualPrice="isDisabledManualPrice"
        />
      </v-expansion-panels>
    </template>
  </expandable-card>
</template>
<script>
import expandableCard from "@/Design_System/common/expandable-card";
import regionItem from "./region-item/region-item";
import { ActionEnum } from "../../../actions-enum";

export default {
  props: ["product", "listMapViewInfo", "isDisabledManualPrice"],
  components: {
    expandableCard,
    regionItem,
  },
  data: () => ({}),
  methods: {
    updateApprovedProducts() {
      this.product.values.forEach((region) => {
        if (
          region?.workflowApproval?.actions?.length === 0 &&
          region?.workflowApproval?.lastAction?.action === ActionEnum.Approve
        ) {
          this.$store.commit("approveProductRegion", {
            idEnterprisePricesProjection:
              region?.price?.idEnterprisePricesProjection,
            productName: this.product.key,
          });
        }
      });
    },
  },
  computed: {
    semaphoreColor() {
      return ["blue", "green", "yellow", "red"][
        this.product?.getStateSemaphore ?? Infinity
      ];
    },
  },
  mounted() {
    this.updateApprovedProducts();
  },
  watch: {
    product() {
      this.updateApprovedProducts();
    },
  },
};
</script>
<style lang="scss" scoped></style>
