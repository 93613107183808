<template>
  <div>
    <Prd-loading-circular v-show="itsProcessingData" />
    <div v-show="!itsProcessingData" id="mapContainer" ref="hereMap" />
  </div>
</template>

<script>
import { formatMonetary } from "@/utils/format-toMonetary";
import { colors } from "../utils/type-color-names";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import { getBrazilianStateData } from "../utils/brazilian-states";
export default {
  components: {
    PrdLoadingCircular,
  },
  props: {
    setMapCenterFlag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      platform: null,
      apikey: "drwIiJtTyYJnI7XbkxI_CZ5fZJiS9x9anPwVFCytJaE",
      ui: null,
      map: null,
      brazilianCenter: { lat: -15.7783, lng: -47.9291 },
      clusteredDataProviders: [],
    };
  },

  computed: {
    mapPoints() {
      return this.$store?.getters?.getMapPoints ?? [];
    },
    itsProcessingData() {
      return this.$store?.getters?.getItsProcessingData ?? false;
    },
    selectedState() {
      return this.$store?.getters?.getSelectedState ?? null;
    },
    lang() {
      const langByLocalStorage = localStorage.getItem("lang");
      const lang =
        langByLocalStorage != "undefined" ? langByLocalStorage : "pt-BR";
      return lang;
    },
    types() {
      return [].concat(...new Set(this.mapPoints.map((item) => item.type)));
    },
  },

  watch: {
    mapPoints: {
      handler(value) {
        this.setPointsV2(value);
      },
      deep: true,
    },
    setMapCenterFlag: {
      handler() {
        this.setMapCenter();
      },
    },
  },

  methods: {
    initializeHereMap() {
      const platform = new window.H.service.Platform({
        apikey: this.apikey,
      });
      this.platform = platform;
      const mapContainer = this.$refs.hereMap;
      const H = window.H;

      var maptypes = this.platform.createDefaultLayers();

      this.map = new H.Map(mapContainer, maptypes.vector.normal.map, {
        center: this.brazilianCenter,
        zoom: 4.25,
        pixelRatio: window.devicePixelRatio || 1,
      });

      setTimeout(() => this.map.getViewPort().resize(), 100);
      addEventListener("resize", () => this.map.getViewPort().resize());

      new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));

      this.ui = H.ui.UI.createDefault(this.map, maptypes, this.lang);

      const zoomRectangle = new H.ui.ZoomRectangle({
        alignment: H.ui.LayoutAlignment.RIGHT_BOTTOM,
      });

      this.ui.addControl("rectangle", zoomRectangle);
    },

    setPointsV2(list) {
      if (!list || list.length == 0) return;

      this.setMapCenter(this.selectedState);
      this.cleanMap();

      this.types.forEach((type) => {
        const dataPoints = list
          .filter((item) => item.type == type)
          .map(
            (item) =>
              new window.H.clustering.DataPoint(item.lat, item.lng, null, item)
          );

        const clusteredDataProvider = new window.H.clustering.Provider(
          dataPoints,
          {
            clusteringOptions: {
              minWeight: 3,
            },
            theme: {
              getClusterPresentation: (cluster) =>
                this.prepareCluster(cluster, type),

              getNoisePresentation: (noisePoint) =>
                this.prepareMarker(noisePoint),
            },
          }
        );
        this.clusteredDataProviders.push(clusteredDataProvider);

        const layer = new window.H.map.layer.ObjectLayer(clusteredDataProvider);

        this.map.addLayer(layer);
      });
    },

    cleanMap() {
      this.clusteredDataProviders.forEach((provider) => {
        provider.setDataPoints([]);
        // provider.dispose();
      });
      this.clusteredDataProviders = [];
    },

    prepareCluster(cluster, paramType) {
      const color = this.getColor(paramType);
      const weight = cluster.getWeight();
      let radius = Math.min(weight, 100) * 0.18 + 13;

      const clusterSvgTemplate = `<svg xmlns="http://www.w3.org/2000/svg" height="${
        radius * 2
      }" width="${radius * 2}">
                                    <circle cx="${radius}" cy="${radius}" r="${radius}" fill="${color}" opacity="0.25" />
                                    <circle cx="${radius}" cy="${radius}" r="${
        radius - 5
      }" fill="${color}" opacity="0.8" />
                                    <text x="${radius}" y="${
        radius + 3
      }" text-anchor="middle" font-size="${
        radius / 2
      }" fill="white">${weight}</text>
                                  </svg>`;

      const clusterIcon = new window.H.map.Icon(clusterSvgTemplate, {
        size: { w: 50, h: 50 },
        anchor: { x: 25, y: 25 },
      });

      const clusterMarker = new window.H.map.Marker(cluster.getPosition(), {
        icon: clusterIcon,
        min: cluster.getMinZoom(),
        max: cluster.getMaxZoom(),
      });

      clusterMarker.setData(cluster);

      return clusterMarker;
    },

    getColor(paramType) {
      const index = this.types.findIndex((type) => type == paramType);
      const color = colors[index];
      return color;
    },

    prepareMarker(noisePoint) {
      const marker = this.getNewMarker(noisePoint);

      const bubble = this.getInfoBubble(noisePoint.getData());

      marker.addEventListener("tap", () => {
        this.ui.addBubble(bubble);
      });

      marker.addEventListener("pointerenter", () => {
        this.ui.addBubble(bubble);
      });

      marker.addEventListener("pointerleave", () => {
        this.ui.removeBubble(bubble);
      });

      return marker;
    },

    getNewMarker(noisePoint) {
      const data = noisePoint.getData();
      const minZoom = noisePoint.getMinZoom();

      const noiseSvg = `<svg xmlns="http://www.w3.org/2000/svg" height="20px" width="20px"><circle cx="5px" cy="5px" r="5px" fill="${this.getColor(
        data.type
      )}" /></svg>`;

      return new window.H.map.Marker(
        { lat: data.lat, lng: data.lng },
        { icon: new window.H.map.Icon(noiseSvg), min: minZoom }
      );
    },

    getInfoBubble(data) {
      const getContent = () => `
        <div style="width: 230px">

          <h3 style="margin-bottom: 18px">${data.name}</h3>

          <p><span style="font-weight: bold; margin-right: 6px">${this.$i18n.t(
            "TXT_AVERAGE_PRICE"
          )}:</span>${formatMonetary(data.price)}</p>

          <p ${
            data.amountAnnouncement == null || data.amountAnnouncement == 1
              ? 'style="display: none"'
              : ""
          }><span style="font-weight: bold; margin-right: 6px">${this.$i18n.t(
        "TXT_AMOUNT_ADS"
      )}:</span>${data.amountAnnouncement}</p> 

          <p ${
            !data.address ? 'style="display: none"' : ""
          }><span style="font-weight: bold; margin-right: 6px">${this.$i18n.t(
        "TXT_ADDRESS"
      )}:</span>${data.address}</p>   
           
        </div>`;

      const bubble = new window.H.ui.InfoBubble(
        { lat: data.lat, lng: data.lng },
        { content: getContent(data.name) }
      );

      return bubble;
    },

    setMapCenter(state) {
      if (!state) {
        this.map.setCenter(this.brazilianCenter, true);
        this.map.setZoom(4.25, true);
      } else {
        const { lat, lng } = getBrazilianStateData(state);
        this.map.setCenter({ lat, lng }, true);
        this.map.setZoom(6, true);
      }
    },
  },

  mounted() {
    this.initializeHereMap();
  },
};
</script>

<style lang="scss" scoped>
#mapContainer {
  height: 600px;
  width: 100%;
}
</style>