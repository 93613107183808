var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":"","justify":"center"}},[(_vm.subtitle == '' && _vm.name == 'DISCOUNT MATRIX')?_c('DiscountMatrixTable',{attrs:{"name":_vm.name,"subtitle":_vm.subtitle,"headers":_vm.headers,"items":_vm.items},on:{"updateOriginalItemsModified":function($event){return _vm.$emit('updateOriginalItemsModified', $event)},"updateMarketingVariationMatrix":function($event){return _vm.$emit('updateMarketingVariationMatrix', $event)},"setBadgeDiscountMatrix":function($event){return _vm.$emit('setBadgeDiscountMatrix', $event)}}}):(_vm.subtitle == '' && _vm.name == 'MARKETING VARIATION MATRIX')?_c('Marketing-variation-matrix-table',{attrs:{"name":_vm.name,"subtitle":_vm.subtitle,"headers":_vm.headers,"items":_vm.items},on:{"updateAllOriginalItems":function($event){return _vm.$emit('updateAllOriginalItems', $event)},"setBadgeRegionalizationMatrix":function($event){return _vm.$emit('setBadgeRegionalizationMatrix', $event)}}}):(
        _vm.subtitle == 'AGRUPAMENTOS' && _vm.name == 'SEGMENTAÇÃO DE MERCADO'
      )?_c('TargetMarketSegmentationMain',{attrs:{"name":_vm.name,"subtitle":_vm.subtitle,"headers":_vm.headers,"items":_vm.items},on:{"refreshTargetMarketSegmentationTable":function($event){return _vm.$emit('refreshTargetMarketSegmentationTable')}}}):_c('v-col',[_c('h4',[_vm._v(_vm._s(_vm.name))]),_c('label',[_vm._v(_vm._s(_vm.subtitle))]),(
          _vm.subtitle == 'FUTURE VALUE | CANAL GERAL' && _vm.name == 'DELIBERATION'
        )?_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headers,"items":_vm.localItems,"hide-default-footer":_vm.localItems == [] || _vm.localItems.length == 0,"footer-props":_vm.footerProps,"no-data-text":_vm.$t('TXT_NO_DATA_AVAILABLE')},scopedSlots:_vm._u([{key:"item.percentLMUSD",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.percentLMUSD + " %")+" ")]}},{key:"item.percentLMBRL",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.percentLMBRL + " %")+" ")]}},{key:"item.unitPriceBRLOnePage",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.unitPriceBRLOnePage, false))+" ")]}},{key:"item.unitPriceUSDOnePage",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.unitPriceUSDOnePage, true))+" ")]}},{key:"item.iaPriceBRL",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.iaPriceBRL, false))+" ")]}},{key:"item.iaPriceUSD",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.iaPriceUSD, true))+" ")]}},{key:"item.unitPriceListUSD",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(!item.isEditUSD)?_c('div',[_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.unitPriceListUSD, true))+" "),(_vm.isAdminUpl)?_c('v-icon',{attrs:{"color":_vm.$prdStyles('color-primary'),"right":""},on:{"click":function($event){item.isEditUSD = true}}},[_vm._v(" mdi-pencil-box")]):_vm._e()],1):_c('div',{staticClass:"my-flex"},[_c('v-text-field',{staticStyle:{"max-width":"120px"},attrs:{"loading":item.isLoading,"disabled":item.isLoading,"outlined":"","dense":"","hide-details":""},model:{value:(item.unitPriceListUSD),callback:function ($$v) {_vm.$set(item, "unitPriceListUSD", $$v)},expression:"item.unitPriceListUSD"}}),_c('v-icon',{attrs:{"disabled":item.isLoading,"color":"#1D8527"},on:{"click":function($event){return _vm.editUnitPriceDeliberation('USD', item)}}},[_vm._v(" mdi-checkbox-outline ")]),_c('v-icon',{attrs:{"disabled":item.isLoading,"color":"#B00020"},on:{"click":function($event){return _vm.closeEditModeDeliberation('USD', item)}}},[_vm._v(" mdi-close-box ")])],1)])]}}],null,true)},[_vm._v(" Last Price: "+_vm._s(_vm.setCurrencyUPL(item.lastPriceUSD, true))+" ")])]}},{key:"item.unitPriceListBRL",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(!item.isEditBRL)?_c('div',[_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.unitPriceListBRL, false))+" "),(_vm.isAdminUpl)?_c('v-icon',{attrs:{"color":_vm.$prdStyles('color-primary'),"right":""},on:{"click":function($event){item.isEditBRL = true}}},[_vm._v(" mdi-pencil-box")]):_vm._e()],1):_c('div',{staticClass:"my-flex"},[_c('v-text-field',{staticStyle:{"max-width":"120px"},attrs:{"loading":item.isLoading,"disabled":item.isLoading,"outlined":"","dense":"","hide-details":""},model:{value:(item.unitPriceListBRL),callback:function ($$v) {_vm.$set(item, "unitPriceListBRL", $$v)},expression:"item.unitPriceListBRL"}}),_c('v-icon',{attrs:{"disabled":item.isLoading,"color":"#1D8527"},on:{"click":function($event){return _vm.editUnitPriceDeliberation('BRL', item)}}},[_vm._v(" mdi-checkbox-outline ")]),_c('v-icon',{attrs:{"disabled":item.isLoading,"color":"#B00020"},on:{"click":function($event){return _vm.closeEditModeDeliberation('BRL', item)}}},[_vm._v(" mdi-close-box ")])],1)])]}}],null,true)},[_vm._v(" Last Price: "+_vm._s(_vm.setCurrencyUPL(item.lastPriceBRL, true))+" ")])]}},{key:"item.coments",fn:function(ref){
        var item = ref.item;
return [(!item.isEditComments)?_c('div',[_vm._v(" "+_vm._s(item.coments)+" "),(_vm.isAdminUpl)?_c('v-icon',{attrs:{"color":_vm.$prdStyles('color-primary'),"right":""},on:{"click":function($event){item.isEditComments = true}}},[_vm._v(" mdi-pencil-box")]):_vm._e()],1):_c('div',{staticClass:"my-flex"},[_c('v-text-field',{attrs:{"loading":item.isLoading,"disabled":item.isLoading,"outlined":"","dense":"","hide-details":""},model:{value:(item.coments),callback:function ($$v) {_vm.$set(item, "coments", $$v)},expression:"item.coments"}}),_c('v-icon',{attrs:{"disabled":item.isLoading,"color":"#1D8527"},on:{"click":function($event){return _vm.editUnitPriceDeliberation('Comments', item)}}},[_vm._v(" mdi-checkbox-outline ")]),_c('v-icon',{attrs:{"disabled":item.isLoading,"color":"#B00020"},on:{"click":function($event){return _vm.closeEditModeDeliberation('Comments', item)}}},[_vm._v(" mdi-close-box ")])],1)]}}],null,true)}):(_vm.subtitle == '' && _vm.name == 'INFO')?_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headers,"items":_vm.localItems,"hide-default-footer":_vm.localItems == [] || _vm.localItems.length == 0,"footer-props":_vm.footerProps,"no-data-text":_vm.$t('TXT_NO_DATA_AVAILABLE')},scopedSlots:_vm._u([{key:"item.priceBook",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [(!item.isEditPriceBook)?_c('div',[_vm._v(" "+_vm._s(item.priceBook)+" "),(_vm.isAdminUpl)?_c('v-icon',{attrs:{"color":_vm.$prdStyles('color-primary'),"right":""},on:{"click":function($event){item.isEditPriceBook = true}}},[_vm._v(" mdi-pencil-box")]):_vm._e()],1):_c('div',{staticClass:"my-flex"},[_c('v-text-field',{staticStyle:{"max-width":"120px"},attrs:{"loading":item.isLoading,"disabled":item.isLoading,"outlined":"","dense":"","hide-details":""},model:{value:(item.priceBook),callback:function ($$v) {_vm.$set(item, "priceBook", $$v)},expression:"item.priceBook"}}),_c('v-icon',{attrs:{"disabled":item.isLoading,"color":"#1D8527"},on:{"click":function($event){return _vm.editUnitPrice('priceBook', item, index)}}},[_vm._v(" mdi-checkbox-outline ")]),_c('v-icon',{attrs:{"disabled":item.isLoading,"color":"#B00020"},on:{"click":function($event){return _vm.closeEditMode('priceBook', item, index)}}},[_vm._v(" mdi-close-box ")])],1)]}},{key:"item.keepOnList",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [(!item.isEditKeepOnList)?_c('div',[(item.keepOnList)?_c('span',[_vm._v("Sim")]):_c('span',[_vm._v("Não")]),(_vm.isAdminUpl)?_c('v-icon',{attrs:{"color":_vm.$prdStyles('color-primary'),"right":""},on:{"click":function($event){item.isEditKeepOnList = true}}},[_vm._v(" mdi-pencil-box")]):_vm._e()],1):_c('div',{staticClass:"my-flex"},[_c('v-select',{staticStyle:{"max-width":"120px"},attrs:{"loading":item.isLoading,"disabled":item.isLoading,"items":_vm.boolItens,"outlined":"","dense":"","hide-details":""},model:{value:(item.keepOnList),callback:function ($$v) {_vm.$set(item, "keepOnList", $$v)},expression:"item.keepOnList"}}),_c('v-icon',{attrs:{"disabled":item.isLoading,"color":"#1D8527"},on:{"click":function($event){return _vm.editUnitPrice('keepOnList', item, index)}}},[_vm._v(" mdi-checkbox-outline ")]),_c('v-icon',{attrs:{"disabled":item.isLoading,"color":"#B00020"},on:{"click":function($event){return _vm.closeEditMode('keepOnList', item, index)}}},[_vm._v(" mdi-close-box ")])],1)]}}],null,true)}):_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headers,"items":_vm.localItems,"hide-default-footer":_vm.localItems == [] || _vm.localItems.length == 0,"footer-props":_vm.footerProps,"no-data-text":_vm.$t('TXT_NO_DATA_AVAILABLE')},scopedSlots:_vm._u([{key:"item.percentLMUSD",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.percentLMUSD + " %")+" ")]}},{key:"item.percentLMBRL",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.percentLMBRL + " %")+" ")]}},{key:"item.grossUnitPriceFvBrlGeneralChannel",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.grossUnitPriceFvBrlGeneralChannel, false))+" ")]}},{key:"item.grossUnitPriceFvBrlKaVf",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.grossUnitPriceFvBrlKaVf, false))+" ")]}},{key:"item.netUnitPriceFvBrlGeneralChannel",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.netUnitPriceFvBrlGeneralChannel, false))+" ")]}},{key:"item.netUnitPriceFvBrlKaVf",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.netUnitPriceFvBrlKaVf, false))+" ")]}},{key:"item.minGrossPriceFvBrlGeneralChannel",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.minGrossPriceFvBrlGeneralChannel, false))+" ")]}},{key:"item.minGrossUnitPriceFvBrlKaVf",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.minGrossUnitPriceFvBrlKaVf, false))+" ")]}},{key:"item.minNetPriceFvBrlGeneralChannel",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.minNetPriceFvBrlGeneralChannel, false))+" ")]}},{key:"item.minNetUnitPriceFvBrlKaVf",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.minNetUnitPriceFvBrlKaVf, false))+" ")]}},{key:"item.grossUnitPricePvBrlGeneralChannel",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.grossUnitPricePvBrlGeneralChannel, false))+" ")]}},{key:"item.grossUnitPricePvBrlKaVf",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.grossUnitPricePvBrlKaVf, false))+" ")]}},{key:"item.netUnitPricePvBrlGeneralChannel",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.netUnitPricePvBrlGeneralChannel, false))+" ")]}},{key:"item.netUnitPricePvBrlKaVf",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.netUnitPricePvBrlKaVf, false))+" ")]}},{key:"item.minGrossPricePvBrlGeneralChannel",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.minGrossPricePvBrlGeneralChannel, false))+" ")]}},{key:"item.minGrossUnitPricePvBrlKaVf",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.minGrossUnitPricePvBrlKaVf, false))+" ")]}},{key:"item.minNetPricePvBrlGeneralChannel",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.minNetPricePvBrlGeneralChannel, false))+" ")]}},{key:"item.minNetUnitPricePvBrlKaVf",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.minNetUnitPricePvBrlKaVf, false))+" ")]}},{key:"item.minNetUnitPricePvUSDKaVf",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.minNetUnitPricePvUSDKaVf, true))+" ")]}},{key:"item.minNetPricePvUSDGeneralChannel",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.minNetPricePvUSDGeneralChannel, true))+" ")]}},{key:"item.minGrossUnitPricePvUSDKaVf",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.minGrossUnitPricePvUSDKaVf, true))+" ")]}},{key:"item.minGrossPricePvUSDGeneralChannel",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.minGrossPricePvUSDGeneralChannel, true))+" ")]}},{key:"item.netUnitPricePvUSDKaVf",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.netUnitPricePvUSDKaVf, true))+" ")]}},{key:"item.netUnitPricePvUSDGeneralChannel",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.netUnitPricePvUSDGeneralChannel, true))+" ")]}},{key:"item.grossUnitPricePvUSDKaVf",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.grossUnitPricePvUSDKaVf, true))+" ")]}},{key:"item.grossUnitPricePvUSDGeneralChannel",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.grossUnitPricePvUSDGeneralChannel, true))+" ")]}},{key:"item.minNetUnitPriceFvUsdKaVf",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.minNetUnitPriceFvUsdKaVf, true))+" ")]}},{key:"item.minNetPriceFvUsdGeneralChannel",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.minNetPriceFvUsdGeneralChannel, true))+" ")]}},{key:"item.minGrossUnitPriceFvUsdKaVf",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.minGrossUnitPriceFvUsdKaVf, true))+" ")]}},{key:"item.minGrossPriceFvUsdGeneralChannel",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.minGrossPriceFvUsdGeneralChannel, true))+" ")]}},{key:"item.netUnitPriceFvUsdKaVf",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.netUnitPriceFvUsdKaVf, true))+" ")]}},{key:"item.netUnitPriceFvUsdGeneralChannel",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.netUnitPriceFvUsdGeneralChannel, true))+" ")]}},{key:"item.grossUnitPriceFvUsdGeneralChannel",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.grossUnitPriceFvUsdGeneralChannel, true))+" ")]}},{key:"item.grossUnitPriceFvUsdKaVf",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.grossUnitPriceFvUsdKaVf, true))+" ")]}},{key:"item.unitPriceListUSD",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.unitPriceListUSD, true))+" ")]}},{key:"item.unitPriceListBRL",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.unitPriceListBRL, false))+" ")]}},{key:"item.ordersPrice",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.ordersPrice, true))+" ")]}}],null,true)})],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.snackbarColor,"timeout":2000},model:{value:(_vm.snackbar.snackbar),callback:function ($$v) {_vm.$set(_vm.snackbar, "snackbar", $$v)},expression:"snackbar.snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }