import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class PredimonitorV2 extends ServiceBase {
    async getProductGroups(idCompany) {
        return await axiosInstance.get(API_URL + "api/V2/Monitoring/ListMonitoringGroup", { params: { idCompany: idCompany } });
    }

    async getProductGroup(idCompany, idMonitoringGroup) {
        return await axiosInstance.get(API_URL + "api/V2/Monitoring/MonitoringGroup", { params: { idCompany: idCompany, idMonitoringGroup: idMonitoringGroup } });
    }

    async getProductList(request) {
        return await axiosInstance.post(API_URL + "api/V2/Monitoring/ListMonitoringItemPaginated", request);
    }

    async searchV3(request) {
        return await axiosInstance.post(API_URL + "api/V2/Monitoring/Search", request);
    }

    async disregardItem(request) {
        return await axiosInstance.post(API_URL + "api/V2/PrediMonitor/SaveIgnoredMonitoringItems", request);
    }

    async getDataToChart(request) {
        return await axiosInstance.post(API_URL + "api/V2/Monitoring/GetHistorySearch", request);
    }

    async getProjection(request) {
        return await axiosInstance.post(API_URL + "api/V2/Monitoring/CalculateProjection", request);
    }
}