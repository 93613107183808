<template>
  <div>
    <v-row no-gutters align="center">
      <TitleBreadCrumbsComponent :hasTooltip="false" :title="'HOME'" />
    </v-row>
    <v-row no-gutters align="center">
      <v-col cols="7">
        <Main-filters
          :isLoading="isLoading"
          @promotionalCalendarEvent="promotionalCalendarData = $event"
          @geralVisionDataEvent="geralVisionData = $event"
          @totalProjection="totalProjectionData = $event"
          @oportunitiesDataEvent="oportunitiesData = $event"
          @alertsDataEvent="alertsData = $event"
          @setLoadingAlerts="isLoadingAlerts = $event"
          @setLoading="isLoading = $event"
          @loadingProjection="loadingProjection = $event"
          @promotionalCalendarLoading="promotionalCalendarLoading = $event"
          @chartIALoading="chartIALoading = $event"
          @listRevenueGraphic="listRevenueGraphic = $event"
          @setLoadingRevenueGraphic="isLoadingRevenueGraphic = $event"
          @unlockIcGraphicVEM="unlockIcGraphicVEM = true"
          @stateByIdPriceGroup="stateByIdPriceGroup = $event"
          @listedProducts="listedProducts = $event"
          @idStore="idStore = $event"
          @setLoadingListedProducts="isLoadingListedProducts = $event"
          @nameStore="nameStore = $event"
          @selectedAffiliate="selectedAffiliate = $event"
          @getChartAdoptionData="
            getChartAdoptionDataFlag = !getChartAdoptionDataFlag
          "
          ref="main-filters"
          class="mr-4"
        />
      </v-col>
      <v-col>
        <Last-precification-main :selectedAffiliate="selectedAffiliate" />
      </v-col>
    </v-row>

    <Promotional-calendar
      v-if="idCompany != 3236"
      :promotionalCalendarData="promotionalCalendarData"
      :promotionalCalendarLoading="promotionalCalendarLoading"
      :listedProducts="listedProducts"
      :isLoadingListedProducts="isLoadingListedProducts"
      @updatePromotionalCalendar="updatePromotionalCalendar"
    />

    <Geral-vision-main
      :geralVisionData="geralVisionData"
      :totalProjectionData="totalProjectionData"
      :isLoading="isLoading"
      :loadingProjection="loadingProjection"
      :chartIALoading="chartIALoading"
      :idCompany="idCompany"
      :listRevenueGraphic="listRevenueGraphic"
      :isLoadingRevenueGraphic="isLoadingRevenueGraphic"
      :selectedAffiliate="selectedAffiliate"
      :getChartAdoptionDataFlag="getChartAdoptionDataFlag"
    />

    <Opportunities-main :oportunitiesData="oportunitiesData" />

    <Alerts-main :alertsData="alertsData" :isLoadingAlerts="isLoadingAlerts" />

    <Competitiveness-graphic-main
      v-if="idCompany == 3026 && isAdminVem"
      :unlockIcGraphicVEM="unlockIcGraphicVEM"
      :stateByIdPriceGroup="stateByIdPriceGroup"
      :nameStore="nameStore"
    />

    <Margin-chart-main
      v-if="idCompany == 3026 && isAdminVem"
      :idStore="idStore"
      :unlockIcGraphicVEM="unlockIcGraphicVEM"
    />
  </div>
</template>

<script>
import TitleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import MainFilters from "./components/main-filters.vue";
import PromotionalCalendar from "./components/promotional-calendar/promotional-calendar.vue";
import GeralVisionMain from "./components/geral-vision/geral-vision-main.vue";
import OpportunitiesMain from "./components/opportunities/opportunities-main.vue";
import AlertsMain from "./components/alerts/alerts-main.vue";
import ServiceHome20 from "@/service/home20/index";
import CompetitivenessGraphicMain from "./components/competitiveness-graphic/competitiveness-graphic-main.vue";
import MarginChartMain from "./components/margin-chart/margin-chart-main.vue";
import LastPrecificationMain from "./components/last-precification/last-precification-main.vue";

export default {
  components: {
    TitleBreadCrumbsComponent,
    MainFilters,
    PromotionalCalendar,
    GeralVisionMain,
    OpportunitiesMain,
    AlertsMain,
    CompetitivenessGraphicMain,
    MarginChartMain,
    LastPrecificationMain,
  },
  props: ["idCompany"],
  data() {
    return {
      isLoading: false,
      loadingProjection: false,
      service: new ServiceHome20(),
      geralVisionData: null,
      totalProjectionData: null,
      oportunitiesData: null,
      alertsData: null,
      isLoadingAlerts: false,
      promotionalCalendarData: null,

      promotionalCalendarLoading: false,
      chartIALoading: false,
      listRevenueGraphic: [],
      isLoadingRevenueGraphic: false,
      unlockIcGraphicVEM: false,
      stateByIdPriceGroup: null,
      listedProducts: [],
      isAdminVem: false,
      idStore: null,
      isLoadingListedProducts: false,
      nameStore: null,
      selectedAffiliate: null,
      getChartAdoptionDataFlag: false,
    };
  },

  computed: {},

  methods: {
    hasResource(value) {
      return this.$store.getters.hasResource(value);
    },

    updatePromotionalCalendar() {
      this.$refs["main-filters"].getPromotionCalendar();
    },

    getIsAdminVem() {
      this.service
        .getIsAdminVem()
        .then((res) => {
          this.isAdminVem = res.data;
        })
        .catch((error) => {
          console.log(error);
          this.isAdminVem = false;
        });
    },
  },
  beforeMount() {
    this.getIsAdminVem();
  },
};
</script>

<style lang="scss" scoped></style>