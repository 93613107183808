<template>
  <div class="my-6">
    <h4 class="mb-6">
      {{ this.$i18n.t("TXT_PRODUCTS_UPPERCASE") }} ({{ totalRecords }})
    </h4>
    <div class="main-info">
      <div class="product-info">
        <div :class="{ blockButton: isLoading }" class="icon-flex">
          <div
            @click="filterItems('allItems', allItems)"
            class="icon-button"
            :class="{ allitemsButton: allItems }"
          >
            <span v-t="'TXT_SEMAPHORE_APPROVAL_REPROVAL'" class="ma-0"> </span>
          </div>
        </div>
        <div :class="{ blockButton: isLoading }" class="icon-flex">
          <div
            @click="filterItems('approvalItems', approvalItems)"
            class="icon-button"
            :class="{ approvalitemsButton: approvalItems }"
          >
            <span v-t="'TXT_SEMAPHORE_ALL_APPROVAL'" class="ma-0"> </span>
          </div>
        </div>
        <div :class="{ blockButton: isLoading }" class="icon-flex">
          <div
            @click="filterItems('repprovalItems', repprovalItems)"
            class="icon-button"
            :class="{ repprovalitemsButton: repprovalItems }"
          >
            <span v-t="'TXT_SEMAPHORE_ALL_REPROVAL'" class="ma-0"> </span>
          </div>
        </div>
        <div :class="{ blockButton: isLoading }" class="icon-flex">
          <div
            @click="filterItems('manualItems', manualItems)"
            class="icon-button"
            :class="{ manualitemsButton: manualItems }"
          >
            <span v-t="'TXT_MANUAL_PRICE'" class="ma-0"> </span>
          </div>
        </div>
      </div>
      <div class="mx-8">
        <Pricing-scenario :products="products" />
      </div>

      <div class="d-flex align-end">
        <Prd-combo-box
          v-model="selectedOrder"
          :items="orderItems"
          :title="$t('TXT_ORDERING')"
          :placeholder="$t('TXT_ALPHABETICAL')"
          @change="setOrder"
          class="mr-2"
        />
        <Prd-combo-box
          v-model="selectedItems"
          :items="itemsPerPage"
          :title="$t('TXT_RESULTS_BY_PAGE')"
          :disabled="isLoading || products.length <= 0"
          @change="setItemsPerPage"
        />
      </div>
    </div>

    <v-row
      v-if="products.length <= 0 && !isLoading"
      no-gutters
      justify="center"
      class="mt-10"
    >
      <h2 v-t="'TXT_NO_DATA_AVAILABLE'"></h2>
    </v-row>
  </div>
</template>

<script>
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import PricingScenario from "./components/pricing-scenario.vue";
export default {
  props: ["totalRecords", "isLoading"],
  components: { PrdComboBox, PricingScenario },
  data() {
    return {
      itemsPerPage: [5, 10, 20, 50, 100],
      selectedItems: 10,
      selectedOrder: { text: this.$i18n.t("TXT_ALPHABETICAL"), value: 0 },
      orderItems: [
        { text: this.$i18n.t("TXT_ALPHABETICAL"), value: 0 },
        { text: this.$i18n.t("TXT_MARGIN"), value: 1 },
        { text: this.$i18n.t("TXT_PROFIT"), value: 2 },
        { text: this.$i18n.t("TXT_REVENUE"), value: 3 },
      ],
      approvalItems: false,
      repprovalItems: false,
      allItems: false,
      manualItems: false,
    };
  },

  computed: {
    products() {
      return this.$store?.getters?.productList?.records ?? [];
    },

    cleanSemaphoreFilters() {
      return this.$store?.getters?.cleanSemaphoreFilters;
    },
  },

  watch: {
    cleanSemaphoreFilters() {
      this.toggleFilter("allItems", true);
      this.toggleFilter("repprovalItems", true);
      this.toggleFilter("approvalItems", true);
      this.toggleFilter("manualItems", true);
    },
  },

  methods: {
    setItemsPerPage() {
      this.$store.commit("setItemsPerPage", this.selectedItems);
    },
    setOrder() {
      this.$store.commit("setOrder", this.selectedOrder?.value ?? 0);
    },

    filterItems(type, value) {
      this.toggleFilter(type, value);

      let itemsToFilter = [];
      if (this.allItems) itemsToFilter.push(0);
      if (this.approvalItems) itemsToFilter.push(1);
      if (this.manualItems) itemsToFilter.push(2);
      if (this.repprovalItems) itemsToFilter.push(3);

      this.$store.commit("setSemaphoreFilter", itemsToFilter);
    },

    toggleFilter(type, value) {
      switch (type) {
        case "allItems":
          this.allItems = !value;
          break;
        case "repprovalItems":
          this.repprovalItems = !value;
          break;
        case "approvalItems":
          this.approvalItems = !value;
          break;
        case "manualItems":
          this.manualItems = !value;
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-info {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.icon-flex {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.product-info {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  span {
    border-radius: 4px;
    padding-left: 4px;
  }

  .icon-flex:nth-child(1) {
    border-left: 8px solid $brand-color-primary-pure;
    .icon-button {
      border: 1px solid $brand-color-primary-pure;
    }
  }

  .icon-flex:nth-child(2) {
    border-left: 8px solid $feedback-color-success-pure;
    .icon-button {
      border: 1px solid $feedback-color-success-pure;
    }
  }

  .icon-flex:nth-child(3) {
    border-left: 8px solid $feedback-color-warning-pure;
    .icon-button {
      border: 1px solid $feedback-color-warning-pure;
    }
  }

  .icon-flex:nth-child(4) {
    border-left: 8px solid $feedback-color-helper-pure;
    .icon-button {
      border: 1px solid $feedback-color-helper-pure;
    }
  }

  .allitemsButton {
    background-color: $brand-color-primary-pure;
    color: #fff;
  }
  .approvalitemsButton {
    background-color: $feedback-color-success-pure;
    color: #fff;
  }
  .repprovalitemsButton {
    background-color: $feedback-color-warning-pure;
    color: #fff;
  }
  .manualitemsButton {
    background-color: $feedback-color-helper-pure;
    color: #fff;
  }
}
.blockButton {
  pointer-events: none;
  opacity: 0.5;
}
.icon-button {
  padding: 0.2rem;
  margin-left: 0.5rem;
  min-width: 250px;
  cursor: pointer;
  transition: 0.4s;
}

.icon-button:hover {
  transform: scale(1.015);
}
</style>