<template>
  <v-expansion-panel :class="productBorder(semaphore)" class="mt-2">
    <Granularity-header
      :product="product"
      :marginToHeader="marginToHeader"
      :priceToHeader="priceToHeader"
    />

    <Granularity-body
      :product="product"
      :pricingName="pricingName"
      @marginToHeader="marginToHeader = $event"
      @setPriceToHeader="priceToHeader = $event"
      @updatedSemaphore="updatedSemaphore = $event"
    />
  </v-expansion-panel>
</template>

<script>
import GranularityHeader from "./granularity-header.vue";
import GranularityBody from "./granularity-body.vue";

export default {
  components: {
    GranularityHeader,
    GranularityBody,
  },
  props: ["product", "index", "pricingName"],
  data() {
    return {
      pricing: {},
      marginToHeader: null,
      priceToHeader: null,
      updatedSemaphore: null,
    };
  },
  computed: {
    semaphore() {
      return this.updatedSemaphore
        ? this.updatedSemaphore
        : this.product?.semaphore;
    },
  },

  methods: {
    productBorder(semaphore) {
      switch (semaphore) {
        case 0:
          return "info-products";

        case 1:
          return "approved-products";

        case 2:
          return "manual-products";

        case 3:
          return "negative-products";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.negative-value {
  color: $feedback-color-warning-pure !important;
  font-weight: bold;
}
.positive-value {
  color: $feedback-color-success-pure !important;
  font-weight: bold;
}
.neutral-value {
  color: $brand-color-primary-pure !important;
  font-weight: bold;
}
.warning-value {
  color: $feedback-color-helper-pure !important;
  font-weight: bold;
}
.approved-products {
  border-left: 6px solid $feedback-color-success-pure !important;
}
.negative-products {
  border-left: 6px solid $feedback-color-warning-pure !important;
}
.manual-products {
  border-left: 6px solid $feedback-color-helper-pure !important;
}
.info-products {
  border-left: 6px solid $brand-color-primary-pure !important;
}
</style>