<template>
  <div>
    <prd-btn
      style="height: 40px"
      :title="'Importar Bases'"
      :disabled="readOnly"
      @click="isDialogOpen = true"
    />
    <v-dialog v-model="isDialogOpen" max-width="400" persistent>
      <v-card class="pa-2">
        <v-row no-gutters justify="end">
          <v-icon
            @click="isDialogOpen = false"
            :color="$prdStyles('color-warning')"
          >
            mdi-close-box
          </v-icon>
        </v-row>
        <base-panel
          v-for="(data, index) in dataBases"
          :disablePanel="isLoading"
          :key="index"
          :title="data.title"
          :icon="data.icon"
          :bases="data.base"
          @download="handlerDownloads(index, $event)"
          @downloadLastFile="downloadLastFileInputed(index, $event)"
          @upload="handlerUploads(index, $event.index, $event.file)"
        />
      </v-card>
    </v-dialog>
    <!-- MESSAGE COMPONENTS -->
    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.snackbarColor"
      :timeout="2000"
    >
      {{ snackbar.snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
import BasePanel from "./components/base-panel.vue";
import ServiceUPL from "@/service/upl/index";

export default {
  props: ["readOnly"],
  components: { prdBtn, BasePanel },
  data() {
    return {
      service: new ServiceUPL(),
      isDialogOpen: false,
      isLoading: false,
      dataBases: [
        {
          title: "Bases de Cálculos",
          s3Title: "baseCalculos",
          icon: "mdi-calculator-variant-outline",
          base: [
            {
              title: "External - MQ",
              s3Title: "externalMQ",
              lastImportation: null,
              isLoadingUpload: false,
              isLoadingDownload: false,
              isLoadingDownloadLast: false,
              useTemplate: true,
            },
            {
              title: "Internal - DataGoal",
              s3Title: "internalDataGoal",
              lastImportation: null,
              isLoadingUpload: false,
              isLoadingDownload: false,
              isLoadingDownloadLast: false,
              useTemplate: true,
            },
            {
              title: "Data Science - DataGoal",
              s3Title: "dataScienceDataGoal",
              lastImportation: null,
              isLoadingUpload: false,
              isLoadingDownload: false,
              isLoadingDownloadLast: false,
              useTemplate: false,
            },
            {
              title: "Custo Reposição",
              s3Title: "custoReposicao",
              lastImportation: null,
              isLoadingUpload: false,
              isLoadingDownload: false,
              isLoadingDownloadLast: false,
              useTemplate: true,
            },
            {
              title: "Encarteiramento",
              s3Title: "encarteiramento",
              lastImportation: null,
              isLoadingUpload: false,
              isLoadingDownload: false,
              isLoadingDownloadLast: false,
              useTemplate: false,
            },
            {
              title: "Importações",
              s3Title: "importacoes",
              lastImportation: null,
              isLoadingUpload: false,
              isLoadingDownload: false,
              isLoadingDownloadLast: false,
              useTemplate: false,
            },
            {
              title: "Baiinfo",
              s3Title: "baiinfo",
              lastImportation: null,
              isLoadingUpload: false,
              isLoadingDownload: false,
              isLoadingDownloadLast: false,
              useTemplate: false,
            },
            {
              title: "Estoque + AG",
              s3Title: "estoqueAG",
              lastImportation: null,
              isLoadingUpload: false,
              isLoadingDownload: false,
              isLoadingDownloadLast: false,
              useTemplate: false,
            },
          ],
        },
        {
          title: "Bases de Gráficos",
          s3Title: "baseGrafico",
          icon: "mdi-chart-bar",
          base: [
            {
              title: "Custo Completo",
              s3Title: "custoCompleto",
              lastImportation: null,
              isLoadingUpload: false,
              isLoadingDownload: false,
              isLoadingDownloadLast: false,
              useTemplate: true,
            },
            {
              title: "Atingimento x Finance",
              s3Title: "atingimentoFinance",
              lastImportation: null,
              isLoadingUpload: false,
              isLoadingDownload: false,
              isLoadingDownloadLast: false,
              useTemplate: true,
            },
            {
              title: "Custo por Hectare",
              s3Title: "custoPorHectare",
              lastImportation: null,
              isLoadingUpload: false,
              isLoadingDownload: false,
              isLoadingDownloadLast: false,
              useTemplate: true,
            },
            {
              title: "Histórico de desempenho",
              s3Title: "historicoDesempenho",
              lastImportation: null,
              isLoadingUpload: false,
              isLoadingDownload: false,
              isLoadingDownloadLast: false,
              useTemplate: true,
            },
          ],
        },
      ],
      snackbarMessage: {
        text: null,
        type: null,
      },
      snackbar: {
        snackbar: false,
        snackbarText: null,
        snackbarColor: "#3C5CA7",
      },
    };
  },
  methods: {
    showSnackbar(text, type) {
      let snackbar = {
        snackbar: true,
        snackbarColor: null,
        snackbarText: null,
      };

      snackbar.snackbarText = text;

      switch (type) {
        case "success":
          snackbar.snackbarColor = "#56a667";
          break;
        case "error":
          snackbar.snackbarColor = "#ec4c37";
          break;
        case "info":
          snackbar.snackbarColor = "#3c5ca7";
      }
      this.snackbar = snackbar;
    },
    handlerDownloads(indexPanel, indexItem) {
      const type = "Download";
      if (indexPanel == 0) {
        if (indexItem == 0) {
          this.externalTemplate(type, indexPanel, indexItem);
          return;
        }
        if (indexItem == 1) {
          this.internalTemplate(type, indexPanel, indexItem);
          return;
        }
        if (indexItem == 3) {
          this.custoReposicaoTemplate(type, indexPanel, indexItem);
          return;
        }
      } else if (indexPanel == 1) {
        if (indexItem == 0) {
          this.buscarCustoReposicaoCompletoFilesTemplate(
            type,
            indexPanel,
            indexItem
          );
          return;
        }
        if (indexItem == 1) {
          this.buscarGraficoAtingimentoFinReviewTemplate(
            type,
            indexPanel,
            indexItem
          );
          return;
        }
        if (indexItem == 2) {
          this.buscarGraficoCustoParaPrecificacaoTemplate(
            type,
            indexPanel,
            indexItem
          );
          return;
        }
        if (indexItem == 3) {
          this.buscarGraficoHistoricoDesempenhoTemplate(
            type,
            indexPanel,
            indexItem
          );
          return;
        }
      }
    },
    setLoading(type, indexPanel, indexItem) {
      const currentValue =
        this.dataBases[indexPanel].base[indexItem][`isLoading${type}`];
      this.dataBases[indexPanel].base[indexItem][`isLoading${type}`] =
        !currentValue;
    },
    async externalTemplate(type, indexPanel, indexItem) {
      this.setLoading(type, indexPanel, indexItem);
      await this.service
        .getExternalTemplate()
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/vnd.ms-excel" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Base Completa - MQ (External).xlsx");
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
          this.snackbarMessage.text = "Download Concluído !";
          this.snackbarMessage.type = "success";
        })
        .catch((error) => {
          console.log(error);
          this.snackbarMessage.text = this.$i18n.t("TXT_DOWNLOAD_ERROR");
          this.snackbarMessage.type = "error";
        });
      this.setLoading(type, indexPanel, indexItem);
    },
    async internalTemplate(type, indexPanel, indexItem) {
      this.setLoading(type, indexPanel, indexItem);
      await this.service
        .getInternalTemplate()
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/vnd.ms-excel" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Base Completa - DataGoal.xlsx");
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
          this.snackbarMessage.text = "Download Concluído !";
          this.snackbarMessage.type = "success";
        })
        .catch((error) => {
          console.log(error);
          this.snackbarMessage.text = this.$i18n.t("TXT_DOWNLOAD_ERROR");
          this.snackbarMessage.type = "error";
        });
      this.setLoading(type, indexPanel, indexItem);
    },
    async custoReposicaoTemplate(type, indexPanel, indexItem) {
      this.setLoading(type, indexPanel, indexItem);
      await this.service
        .getCustoReposicaoTemplate()
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/vnd.ms-excel" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Custo.xlsx");
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
          this.snackbarMessage.text = "Download Concluído !";
          this.snackbarMessage.type = "success";
        })
        .catch((error) => {
          console.log(error);
          this.snackbarMessage.text = this.$i18n.t("TXT_DOWNLOAD_ERROR");
          this.snackbarMessage.type = "error";
        });
      this.setLoading(type, indexPanel, indexItem);
    },
    async buscarCustoReposicaoCompletoFilesTemplate(
      type,
      indexPanel,
      indexItem
    ) {
      this.setLoading(type, indexPanel, indexItem);
      await this.service
        .getCustoReposicaoCompletoFilesTemplate()
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/vnd.ms-excel" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Gráfico - Custo completa.xlsx");
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
          this.snackbarMessage.text = "Download Concluído !";
          this.snackbarMessage.type = "success";
        })
        .catch((error) => {
          console.log(error);
          this.snackbarMessage.text = this.$i18n.t("TXT_DOWNLOAD_ERROR");
          this.snackbarMessage.type = "error";
        });
      this.setLoading(type, indexPanel, indexItem);
    },
    async buscarGraficoAtingimentoFinReviewTemplate(
      type,
      indexPanel,
      indexItem
    ) {
      this.setLoading(type, indexPanel, indexItem);
      await this.service
        .getGraficoAtingimentoFinReviewTemplate()
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/vnd.ms-excel" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Gráfico - Atingimento x Finance.xlsx");
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
          this.snackbarMessage.text = "Download Concluído !";
          this.snackbarMessage.type = "success";
        })
        .catch((error) => {
          console.log(error);
          this.snackbarMessage.text = this.$i18n.t("TXT_DOWNLOAD_ERROR");
          this.snackbarMessage.type = "error";
        });
      this.setLoading(type, indexPanel, indexItem);
    },
    async buscarGraficoCustoParaPrecificacaoTemplate(
      type,
      indexPanel,
      indexItem
    ) {
      this.setLoading(type, indexPanel, indexItem);
      await this.service
        .getGraficoCustoParaPrecificacaoTemplate()
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/vnd.ms-excel" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Gráfico - CustoPorHectare.xlsx");
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
          this.snackbarMessage.text = "Download Concluído !";
          this.snackbarMessage.type = "success";
        })
        .catch((error) => {
          console.log(error);
          this.snackbarMessage.text = this.$i18n.t("TXT_DOWNLOAD_ERROR");
          this.snackbarMessage.type = "error";
        });
      this.setLoading(type, indexPanel, indexItem);
    },
    async buscarGraficoHistoricoDesempenhoTemplate(
      type,
      indexPanel,
      indexItem
    ) {
      this.setLoading(type, indexPanel, indexItem);
      await this.service
        .getGraficoHistoricoDesempenhoTemplate()
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/vnd.ms-excel" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "Gráfico - Histórico de desempenho.xlsx"
          );
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
          this.snackbarMessage.text = "Download Concluído !";
          this.snackbarMessage.type = "success";
        })
        .catch((error) => {
          console.log(error);
          this.snackbarMessage.text = this.$i18n.t("TXT_DOWNLOAD_ERROR");
          this.snackbarMessage.type = "error";
        });
      this.setLoading(type, indexPanel, indexItem);
    },

    async handlerUploads(indexPanel, indexItem, inputedFile) {
      if (!this.checkFileType(inputedFile)) {
        this.showSnackbar(this.$i18n.t("TXT_WRONG_FILE_TYPE"), "info");

        // this.$store.commit("snackbarV2/set", {
        //   message: this.$i18n.t("TXT_WRONG_FILE_TYPE"),
        //   type: "info",
        // });
        return;
      }
      if (!this.checkFileSize(inputedFile)) {
        this.showSnackbar(
          this.$i18n.t("TXT_EXCEDED_LIMIT_FILE", { limit: "1GB" }),
          "info"
        );

        // this.$store.commit("snackbarV2/set", {
        //   message: this.$i18n.t("TXT_EXCEDED_LIMIT_FILE",{limit:'1GB'}),
        //   type: "info",
        // });
        return;
      }
      const type = "Upload";
      await this.uploadsS3Bucket(indexPanel, indexItem, inputedFile, type);
      if (indexPanel == 0) {
        if (indexItem == 0)
          this.externalImport(type, indexPanel, indexItem, inputedFile);

        if (indexItem == 1)
          this.internalImport(type, indexPanel, indexItem, inputedFile);

        if (indexItem == 3)
          this.custoReposicaoImport(type, indexPanel, indexItem, inputedFile);
      } else if (indexPanel == 1) {
        if (indexItem == 0)
          this.enviarCustoReposicaoCompletoFilesImport(
            type,
            indexPanel,
            indexItem,
            inputedFile
          );

        if (indexItem == 1)
          this.enviarGraficoAtingimentoFinReviewImport(
            type,
            indexPanel,
            indexItem,
            inputedFile
          );

        if (indexItem == 2)
          this.enviarGraficoCustoParaPrecificacaoImport(
            type,
            indexPanel,
            indexItem,
            inputedFile
          );

        if (indexItem == 3)
          this.enviarGraficoHistoricoDesempenhoImport(
            type,
            indexPanel,
            indexItem,
            inputedFile
          );
      }
    },
    checkFileType(event) {
      if (!event) return;
      const acceptedTypes = ["csv", "xlsx"];
      const file = event.target.files[0];
      const parts = file.name.split(".");
      const fileType = parts[parts.length - 1];
      return acceptedTypes.includes(fileType);
    },
    checkFileSize(event) {
      if (!event) return;
      const file = event.target.files[0];
      console.log(file);
      const acceptedSize = 999 * 1024 * 1024;
      return file.size <= acceptedSize;
    },
    async uploadsS3Bucket(indexPanel, indexItem, inputedFile, type) {
      this.setLoading(type, indexPanel, indexItem);
      const request = this.createRequestToS3Bucket(
        indexPanel,
        indexItem,
        inputedFile,
        type
      );
      try {
        await this.service.uploadsS3Bucket(request);
        this.snackbarMessage.text =
          "Arquivo importado para o Bucket com sucesso !";
        this.snackbarMessage.type = "success";
        this.dataBases[indexPanel].base[indexItem].lastImportation =
          this.convertToDate(new Date().toISOString());
      } catch (error) {
        console.log(error);
        this.snackbarMessage.text =
          "Erro ao importar arquivo para o Bucket, tente novamente, se o problema persistir, contate a PREDIFY !";
        this.snackbarMessage.type = "error";
      }
      this.setLoading(type, indexPanel, indexItem);
    },
    createRequestToS3Bucket(indexPanel, indexItem, inputedFile) {
      const body = new FormData();

      const [baseName, itemName] = this.getPanelAndBaseName(
        indexPanel,
        indexItem
      );

      body.append("Title", itemName);
      body.append("Folder", `${baseName}/${itemName}/`);
      body.append("Path", inputedFile.target.files[0]);
      return body;
    },
    getPanelAndBaseName(indexPanel, indexItem) {
      const base = this.dataBases[indexPanel].s3Title;
      const item = this.dataBases[indexPanel].base[indexItem].s3Title;
      return [base, item];
    },
    async externalImport(type, indexPanel, indexItem, inputedFile) {
      this.setLoading(type, indexPanel, indexItem);
      try {
        const file = inputedFile.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        await this.service
          .postExternalImport(formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.status == 201) {
              this.snackbarMessage.text = "Arquivo importado com sucesso !";
              this.snackbarMessage.type = "success";
              this.dataBases[indexPanel].base[indexItem].lastImportation =
                this.convertToDate(new Date().toISOString());
            } else {
              this.snackbarMessage.text =
                "Problemas ao importar o arquivo, tente novamente !";
              this.snackbarMessage.type = "info";
            }
          })
          .catch((error) => {
            console.log(error);
            this.snackbarMessage.text =
              "Erro ao importar o arquivo, tente novamente, se o problema persistir, contate a PREDIFY !";
            this.snackbarMessage.type = "error";
          });
      } catch (error) {
        console.log(error);
        this.snackbarMessage.text =
          "Erro ao importar o arquivo, tente novamente, se o problema persistir, contate a PREDIFY !";
        this.snackbarMessage.type = "error";
      }
      this.setLoading(type, indexPanel, indexItem);
    },
    async internalImport(type, indexPanel, indexItem, inputedFile) {
      this.setLoading(type, indexPanel, indexItem);
      try {
        const file = inputedFile.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        await this.service
          .postInternalImport(formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.status == 201) {
              this.snackbarMessage.text = "Arquivo importado com sucesso !";
              this.snackbarMessage.type = "success";
              this.dataBases[indexPanel].base[indexItem].lastImportation =
                this.convertToDate(new Date().toISOString());
            } else {
              this.snackbarMessage.text =
                "Problemas ao importar o arquivo, tente novamente !";
              this.snackbarMessage.type = "info";
            }
          })
          .catch((error) => {
            console.log(error);
            this.snackbarMessage.text =
              "Erro ao importar o arquivo, tente novamente, se o problema persistir, contate a PREDIFY !";
            this.snackbarMessage.type = "error";
          });
      } catch (error) {
        console.log(error);
        this.snackbarMessage.text =
          "Erro ao importar o arquivo, tente novamente, se o problema persistir, contate a PREDIFY !";
        this.snackbarMessage.type = "error";
      }
      this.setLoading(type, indexPanel, indexItem);
    },
    async custoReposicaoImport(type, indexPanel, indexItem, inputedFile) {
      this.setLoading(type, indexPanel, indexItem);
      try {
        const file = inputedFile.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        await this.service
          .postCustoReposicaoImport(formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.status == 201) {
              this.snackbarMessage.text = "Arquivo importado com sucesso !";
              this.snackbarMessage.type = "success";
              this.dataBases[indexPanel].base[indexItem].lastImportation =
                this.convertToDate(new Date().toISOString());
            } else {
              this.snackbarMessage.text =
                "Problemas ao importar o arquivo, tente novamente !";
              this.snackbarMessage.type = "info";
            }
          })
          .catch((error) => {
            console.log(error);
            this.snackbarMessage.text =
              "Erro ao importar o arquivo, tente novamente, se o problema persistir, contate a PREDIFY !";
            this.snackbarMessage.type = "error";
          });
      } catch (error) {
        console.log(error);
        this.snackbarMessage.text =
          "Erro ao importar o arquivo, tente novamente, se o problema persistir, contate a PREDIFY !";
        this.snackbarMessage.type = "error";
      }
      this.setLoading(type, indexPanel, indexItem);
    },
    async enviarCustoReposicaoCompletoFilesImport(
      type,
      indexPanel,
      indexItem,
      inputedFile
    ) {
      this.setLoading(type, indexPanel, indexItem);
      try {
        const file = inputedFile.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        await this.service
          .postCustoReposicaoCompletoFilesImport(formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.status == 201) {
              this.snackbarMessage.text = "Arquivo importado com sucesso !";
              this.snackbarMessage.type = "success";
              this.dataBases[indexPanel].base[indexItem].lastImportation =
                this.convertToDate(new Date().toISOString());
            } else {
              this.snackbarMessage.text =
                "Problemas ao importar o arquivo, tente novamente !";
              this.snackbarMessage.type = "info";
            }
          })
          .catch((error) => {
            console.log(error);
            this.snackbarMessage.text =
              "Erro ao importar o arquivo, tente novamente, se o problema persistir, contate a PREDIFY !";
            this.snackbarMessage.type = "error";
          });
      } catch (error) {
        console.log(error);
        this.snackbarMessage.text =
          "Erro ao importar o arquivo, tente novamente, se o problema persistir, contate a PREDIFY !";
        this.snackbarMessage.type = "error";
      }
      this.setLoading(type, indexPanel, indexItem);
    },
    async enviarGraficoAtingimentoFinReviewImport(
      type,
      indexPanel,
      indexItem,
      inputedFile
    ) {
      this.setLoading(type, indexPanel, indexItem);
      try {
        const file = inputedFile.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        await this.service
          .postGraficoAtingimentoFinReviewImport(formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.status == 201) {
              this.snackbarMessage.text = "Arquivo importado com sucesso !";
              this.snackbarMessage.type = "success";
              this.dataBases[indexPanel].base[indexItem].lastImportation =
                this.convertToDate(new Date().toISOString());
            } else {
              this.snackbarMessage.text =
                "Problemas ao importar o arquivo, tente novamente !";
              this.snackbarMessage.type = "info";
            }
          })
          .catch((error) => {
            console.log(error);
            this.snackbarMessage.text =
              "Erro ao importar o arquivo, tente novamente, se o problema persistir, contate a PREDIFY !";
            this.snackbarMessage.type = "error";
          });
      } catch (error) {
        console.log(error);
        this.snackbarMessage.text =
          "Erro ao importar o arquivo, tente novamente, se o problema persistir, contate a PREDIFY !";
        this.snackbarMessage.type = "error";
      }
      this.setLoading(type, indexPanel, indexItem);
    },
    async enviarGraficoCustoParaPrecificacaoImport(
      type,
      indexPanel,
      indexItem,
      inputedFile
    ) {
      this.setLoading(type, indexPanel, indexItem);
      try {
        const file = inputedFile.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        await this.service
          .postGraficoCustoParaPrecificacaoImport(formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.status == 201) {
              this.snackbarMessage.text = "Arquivo importado com sucesso !";
              this.snackbarMessage.type = "success";
              this.dataBases[indexPanel].base[indexItem].lastImportation =
                this.convertToDate(new Date().toISOString());
            } else {
              this.snackbarMessage.text =
                "Problemas ao importar o arquivo, tente novamente !";
              this.snackbarMessage.type = "info";
            }
          })
          .catch((error) => {
            console.log(error);
            this.snackbarMessage.text =
              "Erro ao importar o arquivo, tente novamente, se o problema persistir, contate a PREDIFY !";
            this.snackbarMessage.type = "error";
          });
      } catch (error) {
        console.log(error);
        this.snackbarMessage.text =
          "Erro ao importar o arquivo, tente novamente, se o problema persistir, contate a PREDIFY !";
        this.snackbarMessage.type = "error";
      }
      this.setLoading(type, indexPanel, indexItem);
    },
    async enviarGraficoHistoricoDesempenhoImport(
      type,
      indexPanel,
      indexItem,
      inputedFile
    ) {
      this.setLoading(type, indexPanel, indexItem);
      try {
        const file = inputedFile.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        await this.service
          .postGraficoHistoricoDesempenhoImport(formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.status == 201) {
              this.snackbarMessage.text = "Arquivo importado com sucesso !";
              this.snackbarMessage.type = "success";
              this.dataBases[indexPanel].base[indexItem].lastImportation =
                this.convertToDate(new Date().toISOString());
            } else {
              this.snackbarMessage.text =
                "Problemas ao importar o arquivo, tente novamente !";
              this.snackbarMessage.type = "info";
            }
          })
          .catch((error) => {
            console.log(error);
            this.snackbarMessage.text =
              "Erro ao importar o arquivo, tente novamente, se o problema persistir, contate a PREDIFY !";
            this.snackbarMessage.type = "error";
          });
      } catch (error) {
        console.log(error);
        this.snackbarMessage.text =
          "Erro ao importar o arquivo, tente novamente, se o problema persistir, contate a PREDIFY !";
        this.snackbarMessage.type = "error";
      }
      this.setLoading(type, indexPanel, indexItem);
    },
    async downloadLastFileInputed(indexPanel, indexItem) {
      this.setLoading("DownloadLast", indexPanel, indexItem);
      const [, titleForDownload] = this.getPanelAndBaseName(
        indexPanel,
        indexItem
      );
      const titlefile = this.dataBases[indexPanel].base[indexItem].title;
      let message,
        type = null;
      try {
        const response = await this.service.downloadLastFileInputed(
          titleForDownload
        );
        const file = response.data;
        this.downloadFile(file, titlefile);
        message = this.$i18n.t("TXT_DOWNLOAD_SUCCESS");
        type = "success";
      } catch (error) {
        console.log(error);
        if (error.response.status == 404) {
          message = this.$i18n.t("TXT_DOWNLOAD_NOT_FOUND");
          type = "info";
        } else {
          message = this.$i18n.t("TXT_DOWNLOAD_ERROR");
          type = "error";
        }
      }
      this.showSnackbar(message, type);
      // this.$store.commit("snackbarV2/set", { message, type });
      this.setLoading("DownloadLast", indexPanel, indexItem);
    },
    downloadFile(file, titlefile) {
      const blob = new Blob([file], { type: "application/vnd.ms-excel" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${titlefile}.xlsx`);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(link.href);
    },

    convertToDate(dateString) {
      if (!dateString) return;
      let d = dateString.split("-");
      let dat = d[2].slice(0, 2) + "/" + d[1] + "/" + d[0];
      return dat;
    },
    async getAllLastImportation() {
      this.isLoading = true;
      await this.service.getExternalFiles().then((res) => {
        this.dataBases[0].base[0].lastImportation = this.convertToDate(
          res.data[0]?.dataInsercao?.toString() ?? null
        );
      });
      await this.service.getInternalFiles().then((res) => {
        this.dataBases[0].base[1].lastImportation = this.convertToDate(
          res.data[0]?.dataInsercao?.toString() ?? null
        );
      });
      await this.service.getCustoReposicao().then((res) => {
        this.dataBases[0].base[3].lastImportation = this.convertToDate(
          res.data[0]?.dataInsercao?.toString() ?? null
        );
      });
      await this.service.gettCustoReposicaoCompletoFiles().then((res) => {
        this.dataBases[1].base[0].lastImportation = this.convertToDate(
          res.data[0]?.dataInsercao?.toString() ?? null
        );
      });
      await this.service.gettGraficoAtingimentoFinReview().then((res) => {
        this.dataBases[1].base[1].lastImportation = this.convertToDate(
          res.data[0]?.dataInsercao?.toString() ?? null
        );
      });
      await this.service.gettGraficoCustoParaPrecificacao().then((res) => {
        this.dataBases[1].base[2].lastImportation = this.convertToDate(
          res.data[0]?.dataInsercao?.toString() ?? null
        );
      });
      await this.service.gettGraficoHistoricoDesempenho().then((res) => {
        this.dataBases[1].base[3].lastImportation = this.convertToDate(
          res.data[0]?.dataInsercao?.toString() ?? null
        );
      });

      await this.service
        .getLastDateFileByTitle(this.dataBases[0].base[2].s3Title)
        .then((res) => {
          this.dataBases[0].base[2].lastImportation = this.convertToDate(
            res.data ?? null
          );
        })
        .catch(() => {
          this.dataBases[0].base[2].lastImportation = this.convertToDate(null);
        });

      await this.service
        .getLastDateFileByTitle(this.dataBases[0].base[4].s3Title)
        .then((res) => {
          this.dataBases[0].base[4].lastImportation = this.convertToDate(
            res.data ?? null
          );
        })
        .catch(() => {
          this.dataBases[0].base[2].lastImportation = this.convertToDate(null);
        });

      await this.service
        .getLastDateFileByTitle(this.dataBases[0].base[5].s3Title)
        .then((res) => {
          this.dataBases[0].base[5].lastImportation = this.convertToDate(
            res.data ?? null
          );
        })
        .catch(() => {
          this.dataBases[0].base[2].lastImportation = this.convertToDate(null);
        });

      await this.service
        .getLastDateFileByTitle(this.dataBases[0].base[6].s3Title)
        .then((res) => {
          this.dataBases[0].base[6].lastImportation = this.convertToDate(
            res.data ?? null
          );
        })
        .catch(() => {
          this.dataBases[0].base[2].lastImportation = this.convertToDate(null);
        });

      await this.service
        .getLastDateFileByTitle(this.dataBases[0].base[7].s3Title)
        .then((res) => {
          this.dataBases[0].base[7].lastImportation = this.convertToDate(
            res.data ?? null
          );
        })
        .catch(() => {
          this.dataBases[0].base[2].lastImportation = this.convertToDate(null);
        });
      this.isLoading = false;
    },
  },
  watch: {
    snackbarMessage: {
      handler(value) {
        if (value.text && value.type) {
          this.showSnackbar(value.text, value.type);
          // this.$store.commit("snackbarV2/set", {
          //   message: value.text,
          //   type: value.type,
          // });
          this.snackbarMessage.text = null;
          this.snackbarMessage.type = null;
        }
      },
      deep: true,
    },
  },
  beforeMount() {
    this.getAllLastImportation();
  },
};
</script>