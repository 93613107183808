<template>
  <div class="main-inputs">
    <v-card
      class="pa-4 pt-0 competitive-price"
      :loading="setLoading"
      :disabled="setLoading || hasError"
    >
      <v-row no-gutters class="pt-4">
        <input
          v-model="selectedOption"
          @click="setOptimizedPrices(false)"
          type="radio"
          id="competitivePrice"
          value="competitivePrice"
          class="mr-2 radio"
        />
        <label for="competitivePrice" class="font-weight-bold">{{
          $i18n.t("TXT_COMPETITIVE_PRICE").toUpperCase()
        }}</label>
      </v-row>

      <Prd-loading-circular v-if="isLoading" class="div-centered" />

      <div v-else class="input-area mt-4">
        <Prd-text-field
          v-model="suggestedPrice"
          :title="$i18n.t('TXT_ELASTICITY_OPTIMIZED_PRICE')"
          :disabled="true"
        />
        <Prd-text-field
          v-model="suggestedMargin"
          :title="$i18n.t('TXT_MARGIN')"
          :disabled="true"
        />
        <Prd-text-field
          v-model="suggestedCompetitivenessIndexi"
          :title="$i18n.t('TXT_COMPETITIVENESS_INDEX')"
          :disabled="true"
        />
      </div>
    </v-card>

    <v-card
      class="pa-4 pt-0 manual-price"
      :loading="setLoading"
      :disabled="setLoading || hasError"
    >
      <v-row no-gutters align="center" class="pt-4">
        <input
          v-model="selectedOption"
          @click="prepareToManual"
          type="radio"
          id="manualPrice"
          value="manualPrice"
          class="mr-2 radio"
        />
        <label for="manualPrice" class="font-weight-bold">{{
          $i18n.t("TXT_MANUAL_PRICE").toUpperCase()
        }}</label>
        <v-icon
          v-if="!isEditable"
          :color="$prdStyles('color-primary')"
          :disabled="isLoading"
          @click="prepareToManual(true)"
          size="30"
          class="ml-2"
          >mdi-pencil-box</v-icon
        >

        <v-row v-if="isEditable" no-gutters>
          <v-icon
            :color="$prdStyles('color-success')"
            @click="setOptimizedPrices(true)"
            size="30"
            class="ml-2"
            >mdi-checkbox-marked</v-icon
          >
          <v-icon
            :color="$prdStyles('color-warning')"
            @click="cancelChanges"
            size="30"
            >mdi-close-box-outline</v-icon
          >
        </v-row>
      </v-row>
      <Prd-loading-circular v-if="isLoading" class="div-centered" />

      <div v-else class="input-area mt-4">
        <Prd-text-field
          v-model="manualPrices.manualPrice"
          :title="$i18n.t('TXT_PRICE')"
          :disabled="selectedOption == 'competitivePrice' || !isEditable"
          :filled="selectedOption == 'competitivePrice'"
        />
        <Prd-text-field
          v-model="manualPrices.manualMargin"
          :title="$i18n.t('TXT_MARGIN')"
          :disabled="selectedOption == 'competitivePrice' || !isEditable"
          :filled="selectedOption == 'competitivePrice'"
          :tooltip="'TXT_CAUTION_MANUAL_PRICE'"
        />
        <Prd-text-field
          v-model="manualPrices.manualCompetitivenessIndexi"
          :title="$i18n.t('TXT_COMPETITIVENESS_INDEX')"
          :disabled="true"
          :filled="selectedOption == 'competitivePrice'"
        />
      </div>
    </v-card>
  </div>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";
import service from "@/service/pricer/product-list.js";
const Service = new service();
import listService from "@/service/pricer/filters.js";
const ListService = new listService();
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import { formatMonetary } from "@/utils/format-toMonetary.js";
import { formatPercentage } from "@/utils/format-percentage.js";
export default {
  props: ["idEnterprisePricesProjection", "pricingName"],
  components: { PrdTextField, PrdLoadingCircular },
  data() {
    return {
      isLoading: false,
      setLoading: false,
      isEditable: false,
      hasError: false,

      manualPrice: null,
      manualMargin: null,
      manualCompetitivenessIndexi: null,

      dataCopy: null,
      selectedOption: "",
    };
  },

  computed: {
    suggestedPrice() {
      return this.competitivePrices?.suggestedPrice ?? "-";
    },
    suggestedMargin() {
      return this.competitivePrices?.suggestedMargin ?? "-";
    },
    suggestedCompetitivenessIndexi() {
      return this.competitivePrices?.suggestedCompetitivenessIndexi ?? "-";
    },
    idPriceGroup() {
      return this.$store?.getters?.idPriceGroup ?? null;
    },
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  methods: {
    formatMonetary,
    formatPercentage,

    async getOptimizedPrices(isFromSet) {
      if (!isFromSet) this.isLoading = true;

      try {
        let response = await Service.getOptimizedPrices(
          this.idEnterprisePricesProjection
        );
        let res = response?.data?.result ?? null;
        if (res) {
          this.setInputs(res);
        }
      } catch (error) {
        console.log(error);
        this.setError();
      }

      this.isEditable = false;
      this.setLoading = false;
      this.isLoading = false;
    },

    setInputs(res) {
      this.competitivePrices = {
        suggestedPrice: this.formatMonetary(res.suggestedPrice),
        suggestedMargin: this.formatPercentage(res.suggestedMargin),
        suggestedCompetitivenessIndexi: this.formatPercentage(
          res.suggestedCompetitivenessIndexi
        ),
      };

      this.manualPrices = {
        manualPrice: this.formatMonetary(res.manualPrice),
        manualMargin: this.formatPercentage(res.manualMargin),
        manualCompetitivenessIndexi: this.formatPercentage(
          res.manualCompetitivenessIndexi
        ),
      };

      this.selectedOption = res.isManual ? "manualPrice" : "competitivePrice";
      this.hasError = false;
    },

    setError() {
      this.$store.commit("snackbarV2/set", {
        message: "Erro",
        type: "error",
      });

      this.competitivePrices = {
        suggestedPrice: 0,
        suggestedMargin: 0,
        suggestedCompetitivenessIndexi: 0,
      };

      this.manualPrices = {
        manualPrice: 0,
        manualMargin: 0,
        manualCompetitivenessIndexi: 0,
      };
      this.data = null;
      this.hasError = true;
    },

    async setOptimizedPrices(isManual) {
      if (!isManual && this.selectedOption == "competitivePrice") return;
      this.$store.commit("setIsManualLoading", true);
      this.setLoading = true;

      let request = {
        idEnterprisePricesProjection: this.idEnterprisePricesProjection,
        manualPrice: this.formatInput(this.manualPrices.manualPrice),
        manualContributionMargin: this.formatInput(
          this.manualPrices.manualMargin
        ),
        isManual: isManual,
      };

      try {
        await Service.setOptimizedPrice(request);
        this.updatePricing();
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: "Erro",
          type: "error",
        });
        this.setLoading = false;
        this.isEditable = false;
      }

      this.$store.commit("setIsManualLoading", false);
      this.getOptimizedPrices();
    },

    formatInput(value) {
      let numericString = value.replace(/[^\d.,-]/g, "");
      numericString = numericString.replace(/,/g, ".");
      return parseFloat(numericString);
    },

    prepareToManual() {
      this.selectedOption = "manualPrice";
      this.dataCopy = Object.assign({}, this.manualPrices);
      this.isEditable = true;
    },

    cancelChanges() {
      if (this.selectedOption == "competitivePrice")
        this.selectedOption = "competitivePrice";

      this.manualPrices = this.dataCopy;
      this.dataCopy = null;
      this.isEditable = false;
    },

    async updatePricing() {
      let request = this.buildRequest();

      try {
        let response = await ListService.getProductList(request);
        let data = response?.data?.result?.records[0] ?? null;
        if (data) this.$emit("updatePricing", data);
      } catch (error) {
        console.log(error);
      }
      this.isEditable = false;
      this.setLoading = false;
    },

    buildRequest() {
      return {
        filter: [
          {
            dbField: 4,
            idEnterprisePriceProjectionDefaultFilter: null,
            value: this.pricingName,
          },
        ],
        idCompany: this.idCompany,
        idEnterprisePriceGroups: this.idPriceGroup,
        isSimulation: false,
        order: 0,
        page: 1,
        recordsPerPage: 1,
      };
    },
  },
  created() {
    this.getOptimizedPrices();
  },
};
</script>

<style lang="scss" scoped>
.div-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.radio {
  cursor: pointer;
  transform: scale(1.5);
  background-color: #3c5ca7 !important;
  color: #3c5ca7;
}
.main-inputs {
  display: flex;
  gap: 0.5rem;
  .competitive-price {
    width: 50%;
    position: relative;
  }
  .manual-price {
    width: 50%;
    position: relative;
  }

  .input-area {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
</style>