<template>
  <v-card :loading="setisLoading" :disabled="setisLoading || setIsManualLoading" class="pa-4 pt-0">
    <h4 class="mt-4">{{ $i18n.t("TXT_ACTIONS").toUpperCase() }}</h4>
    <Prd-loading-circular v-if="isLoading" class="div-centered" />

    <div v-if="!isLoading" class="user-actions pt-4">
      <v-row no-gutters align="center">
        <input
          v-model="actionValue"
          :disabled="!canApprove"
          @click="setActionWorkflow(0)"
          type="radio"
          id="toApprove"
          value="toApprove"
          class="mr-2 radio"
        />
        <v-icon
          :color="$prdStyles('color-success')"
          :disabled="!canApprove"
          small
          class="mr-2"
          >mdi-thumb-up-outline</v-icon
        >
        <span v-t="'TXT_APPROVE'"></span>
      </v-row>

      <v-row no-gutters align="center">
        <input
          v-model="actionValue"
          :disabled="!canScale"
          @click="setActionWorkflow(2)"
          type="radio"
          id="toScale"
          value="toScale"
          class="mr-2 radio"
        />
        <v-icon
          :color="$prdStyles('color-primary')"
          :disabled="!canScale"
          small
          class="mr-2"
          >mdi-chart-line-variant</v-icon
        >
        <span v-t="'TXT_SCALATE'"></span>
      </v-row>
      <v-row no-gutters align="center">
        <input
          v-model="actionValue"
          :disabled="!canRepprove"
          @click="setActionWorkflow(1)"
          type="radio"
          id="toRepprove"
          value="toRepprove"
          class="mr-2 radio"
        />
        <v-icon
          :color="$prdStyles('color-warning')"
          :disabled="!canRepprove"
          small
          class="mr-2"
          >mdi-thumb-down-outline</v-icon
        >

        <span v-t="'TXT_NOT_APPROVE'"></span>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import service from "@/service/pricer/product-list.js";
const Service = new service();
import listService from "@/service/pricer/filters.js";
const ListService = new listService();
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";

export default {
  components: { PrdLoadingCircular },
  props: ["idEnterprisePricesProjection", "pricingName"],
  data() {
    return {
      isLoading: false,
      setisLoading: false,
      actionValue: "",
      data: null,
    };
  },

  computed: {
    canApprove() {
      return !!this.data && this.data.actions.some((el) => el.action === 0);
    },
    canRepprove() {
      return !!this.data && this.data.actions.some((el) => el.action === 1);
    },
    canScale() {
      return !!this.data && this.data.actions.some((el) => el.action === 2);
    },
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    idPriceGroup() {
      return this.$store?.getters?.idPriceGroup ?? null;
    },
    setIsManualLoading(){
      return this.$store?.getters?.setIsManualLoading ?? false
    }
  },

  methods: {
    async getWorkFlowState() {
      this.isLoading = true;

      try {
        let response = await Service.getWorkFlowState(
          this.idEnterprisePricesProjection
        );
        this.data = response?.data?.result ?? null;
        this.verifyModel(this.data);
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: "Erro ao buscar workflow",
          type: "error",
        });
      }
      this.isLoading = false;
    },

    async setActionWorkflow(action) {
      this.setisLoading = true;

      let request = {
        idEnterprisePricesProjection: this.idEnterprisePricesProjection,
        action: action,
      };

      try {
        let response = await Service.setActionWorkFlow(request);

        let data = {
          idPrice: response?.data?.result[0] ?? null,
          action: action,
        };

        if (data) {
          this.updatePricing(data.idPrice);
        }
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: "Erro",
          type: "error",
        });
        this.setisLoading = false;
        this.getWorkFlowState();
      }
    },

    async updatePricing(idPrice) {
      let request = this.buildRequest(idPrice);

      try {
        let response = await ListService.getProductList(request);
        let data = response?.data?.result?.records[0] ?? null;
        if (data) this.$emit("updatePricing", data);
      } catch (error) {
        console.log(error);
      }
      
      this.setisLoading = false;
    },

    buildRequest() {
      return {
        filter: [
          {
            dbField: 4,
            idEnterprisePriceProjectionDefaultFilter: null,
            value: this.pricingName,
          },
        ],
        idCompany: this.idCompany,
        idEnterprisePriceGroups: this.idPriceGroup,
        isSimulation: false,
        order: 0,
        page: 1,
        recordsPerPage: 1,
      };
    },

    verifyModel(values) {
      if (values) {
        let action = values.lastAction.action;
        switch (action) {
          case 0:
            this.actionValue = "toApprove";
            break;
          case 1:
            this.actionValue = "toRepprove";
            break;
          case 2:
            this.actionValue = "toScale";
            break;
        }
      }
    },
  },

  created() {
    this.getWorkFlowState();
  },
};
</script>

<style lang="scss" scoped>
span {
  font-size: 14px;
}
.user-actions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.div-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.radio {
  cursor: pointer;
  transform: scale(1.5);
  background-color: #3c5ca7 !important;
  color: #3c5ca7;
}
</style>