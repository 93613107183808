<template>
  <div>
    <v-icon
      :color="$prdStyles('color-primary')"
      :disabled="isDisable"
      @click.stop="isModalOpen = true"
      right
      class="disablePanel"
      :class="{ enablePanel: !isDisable }"
    >
      mdi-playlist-plus
    </v-icon>
    <v-dialog
      v-model="isModalOpen"
      v-if="isModalOpen"
      :max-width="1000"
      persistent
    >
      <v-card
        :disabled="isLoading"
        :loading="isLoading"
        :loader-height="6"
        class="pa-4 pt-0"
      >
        <v-row no-gutters align="center" class="mb-4 pt-4">
          <h4 class="mr-4">PRODUTOS ADICIONADOS MANUALMENTE</h4>
          <Insert-product-modal
            @addProduct="addProduct"
            :productName="productName"
          />
        </v-row>

        <p v-show="products.length > 0" class="pa-0">
          Nesta relação encontram-se todos os produtos que foram inseridos de
          forma manual. È possível realizar alterações ou exclusões nos itens
          listados.
        </p>

        <v-row no-gutters justify="center" class="pa-0 mt-6">
          <p
            v-show="products.length <= 0 && !isLoading"
            class="font-weight-bold"
          >
            Nenhum produto manualmente adicionado
          </p>
        </v-row>

        <v-expansion-panels v-model="panels" flat>
          <Product-panel-main
            v-for="(product, index) in products"
            :key="index"
            :product="product"
            :idMonitoringItem="idMonitoringItem"
            :idTask="idTask"
            @cancelChanges="cancelChanges"
            @updateOrDeleteItems="updateOrDeleteItems"
          />
        </v-expansion-panels>

        <v-row no-gutters justify="end" align="center" class="mt-4">
          <prd-btn :title="'Fechar'" :outlined="true" @click="closeModal" />
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
import InsertProductModal from "./components/insert-product-modal.vue";
import service from "@/service/predimonitor-V2/add.product.manual.js";
import ProductPanelMain from "./components/product-panel-main.vue";
const Service = new service();
export default {
  components: {
    prdBtn,
    InsertProductModal,
    ProductPanelMain,
  },
  props: [
    "productName",
    "idMonitoringItem",
    "dateRange",
    "disablePanel",
    "idTask",
    "isDisable",
  ],
  data() {
    return {
      isLoading: false,
      isModalOpen: false,
      products: [],
      panels: [],
      insertLoading: false,
    };
  },

  watch: {
    isModalOpen() {
      if (this.isModalOpen) this.getProducts();
      else this.products = [];
    },
  },

  methods: {
    async getProducts() {
      this.isLoading = true;

      try {
        let response = await Service.listProducts(
          this.idMonitoringItem,
          this.dateRange[0],
          this.pushOneDay(this.dateRange[this.dateRange.length - 1]),
          this.idTask || null
        );

        this.products = response?.data?.result ?? [];
        this.products.forEach((el) => {
          el.refDate = this.formatDate(el.refDate);
          el.isModalOpen = false;
        });
      } catch (error) {
        console.log(error);
      }

      this.isLoading = false;
    },

    async addProduct(productObj) {
      this.isLoading = true;

      let request = {
        ...productObj,
        idTask: this.idTask || null,
        idMonitoringItem: this.idMonitoringItem,
      };

      try {
        await Service.insertProduct(request);
        this.$store.commit("snackbarV2/set", {
          message: "Sucesso ao inserir produto",
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: "Erro ao excluír produto",
          type: "error",
        });
      }

      this.getProducts();
      this.panels = [];
    },

    async updateOrDeleteItems(productObj, isForDelete) {
      this.isLoading = true;

      let request = {
        ...productObj,
        idTask: this.idTask || null,
        isDeleted: isForDelete,
      };

      try {
        await Service.updateProduct(request);
        this.$store.commit("snackbarV2/set", {
          message: "Sucesso ao excluír produto",
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: "Erro ao excluír produto",
          type: "error",
        });
      }

      this.getProducts();
    },

    formatDate(baseDate) {
      const date = new Date(baseDate);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      const formatedDate = `${year}-${month}-${day}`;

      return formatedDate;
    },

    pushOneDay(dateBase) {
      let dataObj = new Date(dateBase);
      dataObj.setDate(dataObj.getDate() + 1);

      let newDate = dataObj.toISOString().split("T")[0];

      return newDate;
    },

    closeModal() {
      this.$emit("refreshProduct");
      this.isModalOpen = false;
    },

    cancelChanges() {
      this.panels = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.table-product-name {
  max-width: 350px !important;
  padding: 0.8rem !important;
}
.copy-box {
  max-height: 40px !important;
  overflow: hidden !important;
}
.product-item {
  background-color: #f5f5f5;
  margin-bottom: 8px;
}


.enablePanel {
  pointer-events: all !important;
  color: #3c5ca7;
}
</style>