<template>
  <div>
    <v-icon @click="isModalOpen = true" :color="$prdStyles('color-primary')"
      >mdi-magnify</v-icon
    >
    <v-dialog v-model="isModalOpen">
      <v-card class="pa-4">
        <v-row class="mb-4" no-gutters align="center">
          <h4>RESULTADOS</h4>

          <v-spacer></v-spacer>
          <v-icon
            @click="isModalOpen = false"
            :color="$prdStyles('color-primary')"
            >mdi-close</v-icon
          >
        </v-row>

        <v-row no-gutters justify="center" class="mb-6">
          <v-progress-circular
            v-if="isLoading"
            indeterminate
            :color="$prdStyles('color-primary')"
          ></v-progress-circular>
        </v-row>

        <v-row no-gutters justify="center">
          <h4 v-if="!isLoading && priceResults.length == 0">
            Nenhum produto encontrado
          </h4>
        </v-row>

        <div v-if="!isLoading && priceResults.length != 0">
          <v-row no-gutters align="center" class="mb-4">
            <h4>{{ priceResults.productName }}</h4>
            <v-spacer></v-spacer>
            <!-- HEADER ACTIONS -->
            <div class="my-flex font-weight-bold">
              <div class="my-flex mr-4">
                <v-icon color="#37B352" left small
                  >mdi-arrow-up-bold-outline</v-icon
                >
                {{ formatMonetary(priceResults.maxPrice) }}
              </div>
              <div class="my-flex mr-4">
                <v-icon :color="$prdStyles('color-primary')" left small
                  >mdi-minus-circle-outline</v-icon
                >
                {{ formatMonetary(priceResults.avgPrice) }}
              </div>
              <div class="my-flex mr-4">
                <v-icon color="#B00020" left small
                  >mdi-arrow-down-bold-outline</v-icon
                >
                {{ formatMonetary(priceResults.minPrice) }}
              </div>
            </div>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="priceResults.priceResults"
            :items-per-page="5"
          >
            <!-- product name -->
            <template v-slot:[`item.productName`]="{ item }">
              <td class="table-product-name">
                <div class="copy-box">
                  <v-icon
                    left
                    @click.stop="copyFunction(item.productName)"
                    small
                    :color="$prdStyles('color-primary')"
                    class="copy-button"
                    >mdi-content-copy</v-icon
                  >
                  <a :href="item.productLink" target="_blank">
                    {{ item.productName }}</a
                  >
                </div>
              </td>
            </template>
            <!-- seller name -->
            <template v-slot:[`item.sellerName`]="{ item }">
              <td class="text-center">
                <div
                  style="max-height: 60px; overflow: hidden; text-align: center"
                >
                  {{ item.sellerName }}
                </div>
              </td>
            </template>
            <!-- price -->
            <template v-slot:[`item.price`]="{ item }">
              <td class="text-center">
                {{ formatMonetary(item.price) }}
              </td>
            </template>
            <!-- Crawler date -->
            <template v-slot:[`item.crawlerDate`]="{ item }">
              <td class="text-center">
                {{ item.crawlerDate.slice(0, 10) }}
              </td>
            </template>
            <!-- price_unit_type -->
            <template v-slot:[`item.price_unit_type`]="{ item }">
              {{ item.price_unit_type | unityToString }}
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import service from "@/service/ai-pricing/index.js";
import { formatMonetary } from "@/utils/format-toMonetary.js";

const Service = new service();
export default {
  props: ["idMonitoringItem"],
  data() {
    return {
      isModalOpen: false,
      isTableMounted: false,
      isLoading: false,
      headers: [
        {
          text: this.$i18n.t("TXT_PRODUCT"),
          align: "center",
          sortable: true,
          value: "productName",
          width: "300px",
        },
        {
          text: this.$i18n.t("TXT_BRAND"),
          align: "center",
          sortable: true,
          value: "productBrand",
          width: "170px",
        },

        {
          text: this.$i18n.t("TXT_MARKETPLACE"),
          align: "center",
          sortable: true,
          value: "marketplace",
          width: "200px",
        },
        {
          text: this.$i18n.t("TXT_SELLER"),
          align: "center",
          sortable: true,
          value: "sellerName",
          width: "180px",
        },
        {
          text: this.$i18n.t("TXT_PRICE"),
          align: "center",
          sortable: true,
          value: "price",
          width: "120px",
        },
        {
          text: this.$i18n.t("TXT_PAYMENT"),
          align: "center",
          sortable: true,
          value: "paymentType",
          width: "150px",
        },
        {
          text: this.$i18n.t("TXT_COLECT_DATE"),
          align: "center",
          sortable: true,
          value: "crawlerDate",
          width: "180px",
        },
      ],

      priceResults: [],
    };
  },

  watch: {
    isModalOpen: {
      handler() {
        if (this.isModalOpen && !this.isTableMounted) {
          this.isLoading = true;

          let request = {
            groupBy: 1,
            idCompany: this.idCompany,
            endDate: this.getDateRange[1],
            startDate: this.getDateRange[0],
            filters: {
              idProducts: [this.idMonitoringItem],
            },
          };

          Service.predimonitorTable(request)
            .then((res) => {
              let response = res.data.answer.registers;

              if (response.length != 0) {
                this.priceResults = res.data.answer.registers[0];
              }

              this.isTableMounted = true;
              this.isLoading = false;
            })
            .catch((error) => {
              console.log("Erro na busca do predimonitor", error);
              this.isLoading = false;
            });
        }
      },
    },
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    getDateRange() {
      var currentDate = new Date();
      var endDate = currentDate.toISOString().slice(0, 10);

      currentDate.setDate(currentDate.getDate() - 7);
      var startDate = currentDate.toISOString().slice(0, 10);

      return [startDate, endDate];
    },
  },

  methods: {
    formatMonetary,
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex !important;
  align-items: center !important;
}
.flex-space-between {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.text-center {
  text-align: center !important;
}

.table-product-name {
  max-width: 350px !important;
  padding: 0.8rem !important;
}

.copy-box {
  max-height: 40px !important;
  overflow: hidden !important;
}
</style>