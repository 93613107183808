var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pa-4"},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[(_vm.hasPreFilters)?_c('v-col',{class:_vm.idCompany == 2858 ? 'mr-3' : 'mr-6',attrs:{"cols":"12","md":"3"}},_vm._l((_vm.preFilters),function(item){return _c('combo-box',{key:item.idEnterprisePriceGroupDefaultFilter,attrs:{"title":_vm.$t('TXT_SELECT_A') + item.fieldName,"items":item.values,"text":'description',"loading":_vm.service.getAllFilterGroups.isLoading,"disabled":_vm.loadingProductList ||
            _vm.service.getAllFilterGroups.isLoading ||
            _vm.service.calculateProducts.isLoading ||
            _vm.service.listPriceGroups.isLoading ||
            _vm.service.calculateProducts.isLoading ||
            item.values.length == 0,"multiple":false,"hideIcon":""},on:{"input":function($event){return _vm.selectPreFilter($event, item)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})}),1):_vm._e(),(_vm.idCompany == 2858)?_c('v-col',{staticClass:"mr-3",attrs:{"cols":"auto","align-self":"end"}},[_c('date-select',{attrs:{"disabledIcon":_vm.loadingProductList ||
            _vm.service.getAllFilterGroups.isLoading ||
            _vm.service.calculateProducts.isLoading ||
            _vm.service.listPriceGroups.isLoading ||
            _vm.service.calculateProducts.isLoading},on:{"selectDate":function($event){_vm.selectDate = $event}}})],1):_vm._e(),_c('v-col',{staticClass:"mr-4",attrs:{"cols":"12","md":"3"}},[_c('label',{directives:[{name:"t",rawName:"v-t",value:('TXT_SELECT_PRICING'),expression:"'TXT_SELECT_PRICING'"}]}),_c('v-combobox',{staticClass:"mt-2",attrs:{"items":_vm.priceGroups,"loading":_vm.service.listPriceGroups.isLoading,"disabled":_vm.loadingProductList ||
            _vm.service.listProducts.isLoading ||
            _vm.service.calculateProducts.isLoading ||
            !_vm.isAllPreFiltersSelected,"item-text":"name","item-value":"filter","dense":"","outlined":"","append-icon":"mdi-chevron-down","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [(
                _vm.idCompany == 3026 ||
                _vm.idCompany == 3210 ||
                _vm.idCompany == 3236 ||
                _vm.idCompany == 3202
              )?_c('v-row',{staticClass:"pa-3 my-1",class:{ 'light-green lighten-3': item.published },staticStyle:{"border-radius":"1rem"},attrs:{"no-gutters":"","align":"center","justify":"space-between"}},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.checkPrecification(item)}}},[_c('v-icon',{attrs:{"color":_vm.$prdStyles('color-primary')}},[_vm._v(" "+_vm._s(item.published ? "mdi-checkbox-marked-outline" : "mdi-checkbox-blank-outline")+" ")])],1)],1):(_vm.idCompany == 2858)?_c('v-row',{attrs:{"no-gutters":"","justify":"space-between","align":"center"}},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('delete-precification',{attrs:{"data":item,"idCompany":_vm.idCompany}})],1):_c('v-row',{attrs:{"no-gutters":""}},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])])]}}]),model:{value:(_vm.selectedPriceGroup),callback:function ($$v) {_vm.selectedPriceGroup=$$v},expression:"selectedPriceGroup"}})],1),(
          _vm.isAllPreFiltersSelected &&
          !_vm.service.listPriceGroups.isLoading &&
          !_vm.service.getAllFilterGroups.isLoading
        )?_c('Create-modal-main',{ref:"createPricingModal",attrs:{"preSelect":_vm.generateLockedFilters,"loadingProductList":_vm.loadingProductList,"selectedPreFilters":_vm.selectedPreFilters},on:{"calculateFinish":_vm.calculateFinish,"refreshPriceGroups":_vm.fetchPriceGroups}}):_vm._e(),(_vm.showCalculateToPeralta)?_c('Prd-btn-loading',{class:("mt-7 " + (_vm.idCompany == 2858 ? 'mr-2' : 'mr-4')),attrs:{"title":_vm.$t('TXT_RECALCULATE_PRICING_LOWER'),"disabled":_vm.loadingProductList ||
          !_vm.selectedPriceGroup ||
          (_vm.selectedPriceGroup && _vm.selectedPriceGroup.archive) ||
          _vm.service.getAllFilterGroups.isLoading ||
          _vm.service.calculateProductsRules.isLoading ||
          _vm.service.calculateProducts.isLoading ||
          (_vm.selectedPriceGroup && _vm.selectedPriceGroup.published) ||
          _vm.selectedPriceGroup.isProjectionPublished,"is-loading":_vm.service.calculateProducts.isLoading ||
          _vm.service.calculateProducts.isLoading,"outlined":""},on:{"click":function($event){_vm.controlRecalculateModal = true}}}):_vm._e(),_c('Prd-btn-loading',{staticClass:"mt-7",attrs:{"title":_vm.$t('TXT_RECALCULATE_RULES'),"disabled":_vm.loadingProductList ||
          !_vm.selectedPriceGroup ||
          _vm.service.getAllFilterGroups.isLoading ||
          _vm.service.calculateProductsRules.isLoading ||
          _vm.service.listPriceGroups.isLoading ||
          _vm.service.calculateProductsRules.isLoading ||
          (_vm.selectedPriceGroup && _vm.selectedPriceGroup.published) ||
          _vm.selectedPriceGroup.isProjectionPublished,"is-loading":_vm.service.calculateProductsRules.isLoading ||
          _vm.service.calculateProductsRules.isLoading,"outlined":""},on:{"click":_vm.calculateRules}})],1),(_vm.service.getAllFilterGroups.error)?_c('div',{staticClass:"color-error"},[_vm._v(" "+_vm._s(this.$i18n.t("TXT_ERROR_FIND_PRICING"))+": "+_vm._s(_vm.service.getAllFilterGroups.error)+" ")]):_vm._e(),(_vm.idCompany == 2858)?_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":"","align":"end"}},[_c('Variavel-tempo-ia')],1):_vm._e(),_c('h2',{directives:[{name:"t",rawName:"v-t",value:('TXT_FILTER'),expression:"'TXT_FILTER'"}],staticClass:"font-size-16 font-weight-bold mt-6 mb-4"}),_c('Filter-groups',{ref:"filterGroup",attrs:{"calcDate":_vm.calcDate,"isCalculating":_vm.isCalculating,"idPricingGroup":_vm.idEnterprisePriceGroups},on:{"input":function($event){_vm.projectionFilters = $event},"openCalcModal":_vm.openCalcModal,"openPricingLoading":_vm.openLoadingModal}})],1),_c('Products-list',{ref:"produtcsList",attrs:{"idEnterprisePriceGroups":_vm.idEnterprisePriceGroups,"filters":_vm.projectionFilters,"idPriceGroup":_vm.selectedPriceGroup && _vm.selectedPriceGroup.idEnterprisePriceGroups,"dataByRedirect":_vm.dataByRedirect,"isDisabledManualPrice":_vm.selectedPriceGroup && _vm.selectedPriceGroup.published,"calcDate":_vm.calcDate},on:{"loadingProductList":function($event){_vm.loadingProductList = $event}}}),_c('Precification-modal',{attrs:{"isOpen":_vm.isOpendModalConfirmChanges,"isLoading":_vm.isLoadingConfirmPublish,"precificationData":_vm.selectedPrecification},on:{"publishPricing":_vm.setPublishedPrecification,"closeModalPublishedPrecification":_vm.closeModalPublishedPrecification}}),_c('Recalculate-modal',{attrs:{"recalculateModal":_vm.controlRecalculateModal,"selectedPriceGroup":_vm.selectedPriceGroup},on:{"closeRecalculateModal":function($event){_vm.controlRecalculateModal = false},"recalculatePricing":function($event){return _vm.openCalcModal(true)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }