<template>
  <v-card class="pa-4">
    <h4 class="mb-2">
      DIRECIONADOR DE PREÇOS {{ this.product.tipoPrecificacao.toUpperCase() }}
    </h4>
    <div>
      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th v-for="captura in product.capturas" :key="captura.nomeCaptura"
              :style="{ width: 100 / (2 + product.capturas.length) + '%' }">
              {{ captura.nomeCaptura }}
            </th>
            <th :style="{ width: 100 / (2 + product.capturas.length) + '%' }">{{ product.nome }}</th>
            <th style="width: 18.75%">Δ</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td class="item">Dose</td>
            <td v-for="captura in product.capturas" :key="captura.nomeCaptura + 'dose'">
              <div>
                <span>{{ formatVal(captura.doseCompetidor, 2) }}</span>
              </div>
            </td>
            <td>
              <div>
                <span>{{ formatVal(product.dose, 2) }}</span>
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td rowspan="2" class="currency">BRL</td>
            <td class="item">Preço kg/l</td>
            <td v-for="captura in product.capturas" :key="captura.nomeCaptura + 'precoKgL'">
              {{ setCurrencyUPL(captura.precoQuiloCompetidor, false) }}
            </td>
            <td>{{ setCurrencyUPL(product.precoQuilo, false) }}</td>
            <td rowspan="2" :class="deltaClass(deltaReal)">
              {{ deltaReal + ' %' }}
            </td>
          </tr>
          <tr>
            <td class="item">Custo/hectare</td>
            <td v-for="captura in product.capturas" :key="captura.nomeCaptura + 'custoHectare'">
              {{ setCurrencyUPL(captura.precoCustoHectareCompetidor, false) }}
            </td>
            <td>{{ setCurrencyUPL(product.precoCustoHectare, false) }}</td>
          </tr>
          <tr>
            <td rowspan="2" class="currency">USD</td>
            <td class="item">Preço kg/l</td>
            <td v-for="captura in product.capturas" :key="captura.nomeCaptura + 'precoKgLDolar'">
              {{ calcDolar(captura.precoQuiloCompetidor) }}
            </td>
            <td>{{ calcDolar(product.precoQuilo) }}</td>
            <td rowspan="2" :class="deltaClass(deltaDolar)">
              {{ deltaDolar + ' %' }}
            </td>
          </tr>
          <tr>
            <td class="item">Custo/hectare</td>
            <td v-for="captura in product.capturas" :key="captura.nomeCaptura + 'custoHectareDolar'">
              {{ calcDolar(captura.precoCustoHectareCompetidor) }}
            </td>
            <td>{{ calcDolar(product.precoCustoHectare) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ["product"],
  data() {
    return {
      itemsUfBrandSemCap: [],
      isEdit: true,
      number: 10,
      number2: -10,
    };
  },
  computed: {
    valorDolar() {
      return parseFloat(localStorage.getItem("valorDolar") || "5.0"); // Padrão para 5.0 se não estiver definido
    },
    deltaReal() {
      const totalCompetidor = this.product.capturas.reduce((acc, cur) => acc + cur.precoCustoHectareCompetidor, 0);
      return this.calcPercentage(totalCompetidor, this.product.precoCustoHectare);
    },
    deltaDolar() {
      const totalCompetidor = this.product.capturas.reduce((acc, cur) => acc + cur.precoCustoHectareCompetidor, 0);
      return this.calcPercentage(this.calcDolarWithoutString(totalCompetidor), this.calcDolarWithoutString(this.product.precoCustoHectare));
    }
  },
  methods: {
    deltaClass(delta) {
      if (delta > 0) return 'font-weight-bold green--text';
      else if (delta < 0) return 'font-weight-bold red--text';
      return '';
    },
    calcDolar(val) {
      return this.setCurrencyUPL(val / this.valorDolar, true);
    },
    calcDolarWithoutString(val) {
      return (val / this.valorDolar).toFixed(2);
    },
    formatVal(val, decimalPlaces) {
      return (val).toFixed(decimalPlaces).replace(".", ",");
    },
    calcPercentage(total, own) {
      return (((own / total) - 1) * 100).toFixed(0).replace(".", ",");
    },
    setCurrencyUPL(value, useDollar) {
      if (value != null) {
        value = value.toString().replace(',', '.');
        let [integerPart, decimalPart] = value.split('.');
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        decimalPart = (decimalPart || '').padEnd(2, '0').substring(0, 2);
        const currency = useDollar ? "$" : "R$";
        return `${currency} ${integerPart},${decimalPart}`;
      } else {
        return "-";
      }
    }
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border-collapse: collapse;

  thead {
    tr th:first-child {
      background-color: transparent;
      border: 1px solid transparent;
    }

    tr th:nth-child(2) {
      background-color: transparent;
      border: 1px solid transparent;
    }

    tr th:nth-child(3) {
      border-top-left-radius: 10px;
    }

    tr th:last-child {
      border-top-right-radius: 10px;
    }

    tr th {
      background-color: #3c5ca7;
      color: white;
      border: 2px solid white;
      padding: 5px;
    }
  }

  td {
    padding: 8px;
    border: 2px solid white;
    text-align: center;
  }

  tbody {
    td {
      background-color: #f5f5f5;
    }

    .currency {
      writing-mode: vertical-rl;
      background-color: #3c5ca7;
      color: white;
      font-weight: bold;
      width: 8px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    .item {
      background-color: #cccccc;
      font-weight: bold;
    }

    .item:first-child {
      background-color: #cccccc;
      font-weight: bold;
    }
  }
}

.hidden-cell {
  background-color: transparent;
  border: 1px solid transparent;
}
</style>