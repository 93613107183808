<template>
  <div>
    <highcharts :options="chartOptions" />
  </div>
</template>

<script>
import chartData from "./chart-options.js";
export default {
  props: ["tab", "data"],
  data: () => ({
    chartOptions: {},
  }),

  watch: {
    data: {
      handler(value) {
        let chartData = this.formatChartResponse(value, this.tab);
        let translatedCategorie = this.translateCategories(
          value?.categories ?? []
        );
        this.chartOptions = this.chartDataOption(
          chartData,
          this.tab,
          translatedCategorie
        );
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    VEM() {
      return this.idCompany == 3026 ? true : false;
    },
  },

  methods: {
    chartDataOption(data, tab, categories) {
      let yAxis = this.resolveYAxis(tab);

      return chartData(
        tab,
        data,
        yAxis,
        categories,
        this.$i18n.t("TXT_MONTH_YEAR")
      );
    },

    formatChartResponse(responseData, tab) {
      if (!responseData) return [];
      let chartData = [];

      if (tab == 0) {
        chartData.push({
          name: this.$i18n.t("TXT_ANTERIOR_ADOPTION"),
          data: responseData?.adoptions?.data,
          color: "#EC4C37",
        });

        chartData.push({
          name: this.$i18n.t("TXT_PROJECTED_ADOPTION"),
          data: responseData?.notAdoptions?.data,
          color: "#3c5ca7",
        });

        chartData.push({
          name: this.$i18n.t("TXT_REALIZED_ADOPTION"),
          data: responseData?.pricingCount?.data,
          color: "#9510AC ",
        });

        if (this.VEM) {
          chartData.push({
            name: this.$i18n.t("TXT_ROYALT_ADDITIONAL"),
            type: "spline",
            dataLabels: {
              enabled: false,
            },
            color: "#12CED4",
            data: responseData?.royalties?.data,
            yAxis: 1,
          });

          chartData.push({
            name: this.$i18n.t("TXT_GOAL"),
            type: "line",
            dataLabels: {
              enabled: false,
            },
            marker: {
              enabled: false,
            },
            color: "#F2BB13",
            data: Array(12).fill(responseData?.goal),
            yAxis: 1,
          });
        }
      } else if (tab == 1) {
        chartData.push({
          name: this.$i18n.t("TXT_ANTERIOR_DEMAND"),
          data: responseData?.previousDemand?.data ?? [],
          color: "#EC4C37",
        });

        chartData.push({
          name: this.$i18n.t("TXT_PROJECTED_DEMAND"),
          data: responseData?.projectedDemand?.data ?? [],
          color: "#3c5ca7",
        });

        chartData.push({
          name: this.$i18n.t("TXT_REALIZED_DEMAND"),
          data: responseData?.realizedDemand?.data ?? [],
          color: "#9510AC ",
        });
      } else if (tab == 2) {
        chartData.push({
          name: this.$i18n.t("TXT_ANTERIOR_RECIPE"),
          data: responseData?.previousRevenue?.data ?? [],
          color: "#EC4C37",
        });

        chartData.push({
          name: this.$i18n.t("TXT_PROJECTED_RECIPE"),
          data: responseData?.projectedRevenue?.data ?? [],
          color: "#3c5ca7",
        });

        chartData.push({
          name: this.$i18n.t("TXT_REALIZED_RECIPE"),
          data: responseData?.realizedRevenue?.data ?? [],
          color: "#9510AC ",
        });

        if (this.VEM) {
          chartData.push({
            name: this.$i18n.t("TXT_ROYALT_ADDITIONAL"),
            type: "spline",
            dataLabels: {
              enabled: false,
            },
            color: "#12CED4",
            data: responseData?.royalties?.data,
          });

          chartData.push({
            name: this.$i18n.t("TXT_GOAL"),
            type: "line",
            dataLabels: {
              enabled: false,
            },
            marker: {
              enabled: false,
            },
            color: "#F2BB13",
            data: Array(12).fill(responseData?.goal),
          });
        }
      } else if (tab == 3) {
        chartData.push({
          name: this.$i18n.t("TXT_ANTERIOR_MARGIN"),
          data: responseData?.previousMargin?.data ?? [],
          color: "#EC4C37",
        });

        chartData.push({
          name: this.$i18n.t("TXT_PROJECTED_MARGIN"),
          data: responseData?.projectedMargin?.data ?? [],
          color: "#3c5ca7",
        });

        chartData.push({
          name: this.$i18n.t("TXT_REALIZED_MARGIN"),
          data: responseData?.realizedMargin?.data ?? [],
          color: "#9510AC ",
        });
      }

      return chartData;
    },

    translateCategories(categories) {
      return categories.map(
        (category) => `${this.$i18n.t(category.month)} / ${category.year}`
      );
    },

    resolveYAxis(tab) {
      let yAxis = [];

      const chartTypes = [
        this.$i18n.t("TXT_UNIT"),
        this.$i18n.t("TXT_UNIT"),
        `${this.$i18n.t("TXT_RECIPE")} (R$)`,
        `${this.$i18n.t("TXT_MARGIN")} (%)`,
      ];

      yAxis.push({
        title: {
          text: chartTypes[tab],
        },
        labels: {
          formatter: function () {
            return tab == 3 ? this.value * 100 : this.value;
          },
        },
      });

      if (tab == 0) {
        yAxis.push({
          title: {
            text: `${this.$i18n.t("TXT_RECIPE")} (R$)`,
          },
          opposite: true,
        });
      }

      return yAxis;
    },
  },
};
</script>

<style>
</style>