<template>
  <div class="expansive-panel mb-2" :class="{ open: isOpen }">
    <div class="header" @click="togglePanel">
      <span
        >{{ filter.fieldName }}:
        <span class="font-weight-bold"
          >{{ filter.values.length }} {{ $t("TXT_SELECTED") }}</span
        >
      </span>

      <div>
        <v-icon>{{ icon }}</v-icon>
      </div>
    </div>
    <div class="content" v-show="isOpen" @transitionend="handleTransitionEnd">
      <slot>
        <div class="list-main">
          <span
            v-for="value in filter.values"
            :key="value.id"
            class="list-item"
          >
            {{ value.text }}
          </span>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["filter"],
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    icon() {
      return this.isOpen ? "mdi-chevron-up" : "mdi-chevron-down";
    },
  },
  methods: {
    togglePanel() {
      this.isOpen = !this.isOpen;
    },
    handleTransitionEnd() {
      if (!this.isOpen) {
        this.$refs.content.style.height = "0";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.expansive-panel {
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.content {
  height: 0;
  overflow: hidden;
  transition: height 0.5s ease-out;
}

.open .content {
  height: auto;
}
.list-main {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .list-item {
    border-bottom: 0.5px solid #ccc;
    padding: 4px 0px;
  }
}
</style>
