/**
 * @param {number | null | boolean | undefined} price
 * @param {number | null | boolean | undefined} avgPrice Note: IC stands for
 *   Competitivity Index (Índice de competitividade)
 */
export function calculateIC(price, avgPrice) {
  if (
    typeof price !== "number" ||
    typeof avgPrice !== "number" ||
    Number.isNaN(price) ||
    Number.isNaN(avgPrice)
  )
    return null;

  return (price * 100) / avgPrice;
}

/**
 * @param {number | null | boolean | undefined} ic
 * @param {number | null | boolean | undefined} avgPrice Note: IC stands for
 *   Competitivity Index (Índice de competitividade)
 */
export function calculatePriceFromIC(ic, avgPrice) {
  if (
    typeof ic !== "number" ||
    typeof avgPrice !== "number" ||
    Number.isNaN(ic) ||
    Number.isNaN(avgPrice)
  )
    return null;

  return (ic * avgPrice) / 100;
}
