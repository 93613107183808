import { render, staticRenderFns } from "./thermometer-chart-2.vue?vue&type=template&id=0d0806c8&scoped=true&"
import script from "./thermometer-chart-2.vue?vue&type=script&lang=js&"
export * from "./thermometer-chart-2.vue?vue&type=script&lang=js&"
import style0 from "./thermometer-chart-2.vue?vue&type=style&index=0&id=0d0806c8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d0806c8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VIcon,VRow})
