import { render, staticRenderFns } from "./market-analysis.vue?vue&type=template&id=71c3ca82&scoped=true&"
import script from "./market-analysis.vue?vue&type=script&lang=js&"
export * from "./market-analysis.vue?vue&type=script&lang=js&"
import style0 from "./market-analysis.vue?vue&type=style&index=0&id=71c3ca82&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71c3ca82",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VIcon,VSpacer})
