<template>
  <div>
    <v-icon
      size="28"
      @click.stop="isOpen = true"
      :color="$prdStyles('color-warning')"
    >
      mdi-close-box
    </v-icon>
    <v-dialog v-model="isOpen" persistent max-width="450">
      <v-card class="pa-4">
        <v-row no-gutters class="gap-2 mb-2">
          <h3 v-t="'TXT_DELETE_PRICING'"></h3>
        </v-row>
        <v-row no-gutters justify="center" class="red-border mb-4">
          <v-col cols="12">
            <v-row v-if="data.name" no-gutters class="gap-2 mb-2">
              <h4>{{ $i18n.t("TXT_NAME") }}:</h4>
              <span>{{ data.name }}</span>
            </v-row>
            <v-row v-if="data.calcDate" no-gutters class="gap-2 mb-2">
              <h4>{{ $i18n.t("TXT_LAST_UPDATE") }}:</h4>
              <span>{{ formatDate(data.calcDate) }}</span>
            </v-row>
            <span class="mb-2">
              <v-icon :color="$prdStyles('color-warning')" class="mr-2"
                >mdi-alert-outline</v-icon
              >
              {{ $i18n.t("TXT_CONFIRM_OPERATION") }}
            </span>
          </v-col>
        </v-row>
        <v-row no-gutters justify="end">
          <prd-btn
            :disabled="isLoading"
            @click="isOpen = false"
            :title="$i18n.t('TXT_CANCEL')"
            :outlined="true"
            class="mr-2"
          />
          <prd-btn
            :color="$prdStyles('color-warning')"
            :disabled="isLoading"
            :loading="isLoading"
            @click="deletePrecification"
            :title="$i18n.t('TXT_DELETE')"
          />
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
import AiPricingService from "@/service/ai-pricing";

export default {
  components: { prdBtn },
  props: ["data", "idCompany"],
  data() {
    return {
      isOpen: false,
      isLoading: false,
      service: new AiPricingService(),
    };
  },
  methods: {
    formatDate(date) {
      const splitedDateOnT = date.split("T")[0];
      const splitedDateOnDash = splitedDateOnT.split("-");
      const day = splitedDateOnDash[2];
      const month = splitedDateOnDash[1];
      const year = splitedDateOnDash[0];
      return `${day}/${month}/${year}`;
    },
    deletePrecification() {
      this.isLoading = true;
      const id = this.data.idEnterprisePriceGroups;
      this.service
        .deletePrecification(id, this.idCompany)
        .then(() => {
          this.$store.commit(
            "snackbar-success",
            "Precificação excluída com sucesso !"
          );
          this.isOpen = false;
          this.$emit("refreshList");
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit(
            "snackbar-error",
            "Erro ao deletar precificação, tente novamente, se o problema persistir, contate a PREDIFY !"
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.red-border {
  border: 2px solid red;
  border-radius: 8px;
  padding: 8px;
}
</style>