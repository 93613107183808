<template>
  <v-expansion-panel-header :class="{ disablePanel: disablePanel }">
    <div class="flex-space-between">
      <div class="my-flex">
        <span class="font-weight-bold">{{ product.productName }}</span>
        <Add-product-manual-modal
          :productName="product.productName"
          :idMonitoringItem="product.idMonitoringItem"
          :dateRange="dateRange"
          :disablePanel="disablePanel"
          :idTask="idTask"
          :isDisable="item.status !== 2"
          @refreshProduct="refreshProduct"
        />
        <Prd-tooltip
          :text="$t('TXT_ADD_CRAWLER_MANUAL')"
          v-if="item.status !== 2"
        />
      </div>
      <div class="my-flex font-weight-bold">
        <div class="my-flex mr-4">
          <v-icon color="#37B352" left small>mdi-arrow-up-bold-outline</v-icon>
          {{ formatMonetary(product.maxPrice) }}
        </div>
        <div class="my-flex mr-4">
          <v-icon :color="$prdStyles('color-primary')" left small
            >mdi-minus-circle-outline</v-icon
          >
          {{ formatMonetary(product.avgPrice) }}
        </div>
        <div class="my-flex mr-4">
          <v-icon color="#B00020" left small
            >mdi-arrow-down-bold-outline</v-icon
          >
          {{ formatMonetary(product.minPrice) }}
        </div>
      </div>
    </div>
  </v-expansion-panel-header>
</template>

<script>
import { formatMonetary } from "@/utils/format-toMonetary.js";
import AddProductManualModal from "@/views/main/predimonitor-V2/components/add-product-manual/add-product-manual-modal.vue";
import moment from "moment";
import PrdTooltip from "@/Design_System/common/prd-tooltip.vue";

export default {
  components: { AddProductManualModal, PrdTooltip },
  props: ["product", "item"],

  methods: {
    formatMonetary,
    refreshProduct() {
      this.$emit("refreshProduct", this.product.idMonitoringItem);
    },
  },

  computed: {
    disablePanel() {
      if (!this.product?.priceResults) return true;

      return false;
    },

    dateRange() {
      if (!this.item.createDate || !this.item.updateDate) {
        return [];
      }
      return [
        moment(this.item.createDate).format("YYYY-MM-DD"),
        moment(this.item.updateDate).format("YYYY-MM-DD"),
      ];
    },

    idTask() {
      return this.item?.listMonitoringCrawlers[0]?.idTask ?? null;
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-space-between {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.my-flex {
  display: flex !important;
  align-items: center !important;
}

.disablePanel {
  pointer-events: none;
  color: rgba(0, 0, 0, 0.295);
  background-color: rgba(255, 255, 255, 0.089);
}
</style>