<template>
  <div>
    <v-row no-gutters justify="center" class="mt-4">
      <DiscountMatrixTable
        v-if="subtitle == '' && name == 'DISCOUNT MATRIX'"
        :name="name"
        :subtitle="subtitle"
        :headers="headers"
        :items="items"
        @updateOriginalItemsModified="
          $emit('updateOriginalItemsModified', $event)
        "
        @updateMarketingVariationMatrix="
          $emit('updateMarketingVariationMatrix', $event)
        "
        @setBadgeDiscountMatrix="$emit('setBadgeDiscountMatrix', $event)"
      />
      <Marketing-variation-matrix-table
        v-else-if="subtitle == '' && name == 'MARKETING VARIATION MATRIX'"
        :name="name"
        :subtitle="subtitle"
        :headers="headers"
        :items="items"
        @updateAllOriginalItems="$emit('updateAllOriginalItems', $event)"
        @setBadgeRegionalizationMatrix="
          $emit('setBadgeRegionalizationMatrix', $event)
        "
      />

      <TargetMarketSegmentationMain
        v-else-if="
          subtitle == 'AGRUPAMENTOS' && name == 'SEGMENTAÇÃO DE MERCADO'
        "
        :name="name"
        :subtitle="subtitle"
        :headers="headers"
        :items="items"
        @refreshTargetMarketSegmentationTable="
          $emit('refreshTargetMarketSegmentationTable')
        "
      />

      <v-col v-else>
        <h4>{{ name }}</h4>
        <label>{{ subtitle }}</label>

        <v-data-table
          v-if="
            subtitle == 'FUTURE VALUE | CANAL GERAL' && name == 'DELIBERATION'
          "
          :headers="headers"
          :items="localItems"
          class="mt-4"
          :hide-default-footer="localItems == [] || localItems.length == 0"
          :footer-props="footerProps"
          :no-data-text="$t('TXT_NO_DATA_AVAILABLE')"
        >
          <template v-slot:[`item.percentLMUSD`]="{ item }">
            {{ item.percentLMUSD + " %" }}
          </template>
          <template v-slot:[`item.percentLMBRL`]="{ item }">
            {{ item.percentLMBRL + " %" }}
          </template>
          <template v-slot:[`item.unitPriceBRLOnePage`]="{ item }">
            {{ setCurrencyUPL(item.unitPriceBRLOnePage, false) }}
          </template>
          <template v-slot:[`item.unitPriceUSDOnePage`]="{ item }">
            {{ setCurrencyUPL(item.unitPriceUSDOnePage, true) }}
          </template>
          <template v-slot:[`item.iaPriceBRL`]="{ item }">
            {{ setCurrencyUPL(item.iaPriceBRL, false) }}
          </template>
          <template v-slot:[`item.iaPriceUSD`]="{ item }">
            {{ setCurrencyUPL(item.iaPriceUSD, true) }}
          </template>

          <template v-slot:[`item.unitPriceListUSD`]="{ item }">
            <v-tooltip bottom content-class="custom-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <div v-if="!item.isEditUSD">
                    {{ setCurrencyUPL(item.unitPriceListUSD, true) }}
                    <v-icon
                      v-if="isAdminUpl"
                      :color="$prdStyles('color-primary')"
                      @click="item.isEditUSD = true"
                      right
                    >
                      mdi-pencil-box</v-icon
                    >
                  </div>

                  <div v-else class="my-flex">
                    <v-text-field
                      :loading="item.isLoading"
                      :disabled="item.isLoading"
                      v-model="item.unitPriceListUSD"
                      outlined
                      dense
                      hide-details
                      style="max-width: 120px"
                    >
                    </v-text-field>
                    <v-icon
                      :disabled="item.isLoading"
                      @click="editUnitPriceDeliberation('USD', item)"
                      color="#1D8527"
                    >
                      mdi-checkbox-outline
                    </v-icon>
                    <v-icon
                      :disabled="item.isLoading"
                      @click="closeEditModeDeliberation('USD', item)"
                      color="#B00020"
                    >
                      mdi-close-box
                    </v-icon>
                  </div>
                </div>
              </template>
              Last Price:
              {{ setCurrencyUPL(item.lastPriceUSD, true) }}
            </v-tooltip>
          </template>

          <template v-slot:[`item.unitPriceListBRL`]="{ item }">
            <v-tooltip bottom content-class="custom-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <div v-if="!item.isEditBRL">
                    {{ setCurrencyUPL(item.unitPriceListBRL, false) }}
                    <v-icon
                      v-if="isAdminUpl"
                      :color="$prdStyles('color-primary')"
                      @click="item.isEditBRL = true"
                      right
                    >
                      mdi-pencil-box</v-icon
                    >
                  </div>
                  <div v-else class="my-flex">
                    <v-text-field
                      :loading="item.isLoading"
                      :disabled="item.isLoading"
                      v-model="item.unitPriceListBRL"
                      outlined
                      dense
                      hide-details
                      style="max-width: 120px"
                    >
                    </v-text-field>
                    <v-icon
                      :disabled="item.isLoading"
                      @click="editUnitPriceDeliberation('BRL', item)"
                      color="#1D8527"
                    >
                      mdi-checkbox-outline
                    </v-icon>
                    <v-icon
                      :disabled="item.isLoading"
                      @click="closeEditModeDeliberation('BRL', item)"
                      color="#B00020"
                    >
                      mdi-close-box
                    </v-icon>
                  </div>
                </div>
              </template>
              Last Price:
              {{ setCurrencyUPL(item.lastPriceBRL, true) }}
            </v-tooltip>
          </template>

          <template v-slot:[`item.coments`]="{ item }">
            <div v-if="!item.isEditComments">
              {{ item.coments }}
              <v-icon
                v-if="isAdminUpl"
                :color="$prdStyles('color-primary')"
                @click="item.isEditComments = true"
                right
              >
                mdi-pencil-box</v-icon
              >
            </div>

            <div v-else class="my-flex">
              <v-text-field
                :loading="item.isLoading"
                :disabled="item.isLoading"
                v-model="item.coments"
                outlined
                dense
                hide-details
              >
              </v-text-field>
              <v-icon
                :disabled="item.isLoading"
                @click="editUnitPriceDeliberation('Comments', item)"
                color="#1D8527"
              >
                mdi-checkbox-outline
              </v-icon>
              <v-icon
                :disabled="item.isLoading"
                @click="closeEditModeDeliberation('Comments', item)"
                color="#B00020"
              >
                mdi-close-box
              </v-icon>
            </div>
          </template>
        </v-data-table>

        <v-data-table
          v-else-if="subtitle == '' && name == 'INFO'"
          :headers="headers"
          :items="localItems"
          class="mt-4"
          :hide-default-footer="localItems == [] || localItems.length == 0"
          :footer-props="footerProps"
          :no-data-text="$t('TXT_NO_DATA_AVAILABLE')"
        >
          <template v-slot:[`item.priceBook`]="{ item, index }">
            <div v-if="!item.isEditPriceBook">
              {{ item.priceBook }}
              <v-icon
                v-if="isAdminUpl"
                :color="$prdStyles('color-primary')"
                @click="item.isEditPriceBook = true"
                right
              >
                mdi-pencil-box</v-icon
              >
            </div>

            <div v-else class="my-flex">
              <v-text-field
                :loading="item.isLoading"
                :disabled="item.isLoading"
                v-model="item.priceBook"
                outlined
                dense
                hide-details
                style="max-width: 120px"
              />
              <v-icon
                :disabled="item.isLoading"
                @click="editUnitPrice('priceBook', item, index)"
                color="#1D8527"
              >
                mdi-checkbox-outline
              </v-icon>
              <v-icon
                :disabled="item.isLoading"
                @click="closeEditMode('priceBook', item, index)"
                color="#B00020"
              >
                mdi-close-box
              </v-icon>
            </div>
          </template>

          <template v-slot:[`item.keepOnList`]="{ item, index }">
            <div v-if="!item.isEditKeepOnList">
              <span v-if="item.keepOnList">Sim</span>
              <span v-else>Não</span>
              <v-icon
                v-if="isAdminUpl"
                :color="$prdStyles('color-primary')"
                @click="item.isEditKeepOnList = true"
                right
              >
                mdi-pencil-box</v-icon
              >
            </div>

            <div v-else class="my-flex">
              <v-select
                :loading="item.isLoading"
                :disabled="item.isLoading"
                v-model="item.keepOnList"
                :items="boolItens"
                outlined
                dense
                hide-details
                style="max-width: 120px"
              />
              <v-icon
                :disabled="item.isLoading"
                @click="editUnitPrice('keepOnList', item, index)"
                color="#1D8527"
              >
                mdi-checkbox-outline
              </v-icon>
              <v-icon
                :disabled="item.isLoading"
                @click="closeEditMode('keepOnList', item, index)"
                color="#B00020"
              >
                mdi-close-box
              </v-icon>
            </div>
          </template>
        </v-data-table>

        <v-data-table
          v-else
          :headers="headers"
          :items="localItems"
          class="mt-4"
          :hide-default-footer="localItems == [] || localItems.length == 0"
          :footer-props="footerProps"
          :no-data-text="$t('TXT_NO_DATA_AVAILABLE')"
        >
          <template v-slot:[`item.percentLMUSD`]="{ item }">
            {{ item.percentLMUSD + " %" }}
          </template>
          <template v-slot:[`item.percentLMBRL`]="{ item }">
            {{ item.percentLMBRL + " %" }}
          </template>
          <template
            v-slot:[`item.grossUnitPriceFvBrlGeneralChannel`]="{ item }"
          >
            {{ setCurrencyUPL(item.grossUnitPriceFvBrlGeneralChannel, false) }}
          </template>
          <template v-slot:[`item.grossUnitPriceFvBrlKaVf`]="{ item }">
            {{ setCurrencyUPL(item.grossUnitPriceFvBrlKaVf, false) }}
          </template>
          <template v-slot:[`item.netUnitPriceFvBrlGeneralChannel`]="{ item }">
            {{ setCurrencyUPL(item.netUnitPriceFvBrlGeneralChannel, false) }}
          </template>
          <template v-slot:[`item.netUnitPriceFvBrlKaVf`]="{ item }">
            {{ setCurrencyUPL(item.netUnitPriceFvBrlKaVf, false) }}
          </template>
          <template v-slot:[`item.minGrossPriceFvBrlGeneralChannel`]="{ item }">
            {{ setCurrencyUPL(item.minGrossPriceFvBrlGeneralChannel, false) }}
          </template>
          <template v-slot:[`item.minGrossUnitPriceFvBrlKaVf`]="{ item }">
            {{ setCurrencyUPL(item.minGrossUnitPriceFvBrlKaVf, false) }}
          </template>
          <template v-slot:[`item.minNetPriceFvBrlGeneralChannel`]="{ item }">
            {{ setCurrencyUPL(item.minNetPriceFvBrlGeneralChannel, false) }}
          </template>
          <template v-slot:[`item.minNetUnitPriceFvBrlKaVf`]="{ item }">
            {{ setCurrencyUPL(item.minNetUnitPriceFvBrlKaVf, false) }}
          </template>

          <template
            v-slot:[`item.grossUnitPricePvBrlGeneralChannel`]="{ item }"
          >
            {{ setCurrencyUPL(item.grossUnitPricePvBrlGeneralChannel, false) }}
          </template>
          <template v-slot:[`item.grossUnitPricePvBrlKaVf`]="{ item }">
            {{ setCurrencyUPL(item.grossUnitPricePvBrlKaVf, false) }}
          </template>
          <template v-slot:[`item.netUnitPricePvBrlGeneralChannel`]="{ item }">
            {{ setCurrencyUPL(item.netUnitPricePvBrlGeneralChannel, false) }}
          </template>
          <template v-slot:[`item.netUnitPricePvBrlKaVf`]="{ item }">
            {{ setCurrencyUPL(item.netUnitPricePvBrlKaVf, false) }}
          </template>
          <template v-slot:[`item.minGrossPricePvBrlGeneralChannel`]="{ item }">
            {{ setCurrencyUPL(item.minGrossPricePvBrlGeneralChannel, false) }}
          </template>
          <template v-slot:[`item.minGrossUnitPricePvBrlKaVf`]="{ item }">
            {{ setCurrencyUPL(item.minGrossUnitPricePvBrlKaVf, false) }}
          </template>
          <template v-slot:[`item.minNetPricePvBrlGeneralChannel`]="{ item }">
            {{ setCurrencyUPL(item.minNetPricePvBrlGeneralChannel, false) }}
          </template>
          <template v-slot:[`item.minNetUnitPricePvBrlKaVf`]="{ item }">
            {{ setCurrencyUPL(item.minNetUnitPricePvBrlKaVf, false) }}
          </template>

          <template v-slot:[`item.minNetUnitPricePvUSDKaVf`]="{ item }">
            {{ setCurrencyUPL(item.minNetUnitPricePvUSDKaVf, true) }}
          </template>
          <template v-slot:[`item.minNetPricePvUSDGeneralChannel`]="{ item }">
            {{ setCurrencyUPL(item.minNetPricePvUSDGeneralChannel, true) }}
          </template>
          <template v-slot:[`item.minGrossUnitPricePvUSDKaVf`]="{ item }">
            {{ setCurrencyUPL(item.minGrossUnitPricePvUSDKaVf, true) }}
          </template>
          <template v-slot:[`item.minGrossPricePvUSDGeneralChannel`]="{ item }">
            {{ setCurrencyUPL(item.minGrossPricePvUSDGeneralChannel, true) }}
          </template>
          <template v-slot:[`item.netUnitPricePvUSDKaVf`]="{ item }">
            {{ setCurrencyUPL(item.netUnitPricePvUSDKaVf, true) }}
          </template>
          <template v-slot:[`item.netUnitPricePvUSDGeneralChannel`]="{ item }">
            {{ setCurrencyUPL(item.netUnitPricePvUSDGeneralChannel, true) }}
          </template>
          <template v-slot:[`item.grossUnitPricePvUSDKaVf`]="{ item }">
            {{ setCurrencyUPL(item.grossUnitPricePvUSDKaVf, true) }}
          </template>
          <template
            v-slot:[`item.grossUnitPricePvUSDGeneralChannel`]="{ item }"
          >
            {{ setCurrencyUPL(item.grossUnitPricePvUSDGeneralChannel, true) }}
          </template>

          <template v-slot:[`item.minNetUnitPriceFvUsdKaVf`]="{ item }">
            {{ setCurrencyUPL(item.minNetUnitPriceFvUsdKaVf, true) }}
          </template>
          <template v-slot:[`item.minNetPriceFvUsdGeneralChannel`]="{ item }">
            {{ setCurrencyUPL(item.minNetPriceFvUsdGeneralChannel, true) }}
          </template>
          <template v-slot:[`item.minGrossUnitPriceFvUsdKaVf`]="{ item }">
            {{ setCurrencyUPL(item.minGrossUnitPriceFvUsdKaVf, true) }}
          </template>
          <template v-slot:[`item.minGrossPriceFvUsdGeneralChannel`]="{ item }">
            {{ setCurrencyUPL(item.minGrossPriceFvUsdGeneralChannel, true) }}
          </template>
          <template v-slot:[`item.netUnitPriceFvUsdKaVf`]="{ item }">
            {{ setCurrencyUPL(item.netUnitPriceFvUsdKaVf, true) }}
          </template>
          <template v-slot:[`item.netUnitPriceFvUsdGeneralChannel`]="{ item }">
            {{ setCurrencyUPL(item.netUnitPriceFvUsdGeneralChannel, true) }}
          </template>
          <template
            v-slot:[`item.grossUnitPriceFvUsdGeneralChannel`]="{ item }"
          >
            {{ setCurrencyUPL(item.grossUnitPriceFvUsdGeneralChannel, true) }}
          </template>
          <template v-slot:[`item.grossUnitPriceFvUsdKaVf`]="{ item }">
            {{ setCurrencyUPL(item.grossUnitPriceFvUsdKaVf, true) }}
          </template>

          <template v-slot:[`item.unitPriceListUSD`]="{ item }">
            {{ setCurrencyUPL(item.unitPriceListUSD, true) }}
          </template>
          <template v-slot:[`item.unitPriceListBRL`]="{ item }">
            {{ setCurrencyUPL(item.unitPriceListBRL, false) }}
          </template>
          <template v-slot:[`item.ordersPrice`]="{ item }">
            {{ setCurrencyUPL(item.ordersPrice, true) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- MESSAGE COMPONENTS -->
    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.snackbarColor"
      :timeout="2000"
    >
      {{ snackbar.snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import UPLService from "@/service/upl";
import DiscountMatrixTable from "./components/discount-matrix-table.vue";
import MarketingVariationMatrixTable from "./components/marketing-variation-matrix-table.vue";
import TargetMarketSegmentationMain from "./components/target-market-segmentation/target-market-segmentation-main.vue";
export default {
  props: ["headers", "items", "name", "subtitle"],
  components: {
    DiscountMatrixTable,
    MarketingVariationMatrixTable,
    TargetMarketSegmentationMain,
  },
  data() {
    return {
      service: new UPLService(),
      localItems: null,
      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },
      boolItens: [
        { text: "Sim", value: true },
        { text: "Não", value: false },
      ],
      snackbar: {
        snackbar: false,
        snackbarText: null,
        snackbarColor: "#3C5CA7",
      },
    };
  },

  computed: {
    isAdminUpl() {
      return this.$store.getters.Acesso_E_Edicao_UPL_Geral || this.$store.getters.isPredifyAdmin;
    },
  },

  methods: {
    setSnackarType(number) {
      let text = null;
      let type = null;
      switch (number) {
        case 1:
          text = "Dados atualizados com sucesso !";
          type = "success";
          break;
        case 2:
          text = "Problemas ao atualizar dados, tente novamente !";
          type = "info";
          break;
        case 3:
          text =
            "Erro ao atualizar dados, tente novamente, se o problema persistir, contate a PREDIFY !";
          type = "error";
          break;
        default:
          break;
      }
      this.showSnackbar(text, type);
    },
    showSnackbar(text, type) {
      let snackbar = {
        snackbar: true,
        snackbarColor: null,
        snackbarText: null,
      };

      snackbar.snackbarText = text;

      switch (type) {
        case "success":
          snackbar.snackbarColor = "#56a667";
          break;
        case "error":
          snackbar.snackbarColor = "#ec4c37";
          break;
        case "info":
          snackbar.snackbarColor = "#3c5ca7";
      }
      this.snackbar = snackbar;
    },
    getEditName(name) {
      return `isEdit${name.charAt(0).toUpperCase()}${name.slice(1)}`;
    },
    closeEditMode(type, item, index) {
      const edit = this.getEditName(type);
      item[edit] = false;
      item[type] = this.items[index][type];
    },
    async editUnitPrice(type, item, index) {
      var request = {
        id: item.id,
        keepOnList: item.keepOnList,
        priceBook: item.priceBook,
      };
      const edit = this.getEditName(type);
      item.isLoading = true;
      await this.service
        .updateKeepOnListOrPriceBook(request)
        .then((res) => {
          if (res.status == 200) {
            this.updateOriginalData(index, res.data);
            item[edit] = false;
            this.localItems[index][type] = item[type];
            this.setSnackarType(1);
          } else {
            this.setSnackarType(2);
          }
        })
        .catch((error) => {
          console.log(error);
          item[type] = this.items[index][type];
          this.setSnackarType(3);
        })
        .finally(() => {
          item.isLoading = false;
        });
    },

    getFieldName(fieldType) {
      if (fieldType === "Comments") return "coments";
      return `unitPriceList${fieldType}`;
    },
    closeEditModeDeliberation(type, item) {
      const edit = `isEdit${type}`;
      item[edit] = false;
      const fieldName = this.getFieldName(type);
      const originalIndex = this.getIndex(item.id);
      item[fieldName] = this.items[originalIndex][fieldName];
    },
    updateOriginalData(indexItem, newData) {
      let body = { indexItem, newData };
      this.$emit("updateOriginalData", body);
    },
    getIndex(id) {
      return this.items.findIndex((item) => item.id === id);
    },
    async editUnitPriceDeliberation(type, item) {
      const originalIndex = this.getIndex(item.id);
      item.isLoading = true;
      await this.service
        .updateDeliberationDeliberation(item)
        .then((res) => {
          if (res.status == 200) {
            if (type != "Comments") {
              this.$emit("hasNewValues", true);
            }
            this.updateOriginalData(originalIndex, res.data);
            const response = res.data;
            item.id = response.id;
            item.unitPriceListUSD = response.unitPriceListUSD;
            item.unitPriceUSDOnePage = response.unitPriceUSDOnePage;
            item.iaPriceUSD = response.iaPriceUSD;
            item.percentLMUSD = response.percentLMUSD;
            item.iaPriceBRL = response.iaPriceBRL;
            item.unitPriceListBRL = response.unitPriceListBRL;
            item.unitPriceBRLOnePage = response.unitPriceBRLOnePage;
            item.percentLMBRL = response.percentLMBRL;
            item.coments = response.coments;
            item.brand = response.brand;
            item.segment = response.segment;
            item.isEditUSD = response.isEditUSD;
            item.isEditBRL = response.isEditBRL;
            item.isEditComments = response.isEditComments;
            item.isLoading = response.isLoading;
            item.lastPriceUSD = response.lastPriceUSD;
            item.lastPriceBRL = response.lastPriceBRL;
            this.setSnackarType(1);
          } else {
            this.setSnackarType(2);
          }
        })
        .catch((error) => {
          console.log(error);
          const fieldName = this.getFieldName(type);
          item[fieldName] = this.items[originalIndex][fieldName];
          this.setSnackarType(3);
        })
        .finally(() => {
          item.isLoading = false;
        });
    },

    setCurrencyUPL(value, useDollar) {
      if (value != null) {
        value = value.toString().replace(",", ".");

        let [integerPart, decimalPart] = value.split(".");

        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        decimalPart = (decimalPart || "").padEnd(2, "0").substring(0, 2); // Garante que tenha dois dígitos

        const currency = useDollar ? "$" : "R$";
        return `${currency} ${integerPart},${decimalPart}`;
      } else {
        return "-";
      }
    },
    vertifyProperties(list) {
      const firstProperty = "percentLMUSD";
      const secondProperty = "percentLMBRL";

      for (let i = 0; i < list.length; i++) {
        const object = list[i];
        if (!(firstProperty in object) || !(secondProperty in object))
          return false;
      }
      return true;
    },
    extractValue(string) {
      return string ? parseFloat(string.replace("%", "")) : 0;
    },
    formatValue(list) {
      let formatedList = null;
      formatedList = list.map((item) => {
        return {
          ...item,
          percentLMUSD: this.extractValue(item.percentLMUSD),
          percentLMBRL: this.extractValue(item.percentLMBRL),
        };
      });
      this.localItems = formatedList;
    },
  },

  watch: {
    items: {
      handler(value) {
        this.localItems = JSON.parse(JSON.stringify(value));
        const isCorrectArray = this.vertifyProperties(this.localItems);
        if (isCorrectArray) this.formatValue(this.items);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.custom-tooltip {
  background-color: black;
  color: white;
  max-width: 200px;
  text-align: center;
}
</style>