import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class FiltrosService extends ServiceBase {

  async postProductList(data) {
    return await axiosInstance.post(API_URL + "api/V2/Monitoring/ListMonitoringItemPaginated", data);
  }

  async getFilters(data) {
    return await axiosInstance.get(API_URL + "api/V2/PrediMonitor/Filters", { params: data });
  }

  async ListSavedFilters(data) {
    return await axiosInstance.get(API_URL + "api/V2/Monitoring/predimonitor/filters/list", { params: data });
  }

  async CUDFilters(data) {
    return await axiosInstance.post(API_URL + "api/V2/Monitoring/predimonitor/filters", data);
  }

  async getRegion(idCompany) {
    return await axiosInstance.get(API_URL + `/api/V2/PrediMonitor/RegionCountry?param.idCompany=${idCompany}`);
  }

  async getState(request) {
    return await axiosInstance.post(API_URL + '/api/V2/PrediMonitor/State', request);
  }

  async getCity(request) {
    return await axiosInstance.post(API_URL + "/api/V2/PrediMonitor/City", request);
  }

  async getNeighborhood(idCity) {
    return await axiosInstance.post(API_URL + "/api/V2/PrediMonitor/Neighborhood", idCity);
  }

  async getCompanyItemLimit(idCompany) {
    return await axiosInstance.get(API_URL + `api/V2/PrediMonitor/GetCompanyItemLimit?idCompany=${idCompany}`);
  }
}