<template>
  <v-expansion-panels>
    <Granularity-panel
      v-for="(product, index) in products"
      :product="product"
      :key="index"
      :index="index"
      :pricingName="pricingName"
    />
  </v-expansion-panels>
</template>
<script>
import GranularityPanel from "./components/granularity-item.vue";
export default {
  props: ["products", "pricingName"],
  components: { GranularityPanel },
};
</script>


