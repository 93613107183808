<template>
  <div>
    <Prd-modal
      :controlModal="recalculateModal"
      :title="$t('TXT_RECALCULATE_PRICING')"
      :titleDescription="$t('TXT_RECALCULATE_CONFIRMATION')"
      :icon="'mdi-alert-outline'"
      :iconColor="'#B00020'"
      :description="$t('TXT_RECALCULATE_DESCRIPTION')"
      :warningMessage="$t('TXT_RECALCULATE_WARNING')"
      :buttonTitle1="$t('TXT_CANCEL')"
      :buttonTitle2="$t('TXT_CONFIRM_RECALCULATION')"
      :buttonColor1="'#B00020'"
      :buttonColor2="'#B00020'"
      @actionButton1="$emit('closeRecalculateModal')"
      @actionButton2="$emit('recalculatePricing')"
    >
      <template slot="content">
        <v-row no-gutters justify="space-between" align="center">
          <div class="data-info">
            <p class="font-weight-bold ma-0 pa-0 mb-2">
              {{ pricingName }}
            </p>
            <span
              >{{ $t("TXT_LAST_CALCULATION") }}:
              <span class="font-weight-bold">{{ calcDate }}</span></span
            >
          </div>

          <Prd-btn
            :title="$t('TXT_EXPORT')"
            :leftIcon="true"
            :icon="'mdi-export-variant'"
            :disabled="isLoading"
            :loading="isLoading"
            @click="exportData"
          />
        </v-row>
      </template>
    </Prd-modal>
  </div>
</template>

<script>
import PrdModal from "@/components/common/prd-modal.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import { formatDate } from "@/utils/prd-format-date.js";
import service from "@/service/ai-pricing/create-pricing.js";
const Service = new service();
export default {
  props: [
    "recalculateModal",
    "selectedPriceGroup",
    "preFilters",
    "selectedPreFilter",
  ],
  components: { PrdModal, PrdBtn },
  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    pricingName() {
      return this.selectedPriceGroup?.name ?? "";
    },

    calcDate() {
      return this.formatDate(this.selectedPriceGroup?.calcDate ?? null);
    },

    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  methods: {
    formatDate,
    async exportData() {
      this.isLoading = true;

      try {
        let res = await Service.extractReport(
          this.idCompany,
          this.selectedPriceGroup.idEnterprisePriceGroups
        );
        var downloadLink = document.createElement("a");
        var blob = new Blob(["\ufeff", res.data]);
        var url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = "export.csv";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } catch (error) {
        console.log(error);
      }

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.data-info {
  display: flex;
  flex-direction: column;
}
</style>