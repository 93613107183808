<template>
  <v-tooltip right color="#f5f5f5">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        right
        v-bind="attrs"
        v-on="on"
        :size="sizeIcon"
        :color="colorIcon"
      >
        {{ icon }}</v-icon
      >
    </template>
    <span class="span" v-html="text"/>
  </v-tooltip>
</template>
  
  <script>
export default {
  name: "toolTipInformationComponent",
  data() {
    return {};
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "mdi-information-outline",
    },
    sizeIcon: {
      type: Number,
      default: 16,
    },
    colorIcon: {
      type: String,
      default: "#6792f8",
    },
  },
};
</script>
  
<style lang="scss" scoped>
.span {
  color: black;
  font-family: "Ubuntu";
  font-size: 12px;
}
</style>