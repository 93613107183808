<template>
  <div>
    <prd-btn
      :title="$i18n.t('TXT_IA_DAYS')"
      @click="isDialogOpen = true"
      :disabled="readOnly"
    />
    <v-dialog v-model="isDialogOpen" max-width="250" persistent>
      <v-card class="pa-4" flat id="new-action-dialog">
        <h4 class="text-center">Variáveis de Cálculos</h4>

        <v-row no-gutters class="mt-2 mb-4">
          <v-col>
            <label>Histórico de dias</label>
            <v-text-field
              v-if="historicoIa"
              v-model="historicoIa.dias"
              :disabled="isLoading"
              dense
              outlined
              hide-details
              type="number"
              class="mt-1"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="gap-4" justify="center">
          <prd-btn
            @click="isDialogOpen = false"
            :title="'Cancelar'"
            :outlined="true"
            :disabled="isLoading"
          />
          <prd-btn
            :title="'Salvar'"
            @click="saveHistorico"
            :loading="isLoading"
            :disabled="isLoading"
          />
        </v-row>
      </v-card>
    </v-dialog>
    <!-- MESSAGE COMPONENTS -->
    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.snackbarColor"
      :timeout="2000"
    >
      {{ snackbar.snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
import ServiceUPL from "@/service/upl/index";

export default {
  props: ["readOnly"],
  components: { prdBtn },
  data() {
    return {
      isDialogOpen: false,
      isLoading: false,
      historicoIa: null,
      service: new ServiceUPL(),
      snackbar: {
        snackbar: false,
        snackbarText: null,
        snackbarColor: "#3C5CA7",
      },
    };
  },
  methods: {
    showSnackbar(text, type) {
      let snackbar = {
        snackbar: true,
        snackbarColor: null,
        snackbarText: null,
      };

      snackbar.snackbarText = text;

      switch (type) {
        case "success":
          snackbar.snackbarColor = "#56a667";
          break;
        case "error":
          snackbar.snackbarColor = "#ec4c37";
          break;
        case "info":
          snackbar.snackbarColor = "#3c5ca7";
      }
      this.snackbar = snackbar;
    },
    async saveHistorico() {
      this.isLoading = true;
      let message,
        type = null;
      await this.service
        .addHistoricoIaVariavel(this.historicoIa)
        .then((res) => {
          if (res.status == 201) {
            message = "Informação salva com sucesso !";
            type = "success";
            this.isDialogOpen = false;
          } else {
            message = "Problemas ao salvar a informação, tente novamente !";
            type = "info";
          }
        })
        .catch((error) => {
          console.log(error);
          message =
            "Erro ao salvar a informação, tente novamente, se o problema persistir, contate a PREDIFY !";
          type = "error";
        })
        .finally(() => {
          this.isLoading = false;
          this.showSnackbar(message, type);

          // this.$store.commit("snackbarV2/set", { message, type });
        });
    },
  },
  async beforeMount() {
    await this.service.getHistoricoIaVariavel().then((res) => {
      this.historicoIa = res.data;
    });
  },
};
</script>
