<template>
  <div class="my-4">
    <div class="mb-4">
      <v-row
        no-gutters
        align="center"
        justify="space-between"
        class="grayLine mb-4"
      >
        <span class="font-weight-bold productCap">{{
          `${$t("TXT_PRODUCT")} ${index}`
        }}</span>
        <v-icon
          v-if="!disabledDeleteCap"
          color="red"
          size="30"
          @click="$emit('deleteCapture')"
        >
          mdi-close-box
        </v-icon>
      </v-row>
      <Internal-external-capture
        :productsFiltered="productsFiltered"
        :captureData="cap"
        @setIntExtCapData="$emit('setIntExtCapData', $event)"
      />
    </div>
    <div v-if="isUPL">
      <v-row no-gutters class="grayLine mb-4">
        <span class="font-weight-bold productCap">Produto UPL</span>
      </v-row>
      <Product-upl
        :productsUPL="productsUPL"
        :captureData="cap"
        @setUplCapData="$emit('setUplCapData', $event)"
      />
    </div>
  </div>
</template>

<script>
import InternalExternalCapture from "./components/internal-external-capture/internal-external-capture.vue";
import ProductUpl from "./components/product-upl/product-upl.vue";
export default {
  props: {
    isUPL: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
    },
    cap: {
      type: Object,
    },
    productsFiltered: {
      type: Array,
    },
    productsUPL: {
      type: Array,
    },
    disabledDeleteCap: {
      type: Boolean,
      default: false,
    },
  },
  components: { InternalExternalCapture, ProductUpl },
};
</script>

<style lang="scss" scoped>
.grayLine {
  border-bottom: 1px solid $neutral-color-low-light;
}
.productCap {
  border-bottom: 5px solid $brand-color-secondary-pure;
}
</style>