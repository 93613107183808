<template>
  <div style="display: flex; flex-direction: row" class="my-flex mt-6">
    <table style="width: 20%">
      <thead>
        <tr>
          &nbsp;
        </tr>
        <tr>
          &nbsp;
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="
              text-align: center;
              padding: 8px;
              font-weight: bold;
              background-color: #ccc;
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
            ">
            Captura externa
          </td>
        </tr>
        <tr>
          <td style="
              text-align: center;
              padding: 8px;
              font-weight: bold;
              background-color: #ccc;
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
            ">
            Captura interna
          </td>
        </tr>
        <tr>
          <td style="
              text-align: center;
              padding: 8px;
              font-weight: bold;
              background-color: #ccc;
              border-bottom-left-radius: 10px;
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
            ">
            Δ Cap. Ext. vs Int.
          </td>
        </tr>
      </tbody>
    </table>

    <!-- this.product.tipoPrecificacao == 'Custo' || -->
    <table v-if="this.product.tipoPrecificacao == 'Concorrente'
      " style="width: 80%">
      <thead style="margin-bottom: 4px !important">
        <tr>
          <td style="
              background-color: #3c5ca7;
              color: white;
              text-align: center;
              font-weight: bold;
              padding: 2px 0px 2px 0px;
              border-radius: 10px 10px 0px 0px;
            " colspan="3">
            {{
      this.product.capturas.length > 0
        ? this.product.capturas[0].nomeCaptura
        : this.product.nome
    }}
          </td>
        </tr>
        <tr>
          <td style="
              background-color: #ccc;
              font-weight: bold;
              text-align: center;
            ">
            BRL
          </td>
          <td style="
              background-color: #ccc;
              font-weight: bold;
              text-align: center;
            ">
            USD
          </td>
          <td style="
              background-color: #ccc;
              font-weight: bold;
              text-align: center;
            ">
            PESO
          </td>
        </tr>
      </thead>
      <tbody v-if="this.product.capturas.length > 0">
        <tr>
          <td style="text-align: center; padding: 8px">
            R$
            {{
      this.product.capturas[0].mediaCapturaExterna
        .toFixed(2)
        .replace(".", ",")
    }}
          </td>
          <td style="text-align: center; padding: 8px">
            $
            {{
        (this.product.capturas[0].mediaCapturaExterna / valorDolar)
          .toFixed(2)
          .replace(".", ",")
      }}
          </td>
          <td style="text-align: center; padding: 8px">
            {{ this.product.capturas[0].pesoCapturaExterna }}%
          </td>
        </tr>
        <tr>
          <td style="text-align: center; padding: 8px">
            R$
            {{
      this.product.capturas[0].mediaCapturaInterna
        .toFixed(2)
        .replace(".", ",")
    }}
          </td>
          <td style="text-align: center; padding: 8px">
            $
            {{
        (this.product.capturas[0].mediaCapturaInterna / valorDolar)
          .toFixed(2)
          .replace(".", ",")
      }}
          </td>
          <td style="text-align: center; padding: 8px">
            {{ this.product.capturas[0].pesoCapturaInterna }}%
          </td>
        </tr>
        <tr>
          <td style="text-align: center; padding: 8px">
            {{ getConcorrentePercantage() + "%" }}
          </td>
          <td style="text-align: center; padding: 8px">
            {{ getConcorrentePercantage() + "%" }}
          </td>
          <td style="text-align: center; padding: 8px"></td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td style="text-align: center; padding: 8px">
            {{ "R$" + (0).toFixed(2).replace(".", ",") }}
          </td>
          <td style="text-align: center; padding: 8px">
            {{ "$" + (0).toFixed(2).replace(".", ",") }}
          </td>
          <td style="text-align: center; padding: 8px">
            {{ (0).toFixed(2).replace(".", ",") }}%
          </td>
        </tr>
        <tr>
          <td style="text-align: center; padding: 8px">
            {{ "R$" + (0).toFixed(2).replace(".", ",") }}
          </td>
          <td style="text-align: center; padding: 8px">
            {{ "$" + (0).toFixed(2).replace(".", ",") }}
          </td>
          <td style="text-align: center; padding: 8px">
            {{ (0).toFixed(2).replace(".", ",") }}%
          </td>
        </tr>
        <tr>
          <td style="text-align: center; padding: 8px">
            {{ "R$" + (0).toFixed(2).replace(".", ",") }}
          </td>
          <td style="text-align: center; padding: 8px">
            {{ "$" + (0).toFixed(2).replace(".", ",") }}
          </td>
          <td style="text-align: center; padding: 8px">
            {{ (0).toFixed(2).replace(".", ",") }}%
          </td>
        </tr>
      </tbody>
    </table>

    <table v-else style="width: 80%">
      <thead>
        <tr>
          <td v-if="this.produtoInterno != null" style="
              background-color: #3c5ca7;
              color: white;
              text-align: center;
              font-weight: bold;
              padding: 2px 0px 2px 0px;
              border-radius: 10px 10px 0px 0px;
            " colspan="3">
            {{
      this.product.capturas[0].nomeCaptura +
      " + " +
      this.produtoInterno.nome
    }}
          </td>
          <td v-else style="
              background-color: #3c5ca7;
              color: white;
              text-align: center;
              font-weight: bold;
              padding: 2px 0px 2px 0px;
              border-radius: 10px 10px 0px 0px;
            " colspan="3">
            {{
        captureNames
      }}
          </td>
        </tr>
        <tr>
          <td style="
              background-color: #ccc;
              font-weight: bold;
              text-align: center;
              width: 33.33333333333333333333333%;
            ">
            BRL
          </td>
          <td style="
              background-color: #ccc;
              font-weight: bold;
              text-align: center;
              width: 33.33333333333333333333333%;
            ">
            USD
          </td>
          <td style="
              background-color: #ccc;
              font-weight: bold;
              text-align: center;
              width: 33.33333333333333333333333%;
            ">
            PESO
          </td>
        </tr>
      </thead>

      <tbody v-if="(this.product.capturas && this.product.capturas.length > 0) && this.product.tipoPrecificacao != 'Mistura Interna'  ">
        <tr>
          <td style="text-align: center; padding: 8px">
            {{ setCurrencyUPL(sumMediaCapturaExterna, false) }}
          </td>
          <td style="text-align: center; padding: 8px">
            {{ setCurrencyUPL(sumMediaCapturaExterna / valorDolar, true) }}
          </td>
          <td style="text-align: center; padding: 8px">
            {{ this.product.capturas[0].pesoCapturaExterna }}%
          </td>
        </tr>
        <tr>
          <td style="text-align: center; padding: 8px">
            {{ setCurrencyUPL(sumMediaCapturaInterna, false) }}
          </td>
          <td style="text-align: center; padding: 8px">
            {{ setCurrencyUPL(sumMediaCapturaInterna / valorDolar, true) }}
          </td>
          <td style="text-align: center; padding: 8px">
            {{ this.product.capturas[0].pesoCapturaInterna }}%
          </td>
        </tr>
        <tr>
          <td style="text-align: center; padding: 8px">{{ misturaPercentage + "%" }}</td>
          <td style="text-align: center; padding: 8px">{{ misturaPercentage + "%" }}</td>
          <td style="text-align: center; padding: 8px"></td>
        </tr>
      </tbody>

      <tbody v-if="this.product &&
      this.product.capturas[1] != null &&
      this.product.capturas[0].idProdutoUPL != null &&
      this.produtoInterno != null
      ">
        <tr>
          <td style="text-align: center; padding: 8px">
            {{
      setCurrencyUPL(
        this.product.capturas[0].mediaCapturaExterna +
        this.produtoInterno.precoQuilo,
        false
      )
    }}
          </td>
          <td style="text-align: center; padding: 8px">
            {{
        setCurrencyUPL(
          (this.product.capturas[0].mediaCapturaExterna +
            this.product.capturas[1].mediaCapturaExterna) /
          valorDolar,
          true
        )
      }}
          </td>
          <td style="text-align: center; padding: 8px">
            {{ this.product.capturas[0].pesoCapturaExterna }}%
          </td>
        </tr>
        <tr>
          <td style="text-align: center; padding: 8px">
            {{
      setCurrencyUPL(
        this.product.capturas[0].mediaCapturaInterna +
        this.product.capturas[1].mediaCapturaInterna,
        false
      )
    }}
          </td>
          <td style="text-align: center; padding: 8px">
            {{
        setCurrencyUPL(
          (this.product.capturas[0].mediaCapturaInterna +
            this.product.capturas[1].mediaCapturaInterna) /
          valorDolar,
          true
        )
      }}
          </td>
          <td style="text-align: center; padding: 8px">
            {{ this.product.capturas[0].pesoCapturaInterna }}%
          </td>
        </tr>
        <tr>
          <td style="text-align: center; padding: 8px">
            {{ getMisturaPercantage() + "%" }}
          </td>
          <td style="text-align: center; padding: 8px">
            {{ getMisturaPercantage() + "%" }}
          </td>
          <td style="text-align: center; padding: 8px"></td>
        </tr>
      </tbody>

      <tbody v-if="this.product.tipoPrecificacao == 'Mistura Interna'">
        <tr>
          <td style="text-align: center; padding: 8px">
            {{
      setCurrencyUPL(
        this.product.capturas[0].mediaCapturaExterna,
        false
      )
    }}
          </td>
          <td style="text-align: center; padding: 8px">
            {{
        setCurrencyUPL(
          this.product.capturas[0].mediaCapturaExterna / valorDolar,
          true
        )
      }}
          </td>
          <td style="text-align: center; padding: 8px">
            {{ this.product.capturas[0].pesoCapturaExterna }}%
          </td>
        </tr>
        <tr>
          <td style="text-align: center; padding: 8px">
            {{
      setCurrencyUPL(
        this.product.capturas[0].mediaCapturaInterna,
        false
      )
    }}
          </td>
          <td style="text-align: center; padding: 8px">
            {{
        setCurrencyUPL(
          this.product.capturas[0].mediaCapturaInterna / valorDolar,
          true
        )
      }}
          </td>
          <td style="text-align: center; padding: 8px">
            {{ this.product.capturas[0].pesoCapturaInterna }}%
          </td>
        </tr>
        <tr>
          <td style="text-align: center; padding: 8px">
            {{ getMisturaPercantage() + "%" }}
          </td>
          <td style="text-align: center; padding: 8px">
            {{ getMisturaPercantage() + "%" }}
          </td>
          <td style="text-align: center; padding: 8px"></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ServiceUPL from "@/service/upl/index";

export default {
  props: ["product"],
  data() {
    return {
      service: new ServiceUPL(),
      itemsUfBrandSemCap: [],
      produtoInterno: null,
      variaveisTributacao: null,
    };
  },
  computed: {
    captureNames() {
      return this.product.capturas.map((x) => x.nomeCaptura).join(" + ")
    },
    valorDolar() {
      return localStorage.getItem("valorDolar");
    },
    sumMediaCapturaExterna() {
      return this.product.capturas.reduce((acc, cap) => acc + cap.mediaCapturaExterna, 0);
    },
    sumMediaCapturaInterna() {
      return this.product.capturas.reduce((acc, cap) => acc + cap.mediaCapturaInterna, 0);
    },
    misturaPercentage() {
      let ratio = this.sumMediaCapturaExterna / this.sumMediaCapturaInterna;
      return ((ratio - 1) * 100).toFixed(0);
    }
  },
  methods: {
    calculateCapInt(val1, val2) {
      return val1 / val2 - 1;
    },
    getMisturaPercantage() {
      if (this.product.capturas[1] != null) {
        let val1 = (
          this.product.capturas[0].mediaCapturaExterna +
          this.product.capturas[1].mediaCapturaExterna
        ).toFixed(2);
        let val2 = (
          this.product.capturas[0].mediaCapturaInterna +
          this.product.capturas[1].mediaCapturaInterna
        ).toFixed(2);

        return ((val1 / val2 - 1).toFixed(2) * 100).toFixed(0);
      } else {
        let val1 = this.product.capturas[0].mediaCapturaExterna.toFixed(2);
        let val2 = this.product.capturas[0].mediaCapturaInterna.toFixed(2);
        const result = ((val1 / val2 - 1).toFixed(2) * 100).toFixed(0);
        return result;
      }
    },
    getConcorrentePercantage() {
      let val1 = this.product.capturas[0].mediaCapturaExterna.toFixed(2);
      let val2 = this.product.capturas[0].mediaCapturaInterna.toFixed(2);

      return ((val1 / val2 - 1).toFixed(2) * 100).toFixed(0);
    },
  setCurrencyUPL(value, useDollar) {
      if (value != null) {
        value = value.toString().replace(",", ".");
        let [integerPart, decimalPart] = value.split(".");
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        decimalPart = (decimalPart || "").padEnd(2, "0").substring(0, 2);
        const currency = useDollar ? "$" : "R$";
        return `${currency} ${integerPart},${decimalPart}`;
      }
      return "-";
    },
  },
  async beforeMount() {
    if (this.product?.capturas?.length > 0 && this.product.capturas[0].idProdutoUPL) {
      await this.service.getProductById(this.product.capturas[0].idProdutoUPL)
        .then((res) => {
          this.produtoInterno = res.data;
        });
    }
  },
};
</script>