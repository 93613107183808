<template>
  <div class="mt-10">
    <!-- CALENDAR BUTTON -->
    <div class="my-flex">
      <v-tabs v-model="tab" color="black" slider-color="#3c5ca7" background-color="transparent"
        class="group-filters mb-4 mr-4" height="25px">
        <v-tab class="mr-6">
          <h4>CALENDÁRIO PROMOCIONAL</h4>
        </v-tab>
        <v-tab v-if="idCompany == 3026">
          <h4>PRODUTOS TABELADOS</h4>
        </v-tab>
      </v-tabs>
      <new-action-dialog v-if="tab == 0" @listActions="getActions" :loading="promotionalCalendarLoading" />
    </div>

    <promotional-calendar-main v-show="tab == 0" :promotionalCalendarData="promotionalCalendarData"
      :promotionalCalendarLoading="promotionalCalendarLoading" :idCompany="idCompany" />
    <comparision-list-main v-show="tab == 1 && listedProducts" :list="listedProducts"
      :isLoading="promotionalCalendarLoading || isLoadingListedProducts" />


  </div>
</template>

<script>
import ComparisionListMain from './components/comparision-list/comparision-list-main.vue';
import newActionDialog from './components/promotional-calendar/components/new-action-dialog.vue';
import PromotionalCalendarMain from './components/promotional-calendar/promotional-calendar-main.vue';
export default {
  components: { newActionDialog, PromotionalCalendarMain, ComparisionListMain },
  props: ["promotionalCalendarData", "promotionalCalendarLoading", "listedProducts", "isLoadingListedProducts"],
  data() {
    return {
      tab: 0,
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  methods: {
    getActions() {
      this.$emit("updatePromotionalCalendar");
    },
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.group-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.281);
  display: flex;
}
</style>