export function formatDecimals(value, decimals) {
    if(value == 0) return '0.00'

    if (typeof value !== "number" && !Number.isNaN(value)) return '-';

    if (!decimals) decimals = 0

    const formated = value.toLocaleString('pt-BR', {
        maximumFractionDigits: decimals,
        minimumFractionDigits: decimals
    });

    return formated


}