<template>
  <v-card flat color="transparent">
    <h4>FILTROS</h4>
    <v-row no-gutters>
      <!-- DATA PICKER -->
      <v-col cols="12" sm="5" md="4">
        <span>
          <label v-t="'TXT_REFERENCE_PERIOD'"></label>
        </span>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          ref="menu"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              v-bind="attrs"
              v-on="on"
              :label="$t('TXT_SELECT_PERIOD')"
              :placeholder="$t('TXT_SELECT_PERIOD')"
              hide-details
              outlined
              single-line
              dense
              readonly
              class="input-datapicker mt-1"
            >
              <template v-slot:append>
                <v-icon class="append-calendar" color="white" small>
                  mdi-calendar</v-icon
                >
              </template>
            </v-text-field>
          </template>
          <!-- TODO - properly use user's locale -->
          <v-date-picker
            v-model="date"
            no-title
            scrollable
            range
            :min="minDay"
            :max="maxDay"
          >
            <v-spacer></v-spacer>

            <v-btn
              text
              color="error"
              @click="menu = false && dateRangeText == null"
              v-t="'TXT_CANCEL'"
            ></v-btn>
            <v-btn outlined color="primary" @click="setDate">Reset</v-btn>

            <v-btn
              color="primary"
              @click="
                () => {
                  $refs.menu.save(date);
                  $emit('dateSelection', this.date);
                }
              "
              v-t="'TXT_OK'"
            ></v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
    },
  },
  data() {
    return {
      // data picker vars
      menu: false,
      date: [],
      minDay: null,
      maxDay: null,
    };
  },
  computed: {
    dateRangeText() {
      return this.date.join("  ~  ");
    },
  },
  watch: {
    isOpen() {
      this.setDate();
    },
  },
  methods: {
    setDate() {
      this.minDay = null;
      this.maxDay = null;
      var startDay = new Date();
      startDay.setDate(startDay.getDate() - 6);
      this.minRange = startDay.toISOString().slice(0, 10);
      this.maxDay = new Date().toISOString().slice(0, 10);

      this.date[0] = this.minRange;
      this.date[1] = new Date().toISOString().slice(0, 10);
      this.$emit("dateSelection", this.date);
    },
  },
  created() {
    this.setDate();
  },
};
</script>

<style lang="scss" scoped>
.input-datapicker .v-input__app end-inner {
  margin-top: 0px !important;
}

.append-calendar {
  height: 40px;
  width: 40px;
  background: #3c5ca7;
  margin-right: -11px;
  border-radius: 0px 4px 4px 0px;
  color: white;
}
</style>