<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" sm="4">
        <prd-combo-box
          v-model="originalSelectedProduct"
          :title="$t('TXT_PRODUCT')"
          :placeholder="$t('TXT_SELECT')"
          :items="productsFiltered"
          @input="setTypeCaptureByPrices"
        />
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <span class="mr-2">{{ $t("TXT_TYPE_CAPTURE_USE") }}:</span>
      <v-radio-group row v-model="typeCapture" :disabled="disableFields">
        <v-radio :label="$t('TXT_BOTH')" :value="0" />
        <v-radio :label="$t('TXT_EXTERNAL')" :value="1" />
        <v-radio :label="$t('TXT_INTERNAL')" :value="2" />
      </v-radio-group>
    </v-row>
    <v-row no-gutters>
      <v-col
        cols="12"
        :sm="typeCapture == 0 ? 6 : 12"
        class="pr-2"
        v-show="typeCapture == 0 || typeCapture == 1"
      >
        <Capture-card
          :typeCaptureTitle="$t('TXT_EXTERNAL')"
          :typeCapture="typeCapture"
          :disableFields="disableFields"
          :peso="selectedProduct.pesoExt"
          :preco="selectedProduct.precoExt"
          :dose="selectedProduct.doseExt"
          @setPeso="setPeso('Ext', $event)"
          @setPreco="selectedProduct.precoExt = parseFloat($event)"
          @setDose="selectedProduct.doseExt = parseFloat($event)"
        />
      </v-col>
      <v-col
        cols="12"
        :sm="typeCapture == 0 ? 6 : 12"
        class="pl-2"
        v-show="typeCapture == 0 || typeCapture == 2"
      >
        <Capture-card
          :typeCaptureTitle="$t('TXT_INTERNAL')"
          :typeCapture="typeCapture"
          :disableFields="disableFields"
          :peso="selectedProduct.pesoInt"
          :preco="selectedProduct.precoInt"
          :dose="selectedProduct.doseInt"
          @setPeso="setPeso('Int', $event)"
          @setPreco="selectedProduct.precoInt = parseFloat($event)"
          @setDose="selectedProduct.doseInt = parseFloat($event)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";
import CaptureCard from "./capture-card.vue";
export default {
  components: { CaptureCard, PrdComboBox },
  props: {
    productsFiltered: {
      type: Array,
    },
    captureData: {
      type: Object,
    },
  },
  data() {
    return {
      typeCapture: 0,
      selectedProduct: {
        text: null,
        precoInt: null,
        doseInt: null,
        pesoInt: null,
        precoExt: null,
        doseExt: null,
        pesoExt: null,
      },
      originalSelectedProduct: null,
    };
  },
  computed: {
    disableFields() {
      return this.selectedProduct.text ? false : true;
    },
  },
  watch: {
    selectedProduct: {
      handler(value) {
        this.$emit("setIntExtCapData", value);
      },
      deep: true,
    },
    originalSelectedProduct: {
      handler(value) {
        if (value) {
          if (typeof value == "object") {
            this.selectedProduct = JSON.parse(JSON.stringify(value));
          } else
            this.$store.commit("snackbarV2/set", {
              message: this.$i18n.t("TXT_NON_EXISTENT_PRODUCT"),
              type: "info",
            });
        }
      },
      deep: true,
    },
    typeCapture: {
      handler(value) {
        this.setSelectedProducts(value);
      },
    },
    captureData: {
      handler(value) {
        this.selectedProduct.doseExt = value.doseCompetidor;
        this.selectedProduct.doseInt = value.doseCompetidor;
        this.selectedProduct.precoExt = value.mediaCapturaExterna;
        this.selectedProduct.precoInt = value.mediaCapturaInterna;
        this.selectedProduct.pesoExt = value.pesoCapturaExterna;
        this.selectedProduct.pesoInt = value.pesoCapturaInterna;
        this.selectedProduct.text = value.nomeCaptura;
        this.originalSelectedProduct = JSON.parse(
          JSON.stringify(this.selectedProduct)
        );
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setSelectedProducts(typeCapture) {
      this.selectedProduct = JSON.parse(
        JSON.stringify(this.originalSelectedProduct)
      );
      this.selectedProduct = {
        ...this.selectedProduct,
        pesoInt: 0,
        pesoExt: 0,
      };
      if (typeCapture == 1) {
        this.selectedProduct.pesoExt = 100;
      } else if (typeCapture == 2) {
        this.selectedProduct.pesoInt = 100;
      }
    },
    setPeso(type, value) {
      if (this.typeCapture != 0) return;

      if (type == "Ext") {
        if (isNaN(value) || value == null || value == undefined) {
          this.selectedProduct.pesoExt = 0;
          this.selectedProduct.pesoInt = 100;
        } else {
          this.selectedProduct.pesoInt = 100 - parseFloat(value);
        }
      } else if (type == "Int") {
        if (isNaN(value) || value == null || value == undefined) {
          this.selectedProduct.pesoExt = 100;
          this.selectedProduct.pesoInt = 0;
        } else {
          this.selectedProduct.pesoExt = 100 - parseFloat(value);
        }
      }
    },
    setTypeCaptureByPrices(product) {
      if (product.precoExt != 0 && product.precoInt != 0) this.typeCapture = 0;
      else if (product.precoExt == 0) this.typeCapture = 2;
      else this.typeCapture = 1;
    },
  },
};
</script>

<style>
</style>