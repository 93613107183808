<template>
  <v-row no-gutters align="center" justify="start" class="gap-4 mb-4">
    <v-progress-circular
      indeterminate
      :color="$prdStyles('color-primary')"
    ></v-progress-circular>
    <span>{{ `${text} ${$t("TXT_INFORMATIONS")}` }} </span>
  </v-row>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
    },
  },
};
</script>
