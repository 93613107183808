<template>
  <v-dialog v-model="isModalOpen" :max-width="1000" persistent>
    <v-card class="pa-4">
      <div class="text-center mt-6">
        <img
          style="width: 120px"
          src="@/assets/flags/GIF_LOGO_PREDIFY.gif"
          alt="GIF Description"
        />
      </div>
      <h2 class="text-center mb-6 mt-14">{{ this.$i18n.t(loadingText) }}</h2>

      <v-row no-gutters justify="center">
        <v-col cols="12" md="6">
          <v-progress-linear
            :color="$prdStyles('color-primary')"
            background-color="#f5f5f5"
            height="8px"
            :value="loadingValue"
            rounded
          ></v-progress-linear>
        </v-col>
      </v-row>

      <p class="text-center mt-6">{{ $t("TXT_MAY_TAKE_A_WHILE") }}</p>
      <p class="text-center pricing-warning">
        {{ this.$i18n.t("TXT_PREDIMONITOR_LOADING") }}
      </p>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["controlModal"],

  data() {
    return {
      isModalOpen: false,
      isLoading: false,
      loadingText: "TXT_LOADING_PRODUCTS",
      loadingList: [
        "TXT_LOADING_FETCHING_DATA",
        "TXT_LOADING_GETTING_DATA",
        "TXT_LOADING_PROCESSING_DATA",
        " ",
      ],
      loadingValue: 3,
      intervalId: null,
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  watch: {
    controlModal: {
      handler(value) {
        if (value) {
          this.isModalOpen = value;
          this.getStatus();
        } else {
          clearInterval(this.intervalId);
          this.isModalOpen = value;
          this.loadingValue = 3;
          this.loadingText = "TXT_LOADING_PRODUCTS";
        }
      },
    },
  },

  methods: {
    getStatus() {
      let index = 0;
      this.intervalId = setInterval(() => {
        if (index === this.loadingList.length - 1) {
          clearInterval(this.intervalId);
          this.loadingValue = 80;
        } else {
          this.loadingText = this.loadingList[index];
          this.loadingValue =
            ((index + 1) / (this.loadingList.length - 1)) * 80;
          index++;
        }
      }, 2000);
    },
    finishLoading() {
      this.loadingValue = 100;
      this.loadingText = "TXT_LOADING_LIST_PRODUCTS";
      setTimeout(() => {
        this.$emit("closeLoadingModal");
        clearInterval(this.intervalId);
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.pricing-warning {
  border: 0.5px solid $feedback-color-warning-pure;
  border-radius: 5px;
  color: $feedback-color-warning-pure;
  padding: 8px;
  margin-top: 140px;
}
</style>
