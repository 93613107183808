<template>
  <div class="mt-6">
    <!-- PRODUCT INFO -->
    <div>
      <h4>PRODUTOS ({{ totalRecords }})</h4>
      <v-row no-gutters align="center">
        <div class="info-product-box">
          <span class="info-product-reproved"
            >Preços reprovado pelas regras
          </span>
          <span class="info-product-approved"
            >Preços aprovado pelas regras
          </span>
          <span class="info-product-sistem">Preço aprovado pelo usuário </span>
        </div>
        <v-spacer></v-spacer>

        <prd-btn
          @click="extractReport"
          :title="'Extrair relatório'"
          :disabled="extractReportLoading"
          :loading="extractReportLoading"
          class="mt-7 mr-4"
        />

        <prd-combo-box
          v-model="selectedPages"
          :items="itemsPerPage"
          :title="'Resultados por página'"
        />
      </v-row>
    </div>

    <!-- PRODUCT PANEL -->
    <v-card class="pa-4 mt-6" :disabled="productListLoading">
      <v-expansion-panels>
        <product-panel
          v-for="(product, index) in products"
          :key="product.idEnterprisePricesProjection"
          :product="product"
          :index="index"
          class="mb-2"
          @attNewPricing="$emit('attNewPricing', $event)"
          @showSnackbar="showSnackbar"
        />
      </v-expansion-panels>
    </v-card>

    <erp-list-modal
      :idPricegroup="idPricegroup"
      @attWorfklowERP="$emit('attWorfklowERP')"
      @showSnackbar="showSnackbar"
    />

    <!-- MESSAGE COMPONENTS -->
    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.snackbarColor"
      :timeout="2000"
    >
      {{ snackbar.snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import ProductPanel from "./components/product-panel.vue";
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";
import ErpListModal from "@/views/main/new-pricing/components/erp-list-modal.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import service from "@/service/new-ai-pricing/search-filters.js";
const Service = new service();

export default {
  components: { ProductPanel, PrdComboBox, ErpListModal, PrdBtn },
  props: ["recordsPerPage", "products", "totalRecords", "productListLoading"],
  data() {
    return {
      itemsPerPage: [5, 10, 25, 50],

      snackbar: {
        snackbar: false,
        snackbarText: null,
        snackbarColor: "#3C5CA7",
      },

      extractReportLoading: false,
    };
  },

  computed: {
    selectedPages: {
      get() {
        return this.recordsPerPage;
      },
      set(newValue) {
        this.$emit("setRecordsPerPage", newValue);
      },
    },

    idPricegroup() {
      return this.products[0]?.idEnterprisePriceGroups ?? null;
    },

    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  methods: {
    showSnackbar(text, type) {
      let snackbar = {
        snackbar: true,
        snackbarColor: null,
        snackbarText: null,
      };

      snackbar.snackbarText = text;

      switch (type) {
        case "success":
          snackbar.snackbarColor = "#56a667";
          break;
        case "error":
          snackbar.snackbarColor = "#ec4c37";
          break;
        case "info":
          snackbar.snackbarColor = "#3c5ca7";
      }
      this.snackbar = snackbar;
    },

    extractReport() {
      this.extractReportLoading = true;

      let data = {
        idCompany: this.$store.getters.userData.idCompany,
        idEnterprisePriceGroups: this.idPricegroup,
        isSimulation: false,
      };

      Service.extractReportSAP(data)
        .then((res) => {
          var downloadLink = document.createElement("a");
          var blob = new Blob(["\ufeff", res.data]);
          var url = URL.createObjectURL(blob);
          downloadLink.href = url;
          downloadLink.download = "relatorio-pricing.csv";

          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);

          this.extractReportLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.showSnackbar("Erro ao gerar relatório");
          this.extractReportLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.info-product-box {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 4px;
  .info-product-reproved {
    padding: 0;
    border-left: 6px solid #b00020;
    padding-left: 4px;
  }
  .info-product-approved {
    padding: 0;
    border-left: 6px solid #1d8527;
    padding-left: 4px;
  }
  .info-product-sistem {
    padding: 0;
    border-left: 6px solid #3c5ca7;
    padding-left: 4px;
  }
}
</style>
    